import React from 'react';

function Smtp() {
    return (
        <>
            <h1>hello</h1>
        </>
    )
}
export default Smtp;
