import React, { useContext, useState } from "react";

import TextareaAutosize from "react-textarea-autosize";
import { DeleteOutline } from "@material-ui/icons";
import { Draggable } from "react-beautiful-dnd";
// import StoreApi from "./StoreApi";
import { useData } from "../../ContextProvider/Context";
import { Link } from "react-router-dom";



export default function Card({ card, index, listId, draggable }) {
  console.log(card);
  let number = card.id;
  let numberAsString = number.toString();
  const [open, setOpen] = useState(false);
  const [newTitle, setNewTitle] = useState(card.title);
  const { removeCard, updateCardTitle } = useData();

  const handleOnBlur = () => {
    updateCardTitle(newTitle, index, listId);
    setOpen(!open);
  };

  return (
    <Draggable isDragDisabled={draggable} draggableId={numberAsString} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          <div className="card-content">

            <div
              onClick={() => setOpen(!open)}
              className="card-title-container"
            >
              <p>
                <Link to={`/admin/task/${card.id}`} className='link_hover' style={{ textDecoration: "none",color:"black" }}>
                  {card.title}
                </Link>
              </p>
            </div>

          </div>
        </div>
      )}
    </Draggable>
  );
}
