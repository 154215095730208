import React, { useState } from 'react';
import logo from '../Images/logo-dark.png';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, TextField, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useData } from '../ContextProvider/Context';
import { useEffect } from 'react';
import ShowAlert from './ShowAlert';

function UserSignUp() {
  const { UserSignUp ,userSignUp,handleClick,setSnackbarValue,setuserSignUp} = useData()
  const params=useParams()
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleFormChange = () => {
    const isValid = username && email && password;
    setIsFormValid(isValid);
  };
  const navigate=useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
    name: username,
      email: email,
      password: password
    };
    console.log(formData);
    UserSignUp(formData);
    setUsername("")
    setEmail("")
    setPassword("")
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  useEffect(()=>{
    console.log(userSignUp);
    if(userSignUp){
      if(userSignUp?.errors){
        // if(data?.message=="The email has already been taken."){
          handleClick();
          setSnackbarValue({ message: userSignUp?.message, color: "#DC4C64" });
          setuserSignUp(null)
      // }
   
      }
      else{
        navigate(`/user-login/${params.id}`)
        setuserSignUp(null)
    }
    }
   
  },[userSignUp])

  return (
    <>
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="card">
            <div className="row align-items-center text-center">
              <div className="col-md-12">
                <div className="card-body">
                  <img src={logo} alt="" className="img-fluid mb-4" />
                  <h4 className="mb-3 f-w-400">Signup</h4>
                  <form onSubmit={handleSubmit} onChange={handleFormChange}>
                    <div className="form-group mb-3">
                      <Box
                        component="form"
                        sx={{
                          '& > :not(style)': { m: 0, width: '100%' },
                          '& label.MuiInputLabel-root': { fontSize: '14px' },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="username"
                          label="Full Name"
                          variant="standard"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          required
                        />
                      </Box>
                    </div>
                    <div className="form-group mb-3">
                      <Box
                        component="form"
                        sx={{
                          '& > :not(style)': { m: 0, width: '100%' },
                          '& label.MuiInputLabel-root': { fontSize: '14px' },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="email"
                          label="Email Address"
                          variant="standard"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </Box>
                    </div>
                    <div className="form-group mb-3">
                      <Box
                        component="form"
                        sx={{
                          '& > :not(style)': { m: 0, width: '100%' },
                          '& label.MuiInputLabel-root': { fontSize: '14px' },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="password"
                          label="Password Address"
                          variant="standard"
                          type={showPassword ? 'text' : 'password'}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </div>
                    <div className="custom-control custom-checkbox mb-4 mt-2" style={{ textAlign: "start", display: "flex", alignItems: "center" }}>
                      <input type="checkbox" className="custom-control-input" id="customCheck1" />
                      <label className="custom-control-label ms-2" htmlFor="customCheck1" style={{ color: "#666666", fontSize: "15px" }}>Send me the <Link style={{ textDecoration: "none", fontWeight: "600" }} to={"/"}>Newsletter</Link> weekly. </label>
                    </div>
                    <button
                      type="submit"
                      className={`btn btn-block btn-primary mb-4 w-100 has-ripple ${!isFormValid && 'disabled'}`}
                      style={{ borderRadius: "2px", fontSize: "15px", height: "43px", cursor: !isFormValid ? 'not-allowed' : 'pointer' }}
                      disabled={!isFormValid}
                    >
                      Signup
                    </button>
                  </form>
                  <p className="mb-0 text-muted" style={{ fontSize: "14px" }}>Already have an account? <Link to={`/user-login/${params.id}`} style={{ textDecoration: "none" }} className="f-w-400">Signin</Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ShowAlert/>
      </div>
    </>
  );
}

export default UserSignUp;
