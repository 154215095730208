import React, { useState, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import { useData } from '../../ContextProvider/Context';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function Email() {
    const { getEmailData, customEmailData, img_url } = useData();
    const [open, setOpen] = useState(false);
    const [selectedContent, setSelectedContent] = useState('');

    useEffect(() => {
        getEmailData();
    }, []);

    const handleOpen = (content) => {
        setSelectedContent(content);
        setOpen(true);
    };

    const handleClose = () => {
        setSelectedContent(''); // Clear the content when closing the modal
        setOpen(false);
    };

    return (
        <>
            <div className='emailModuleSection'>
                <div className='expense-header d-flex justify-content-between align-items-baseline'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Email</li>
                        </ol>
                    </nav>
                    <div className='inp-buttons d-flex gap-2'>
                        {/* <input placeholder='Search'></input> */}
                        {/* <button type="button" className="btn btn-primary">
                            <Link to={"/admin/create_email_template"} style={{ color: "white", textDecoration: "none" }}>
                                Create Template
                            </Link>
                        </button> */}
                        <button type="button" className="btn btn-primary">
                            <Link to={"/admin/create_email"} style={{ color: "white", textDecoration: "none" }}>
                                Compose
                            </Link>
                        </button>
                    </div>
                </div>
                <hr />
                <div className='card'>
                    <div className='card-body'>
                        <table className="table table-hover table-responsive">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Subject</th>
                                    <th scope="col">Message</th>
                                    <th scope="col">Attachment</th>
                                    <th scope="col">Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {customEmailData?.email?.map((item, ind) => {
                                    return (
                                        <tr key={ind}>
                                            <th scope="row">{ind + 1}</th>
                                            <td>{item.subject}</td>
                                            <td>
                                                <button onClick={() => handleOpen(item.content)} style={{ fontSize: "14px", border: "none", background: "none" }}>
                                                    View
                                                </button>
                                            </td>
                                            <td>
                                                <div style={{ width: '30px', height: '30px' }}>
                                                    <img style={{ width: '100%', height: '100%' }} src={img_url + item.attachment} alt='' />
                                                </div>
                                            </td>
                                            <td>{item.date}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} style={{ height: "600px", overflowY: "scroll" }} className="scrollBar">
                    <div className='emailContent' dangerouslySetInnerHTML={{ __html: selectedContent }} />
                </Box>
            </Modal>
        </>
    );
}

export default Email;
