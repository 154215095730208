import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useData } from '../ContextProvider/Context';
import { BsFillImageFill } from 'react-icons/bs';
import { CiTrash } from 'react-icons/ci';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
function Transfer() {
    const params = useParams()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open1 = Boolean(anchorEl);
    const handleClick1 = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl(null);
    };
    const { handleClick, setSnackbarValue, accountCom, getAccountCom, transferData, getTransferData, storeTransferData, updateTransferData, deleteTransferData, img_url, getAccountData, accountData, getFilterTransfer, base_url } = useData()
    useEffect(() => {
        getTransferData()
        getAccountData()
        getAccountCom()
    }, [])
    const [selectedCompany, setSelectedCompany] = useState(params.id || null)
    const filteredCompanies = accountCom?.company?.filter(item => item.deleteStatus === 0);
    const filteredAccounts = accountCom?.account?.filter(item =>
        item.company_id == (params.id ? params.id : selectedCompany)
    );
    const filteredAccounts1 = accountCom?.account?.filter(item =>
        item.company_id == (params.id ? params.id : selectedCompany)
    );

    const [open, setOpen] = React.useState(false);

    const handleOpen = (e) => {
        e.preventDefault()
        setOpen(true)
    };
    const handleClose = () => {
        setSelectedImage(null)
        setOpen(false)
    };
    const [selectedImage, setSelectedImage] = useState(null);
    const [showImageOverlay, setShowImageOverlay] = useState(false);
    const [selectedImageURL, setSelectedImageURL] = useState('');

    const [obj, setObj] = useState({
        company_id: params.id ? params.id : selectedCompany,
        from_account_id: '',
        to_account_id: '',
        amount: '',
        date: new Date().toISOString().split('T')[0],
        description: '',
        invoice: '',
    })
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64URL = event.target.result;
                const parts = base64URL.split(',');
                const base64ImageData = parts[1];
                setObj({
                    ...obj,
                    invoice: base64ImageData,
                });
                const imgBox = document.getElementById('image-box');
                imgBox.src = base64URL;
            };
            reader.readAsDataURL(file);
        }
    };
    const isFormValid = () => {
        for (const key in obj) {
            if (key !== "company_id" && obj[key] === '') {
                return false;
            }
        }
        return true;
    };
    const handleSubmit = (e) => {
        if (isFormValid()) {
            e.preventDefault();
            storeTransferData(obj)
            setObj({
                company_id: params.id ? params.id : selectedCompany,
                from_account_id: '',
                to_account_id: '',
                amount: '',
                date: new Date().toISOString().split('T')[0],
                description: '',
                invoice: '',
            })
            setSelectedImage(null)
            handleClose()
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }
    const handelTransferDelete = (id) => {
        deleteTransferData(id);
    }
    const [date, setdate] = useState({
        company: "",
        startDate: "",
        endddate: ""
    })
    const [selectedDescription, setSelectedDescription] = useState('');
    const [showDescriptionOverlay, setShowDescriptionOverlay] = useState(false);
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64URL = event.target.result;
                const parts = base64URL.split(",")[1];
                csvToJSON(parts)

            };
            reader.readAsDataURL(file);
        }
    };
    const csvToJSON = (csvData) => {
        fetch(`${base_url}importtransfer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',

            },
            body: JSON.stringify({ file: csvData }),
        })
            .then((response) => response.json())
            .then((data) => {
                getTransferData()
                handleClick();
                setSnackbarValue({ message: "File Add Succssfully", color: "#2ECC71" });
            })
            .catch((error) => {
                console.log('Error:', error)
            });

    };
    const downloadDemo = () => {
        const dataDemo = [{
            company_id: "Company Name",
            from_account_id: "From Account Number",
            to_account_id: "To Account Number",
            amount: "Amount",
            date: 'Date',
            description: "Description",
        }]

        const csv = Papa.unparse(dataDemo);

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'transfer.csv');
        handleClose1()
    }
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const visibleTransfers = params.id
        ? transferData?.transfer?.filter(items => items?.company_id?.id === params.id) || []
        : transferData?.transfer || [];

    const currentTransfers = visibleTransfers?.slice(indexOfFirstItem, indexOfLastItem) || [];

    const totalPages = Math.ceil(visibleTransfers.length / itemsPerPage);

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button key={i} className='btn btn-primary p-2' style={{ margin: "0px 5px" }} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    return (
        <div>
            <div classname="row " style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                <div className='inp-buttons d-flex gap-2 w-100' style={{ alignItems: "end", justifyContent: "space-between" }}>
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Transfer</li>
                            </ol>
                        </nav>
                    </div>
                    <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                        <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                            <div>
                                <label style={{ fontSize: "12px", fontWeight: "bold" }}>Company:</label> <br />
                                <select disabled={params.id ? true : false} className='form-select' onChange={(e) => setdate({ ...date, company: e.target.value })} >
                                    <option value="">Select a company</option>
                                    {filteredCompanies?.map((item) => (
                                        <option key={item.id} value={item.name}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='startdate'>
                                <label style={{ fontSize: "12px", fontWeight: "bold" }}>Select Start Date:</label> <br />
                                <input type='date' name='start' onChange={(e) => setdate({ ...date, startDate: e.target.value })} className='form-control' />
                            </div>
                            <div className='startdate'>
                                <label style={{ fontSize: "12px", fontWeight: "bold" }}>Select End Date:</label> <br />
                                <input type='date' name='end' onChange={(e) => setdate({ ...date, endddate: e.target.value })} className='form-control' />
                            </div>
                            <button style={{ padding: "8px 16px" }} onClick={() => {
                                handlePageChange(1)
                                getFilterTransfer(date.startDate, date.endddate, date.company)
                            }} className='btn btn-primary'>Search</button>

                        </div>
                        <div>
                            <button className='btn btn-primary' onClick={handleOpen} style={{ padding: "8px 16px", marginRight: "10px" }}>
                                Create Transfer
                            </button>
                            <button
                                id="basic-button"
                                className="btn btn-primary"
                                aria-controls={open1 ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open1 ? 'true' : undefined}
                                onClick={handleClick1}
                                style={{ padding: "8px 16px", width: "max-content" }}
                            >
                                Import Transfer
                            </button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open1}
                                onClose={handleClose1}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={downloadDemo}>Download Demo</MenuItem>
                                <MenuItem onClick={handleClose1}> <label for="csv_file" >Import File</label></MenuItem>

                            </Menu>
                            <input type='file' onChange={handleFileChange} style={{ display: "none" }} id='csv_file' />
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} style={{ width: "80%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", overflowY: "scroll" }} className='scrollBar'>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ width: "100%", textAlign: "left", marginBottom: "10px" }}>
                        Add
                    </Typography>
                    {obj.from_account_id == obj.to_account_id && obj.from_account_id !== "" && <p style={{ padding: "0", margin: "0", color: "red" }}>From Account and To Account are same!</p>}

                    <div>
                        <form>
                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <label>Select Company</label>
                                    <select value={selectedCompany || ''} disabled={params.id ? true : false} className='form-select' onChange={(e) => {
                                        setSelectedCompany(parseInt(e.target.value));
                                        setObj({
                                            ...obj,
                                            company_id: parseInt(e.target.value)
                                        });
                                    }}>
                                        <option value="">Select a company</option>
                                        {filteredCompanies?.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>

                                </div>
                                <div className='col-md-6 mb-3'>
                                    <div className="form-group">
                                        <label>From Account</label><br />
                                        <select className='form-select' onChange={(e) => setObj({ ...obj, from_account_id: e.target.value })}>
                                            <option value="">Select From Account</option>
                                            {filteredAccounts?.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.bank_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <div className="form-group">
                                        <label>To Account</label><br />
                                        <select className='form-select' onChange={(e) => setObj({ ...obj, to_account_id: e.target.value })}>
                                            <option value="">Select To Account</option>
                                            {filteredAccounts?.map((item) => {

                                                return item.id !== obj?.from_account_id ? <option key={item.id} value={item.id}>{item.bank_name}</option> : ""

                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <div className="form-group">
                                        <label>Amount</label>
                                        <input type="number" className="form-control" placeholder="Enter Amount" onChange={(e) => setObj({ ...obj, amount: e.target.value })} />
                                    </div>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <div className="form-group">
                                        <label>Data</label>
                                        <input type="date" className="form-control" placeholder="Select Date" onChange={(e) => setObj({ ...obj, date: e.target.value })} value={obj?.date} />
                                    </div>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <div className="form-group">
                                        <label>Description</label>
                                        <input type="text" className="form-control" placeholder="Enter Description" onChange={(e) => setObj({ ...obj, description: e.target.value })} />
                                    </div>
                                </div>
                                <div className='col-md-6 mb-3'>
                                    <div className='image-upload border p-1' style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                        <label for='inp'>
                                            <BsFillImageFill /> <span style={{ fontSize: '14px' }}>Screenshot</span>
                                        </label>
                                        <input
                                            id='inp'
                                            style={{ display: 'none' }}
                                            type='file'
                                            onChange={handleImageChange}
                                        />
                                        <img id="image-box" style={{ width: '30px', height: '30px' }} src={selectedImage ? URL.createObjectURL(selectedImage) : ''} alt="" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div style={{ width: "100%", textAlign: "end", gap: "10px" }}>
                        <button className='btn btn-danger' onClick={handleClose} style={{ padding: "8px 16px", marginRight: "10px" }}>Close</button>
                        <button disabled={obj.from_account_id == obj.to_account_id ? true : false} className='btn btn-primary' style={{ padding: "8px 16px" }} type='submit' onClick={handleSubmit}>Submit</button>
                    </div>
                </Box >

            </Modal>
            <hr />
            <div className='row'>
                <div>
                    <div className='card'>
                        <div className="card-body table-responsive recruitment">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Company</th>
                                        <th scope="col">From Bank Account</th>
                                        <th scope="col">To Bank Account</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Screenshot</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentTransfers.length > 0 ? (
                                        currentTransfers.map((items, indx) => {
                                            const truncatedDescription = items.description.slice(0, 20);
                                            const isDescriptionTruncated = items.description.length > 20;

                                            return (
                                                <tr key={indx}>
                                                    <th scope="row" style={{ paddingTop: "22px" }}>{indexOfFirstItem + indx + 1}</th>
                                                    <td scope="col" style={{ paddingTop: "22px" }}>{items.company_id?.name}</td>
                                                    <td scope="col" style={{ paddingTop: "22px" }}>{items.from_account_id?.bank_name}</td>
                                                    <td scope="col" style={{ paddingTop: "22px" }}>{items.to_account_id?.bank_name}</td>
                                                    <td scope="col" style={{ paddingTop: "22px" }}>{items.date ? items.date.split(" ")[0] : '-'}</td>
                                                    <td scope="col" style={{ paddingTop: "22px" }}>
                                                        {isDescriptionTruncated ? (
                                                            <span
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setSelectedDescription(items.description);
                                                                    setShowDescriptionOverlay(true);
                                                                }}
                                                            >
                                                                {truncatedDescription}...
                                                            </span>
                                                        ) : (
                                                            items.description
                                                        )}
                                                    </td>
                                                    <td style={{ display: "flex", justifyContent: "center", paddingTop: "22px" }}>
                                                        <div style={{ width: '30px', height: '30px' }}>
                                                            <img
                                                                style={{ width: '100%', height: '100%', cursor: "pointer" }}
                                                                src={img_url + items.invoice}
                                                                alt=''
                                                                onClick={() => {
                                                                    setSelectedImageURL(img_url + items.invoice);
                                                                    setShowImageOverlay(true);
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                    {showImageOverlay && (
                                                        <div className="overlayy" style={{ background: "rgba(0,0,0,0.38)" }} onClick={() => setShowImageOverlay(false)}>
                                                            <img
                                                                src={selectedImageURL}
                                                                alt="Full-Screen Image"
                                                                style={{ maxHeight: '100%', maxWidth: '100%' }}
                                                            />
                                                        </div>
                                                    )}
                                                    {showDescriptionOverlay && (
                                                        <div className="overlayy" style={{ background: "rgba(0,0,0,1)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                            <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                                <p style={{ color: "white" }}>{selectedDescription}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <td><span>{items.amount}</span></td>
                                                    <td scope="col">
                                                        <button className="btn btn-danger p-1" onClick={() => handelTransferDelete(items.id)}>
                                                            <CiTrash style={{ fontSize: "20px" }} />
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="9" style={{ textAlign: "center" }}>
                                                No Data
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <div>
                                {paginationControls.map((button, index) => (
                                    <React.Fragment key={index}>{button}</React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Transfer
