import React, { useContext, useState } from "react";
import { MoreVert } from "@material-ui/icons";
import ClickOutHandler from "react-onclickout";
import { useData } from "../../ContextProvider/Context";
import { useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

// import storeApi from "./StoreApi";
export default function Title({ title, listId }) {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen = () => {
    setNewTitle(title)
    setOpen1(true)};
  const handleClose = () => setOpen1(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const { updateListTitle, deleteList } = useData();
  const parms=useParams()
  const handleOnBlur = () => {
    updateListTitle(newTitle,listId,parms.id);
    setOpen(!open);
    handleClose()
    setNewTitle("")
  };

  return (
    <>
     
        <div className="editable-title-container">
          <h2  className="editable-title">
            {title}
          </h2>
          <button
            className="list-button"
            onClick={() => setOpenOptions(!openOptions)}
          >
            <MoreVert />
          </button>
          {openOptions && (
            <ClickOutHandler
              onClickOut={(e) => {
                setOpenOptions(!openOptions);
              }}
            >
              <ul className="menu-card">
                <li
                  onClick={() => {
                    setOpenOptions(!openOptions);
                    deleteList(listId,parms?.id);
                  }}
                >
                  Delete list
                </li>
                <li
                  onClick={() => {
                    handleOpen()
                    setOpen(!open);
                  }}
                >
                  Edit card title
                </li>
              </ul>
            </ClickOutHandler>
          )}
        </div>
    
       <Modal
        open={open1}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h6>Edit Title</h6>
          <input type="text" style={{padding:".3rem",border:"1px solid lightgray",borderRadius:"5px",width:"100%"}} value={newTitle} onChange={(e)=>setNewTitle(e.target.value)} />
          <div style={{padding:"1rem",display:"flex",justifyContent:"end"}}>
              <button onClick={handleClose} className="btn btn-primary mx-2">Close </button>
              <button onClick={handleOnBlur} className="btn btn-primary">Edit </button>
          </div>
        </Box> 
      </Modal>
    </>
  );
}
