import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { PieChart } from '@mui/x-charts/PieChart';

ChartJS.register(ArcElement, Tooltip, Legend);

function EmplyeePerformenes({ performance }) {
  const data = [
    { label: 'Attendance percentage', value: Math.floor(performance?.attendance_percentage) == 0 ? 100 : Math.floor(performance?.attendance_percentage) },
    { label: 'task completion percentage', value: Math.floor(performance?.task_completion_percentage) == 0 ? 100 : Math.floor(performance?.task_completion_percentage) },
  ];
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <PieChart
        series={[
          {
            paddingAngle: 5,
            innerRadius: 60,
            outerRadius: 80,
            data,
          },
        ]}
        margin={{ right: 5 }}
        width={200}
        height={200}
        legend={{ hidden: true }}
      />
    </div>
  )
}

export default EmplyeePerformenes
