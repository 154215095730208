import React, { useEffect, useState } from 'react'
import { useData } from '../../../ContextProvider/Context'
import { Link, useParams } from 'react-router-dom'
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { CiImport } from 'react-icons/ci'
function AccountView() {
    const params = useParams()
    const { getAccountIndividualData, accountIndividualData, getFilterAccountIndiData } = useData()
    useEffect(() => {
        getAccountIndividualData(params.id)
    }, [])
    const handleDownloadCsv = () => {
        var array = []
        accountIndividualData?.transactions?.map((item) => {
            let obj = {
                service: item.service_id?.name,
                client: item.client_id?.name ? item.client_id?.name : '-',
                supplier: item.supplier_id?.name ? item.supplier_id?.name : '-',
                date: item?.date ? item?.date : '-',
                description: item?.description ? item?.description : '-',
                debit: item?.debit ? item?.debit : '-',
                credit: item?.credit ? item?.credit : '-',
                balance: item?.balance ? item?.balance : '-'
            }
            array.push(obj)
        })
        const csv = Papa.unparse(array);

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'Accounts.csv');
    };
    const [date, setdate] = useState({
        id: params.id,
        startDate: "",
        endDate: ""
    });
    const itemsPerPage = 10;
    const totalPages = Math.ceil(accountIndividualData?.transactions?.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentTransactions = accountIndividualData?.transactions?.slice(indexOfFirstItem, indexOfLastItem) || [];

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button key={i} className='btn btn-primary p-2' style={{ margin: "0px 5px" }} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    return (
        <>
            <div classname="row " style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                            <li className="breadcrumb-item"><Link to={"/admin/accounts/banking/account"}>Accounts</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Detail</li>
                        </ol>
                    </nav>
                </div>
                <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                    <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                        <div className='startdate'>
                            <label style={{ fontWeight: "bold" }}>Select Start Date</label> <br />
                            <input type='date' name='start' onChange={(e) => setdate({ ...date, startDate: e.target.value })} className='form-control' />
                        </div>
                        <div className='startdate'>
                            <label style={{ fontWeight: "bold" }}>Select End Date</label> <br />
                            <input type='date' name='end' onChange={(e) => setdate({ ...date, endDate: e.target.value })} className='form-control' />
                        </div>
                        <button onClick={() => {
                            handlePageChange(1)
                            getFilterAccountIndiData(date.id, date.startDate, date.endDate)
                        }} className='btn btn-primary'>Search</button>
                    </div>
                    <div>
                        <button onClick={handleDownloadCsv} className='btn btn-primary' style={{ display: "flex", alignItems: "end", gap: "10px" }}><CiImport style={{ fontSize: "20px" }} />Export</button>
                    </div>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='card'>
                    <div className='card-body table-responsive recruitment'>
                        <div className='row mb-3' style={{ display: "flex", justifyContent: "center", alignItems: "end", flexDirection: "column" }}>
                            <div style={{ width: "max-content" }}>
                                <div className='card' style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "1rem" }}>
                                    <h3 style={{ fontSize: "13px", fontWeight: "bold" }}>Remaining Opening Balance</h3>
                                    <p style={{ fontSize: "15px", fontWeight: "bold", margin: "0" }}>{accountIndividualData?.account?.opening_balance?.toLocaleString()}</p>
                                </div>
                            </div>
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Service</th>
                                    <th scope="col">Client</th>
                                    <th scope="col">Supplier</th>
                                    <th scope="col" className='text-center'>Debit</th>
                                    <th scope="col" className='text-center'>Credit</th>
                                    <th scope="col">Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentTransactions.length > 0 ? (
                                    currentTransactions?.map((item, idx) => (
                                        <tr key={idx}>
                                            <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                            <td>{item.date ? item?.date?.split(" ")[0] : '-'}</td>
                                            <td>{item.description ? item.description : '-'}</td>
                                            <td>{item.service_id?.name ? item.service_id?.name : '-'}</td>
                                            <td>{item.client_id?.name ? item.client_id?.name : '-'}</td>
                                            <td>{item.supplier_id?.name ? item.supplier_id?.name : '-'}</td>
                                            <td className='text-center'><span style={{ padding: "5px", borderRadius: "30px", background: "green", color: "white" }}>{item.debit?.toLocaleString() ? item.debit?.toLocaleString() : '0'}</span></td>
                                            <td className='text-center'><span style={{ padding: "5px", borderRadius: "30px", background: "red", color: "white" }}>{item.credit?.toLocaleString() ? item.credit?.toLocaleString() : '0'}</span></td>
                                            <td>{item.balance?.toLocaleString() ? item.balance?.toLocaleString() : '0'}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="9" style={{ textAlign: "center" }}>
                                            No Data
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div>
                            {paginationControls.map((button, index) => (
                                <React.Fragment key={index}>{button}</React.Fragment>
                            ))}
                        </div>

                    </div>
                </div>
            </div >
        </>
    )
}

export default AccountView
