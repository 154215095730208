import React, { useState } from 'react'
import { BsCashCoin } from 'react-icons/bs'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useData } from '../ContextProvider/Context'
import { CiCalendarDate } from 'react-icons/ci';
import { useParams } from 'react-router-dom'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
function AddComition({ getBonus }) {
  const params = useParams()
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { base_url, handleClick, setSnackbarValue } = useData()
  const [bounsData, setBounsData] = useState({
    emp_id: params?.id,
    amount: "",
    month: ""
  })
  const isFormValid = () => {
    for (const key in bounsData) {
      if (bounsData[key] === '') {
        return false;
      }
    }
    return true;
  };
  const createBouns = () => {
    if(isFormValid()){

      fetch(`${base_url}commissions/upsert`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(bounsData),
      })
        .then((res) => res.json())
        .then((data) => {
          getBonus()
          handleClose()
          handleClick()
          setSnackbarValue({ message: "Commission Create successfully", color: "#2ECC71" });
        });
    }
    else{
      handleClick()
      setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
    }
  }
  return (
    <>
      <button type="button" className="btn btn-primary" onClick={handleOpen}>Add Commissions</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div id="modal-modal-description" sx={{ mt: 2 }}>
            <div className='container'>
              <div className='inputFields'>
                <div className='row'>
                  <div className='col-6'>
                    <div className='inputBox mt-3'>
                      <label htmlFor="dateInput"><CiCalendarDate /></label>
                      <input
                        id="dateInput"
                        onChange={(e) => {
                          setBounsData({ ...bounsData, month: e.target.value })
                        }}
                        placeholder='date' type='date'
                      ></input>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='inputBox mt-3'>
                      <label htmlFor="amountInput"><BsCashCoin /></label>
                      <input
                        id="amountInput"
                        onChange={(e) => {
                          setBounsData({ ...bounsData, amount: e.target.value })
                        }}
                        placeholder='Amount' type='number'
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-4'>
              <div className='modalBtns d-flex justify-content-end gap-2'>
                <button onClick={createBouns} className='btn btn-primary'>Add</button>
                <button onClick={handleClose} className='btn btn-secondary'>Cancel</button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>


    </>
  )
}

export default AddComition
