import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Tabs } from 'antd';
import { useData } from '../ContextProvider/Context';
import { CiLocationOn } from 'react-icons/ci';
import { CiUser, CiPhone, CiGlobe, CiClock2, CiMail, CiCalendar, CiLocationArrow1 } from 'react-icons/ci';
import { BsShield } from 'react-icons/bs';

function CompanyProfile() {
    const { companyProfile, getCompanyProfile, img_url } = useData();
    const params = useParams();
    useEffect(() => {
        getCompanyProfile(params.id)
    }, [])

    const created_at = companyProfile?.data?.created_at?.split('T')[0]
    return (
        <>
            <div className="pcoded-main-container employeeProfileData">
                <div className="pcoded-content">
                    <div className="user-profile user-card">
                        <div className="card-body py-0">
                            <div className="user-about-block m-0">
                                <div className="row" style={{ padding: "1rem" }}>
                                    <div className='col-2'>
                                        <div style={{ height: "150px", width: "150px", borderRadius: "5px", position: "relative", overflow: "hidden" }}>
                                            <img src={img_url + companyProfile?.data?.logo} alt="logo" style={{ height: "100%", width: "100%" }} />
                                        </div>
                                    </div>
                                    <div className='col-6' style={{ padding: "0 1rem" }}>
                                        <div style={{ borderBottom: "1px solid lightgray" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <h5 style={{ color: "#1677FF", fontWeight: "bold" }}>{companyProfile?.data?.name}</h5>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <h6 style={{ color: "gray", fontSize: "13px" }}><CiMail style={{ fontSize: "24px", marginRight: ".5rem", color: "#2e70bc" }} />{companyProfile?.data?.email}</h6>
                                                <h6 style={{ fontSize: "13px", color: "gray", borderRadius: "50px", padding: ".3rem 1rem", display: "flex", alignItems: "center" }}><CiCalendar style={{ fontSize: "24px", marginRight: ".5rem", color: "#2e70bc" }} />{created_at}</h6>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "1rem" }}>
                                            <h6 style={{ color: "gray", fontSize: "13px" }}> <CiLocationArrow1 style={{ fontSize: "24px", marginRight: ".5rem", color: "#2e70bc" }} />{companyProfile?.data?.trading_addr}</h6>
                                            <h6 style={{ color: "gray", fontSize: "13px" }}><CiLocationOn style={{ fontSize: "24px", marginRight: ".5rem", color: "#2e70bc" }} />{companyProfile?.data?.address}</h6>
                                            <h6 style={{ color: "gray", fontSize: "13px" }}><CiPhone style={{ fontSize: "24px", marginRight: ".5rem", color: "#2e70bc" }} />{companyProfile?.data?.phone}</h6>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "end", marginTop: "1rem" }}>
                                            <Link to={`/admin/dashboard / ${params.id}`} target='_blank'>
                                                <h6 style={{ color: "white", background: "#2e70bc", fontSize: "13px", border: "1px solid", borderRadius: "50px", padding: ".3rem 1rem" }} onClick={() => {
                                                    localStorage.setItem("company_id", params.id)
                                                }}>Detail</h6>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row employeeBody mt-3">
                        <div className='card'>
                            <div className='card-body'>
                                <div className="col-md-12 order-md-2">
                                    <Tabs
                                        defaultActiveKey="1"
                                        items={[
                                            {
                                                label: (
                                                    <span className='tabLabel'>
                                                        <CiUser style={{ marginRight: "5px", fontSize: "20px" }} />
                                                        Profile
                                                    </span>
                                                ),
                                                key: "1",
                                                children: (
                                                    <section>
                                                        <div className="container">
                                                            <div className='row'>
                                                                <div className="col-12">
                                                                    <div className="card mb-4">
                                                                        <div className='card-header bg-light' style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                                                                            <div><h5 style={{ fontWeight: "600", fontSize: "1.1rem" }}>Personal details</h5></div>

                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-sm-3">
                                                                                    <p className="mb-0" style={{ fontWeight: "600" }}>Name</p>
                                                                                </div>
                                                                                <div className="col-sm-9">
                                                                                    <p className="text-muted mb-0">{companyProfile?.data?.name}</p>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            <div className="row">
                                                                                <div className="col-sm-3">
                                                                                    <p className="mb-0" style={{ fontWeight: "600" }}>Location</p>
                                                                                </div>
                                                                                <div className="col-sm-9">
                                                                                    <p className="text-muted mb-0">{companyProfile?.data?.address}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className="col-12">
                                                                    <div className="card mb-4">
                                                                        <div className='card-header bg-light' style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                                                                            <div><h5 style={{ fontWeight: "600", fontSize: "1.1rem" }}>Contact details</h5></div>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-sm-3">
                                                                                    <p className="mb-0" style={{ fontWeight: "600" }}>Email Address</p>
                                                                                </div>
                                                                                <div className="col-sm-9">
                                                                                    <p className="text-muted mb-0">{companyProfile?.data?.email}</p>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            <div className="row">
                                                                                <div className="col-sm-3">
                                                                                    <p className="mb-0" style={{ fontWeight: "600" }}>Mobile Number</p>
                                                                                </div>
                                                                                <div className="col-sm-9">
                                                                                    <p className="text-muted mb-0">{companyProfile?.data?.phone}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </section>

                                                )
                                            },
                                            {
                                                label: (
                                                    <span className='tabLabel'>
                                                        <CiGlobe style={{ marginRight: "5px", fontSize: "20px" }} />
                                                        Social Channels
                                                    </span>
                                                ),
                                                key: "2",
                                                children: (
                                                    <section>
                                                        <div className='card'>
                                                            <div className='card-header bg-light' style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                                                                <div><h5 style={{ fontWeight: "600", fontSize: "1.1rem" }}>Social Chanel</h5></div>
                                                            </div>
                                                            <div className='card-body'>
                                                                <div className='container p-2'>
                                                                    <div className="row text-center">
                                                                        {companyProfile?.data?.social_links?.map((items) => {
                                                                            return (
                                                                                <div className="col-6 mb-3" key={items.id}>
                                                                                    <div className="bg-white rounded shadow-sm py-5 px-4" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                                                                        <div style={{ height: "50px", width: "50px", borderRadius: "50%", marginBottom: "15px" }}>
                                                                                            <img src={img_url + items.icon} alt="" width={'100%'} height={'100%'} style={{ borderRadius: "50%", marginBottom: "10px" }} />
                                                                                        </div>                                                                                        <h5 className="mb-0">
                                                                                            <Link target='_blank' style={{ color: "black", textDecoration: "none" }} to={`${items.link}`}>
                                                                                                {items.link?.slice(0, 25)}
                                                                                            </Link>
                                                                                        </h5>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </section>

                                                )
                                            },
                                            {
                                                label: (
                                                    <span className='tabLabel'>
                                                        <CiClock2 style={{ marginRight: "5px", fontSize: "20px" }} />
                                                        Timing
                                                    </span>
                                                ),
                                                key: "3",
                                                children: (
                                                    <div className='row'>
                                                        <div className="col-12">
                                                            <div className="card mb-4">
                                                                <div className='card-header bg-light' style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                                                                    <div><h5 style={{ fontWeight: "600", fontSize: "1.1rem" }}>Timings</h5></div>

                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <table className='table'>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">Days</th>
                                                                                    <th scope="col">Start Time</th>
                                                                                    <th scope="col">End Time</th>
                                                                                    <th scope="col">Total Time</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {companyProfile?.data?.timings?.map((items, index) => {
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td>{items.day}</td>
                                                                                            <td>{items.startTime ? items.startTime : '-'}</td>
                                                                                            <td>{items.endTime ? items.endTime : '-'}</td>
                                                                                            <td>{items?.totalTime}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                )
                                            },
                                            {
                                                label: (
                                                    <span className='tabLabel'>
                                                        <BsShield style={{ marginRight: "5px" }} />
                                                        Policies & TC
                                                    </span>
                                                ),
                                                key: "5",
                                                children: (
                                                    <div className='row'>
                                                        <div className="col-12">
                                                            <div className="card mb-4">
                                                                <div className='card-header bg-light' style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                                                                    <div><h5 style={{ fontWeight: "600", fontSize: "1.1rem" }}>Fine Policy</h5></div>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <table className='table'>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">Type</th>
                                                                                    <th scope="col">Time</th>
                                                                                    <th scope="col">Amount</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {companyProfile?.data?.fine_policy?.map((items, index) => {
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td>{items.type}</td>
                                                                                            <td>{items.time}</td>
                                                                                            <td>{items.fine}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                )
                                            },
                                            {
                                                label: (
                                                    <span className='tabLabel'>
                                                        <CiMail style={{ marginRight: "5px", fontSize: "20px" }} />
                                                        SMTP
                                                    </span>
                                                ),
                                                key: "6",
                                                children: (
                                                    <div className='row'>
                                                        <div className="col-12">
                                                            <div className="card mb-4">
                                                                <div className='card-header bg-light' style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                                                                    <div><h5 style={{ fontWeight: "600", fontSize: "1.1rem" }}>SMTP details</h5></div>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}> Mail Host Name</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{companyProfile?.data?.smtp[0]?.mail_host}</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}>Mail Port</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{companyProfile?.data?.smtp[0]?.mail_port}</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}>Mail User Name</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{companyProfile?.data?.smtp[0]?.mail_username}</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}>Mail Password</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{companyProfile?.data?.smtp[0]?.mail_password}</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}>Mail From Address</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{companyProfile?.data?.smtp[0]?.from_address}</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}>Mail From Name</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{companyProfile?.data?.smtp[0]?.from_name}</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}>Mailer</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{companyProfile?.data?.smtp[0]?.mail_mailer}</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}>Mail Encryption</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{companyProfile?.data?.smtp[0]?.mail_encryption}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            },
                                        ]}
                                    />

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default CompanyProfile
