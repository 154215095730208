import React, { useEffect } from 'react'
import { useData } from '../../../ContextProvider/Context'
import { Link, useParams } from 'react-router-dom'
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { CiImport } from 'react-icons/ci'

function BalanceSheet() {
    const params = useParams()
    const { getBalanceSheetData, balanceSheetData, } = useData()
    useEffect(() => {
        getBalanceSheetData(params.id)
    }, [])
    const handleDownloadCsv = () => {
        const csv = Papa.unparse([balanceSheetData]);

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'BalanceSheet.csv');
    };
    return (
        <div>
            <div className='services'>
                <div className='expense-header d-flex justify-content-between align-items-baseline'>
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Balance Sheet</li>
                            </ol>
                        </nav>
                    </div>
                    <div>
                        <button onClick={handleDownloadCsv} className='btn btn-primary' style={{ display: "flex", alignItems: "end", gap: "10px" }}><CiImport style={{ fontSize: "20px" }} />Export</button>
                    </div>
                </div>
                <hr />
                <div className='card'>
                    <div className='card-body'>
                        <table class="table w-100">
                            <thead style={{ textAlign: 'center' }}>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Amount</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                <tr>
                                    <td scope="col">Total Assets</td>
                                    <td>{balanceSheetData.totalAssets?.toLocaleString()}</td>
                                </tr>
                                <tr>
                                    <td scope="col">Total Equity</td>
                                    <td>{balanceSheetData.totalEquity?.toLocaleString()}</td>
                                </tr>
                                <tr>
                                    <td scope="col">Total Liabilities</td>
                                    <td>{balanceSheetData.totalLiabilities?.toLocaleString()}</td>
                                </tr>
                                <tr>
                                    <td scope="col">Total Equality Liability</td>
                                    <td>{balanceSheetData.totalEquityLiabilities?.toLocaleString()}</td>
                                </tr>
                            </tbody>
                        </table>
                        <hr />
                        <div className='row'>
                            <div className='col-12' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div>
                                    <h6 style={{ fontWeight: "bold" }}>Total Assets</h6>
                                </div>
                                <div>
                                    <h6>{balanceSheetData.totalAssets?.toLocaleString()}</h6>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div>
                                    <h6 style={{ fontWeight: "bold" }}>Total EqualityLiability</h6>
                                </div>
                                <div>
                                    <h6>{balanceSheetData.totalEquityLiabilities?.toLocaleString()}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BalanceSheet
