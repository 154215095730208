import React from 'react'

function Dashboard() {
    return (
        <div>
            <p className='leads' style={{ fontWeight: "bold", fontSize: "14px" }}>Dashboard</p>
        </div>
    )
}

export default Dashboard
