import React, { useEffect, useState } from 'react';
import { useData } from '../ContextProvider/Context';
import { Link, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Form } from 'antd';
import { CiTrash, CiEdit } from 'react-icons/ci';
import { Alert, Snackbar } from '@mui/material';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function Designation() {
  const params = useParams()
  const { handleClick, setSnackbarValue, company, deleteDesignation, editDesignation, designationData, departmentval, getdepartmetById, branch, getBarnchById, getdesignation, getCompnayCrud, storeDesignationData, permission } = useData();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setdesignationData({
      companyId: params.id ? params.id : "",
      branchId: "",
      departmentId: "",
      designation: "",
    })
  };
  useEffect(() => {
    getCompnayCrud()
    getdesignation()
  }, []);

  const [designation, setdesignationData] = useState({
    companyId: params.id ? params.id : "",
    branchId: "",
    departmentId: "",
    designation: "",
  })
  const state = {
    open: false,
    vertical: 'bottom',
    horizontal: 'right',
  }
  const { vertical, horizontal } = state;
  const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSubmit = () => {
    if (designation?.companyId?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Company is required.');
    } else if (designation?.branchId?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Branch is required.');
    } else if (designation?.departmentId?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Department is required.');
    } else if (designation?.designation?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Designation is required.');
    } else {
      storeDesignationData(designation)
      handleClose()
      setdesignationData({
        companyId: params.id ? params.id : "",
        branchId: "",
        departmentId: "",
        designation: "",
      })
    }
  }
  const handleUpdate = () => {
    if (designation?.title?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Designation is required.');
    } else {
      editDesignation(designation, designation?.id)
      handleClose()
      setdesignationData({
        companyId: params.id ? params.id : "",
        branchId: "",
        departmentId: "",
        designation: "",
      })
    }
  }

  const handleCloseSnackbar = () => {
    setAnyFieldEmpty(false);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = params.id
    ? designationData?.filter(item => item?.company?.id == params.id) || []
    : designationData || [];

  const totalPages = Math.ceil(currentItems.length / itemsPerPage);

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const paginationControls = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationControls.push(
      <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
        {i}
      </button>
    );
  }
  return (
    <>
      <div className='designationData'>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Designation</li>
              </ol>
            </nav>
          </div>
          <div className='d-flex justify-content-end mb-2 gap-2'>
            <div className='text-end'>
              {permission.includes("Designation Create") && <button className='btn btn-primary' onClick={handleOpen} style={{ padding: ".5rem 1rem" }}>Add</button>}
            </div>
          </div>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ padding: "1rem" }}
        >
          <Box sx={style} style={{ padding: "1rem" }}>
            <Form name="add_designation">
              <div className="row">
                <div className='col-md-6 mb-2'>
                  <div className="form-group">
                    <label htmlFor='companyyy'>Company<span style={{ color: "red" }}>*</span></label>
                    <select
                      id="selectCompany"
                      placeholder="Select Company"
                      style={{ width: '100%' }}
                      value={designation.companyId}
                      disabled={params.id ? true : false}
                      onChange={(e) => {
                        setdesignationData({ ...designation, companyId: e.target.value })
                        getBarnchById(e.target.value)
                      }
                      }
                      className='form-select'
                    >
                      <option hidden>Select Company</option>

                      {company?.data?.map((item) => (
                        <React.Fragment key={item.id}>
                          {item?.deleteStatus == 0 &&
                            <option value={item.id}>
                              {item.name}
                            </option>
                          }
                        </React.Fragment>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6 mb-2">
                  <div className="form-group">
                    <label htmlFor='branch'>Branch<span style={{ color: "red" }}>*</span></label>
                    <select
                      placeholder="Select Branch"
                      id="selectBranch"
                      style={{ width: '100%' }}
                      value={designation.branchId}
                      onChange={(e) => {
                        setdesignationData({ ...designation, branchId: e.target.value })
                        getdepartmetById(e.target.value)
                      }
                      }
                      disabled={!branch}
                      className='form-select'
                    >
                      <option hidden>Select Branch</option>
                      {branch?.data?.map((b) => (
                        <option key={b.id} value={b.id}>
                          {b.branch}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6 mb-2">
                  <div className="form-group">
                    <label htmlFor='department'>Department<span style={{ color: "red" }}>*</span></label>
                    <select
                      placeholder="Select Department"
                      style={{ width: '100%' }}
                      id="selectDepartment"
                      value={designation.departmentId}
                      onChange={(e) => {
                        setdesignationData({ ...designation, departmentId: e.target.value })
                      }
                      }
                      disabled={!branch}
                      className='form-select'
                    >
                      <option hidden>Select Department</option>

                      {departmentval?.map((b) => (

                        <option value={b.id} key={b.id}>
                          {b.department}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6 mb-2">
                  <div className="form-group">
                    <label htmlFor='designation'>Designation<span style={{ color: "red" }}>*</span></label>
                    <input
                      type='text'
                      id="designationtitle"
                      label="Designation Title"
                      variant="standard"
                      placeholder='Add Designation'
                      value={designation.title}
                      onChange={(e) => setdesignationData({ ...designation, designation: e.target.value })}
                      className='form-control'
                    />
                  </div>
                </div>
              </div>
              <Form.Item>
                <button className='btn btn-primary mt-3' type="primary" onClick={() => {
                  designation?.id ? handleUpdate() : handleSubmit()
                }}>
                  {designation?.id ? 'Update' : 'Submit'}
                </button>
              </Form.Item>
            </Form>
          </Box>
        </Modal>
        <hr />
        <div className='row'>
          <div>
            <div className='card'>
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Designation</th>
                      <th scope="col">Department</th>
                      <th scope="col">Branch</th>
                      <th scope="col">Company</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {params.id ? currentItems
                      .slice(indexOfFirstItem, indexOfLastItem)?.map((item, idx) => {
                        return (
                          <>
                            {item?.company?.id == params.id && <tr key={item.id}>
                              <th scope="row">{indexOfFirstItem + idx + 1}</th>
                              <td>
                                <Link className='link linkk' >
                                  {item.title}
                                </Link>
                              </td>
                              <td>
                                {item?.department?.department}
                              </td>
                              <td>
                                {item?.branch?.branch}
                              </td>
                              <td>
                                {item?.company?.name}
                              </td>
                              <td>
                                {permission.includes("Designation Edit") && <button

                                  className='btn btn-primary p-1 m-2'
                                  onClick={() => {
                                    handleOpen()
                                    setdesignationData(item)
                                  }}
                                >
                                  <CiEdit style={{ fontSize: "20px" }} />
                                </button>}

                                {permission.includes("Designation Delete") && <button
                                  className='btn btn-danger p-1 m-2'
                                  onClick={() => deleteDesignation(item.id)}
                                >
                                  <CiTrash style={{ fontSize: "20px" }} />
                                </button>}
                              </td>
                            </tr>}
                          </>

                        )
                      }) :
                      currentItems
                        .slice(indexOfFirstItem, indexOfLastItem)?.map((item, idx) => {
                          return (
                            <tr key={item.id}>
                              <th scope="row">{indexOfFirstItem + idx + 1}</th>
                              <td>
                                <Link className='link linkk' >
                                  {item.title}
                                </Link>
                              </td>
                              <td>
                                {item?.department?.department}
                              </td>
                              <td>
                                {item?.branch?.branch}
                              </td>
                              <td>
                                {item?.company?.name}
                              </td>
                              <td>
                                {permission.includes("Designation Edit") && <button

                                  className='btn btn-primary p-1 m-2'
                                  onClick={() => {
                                    handleOpen()
                                    setdesignationData(item)
                                  }}
                                >
                                  <CiEdit style={{ fontSize: "20px" }} />
                                </button>}

                                {permission.includes("Designation Delete") && <button
                                  className='btn btn-danger p-1 m-2'
                                  onClick={() => deleteDesignation(item.id)}
                                >
                                  <CiTrash style={{ fontSize: "20px" }} />
                                </button>}
                              </td>
                            </tr>
                          )
                        })
                    }
                  </tbody>
                </table>
                <div className="pagination-controls">{paginationControls}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={anyFieldEmpty}
        autoHideDuration={2000}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          severity="error"
          style={{
            backgroundColor: '#8f2018',
            color: "white",
          }}
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
export default Designation;
