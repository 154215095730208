import React, { useEffect, useState } from 'react'
import { BiUser, BiMessageDetail } from 'react-icons/bi'
import { TbFileInvoice } from 'react-icons/tb'
import { MdOutlineIndeterminateCheckBox } from 'react-icons/md'
import { RiAccountPinCircleLine } from 'react-icons/ri'
import { BsFillImageFill } from 'react-icons/bs'
import { GrServices } from 'react-icons/gr'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useData } from '../../../ContextProvider/Context'
import { Link, useParams } from 'react-router-dom'
import { Alert, Snackbar } from '@mui/material';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


function Services() {
    const params = useParams()
    const { handleClick, setSnackbarValue, accountsData, getAccountsData, getInvoiceData, invoiceData, img_url, storeInvoiceData, getInvoiceDetailData, invoiceDetailData, getFilterInvoice, postPaidStatus } = useData()
    useEffect(() => {
        getInvoiceData()
        getInvoiceDetailData()
        getAccountsData()
    }, [])
    const [selectedCompany, setSelectedCompany] = useState(params.id || null)
    const filteredCompanies = accountsData?.company?.filter(item => item.deleteStatus === 0);
    const filteredService = accountsData?.services?.filter(item => item.company_id == (params.id ? params.id : selectedCompany));
    const filteredClient = accountsData?.client?.filter(item => item.company_id == (params.id ? params.id : selectedCompany));
    const filteredAccount = accountsData?.account?.filter(item => item.company_id == (params.id ? params.id : selectedCompany));

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setObj({
            company_id: params.id ? params.id : selectedCompany,
            client_id: "",
            service_id: "",
            account_id: "",
            invoice_date: new Date().toISOString().substr(0, 10),
            due_date: "",
            description: "",
            message: "",
            invoice_no: "",
            term: "",
            image: ""
        });
        setSelectedImage(null);
        setOpen(false)
    };
    const [obj, setObj] = useState(
        {
            company_id: params.id ? params.id : selectedCompany,
            client_id: "",
            service_id: "",
            account_id: "",
            invoice_date: new Date().toISOString().substr(0, 10),
            due_date: "",
            description: "",
            message: "",
            invoice_no: "",
            term: "",
            image: ""
        }
    )

    const [selectedImage, setSelectedImage] = useState(null);
    const [showImageOverlay, setShowImageOverlay] = useState(false);
    const [selectedImageURL, setSelectedImageURL] = useState('');


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64URL = event.target.result;
                const parts = base64URL.split(',');
                const base64ImageData = parts[1];
                setObj({
                    ...obj,
                    image: base64ImageData,
                });
                const imgBox = document.getElementById('image-box');
                imgBox.src = base64URL;
            };
            reader.readAsDataURL(file);
        }
    };
    const clearFields = () => {
        setObj({
            company_id: params.id ? params.id : selectedCompany,
            client_id: "",
            service_id: "",
            account_id: "",
            invoice_date: new Date().toISOString().substr(0, 10),
            due_date: "",
            description: "",
            message: "",
            invoice_no: "",
            term: "",
            image: ""
        });

        setSelectedImage(null);
    };
    const isFormValid = () => {
        for (const key in obj) {
            if (obj[key] === '') {
                return false;
            }
        }
        return true;
    };
    const state = {
        open: false,
        vertical: 'bottom',
        horizontal: 'right',
    }
    const { vertical, horizontal } = state;
      const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
      const [snackbarMessage, setSnackbarMessage] = useState('');
    const handleSubmit = (e) => {
        e && e.preventDefault();
        if (!obj?.company_id) {
          setAnyFieldEmpty(true);
          setSnackbarMessage("Company is required.");
        } else if (!obj?.account_id) {
          setAnyFieldEmpty(true);
          setSnackbarMessage("account_id is required.");
        } else if (!obj?.service_id) {
          setAnyFieldEmpty(true);
          setSnackbarMessage("service_id is Required.");
        } else if (!obj?.image) {
          setAnyFieldEmpty(true);
          setSnackbarMessage("image is required.");
        } else if (!obj?.description) {
          setAnyFieldEmpty(true);
          setSnackbarMessage("description is required.");
        } 
        else if (!obj?.invoice_no) {
            setAnyFieldEmpty(true);
            setSnackbarMessage("invoice_no is required.");
          } 
          else if (!obj?.invoice_date) {
            setAnyFieldEmpty(true);
            setSnackbarMessage("invoice_date is required.");
          } 
          else if (!obj?.client_id) {
            setAnyFieldEmpty(true);
            setSnackbarMessage("client_id is required.");
          } 
          else if (!obj?.due_date) {
            setAnyFieldEmpty(true);
            setSnackbarMessage("due_date is required.");
          } 
      
         
           
        else {
          handleClose();
          storeInvoiceData(obj);
          setObj({
            company_id: params.id ? params.id : selectedCompany,
            client_id: "",
            service_id: "",
            account_id: "",
            invoice_date: new Date().toISOString().substr(0, 10),
            due_date: "",
            description: "",
            message: "",
            invoice_no: "",
            term: "",
            image: ""
          });
        }
      };
      const handleCloseSnackbar = () => {
        setAnyFieldEmpty(false);
    };
            
        
    const [service, setService] = useState([])
    const [client, setClient] = useState([])
    const clientAddress = client[0]?.bill_address + " " + client[0]?.bill_city + " " + client[0]?.bill_country + " " + client[0]?.bill_province + " " + client[0]?.bill_postal

    const [date, setdate] = useState({
        company: '',
        startDate: "",
        endDate: ""
    });


    const handleTermChange = (e) => {
        const selectedTerm = parseInt(e.target.value);
        if (selectedTerm >= 0) {
            if (selectedTerm === 0) {
                const currentDate = new Date();
                const formattedDueDate = currentDate.toISOString().substr(0, 10);

                setObj({
                    ...obj,
                    term: selectedTerm,
                    due_date: formattedDueDate,
                });
            } else {
                const currentDate = new Date();
                currentDate.setDate(currentDate.getDate() + selectedTerm);

                const formattedDueDate = currentDate.toISOString().substr(0, 10);

                setObj({
                    ...obj,
                    term: selectedTerm,
                    due_date: formattedDueDate,
                });
            }
        }
    };
    const [selectedDescription, setSelectedDescription] = useState('');
    const [showDescriptionOverlay, setShowDescriptionOverlay] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const visibleInvoices = params.id
        ? invoiceData?.invoices?.filter(item => item?.company?.id == params.id) || []
        : invoiceData?.invoices || [];

    const currentInvoices = visibleInvoices?.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(visibleInvoices.length / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }

    return (
        <>
            <div className='services'>
                <div className='expense-header d-flex justify-content-between align-items-baseline'>

                    <div className='inp-buttons d-flex gap-2 w-100' style={{ alignItems: "end", justifyContent: "space-between" }}>
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Invoice</li>
                                </ol>
                            </nav>
                        </div>
                        <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                            <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                                <div>
                                    <label style={{ fontWeight: "bold" }}>Company</label> <br />
                                    <select disabled={params.id ? true : false} className='form-select' onChange={(e) => setdate({ ...date, company: e.target.value })}>
                                        <option value="">Select a company</option>
                                        {filteredCompanies?.map((item) => (
                                            <option key={item.id} value={item.name}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='startdate'>
                                    <label style={{ fontWeight: "bold" }}>Invoice Start Date</label> <br />
                                    <input type='date' name='start' onChange={(e) => setdate({ ...date, startDate: e.target.value })} className='form-control' />
                                </div>
                                <div className='startdate'>
                                    <label style={{ fontWeight: "bold" }}>Invoice End Date</label> <br />
                                    <input type='date' name='end' onChange={(e) => setdate({ ...date, endDate: e.target.value })} className='form-control' />
                                </div>
                                <button onClick={() => {
                                    handlePageChange(1)
                                    getFilterInvoice(date.startDate, date.endDate, date.company)
                                }} className='btn btn-primary'>Search</button>
                            </div>
                            <button type="button" className="btn btn-primary" onClick={handleOpen}>Add</button>
                        </div>
                    </div>

                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"

                    >
                        <Box sx={style} style={{
                            height: '600px',
                            overflow: 'scroll',
                            paddingTop: '20px'
                        }} className='scrollBar'>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Add
                            </Typography>
                            <div id="modal-modal-description" sx={{ mt: 2 }}>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className='inputFields'>
                                            <label>Company</label>
                                            <div className='selectBox'>
                                                <select disabled={params.id ? true : false} value={obj?.company_id} onChange={(e) => {
                                                    setSelectedCompany(parseInt(e.target.value));
                                                    setObj({
                                                        ...obj,
                                                        company_id: parseInt(e.target.value)
                                                    });
                                                }}>
                                                    <option value="">Select a company</option>
                                                    {filteredCompanies?.map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <label>Service</label>
                                            <div className='  selectBox'>
                                                <label><GrServices /></label>
                                                <select onChange={(e) => {
                                                    setObj({ ...obj, service_id: e.target.value })
                                                    let data = invoiceDetailData?.service?.filter(service => service.id == e.target.value)
                                                    setService(data)
                                                }}>
                                                    <option value="">Select Service</option>
                                                    {selectedCompany && (
                                                        <>
                                                            {filteredService?.map((item) => (
                                                                <option key={item.id} value={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                        </>
                                                    )}
                                                </select>
                                            </div>
                                            <label>Description</label>
                                            <div className='inputBox ' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <label style={{ fontSize: '14px' }}><MdOutlineIndeterminateCheckBox /></label>
                                                {/* <input
                                                    placeholder='Service Description'
                                                    style={{
                                                        fontSize: '14px'
                                                    }}
                                                /> */}
                                                <div style={{ width: "100%", overflowY: "scroll", height: "30px", top: "0px", background: "white" }} className='invoce_Extra'>
                                                    <p>{service[0]?.description}</p>
                                                </div>
                                            </div>
                                            <label>Amount</label>
                                            <div className='inputBox ' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <label style={{ fontSize: '14px' }}><MdOutlineIndeterminateCheckBox /></label>
                                                {/* <input
                                                    placeholder='Services Amount'
                                                    style={{
                                                        fontSize: '14px'
                                                    }}
                                                /> */}
                                                <div style={{ width: "100%", overflowY: "scroll", height: "30px", top: "0px", background: "white" }} className='invoce_Extra'>
                                                    <p>{service[0]?.amount}</p>
                                                </div>
                                            </div>
                                            <label>Tax</label>
                                            <div className='inputBox ' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <label style={{ fontSize: '14px' }}><MdOutlineIndeterminateCheckBox /></label>
                                                {/* <input
                                                    placeholder='Services Tax'
                                                    style={{
                                                        fontSize: '14px'
                                                    }}
                                                /> */}
                                                <div style={{ width: "100%", overflowY: "scroll", height: "30px", top: "0px", background: "white" }} className='invoce_Extra'>
                                                    <p>{service[0]?.tax}</p>
                                                </div>
                                            </div>
                                            <label>Customer</label>
                                            <div className='  selectBox'>
                                                <label><BiUser /></label>
                                                <select onChange={(e) => {
                                                    setObj({ ...obj, client_id: e.target.value })
                                                    let clientData = invoiceDetailData?.client?.filter(client => client.id == e.target.value)
                                                    setClient(clientData)
                                                }
                                                }>
                                                    <option value="">Customer</option>
                                                    {selectedCompany && (
                                                        <>
                                                            {filteredClient?.map((item) => (
                                                                <option key={item.id} value={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                        </>
                                                    )}
                                                </select>
                                            </div>
                                            <label>Email</label>
                                            <div className='inputBox ' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <label style={{ fontSize: '14px' }}><MdOutlineIndeterminateCheckBox /></label>
                                                {/* <input
                                                    placeholder='Client Email'
                                                    style={{
                                                        fontSize: '14px'
                                                    }}
                                                /> */}
                                                <div style={{ width: "100%", overflowY: "scroll", height: "30px", top: "0px", background: "white" }} className='invoce_Extra'>
                                                    <p>{client[0]?.email}</p>
                                                </div>
                                            </div>
                                            <label>Address</label>
                                            <div className='inputBox ' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <label style={{ fontSize: '14px' }}><MdOutlineIndeterminateCheckBox /></label>
                                                {/* <input
                                                    placeholder='Client Address'
                                                    style={{
                                                        fontSize: '14px'
                                                    }}
                                                /> */}
                                                <div style={{ width: "100%", overflowY: "scroll", height: "30px", top: "0px", background: "white" }} className='invoce_Extra'>
                                                    <p>{clientAddress ? clientAddress : 'N/A'}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='inputFields'>
                                            <label>Account</label>
                                            <div className='  selectBox'>
                                                <label><RiAccountPinCircleLine /></label>
                                                <select onChange={(e) => setObj({ ...obj, account_id: e.target.value })}>
                                                    <option value="">Account</option>
                                                    {selectedCompany && (
                                                        <>
                                                            {filteredAccount?.map((item) => (
                                                                <option key={item.id} value={item.id}>
                                                                    {item.bank_name}
                                                                </option>
                                                            ))}
                                                        </>
                                                    )}
                                                </select>
                                            </div>
                                            <label>Screenshot</label>
                                            <div className='inputBox '>
                                                <div className='image-upload' style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                                    <label for='inp'>
                                                        <BsFillImageFill /> <span style={{ fontSize: '14px' }}>Screenshot</span>
                                                    </label>
                                                    <input
                                                        id='inp'
                                                        style={{ display: 'none' }}
                                                        type='file'
                                                        onChange={handleImageChange}
                                                    />
                                                    {selectedImage && <img id="image-box" style={{ width: '30px', height: '30px' }} src={selectedImage ? URL.createObjectURL(selectedImage) : ''} alt="" />}
                                                </div>
                                            </div>
                                            <label>Invoice No.</label>
                                            <div className='inputBox '>
                                                <label><TbFileInvoice /></label>
                                                <input placeholder='Invoice No.' onChange={(e) => setObj({ ...obj, invoice_no: e.target.value })} />
                                            </div>
                                            <label>Terms</label>
                                            <div className='inputBox '>
                                                <label><MdOutlineIndeterminateCheckBox /></label>
                                                <select className='form-select' onChange={handleTermChange}>
                                                    <option hidden>Select Terms</option>
                                                    <option value={''}>Days</option>
                                                    <option value={3}>Net Three Days</option>
                                                    <option value={7}>Net Seven Days</option>
                                                    <option value={10}>Net Ten Days</option>
                                                    <option value={15}>Net Fifteen Days</option>
                                                    <option value={30}>Net Thirty Days</option>
                                                </select>
                                            </div>

                                            <label className=''>Invoice Date</label>
                                            <div className='inputBox mt-1'>
                                                <input
                                                    type='date'
                                                    placeholder='Invoice Date'
                                                    value={new Date().toISOString().substr(0, 10)}

                                                />
                                            </div>

                                            <label className=''>Due Date</label>
                                            <div className='inputBox mt-1'>
                                                <input
                                                    type='date'
                                                    placeholder='Due Date'
                                                    value={obj.due_date}
                                                    min={obj?.invoice_date}
                                                    onChange={(e) => setObj({ ...obj, due_date: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-6'>
                                        <div className='textarea'>
                                            <label><BiMessageDetail /></label>
                                            <textarea placeholder='Description' onChange={(e) => setObj({ ...obj, description: e.target.value })}></textarea>
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='textarea'>
                                            <label><BiMessageDetail /></label>
                                            <textarea placeholder='Message' onChange={(e) => setObj({ ...obj, message: e.target.value })}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='modalBtns d-flex justify-content-end gap-2'>
                                        <button className='btn btn-primary' onClick={handleSubmit}>Submit</button>
                                        <button className='btn btn-secondary' onClick={handleClose}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                </div>
                <hr />
                <div className='card'>
                    <div className='card-body table-responsive recruitment'>
                        <table class="table">
                            <thead style={{ textAlign: 'center' }}>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Invoice Date</th>
                                    <th scope="col">Due Date </th>
                                    <th scope="col">Customer</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Screenshot</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                {params?.id ? currentInvoices?.map((item, idx) => {
                                    const truncatedDescription = item.description.slice(0, 20);
                                    const isDescriptionTruncated = item.description.length > 20;

                                    return (
                                        <>
                                            {item?.company?.id == params?.id && <tr key={item.id}>
                                                <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                <td>{item.company?.name}</td>
                                                <td>
                                                    <Link to={`/admin/accounts/banking/Invoice/${item.id}`} style={{ textDecoration: "none", color: "black" }} className='link_hover'>
                                                        {item.service_id?.name}
                                                    </Link>
                                                </td>
                                                <td>{item.invoice_date}</td>
                                                <td>{item.due_date}</td>
                                                <td>{item.client_id?.name}</td>
                                                <td>
                                                    {isDescriptionTruncated ? (
                                                        <div
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setSelectedDescription(item.description);
                                                                setShowDescriptionOverlay(true);
                                                            }}
                                                        >
                                                            {truncatedDescription}...
                                                        </div>
                                                    ) : (
                                                        item.description
                                                    )}
                                                </td>
                                                <td style={{ display: "flex", justifyContent: "center", paddingTop: "21px" }}>
                                                    <div style={{ width: '30px', height: '30px' }}>
                                                        <img style={{ width: '100%', height: '100%' }}
                                                            onClick={() => {
                                                                setSelectedImageURL(img_url + item.image);
                                                                setShowImageOverlay(true);
                                                            }}
                                                            src={img_url + item.image} alt='' />
                                                    </div>
                                                </td>
                                                {showDescriptionOverlay && (
                                                    <div className="overlayy" style={{ background: "rgba(0,0,0,1)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                        <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                            <p style={{ color: "white" }}>{selectedDescription}</p>
                                                        </div>
                                                    </div>
                                                )}
                                                <td>{item.status}</td>
                                                <td><span>{item.amount}</span></td>
                                                <td>
                                                    {item.status === "paid" ? (
                                                        <button className='btn btn-primary' disabled>
                                                            Already Paid
                                                        </button>
                                                    ) : (
                                                        <button className='btn btn-primary' onClick={() => postPaidStatus(item.id)}>
                                                            Pay Now
                                                        </button>
                                                    )}
                                                </td>
                                            </tr>}
                                        </>

                                    )
                                }) : currentInvoices?.map((item, idx) => {
                                    const truncatedDescription = item.description.slice(0, 20);
                                    const isDescriptionTruncated = item.description.length > 20;

                                    return (
                                        <tr key={item.id}>
                                            <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                            <td>{item.company?.name}</td>
                                            <td>
                                                <Link to={`/admin/accounts/banking/Invoice/${item.id}`} style={{ textDecoration: "none", color: "black" }} className='link_hover'>
                                                    {item.service_id?.name}
                                                </Link>
                                            </td>
                                            <td>{item.invoice_date}</td>
                                            <td>{item.due_date}</td>
                                            <td>{item.client_id?.name}</td>
                                            <td>
                                                {isDescriptionTruncated ? (
                                                    <div
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedDescription(item.description);
                                                            setShowDescriptionOverlay(true);
                                                        }}
                                                    >
                                                        {truncatedDescription}...
                                                    </div>
                                                ) : (
                                                    item.description
                                                )}
                                            </td>
                                            <td style={{ display: "flex", justifyContent: "center", paddingTop: "21px" }}>
                                                <div style={{ width: '30px', height: '30px' }}>
                                                    <img style={{ width: '100%', height: '100%' }}
                                                        onClick={() => {
                                                            setSelectedImageURL(img_url + item.image);
                                                            setShowImageOverlay(true);
                                                        }}
                                                        src={img_url + item.image} alt='' />
                                                </div>
                                            </td>
                                            {showDescriptionOverlay && (
                                                <div className="overlayy" style={{ background: "rgba(0,0,0,1)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                    <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                        <p style={{ color: "white" }}>{selectedDescription}</p>
                                                    </div>
                                                </div>
                                            )}
                                            <td>{item.status}</td>
                                            <td><span>{item.amount}</span></td>
                                            <td>
                                                {item.status === "paid" ? (
                                                    <button className='btn btn-primary' disabled>
                                                        Already Paid
                                                    </button>
                                                ) : (
                                                    <button className='btn btn-primary' onClick={() => postPaidStatus(item.id)}>
                                                        Pay Now
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                        <div>
                            {paginationControls.map((button, index) => (
                                <React.Fragment key={index}>{button}</React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
                {showImageOverlay && (
                    <div className="overlayy" style={{ background: "rgba(0,0,0,0.38)" }} onClick={() => setShowImageOverlay(false)}>
                        <img
                            src={selectedImageURL}
                            alt="Full-Screen Image"
                            style={{ maxHeight: '100%', maxWidth: '100%' }}
                        />
                    </div>
                )}
                <Snackbar
                open={anyFieldEmpty}
                autoHideDuration={2000}
                anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}
            >
                <Alert
                    severity="error"
                    style={{
                        backgroundColor: '#8f2018',
                        color: "white",
                    }}
                    onClose={handleCloseSnackbar}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            </div>
        </>
    )
}

export default Services

