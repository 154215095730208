import React, { useState, useRef, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import register from '../../Images/revenue.png'
import { useData } from '../../ContextProvider/Context';
import { Link, useParams } from 'react-router-dom';
import { CiEdit } from 'react-icons/ci';
function CreateEmail() {
  const params = useParams()
  const { getAllCrudData, allCrudData, emailTemplate, getEmailTemplate, storeCustomEmail, getCompnayCrud, company, getBarnchById, departmentval, branch, getdepartmetById, getdseignationById, designarion, getEmploayeeByid, employeeID } = useData()
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedDesignation, setSelectedDesignation] = useState(null);

  const filteredCompanies = allCrudData?.companies?.filter(item => item.deleteStatus === 0);

  const filteredBranches = allCrudData?.branches?.filter(item => item.companyId === selectedCompany);

  const filteredDepartments = allCrudData?.department?.filter(item => item.branchId === selectedBranch);

  const filteredDesignation = allCrudData?.designations?.filter(item => item.departmentId === selectedDepartment);

  const filteredEmployee = allCrudData?.employees?.filter(item => item.designationId === selectedDesignation);
  useEffect(() => {
    getCompnayCrud()
    getEmailTemplate()
    getAllCrudData()

  }, [])
  const openDrawer = () => {
    setIsDrawerOpen(true);
  };
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState({
    content: '',
    type: '',
  });
  const selectEmailTemplate = (content, type) => {
    setSelectedEmailTemplate({ content, type });
    setvalue(content)
  };

  const editor = useRef(null);
  const insertHTMLContent = (htmlContent) => {
    setvalue(htmlContent);
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Image = e.target.result;
        const imagePart = base64Image;
        setAllData({ ...alldata, attachment: imagePart });
      };
      reader.readAsDataURL(selectedFile);
    }

  };

  const [value, setvalue] = useState('');
  const [allcompanies, setAllCompanies] = useState()
  const [alldata, setAllData] = useState({
    hostId: '',
    companyId: params.id ? params.id : "",
    branchId: '',
    departmentId: '',
    designationId: '',
    empId: '',
    email: '',
    users: '',
    vendor: '',
    suppliers: '',
    customers: '',
    subject: '',
    content: '',
    attachment: '',

  });
  const handleSaveChange = (e) => {
    e.preventDefault();
    storeCustomEmail(alldata)
  }
  function createMarkup(content) {
    return { __html: content };
  }
  return (
    <>
      <div className='createEmailPageData'>
        <div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/admin/dashboard">Admin</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Email Compose</li>
            </ol>
          </nav>
        </div>
        <div>
          <hr />
          <div className='card'>
            <div className='card-body'>
              <div className='emailContainer'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div
                    onClick={openDrawer}
                    className='my-3 cursorPointer'
                    style={{ width: "max-content", cursor: "pointer" }}
                  >
                    <MenuIcon /> <span style={{ fontWeight: "bold", cursor: "pointer" }}>Choose Templetes</span>
                  </div>
                  <div>
                    <select
                    id='11'
                      onChange={(e) => setAllData({ ...alldata, hostId: e.target.value })}
                      style={{ height: "38px", borderRadius: "5px", border: "1px solid lightgray" }}
                      className='form-control'
                    >
                      <option hidden>Select Host </option>
                      <option value=''>Select Host</option>
                      {allCrudData?.smtp?.map((item) => (
                        <option key={item.id} value={item?.companyId}>
                          {item?.company?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <hr />

                <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
                  <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={() => setIsDrawerOpen(false)}
                    onKeyDown={() => setIsDrawerOpen(false)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "10px"
                    }}
                    className="scrollBar"
                  >
                    <h3 style={{ fontWeight: "bold", margin: "1rem 0", padding: "1rem", fontSize: "17px" }}>Choose Template For Mail</h3>

                    {emailTemplate?.map((item) => (
                      <div key={item.id}>
                        <div style={{ display: "flex", justifyContent: "space-around", marginBottom: "6px" }}>
                          <button
                            className='btn btn-primary'
                            type="button"
                            onClick={() => selectEmailTemplate(item.content, item.type)}>Use</button>
                          <button
                            className='btn btn-primary'>
                            <Link to={`/admin/create_email_template/${item.id}`} style={{ textDecoration: "none", color: "white" }}>
                              Edit
                            </Link>
                          </button>
                        </div>
                        <button
                          style={{ height: "200px", width: "200px", overflow: "hidden" }}
                          dangerouslySetInnerHTML={createMarkup(item.content)}
                        />
                        <p className="mt-3 text-center" style={{ fontWeight: "bold" }}>{item.type}</p>

                      </div>
                    ))}
                  </Box>
                </Drawer>

                <form>
                  <div className='row' style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ width: "20%" }}>
                      <select
                    id='12'
                        disabled={params.id ? true : false}
                        onChange={(e) => {
                          const selectedCompanyId = e.target.value;
                          setAllData({ ...alldata, companyId: selectedCompanyId });
                          setSelectedCompany(parseInt(e.target.value))
                          if (selectedCompanyId === '') {
                            setAllCompanies(company?.data);
                          } else {
                            getBarnchById(selectedCompanyId);
                          }
                        }}
                        style={{ width: "100%", height: "38px", borderRadius: "5px", border: "1px solid lightgray" }}
                      >
                        <option hidden  >Select Company </option>
                        <option value=''>Select Compnay</option>
                        <option value='all'>All Companies</option>
                        {filteredCompanies?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div style={{ width: "20%" }}>
                      <select
                    id='13'
                        onChange={(e) => {
                          const selectedBranchId = e.target.value;
                          setSelectedBranch(parseInt(e.target.value))
                          setAllData({ ...alldata, branchId: selectedBranchId });
                          getdepartmetById(selectedBranchId);
                        }}
                        style={{ width: "100%", height: "38px", borderRadius: "5px", border: "1px solid lightgray" }}
                      >
                        <option hidden  >Select Branch </option>
                        <option value=''>All Branches</option>
                        {selectedCompany && (
                          <>
                            {filteredBranches?.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.branch}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                    <div style={{ width: "20%" }}>
                      <select
                       id='14'
                        onChange={(e) => {
                          const selectedDepartmentId = e.target.value;
                          setAllData({ ...alldata, departmentId: selectedDepartmentId });
                          getdseignationById(selectedDepartmentId);
                          setSelectedDepartment(parseInt(e.target.value))

                        }}
                        style={{ width: "100%", height: "38px", borderRadius: "5px", border: "1px solid lightgray" }}
                      >
                        <option hidden  >Select Department </option>
                        <option value=''>All Departments</option>
                        {selectedBranch && (
                          <>
                            {filteredDepartments?.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.department}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                    <div style={{ width: "20%" }}>
                      <select
                       id='15'
                        onChange={(e) => {
                          const selectedDesignationId = e.target.value;
                          setAllData({ ...alldata, designationId: selectedDesignationId });
                          getEmploayeeByid(selectedDesignationId);
                          setSelectedDesignation(parseInt(e.target.value))

                        }}
                        style={{ width: "100%", height: "38px", borderRadius: "5px", border: "1px solid lightgray" }}
                      >
                        <option hidden  >Select Designation </option>
                        <option value=''>All Designations</option>
                        {selectedDepartment && (
                          <>
                            {filteredDesignation?.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.title}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                    <div style={{ width: "20%" }}>
                      <select
                       id='16'
                        onChange={(e) => {
                          const selectedEmployeeId = e.target.value;
                          setAllData({ ...alldata, empId: selectedEmployeeId });
                        }}
                        style={{ width: "100%", height: "38px", borderRadius: "5px", border: "1px solid lightgray" }}
                      >
                        <option hidden  >Select Employee </option>
                        <option value=''>All Employees</option>
                        {selectedDesignation && (
                          <>
                            {filteredEmployee?.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-2'>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={alldata.users}
                          onChange={(e) => {
                            setAllData({ ...alldata, users: e.target.checked })
                          }}
                          id="flexCheckChecked"
                        />

                        <label style={{ fontWeight: "bold" }} className="form-check-label" htmlFor="flexCheckChecked">
                          Users
                        </label>
                      </div>
                    </div>
                    <div className='col-2'>
                      <div className="form-check">
                        <input className="form-check-input"  checked={alldata.suppliers}
                          onChange={(e) => {
                            setAllData({ ...alldata, suppliers: e.target.checked })
                          }} type="checkbox" defaultValue id="suppliers" />
                        <label style={{ fontWeight: "bold" }} className="form-check-label" htmlFor="suppliers">
                          Suppliers
                        </label>
                      </div>
                    </div>
                    <div className='col-2'>
                      <div className="form-check">
                        <input className="form-check-input" checked={alldata.vendor}
                          onChange={(e) => {
                            setAllData({ ...alldata, vendor: e.target.checked })
                          }} type="checkbox" defaultValue id="vendor" />
                        <label style={{ fontWeight: "bold" }} className="form-check-label" htmlFor="vendor">
                          Vendor
                        </label>
                      </div>
                    </div>
                    <div className='col-2'>
                      <div className="form-check">
                        <input className="form-check-input" checked={alldata.customers}
                          onChange={(e) => {
                            setAllData({ ...alldata, customers: e.target.checked })
                          }} type="checkbox" defaultValue id="customers" />
                        <label style={{ fontWeight: "bold" }} className="form-check-label" htmlFor="customers">
                          Customers
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className='row mt-2'>
                    <div className='col-1'>
                      <label>
                        To:
                      </label>
                    </div>
                    <div className='col-11'>
                      <input
                        type='text'
                        id='20'
                        onChange={(e) => setAllData({ ...alldata, email: e.target.value })}
                        className='form-control w-25'
                      />
                    </div>
                  </div>
                  <div className='row my-3'>
                    <div className='col-1'>
                      <label>
                        Subject:
                      </label>
                    </div>
                    <div className='col-11'>
                      <input type='text' onChange={(e) => setAllData({ ...alldata, subject: e.target.value })}  id='21' className='form-control w-25' />
                    </div>
                  </div>
                  <div className='emailSummerNoteArea'>
                    <JoditEditor
                      ref={editor}
                      value={value}
                      onChange={(content) => setAllData({ ...alldata, content })}
                    />
                  </div>
                  <div className='row mt-3'>
                    <div>
                      <label htmlFor='file'>Upload File</label>
                      <input
                        id='file'
                        className='form-control'
                        type='file'
                        name=''
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                  <div className='row my-3' style={{ display: "flex", justifyContent: "end" }}>
                    <button className='btn btn-primary' onClick={handleSaveChange} style={{ width: "100px" }} type='submit'>
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateEmail
