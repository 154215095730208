import React from 'react'
import AdminUserForm from './AdminUserForm'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { CiCirclePlus } from 'react-icons/ci';
import { useData } from '../ContextProvider/Context';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    bgcolor: 'background.paper',
    boxShadow: 24,
};
function EditEmplyee({ employeeData, setEmployees }) {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { base_url, getEmployeesIndividual, handleClick, setSnackbarValue } = useData()
    const editEmplyee = () => {
        fetch(`${base_url}employeeupdate/${employeeData?.id}`, {
            method: "PUT",
            headers: {
                'Content-Type': "application/json",
                "Accept": 'application/json',

            },
            body: JSON.stringify(employeeData),
        })
            .then((res) => res.json())
            .then((data) => {
                handleClick()
                setSnackbarValue({ message: "Emplyee Update successfully", color: "#2ECC71" });
                getEmployeesIndividual(employeeData.id)

            });
    }
    return (
        <div>
            <button style={{ marginRight: "1rem" }} className='btn d-flex align-items-center gap-1 ' onClick={handleOpen}><span style={{ fontSize: "12px" }}>Edit Profile</span> <CiCirclePlus style={{ fontSize: "20px", fontWeight: "600" }} className='text-primary' /></button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} style={{ height: "600px", overflowY: "scroll" }} className='scrollBar'>
                    {/* Tabs navs */}
                    <AdminUserForm employeeData={employeeData} setEmployees={setEmployees} />
                    <div style={{ display: "flex", justifyContent: "end", padding: "1rem" }}>
                        <button className='btn btn-primary' onClick={() => {
                            editEmplyee(employeeData)
                            handleClose()
                        }} style={{ padding: "8px 16px", marginRight: "1rem" }}>Edit</button>
                        <button className='btn btn-primary' style={{ padding: "8px 16px", marginRight: "1rem" }} onClick={handleClose}>Cancel</button>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default EditEmplyee
