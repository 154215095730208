import React, { useState } from 'react';
import Step1Component from './Step1Component';
import Step2Component from './Step2Component';
import Step3Component from './Step3Component';
import Step4Component from './Step4Component';
import Step6Component from './Step6Component';
import { useData } from '../../ContextProvider/Context';
import { Link } from 'react-router-dom';
import { Snackbar, Alert } from '@mui/material';

function WizardForm({ handleClose }) {
  const { storeCompnayData, handleClick, setSnackbarValue } = useData()
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    trading_addr: '',
    logo: null,
    socialLinks: [{
      icon: "",
      link: ""
    }],
    finePolicy: [
      {
        time: "",
        type: "fixed",
        fine: 0
      }
    ],
    timings: [
      { day: 'Monday', startTime: '09:00', endTime: '17:00', totalTime: '8' },
      { day: 'Tuesday', startTime: '09:00', endTime: '17:00', totalTime: '8' },
      { day: 'Wednesday', startTime: '09:00', endTime: '17:00', totalTime: '8' },
      { day: 'Thursday', startTime: '09:00', endTime: '17:00', totalTime: '8' },
      { day: 'Friday', startTime: '09:00', endTime: '17:00', totalTime: '8' },
      { day: 'Saturday', startTime: '09:00', endTime: '17:00', totalTime: '8' },
      { day: 'Sunday', startTime: '09:00', endTime: '17:00', totalTime: '8' },
    ],
    smtp: [{
      mail_host: '',
      mail_port: '',
      mail_username: '',
      mail_password: '',
      from_address: '',
      from_name: '',
      mail_mailer: '',
      mail_encryption: '',
    }]
  });
  const handleChange = (field, value) => {
    if (field == 'logo') {
      if (value) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const base64String = event.target.result;
          setFormData({ ...formData, [field]: base64String });

        };
        reader.readAsDataURL(value);
      }
    }
    else {

      setFormData({ ...formData, [field]: value });
    }
  };
  async function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }
  async function prepareDataForBackend(data) {
    const preparedData = { ...data };
    if (preparedData.socialLinks) {
      for (let i = 0; i < preparedData.socialLinks.length; i++) {
        const iconFile = preparedData.socialLinks[i].icon;
        if (iconFile instanceof File) {
          const base64String = await fileToBase64(iconFile);
          preparedData.socialLinks[i].icon = base64String;
        }
      }
    }
    return preparedData;
  }
  const isFormValid = () => {
    for (const key in formData) {
      if (formData[key] === '') {
        return false;
      }
    }
    return true;
  };
  // const submit = () => {
  //   if (isFormValid()) {
  //     prepareDataForBackend(formData)
  //       .then((preparedData) => {
  //         var newData = [...preparedData.socialLinks]
  //         var array = []
  //         newData.map((item) => {
  //           var obj = {
  //             icon: item.icon,
  //             link: item.link
  //           }
  //           array.push(obj)
  //         })
  //         preparedData.socialLinks = array
  //         storeCompnayData(preparedData)
  //         handleClose()
  //       })
  //       .catch((error) => {
  //         console.error("Error preparing data:", error);
  //       });
  //   } else {
  //     handleClick()
  //     setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
  //   }
  // };
  const [state, setState] = React.useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'right',
  });
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const { vertical, horizontal } = state;
  const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const submit = () => {
    if (formData?.logo?.trim() == null) {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Logo is required.');
    } else if (formData?.name?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Name is required.');
    } else if (formData?.email?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Email is required.');
    } else if (!isValidEmail(formData?.email?.trim())) {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Enter Valid Email Address');
    } else if (formData?.phone?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Phone is required.');
    } else if (formData?.address?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Company Address is required.');
    } else if (formData?.address?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Company Address is required.');
    } else if (formData.finePolicy.some(item => item.time.trim() === '' || item.type.trim() === '' || item.fine.trim() === '')) {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Fine Policy fields are required.');
    } else {
      prepareDataForBackend(formData)
        .then((preparedData) => {
          var newData = [...preparedData.socialLinks]
          var array = []
          newData.map((item) => {
            var obj = {
              icon: item.icon,
              link: item.link
            }
            array.push(obj)
          })
          preparedData.socialLinks = array
          storeCompnayData(preparedData)
          handleClose();
        })
        .catch((error) => {
          console.error("Error preparing data:", error);
        });
    }
  };
  const handleCloseSnackbar = () => {
    setAnyFieldEmpty(false);
  };
  return (
    <>
      <div>
        <ul className="nav nav-tabs nav-fill mb-3" id="ex1" role="tablist">
          <li className="nav-item" role="presentation">
            <Link className="nav-link active" id="ex2-tab-1" data-mdb-toggle="tab" to="#ex2-tabs-1" role="tab" aria-controls="ex2-tabs-1" aria-selected="true">Basic Info</Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link className="nav-link" id="ex2-tab-2" data-mdb-toggle="tab" to="#ex2-tabs-2" role="tab" aria-controls="ex2-tabs-2" aria-selected="false">Social Channels</Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link className="nav-link" id="ex2-tab-3" data-mdb-toggle="tab" to="#ex2-tabs-3" role="tab" aria-controls="ex2-tabs-3" aria-selected="false">Polities & TC</Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link className="nav-link" id="ex2-tab-4" data-mdb-toggle="tab" to="#ex2-tabs-4" role="tab" aria-controls="ex2-tabs-4" aria-selected="false">Custom Timings</Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link className="nav-link" id="ex2-tab-5" data-mdb-toggle="tab" to="#ex2-tabs-5" role="tab" aria-controls="ex2-tabs-5" aria-selected="false">SMTP</Link>
          </li>
        </ul>
        <div className="tab-content" id="ex2-content">
          <div className="tab-pane fade show active" id="ex2-tabs-1" role="tabpanel" aria-labelledby="ex2-tab-1">
            <Step1Component formData={formData} handleChange={handleChange} />
          </div>
          <div className="tab-pane fade" id="ex2-tabs-2" role="tabpanel" aria-labelledby="ex2-tab-2">
            <Step2Component formData={formData} handleChange={handleChange} setFormData={setFormData} />
          </div>
          <div className="tab-pane fade" id="ex2-tabs-3" role="tabpanel" aria-labelledby="ex2-tab-3">
            <Step3Component formData={formData} handleChange={handleChange} setFormData={setFormData} />
          </div>
          <div className="tab-pane fade" id="ex2-tabs-4" role="tabpanel" aria-labelledby="ex2-tab-4">
            <Step4Component formData={formData} handleChange={handleChange} setFormData={setFormData} />
          </div>
          <div className="tab-pane fade" id="ex2-tabs-5" role="tabpanel" aria-labelledby="ex2-tab-5">
            <Step6Component formData={formData} handleChange={handleChange} setFormData={setFormData} />
            <button className='submit_btn btn btn-primary my-3' onClick={submit}>Submit</button>
          </div>
        </div>
      </div>
      <Snackbar
        open={anyFieldEmpty}
        autoHideDuration={2000}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          severity="error"
          style={{
            backgroundColor: '#8f2018',
            color: "white",
          }}
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default WizardForm;
