import React from 'react';
import { BiTimeFive } from 'react-icons/bi';
import { IoOptionsOutline } from 'react-icons/io5';
import { AiOutlineDollarCircle } from 'react-icons/ai'
import { CiCircleMinus } from 'react-icons/ci';
function Step3Component({ formData, handleChange, setFormData }) {
  const addFinePolicy = () => {
    const newData = [...formData.finePolicy]
    newData.push({ time: '', type: 'fixed', fine: '' })

    setFormData({ ...formData, finePolicy: newData });

  };

  const removeFinePolicy = (index) => {
    const updatedPolicies = [...formData.finePolicy];
    updatedPolicies.splice(index, 1);
    setFormData({ ...formData, finePolicy: updatedPolicies });

  };

  return (
    <div className="step-content row">
      <div className='mb-2' style={{ display: "flex", justifyContent: "end", alignItems: 'center' }}>
        <button style={{ padding: ".5rem 1rem", border: "none", color: "white", background: "#007bff", borderRadius: "5px" }} onClick={addFinePolicy}>Add More</button>
      </div>
      {formData?.finePolicy?.map((policy, index) => (
        <div>
          {index > 0 && (
            <div className='mb-2' style={{ display: "flex", justifyContent: "end", marginTop: "1rem" }}>
              <button style={{ padding: "0rem 1rem", border: "none", color: "white", background: "#007bff", borderRadius: "5px" }} onClick={() => removeFinePolicy(index)}>
                <CiCircleMinus />
              </button>
            </div>
          )}
          <div style={{ alignItems: "center" }} key={index} className='row'>

            <div className='col-4'>
              <label>Time<span style={{ color: "red" }}>*</span></label>
              <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: 'flex' }}>
                <label htmlFor={`minutes${index}`}><BiTimeFive /></label>
                <input
                  type="number"
                  id={`minutes${index}`}
                  placeholder='Minutes'
                  value={policy.time}
                  onChange={(e) => {
                    const newData = [...formData.finePolicy]
                    newData[index].time = e.target.value
                    setFormData({ ...formData, finePolicy: newData })
                  }
                  }
                />
              </div>
            </div>
            <div className='col-4'>
              <label>Type<span style={{ color: "red" }}>*</span></label>
              <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem" }}>
                <label htmlFor={`option${index}`}><IoOptionsOutline /></label>
                <select
                  id={`option${index}`}
                  style={{ border: "none", outline: "none", width: "90%", fontSize: '13px' }}
                  value={policy.type}
                  onChange={(e) => {
                    const newData = [...formData.finePolicy]
                    newData[index].type = e.target.value
                    setFormData({ ...formData, finePolicy: newData })
                  }
                  }
                >
                  <option hidden>Select</option>
                  <option value="fixed">Fixed Amount</option>
                  <option value="percentage">Percentage of Salary</option>
                </select>
              </div>
            </div>
            <div className='col-4'>
              <label>Amount<span style={{ color: "red" }}>*</span></label>
              <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: 'flex' }}>
                <label htmlFor={`amount${index}`}><AiOutlineDollarCircle /></label>
                <input
                  type="text"
                  id={`amount${index}`}
                  value={policy.fine}
                  placeholder='Amount'
                  onChange={(e) => {
                    const newData = [...formData.finePolicy]
                    newData[index].fine = e.target.value
                    setFormData({ ...formData, finePolicy: newData })
                  }
                  }
                />
              </div>
            </div>

          </div>
        </div>
      ))}
    </div>
  );
}

export default Step3Component;
