import React, { useEffect, useRef, useState } from 'react'
import { CiEdit, CiFileOn, CiLink, CiTrash } from 'react-icons/ci'
import EditCompany from './Editcompany/EditCompany'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { debounce } from 'lodash';
import { FaRegAddressCard } from 'react-icons/fa'
import JoditEditor from 'jodit-react'
import { useData } from '../ContextProvider/Context';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px'
};
function AnnouncementCreate() {
    const editorRef = useRef(null);
    const [open4, setOpen4] = React.useState(false);
    const handleOpen4 = () => setOpen4(true);
    const handleClose4 = () => {
        setdata({
            title: "",
            announcement_date: "",
            content: ""
        })
        setOpen4(false)
    };
    const { base_url, setSnackbarValue, handleClick } = useData()
    const [data, setdata] = useState({
        title: "",
        announcement_date: "",
        content: ""

    })
    const [announcement, setAnnouncement] = useState([])
    useEffect(() => {
        getAnnouncement()
    }, [])
    const getAnnouncement = () => {
        fetch(`${base_url}company-announcements`)
            .then((res) => res.json())
            .then((data) => {
                setAnnouncement(data);
            });
    };
    const debouncedHandleContentChange = debounce((e) => {
        setdata({ ...data, content: e });
    }, 1000);

    const handleContentChange = (e) => {
        debouncedHandleContentChange(e);
    };
    const updateannouncement = (obj) => {
        if (data.title.trim() === '') {
            handleClick();
            setSnackbarValue({ message: "Title field is required.", color: "#DC4C64" });
            return;
        }

        if (data.announcement_date === '') {
            handleClick();
            setSnackbarValue({ message: "Date is required.", color: "#DC4C64" });
            return;
        }
        if (data.content === '') {
            handleClick();
            setSnackbarValue({ message: "Annoucement is required.", color: "#DC4C64" });
            return;
        }
        fetch(`${base_url}company-announcements/${obj?.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(obj),
        })
            .then((res) => res.json())
            .then((data) => {
                getAnnouncement()
                handleClose4()
                handleClick();
                setSnackbarValue({ message: "Announcement update successfully", color: "#2ECC71" });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const postannouncement = (obj) => {
        if (data.title.trim() === '') {
            handleClick();
            setSnackbarValue({ message: "Title field is required.", color: "#DC4C64" });
            return;
        }

        if (data.announcement_date === '') {
            handleClick();
            setSnackbarValue({ message: "Date is required.", color: "#DC4C64" });
            return;
        }
        if (data.content === '') {
            handleClick();
            setSnackbarValue({ message: "Annoucement is required.", color: "#DC4C64" });
            return;
        }
        fetch(`${base_url}company-announcements`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(obj),
        })
            .then((res) => res.json())
            .then((data) => {
                getAnnouncement()
                handleClose4()
                handleClick();
                setSnackbarValue({ message: "Announcement Create successfully", color: "#2ECC71" });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const deleteannouncement = (id) => {
        fetch(`${base_url}company-announcement/${id}`, {
            method: 'DELETE',
        })
            .then((res) => res.json())
            .then((data) => {
                getAnnouncement()
                handleClick();
                setSnackbarValue({ message: "Announcement Deleted", color: "#2ECC71" });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentAnnoucement = announcement?.slice(indexOfFirstItem, indexOfLastItem);

    const paginationControls = [];
    for (let i = 1; i <= Math.ceil(announcement?.length / itemsPerPage); i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => setCurrentPage(i)}>
                {i}
            </button>
        );
    }
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className='row'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Announcement</li>
                        </ol>
                    </nav>
                </div>
                <button onClick={() => handleOpen4()} className='btn btn-primary'>Create</button>
                <Modal
                    open={open4}
                    onClose={handleClose4}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} className="scrollBar" style={{ height: "600px", overflowY: "scroll" }}>
                        <div id="modal-modal-description" sx={{ mt: 2 }}>

                            <div className='row'>
                                <div className='col-md-6 mb-3'>
                                    <div className='inputBox mt-3'>
                                        <label><FaRegAddressCard style={{ fontSize: "20px", marginRight: "3px" }} /></label>
                                        <input style={{ outline: "none" }}
                                            onChange={(e) => {
                                                setdata({ ...data, title: e.target.value });
                                            }
                                            }
                                            placeholder='Title'
                                            value={data?.title}
                                            type='text' />
                                    </div>
                                </div>
                                <div className='col-md-6'>

                                    <div className='inputBox mt-3'>
                                        <label><CiLink style={{ fontSize: "20px", marginRight: "3px" }} /></label>
                                        <input style={{ outline: "none" }}
                                            value={data?.announcement_date}
                                            placeholder='Link' type='date'
                                            onChange={(e) => {
                                                setdata({ ...data, announcement_date: e.target.value });
                                            }
                                            }
                                            className='form-control' />

                                    </div>
                                </div>

                            </div>

                        </div>
                        <JoditEditor
                            ref={editorRef}
                            value={data?.content}
                            onChange={handleContentChange}
                        />
                        <div className='row mt-4'>
                            <div className='modalBtns d-flex justify-content-end gap-2'>
                                <div style={{ display: "flex", justifyContent: "end" }}>
                                    <button
                                        onClick={() => {
                                            data?.id ? updateannouncement(data) : postannouncement(data)
                                        }}
                                        className='btn btn-primary'>
                                        {data?.id ? "Update" : "Submit"}
                                    </button>
                                </div>
                                <button className='btn btn-secondary'
                                    onClick={handleClose4}
                                >Cancel</button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
            <div className='card mt-2' >
                <div className="card-body">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Announcement</th>
                                <th scope="col">Date</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentAnnoucement?.map((item) => {
                                return (
                                    <tr key={item?.id}>
                                        <td>
                                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>

                                                <div>
                                                    <Link
                                                        className='link linkk'
                                                        to={`/admin/announcement/${item.id}`}
                                                    >

                                                        {item.title}
                                                    </Link>
                                                </div>
                                            </div>

                                        </td>
                                        <td>
                                            {item.announcement_date}

                                        </td>

                                        <td>
                                            <button
                                                className="btn p-1 m-2 btn-danger"
                                                onClick={() => deleteannouncement(item.id)
                                                }
                                            >
                                                <CiTrash style={{ fontSize: "20px" }} />
                                            </button>
                                            <button
                                                className="btn p-1 m-2 btn-primary"
                                                onClick={() => {
                                                    setdata(item)
                                                    handleOpen4()
                                                }}
                                            >
                                                <CiEdit style={{ fontSize: "20px" }} />
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="pagination-controls">{paginationControls}</div>
                </div>
            </div>
        </div>
    )
}

export default AnnouncementCreate