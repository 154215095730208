import React from 'react'
import Reset_Password_email from '../Components/Reset_Password_email'

function ResetPassword() {
  return (
    <>
      <Reset_Password_email/>
    </>
  )
}

export default ResetPassword
