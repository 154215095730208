import React from 'react';
import { CiImport } from 'react-icons/ci';
import { useData } from '../ContextProvider/Context';

const DownloadPDFButton = ({ pdfUrl, fileName,cv,buttonText }) => {
  const{handleClick,setSnackbarValue }=useData()
  const handleDownload = () => {
    if(cv){
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.setAttribute("target","_blank")
      link.download = fileName || 'document.pdf';  
      document.body.appendChild(link);  
      link.click();
      document.body.removeChild(link);
    }
    else{
      handleClick()
      setSnackbarValue({ message: "File Not Found !", color: "#DC4C64" });
    }   
  };

  return (
    <button style={{ marginRight: "1rem" }} className='btn d-flex align-items-center gap-1 ' onClick={handleDownload}><span style={{ fontSize: "12px" }}>{buttonText}</span> <CiImport style={{ fontSize: "20px", fontWeight: "600" }} className='text-primary' /></button>
  );
};

export default DownloadPDFButton;
