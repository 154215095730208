import React, { useState } from 'react'
import { CiEdit } from 'react-icons/ci'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Close } from '@mui/icons-material';
import { MdOutlineLocationCity } from 'react-icons/md'
import { MdOutlineAttachEmail } from 'react-icons/md'
import { FaPhoneAlt } from 'react-icons/fa'
import { MdLocationPin } from 'react-icons/md'
import { LuImagePlus } from 'react-icons/lu'
import { BiTimeFive } from 'react-icons/bi';
import { IoOptionsOutline } from 'react-icons/io5';
import { AiOutlineDollarCircle } from 'react-icons/ai'
import { BiLinkAlt } from 'react-icons/bi'
import { MdOutlineDeleteSweep, MdOutlineIndeterminateCheckBox } from 'react-icons/md'
import { useData } from '../../ContextProvider/Context';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  boxShadow: 24,
};
function EditCompany({ value }) {
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState()
  const { storeCompnayData, editCompany, img_url } = useData()
  const handleOpen = () => {
    setFormData(value)
    setOpen(true)
  };
  const handleClose = () => setOpen(false);
  const addSocialChannel = () => {
    const newData = [...formData?.social_links]
    newData.push({ icon: '', link: '' })
    setFormData({ ...formData, social_links: newData });
  };

  const removeSocialChannel = (index) => {
    const updatedChannels = [...formData?.social_links];
    updatedChannels.splice(index, 1);
    setFormData({ ...formData, social_links: updatedChannels });
  };
  const addFinePolicy = () => {
    const newData = [...formData?.fine_policy]
    newData.push({ time: '', type: 'fixed', fine: '' })

    setFormData({ ...formData, fine_policy: newData });

  };

  const removeFinePolicy = (index) => {
    const updatedPolicies = [...formData?.fine_policy];
    updatedPolicies.splice(index, 1);
    setFormData({ ...formData, fine_policy: updatedPolicies });

  };

  const calculateTotalTime = (day, idx) => {
    const dayData = formData?.timings.find((item) => item.day === day);

    if (dayData && dayData.startTime && dayData.endTime) {
      const startMinutes = parseInt(dayData.startTime.split(':')[0]) * 60 + parseInt(dayData.startTime.split(':')[1]);
      const endMinutes = parseInt(dayData.endTime.split(':')[0]) * 60 + parseInt(dayData.endTime.split(':')[1]);

      const totalMinutes = endMinutes - startMinutes;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      const newdata = [...formData?.timings]
      newdata[idx].totalTime = hours
      setFormData({ ...formData, timings: newdata })
      return `${hours} hours and ${minutes} minutes`;
    }

    return 'Not specified';
  };
  const [logoCompany, setLogo] = useState(null)
  const handleChange = (field, value) => {
    if (field == 'logo') {
      if (value) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const base64String = event.target.result;
          setLogo(base64String);
          setFormData({ ...formData, [field]: base64String });

        };
        reader.readAsDataURL(value);
      }
    }
    else {

      setFormData({ ...formData, [field]: value });
    }
  };
  // const handleSmtp = () => {
  //   handleChange('smtp', [
  //     ...formData.smtp,
  //     {
  //       mail_host: '',
  //       mail_port: '',
  //       mail_username: '',
  //       mail_password: '',
  //       from_address: '',
  //       from_name: ''
  //     },
  //   ]);
  // };
  const edit = () => {
    handleClose()
    editCompany(formData)
  }
  return (
    <>
      <button
        className="btn p-1 m-2 btn-primary"
        onClick={handleOpen}
      >
        <CiEdit style={{ fontSize: "20px" }} />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ height: "600px", overflowY: "scroll", width: "80%" }} className='scrollBar'>
          <div style={{ display: "flex", justifyContent: "space-between", padding: "1rem" }}>
            <h6> Edit Company</h6>
            <button style={{ border: "none", background: "none" }} onClick={handleClose}><Close /></button>
          </div>
          {/* Tabs navs */}
          <div style={{ padding: '1rem' }} className='edit_company'>
            <ul className="nav nav-tabs mb-3 justify-content-center" id="ex1" role="tablist">
              <li className="nav-item" role="presentation" >
                <a className="nav-link active" id="ex1-tab-1" data-mdb-toggle="tab" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1" aria-selected="true">Company Information</a>
              </li>
              <li className="nav-item" role="presentation">
                <a className="nav-link" id="ex1-tab-2" data-mdb-toggle="tab" href="#ex1-tabs-2" role="tab" aria-controls="ex1-tabs-2" aria-selected="false">Social Links</a>
              </li>
              <li className="nav-item" role="presentation">
                <a className="nav-link" id="ex1-tab-4" data-mdb-toggle="tab" href="#ex1-tabs-4" role="tab" aria-controls="ex1-tabs-4" aria-selected="false">Custom Timings</a>
              </li>
              <li className="nav-item" role="presentation">
                <a className="nav-link" id="ex1-tab-3" data-mdb-toggle="tab" href="#ex1-tabs-3" role="tab" aria-controls="ex1-tabs-3" aria-selected="false">Fine Policy</a>
              </li>
              <li className="nav-item" role="presentation">
                <a className="nav-link" id="ex1-tab-5" data-mdb-toggle="tab" href="#ex1-tabs-5" role="tab" aria-controls="ex1-tabs-5" aria-selected="false">SMTP</a>
              </li>
            </ul>
            <div className="tab-content" id="ex1-content">
              <div className="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
                <div className="step-content row">
                  <div className='col-12'>
                    <div style={{ border: "none" }} className='row'>
                      <div className='col-12'>
                        <div style={{ border: '2px solid', height: "100px", borderRadius: "50%", width: "100px", position: "relative" }}>
                          <label style={{ border: "1px solid", borderRadius: "50%", width: "25px", height: "25px", background: "lightgray", color: "gray", position: "absolute", right: "0%", paddingLeft: ".2rem", paddingBottom: ".2rem" }} htmlFor="companyLogo"><LuImagePlus /></label>
                          <img style={{ width: "100%", height: "100%", borderRadius: "50%" }} src={logoCompany ? logoCompany : img_url + formData?.logo} />
                        </div>
                        <input
                          type="file"
                          id="companyLogo"
                          name="companyLogo"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(e) => handleChange("logo", e.target.files[0])}
                        />
                      </div>
                    </div>

                  </div>
                  <div className='col-6'>
                    <div className='row'>
                      <div className='col-12'>
                        <label htmlFor="companyName"><MdOutlineLocationCity /></label>
                        <input
                          type="text"
                          id="companyName"
                          name="companyName"
                          placeholder='Company Name'
                          value={formData?.name}
                          onChange={(e) => handleChange('name', e.target.value)}
                        />
                      </div>
                    </div>

                  </div>

                  <div className='col-6'>
                    <div className='row'>
                      <div className='col-12'>
                        <label htmlFor="companyEmail"><MdOutlineAttachEmail /></label>
                        <input
                          type="email"
                          id="companyEmail"
                          name="companyEmail"
                          placeholder='Company Email'
                          value={formData?.email}
                          onChange={(e) => handleChange('email', e.target.value)}
                        />
                      </div>
                    </div>

                  </div>
                  <div className='col-6'>
                    <div className='row'>
                      <div className='col-12'>
                        <label htmlFor="companyPhone"><FaPhoneAlt /></label>
                        <input
                          type="tel"
                          id="companyPhone"
                          name="companyPhone"
                          placeholder='Company Phone'

                          value={formData?.phone}
                          onChange={(e) => handleChange('phone', e.target.value)}
                        />
                      </div>
                    </div>

                  </div>
                  <div className='col-6'>
                    <div className='row'>
                      <div className='col-12'>
                        <label htmlFor="trading_addr"><MdLocationPin /></label>


                        <input
                          type="tel"
                          id="trading_addr"
                          name="trading_addr"
                          placeholder='Trading Address'
                          value={formData?.trading_addr}
                          onChange={(e) => handleChange('trading_addr', e.target.value)}
                        />
                      </div>
                    </div>

                  </div>


                  <div className='col-12'>
                    <div className='row'>
                      <div className='col-12'>
                        <label htmlFor="companyAddress"><MdLocationPin /></label>


                        <input
                          id="companyAddress"
                          name="companyAddress"
                          placeholder="Company Address"
                          value={formData?.address}
                          onChange={(e) => handleChange('address', e.target.value)}
                        />
                      </div>
                    </div>

                  </div>

                </div>
              </div>
              <div className="tab-pane fade" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
                <div className="step-content row" style={{ width: "98%" }}>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <button style={{ padding: ".5rem 1rem", border: "none", color: "white", background: "#007bff", borderRadius: "5px" }} onClick={addSocialChannel}>Add More</button>
                  </div>
                  {formData?.social_links?.map((channel, index) => (
                    <div style={{ alignItems: "center" }} key={index} className='row'>
                      <div className='col-2'>
                        <div style={{ height: "80px", width: "80px", borderRadius: "50%", border: "1px solid", position: "relative" }}>
                          <label style={{ border: "1px solid", borderRadius: "50%", width: "25px", height: "25px", background: "lightgray", color: "gray", position: "absolute", right: "0%", paddingLeft: ".2rem", paddingBottom: ".2rem" }} htmlFor={`channelLogo${index}`}><LuImagePlus /></label>
                          <input
                            type="file"
                            id={`channelLogo${index}`}
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              const newData = [...formData?.social_links]
                              if (e.target.files[0]) {
                                const reader = new FileReader();
                                reader.onload = (event) => {
                                  const base64String = event.target.result;
                                  // setSelectedImage(URL.createObjectURL(file));
                                  // setFormData({ ...formData, [field]: base64String });
                                  newData[index].icon = base64String
                                  setFormData({ ...formData, social_links: newData })

                                };
                                reader.readAsDataURL(e.target.files[0]);
                              }
                            }}
                          />
                          <img src={channel?.icon?.split(',').length > 1 ? channel?.icon : img_url + channel?.icon} style={{ width: "100%", height: "100%", borderRadius: "50%" }} />
                        </div>
                      </div>
                      <div className='col-4'>
                        <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: "flex", alignItems: "baseline" }}>
                          <label htmlFor={`social_links${index}`}><BiLinkAlt /></label>
                          <input
                            type="text"
                            id={`social_links${index}`}
                            placeholder='Channel Link'
                            value={channel.link}
                            onChange={(e) => {
                              const newData = [...formData?.social_links]
                              newData[index].link = e.target.value
                              setFormData({ ...formData, social_links: newData })
                            }}
                          />
                        </div>
                      </div>
                      <div className='col-2'>
                        {index > 0 && (
                          <button style={{ padding: "4px 10px", border: "none", color: "white", background: "#007bff", borderRadius: "5px" }} onClick={() => removeSocialChannel(index)}>
                            <MdOutlineDeleteSweep />
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="tab-pane fade" id="ex1-tabs-3" role="tabpanel" aria-labelledby="ex1-tab-3">
                <div className="step-content row">
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <button style={{ padding: ".5rem 1rem", border: "none", color: "white", background: "#007bff", borderRadius: "5px" }} onClick={addFinePolicy}>Add More</button>
                  </div>
                  {formData?.fine_policy?.map((policy, index) => (
                    <div style={{ alignItems: "center", width: "98%" }} key={index} className='row'>
                      <div className='col-1'>
                        {index > 0 && (
                          <button style={{ padding: "0rem 1rem", border: "none", color: "white", background: "#007bff", borderRadius: "5px", display: "flex", justifyContent: "center", alignItems: "center", width: "max-content" }} onClick={() => removeFinePolicy(index)}>
                            -
                          </button>
                        )}
                      </div>
                      <div className='col-3'>
                        <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: "flex", alignItems: "baseline" }}>
                          <label htmlFor={`minutes${index}`}><BiTimeFive /></label>
                          <input
                            type="text"
                            id={`minutes${index}`}
                            placeholder='Minutes'
                            value={policy.time}
                            onChange={(e) => {
                              const newData = [...formData?.fine_policy]
                              newData[index].time = e.target.value
                              setFormData({ ...formData, fine_policy: newData })
                            }}
                          />
                        </div>
                      </div>
                      <div className='col-4'>
                        <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem" }}>
                          <label htmlFor={`option${index}`}><IoOptionsOutline /></label>
                          <select
                            id={`option${index}`}
                            style={{ border: "none", outline: "none", width: "90%", fontSize: "14px" }}
                            value={policy.type}
                            onChange={(e) => {
                              const newData = [...formData?.fine_policy]
                              newData[index].type = e.target.value
                              setFormData({ ...formData, fine_policy: newData })
                            }}
                          >
                            <option hidden>Select</option>
                            <option value="fixed">Fixed Amount</option>
                            <option value="percentage">Percentage of Salary</option>
                          </select>
                        </div>
                      </div>
                      <div className='col-4'>
                        <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: "flex", alignItems: "baseline" }}>
                          <label htmlFor={`amount${index}`}><AiOutlineDollarCircle /></label>
                          <input
                            type="text"
                            id={`amount${index}`}
                            value={policy.fine}
                            placeholder='Amount'
                            onChange={(e) => {
                              const newData = [...formData?.fine_policy]
                              newData[index].fine = e.target.value
                              setFormData({ ...formData, fine_policy: newData })
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="tab-pane fade" id="ex1-tabs-4" role="tabpanel" aria-labelledby="ex1-tab-4">
                <div style={{ padding: "1rem" }}>
                  <div className="timing-form">
                    {formData?.timings?.map((day, index) => (
                      <div key={index} className='row'>
                        <div className='col-2'>
                          <p>{day.day}</p>
                        </div>
                        <div className='col-3'>
                          <label>Start Time</label>
                          <input
                            type='time'
                            className='form-control'
                            value={formData?.timings?.startTime}
                            onChange={(e) => {
                              const newData = [...formData?.timings]
                              newData[index].startTime = e.target.value
                              setFormData({ ...formData, timings: newData })
                              calculateTotalTime(day.day, index)
                            }}
                          />
                        </div>
                        <div className='col-3'>
                          <label>End Time</label>
                          <input
                            type='time'
                            className='form-control'
                            onChange={(e) => {
                              const newData = [...formData?.timings]
                              newData[index].endTime = e.target.value
                              setFormData({ ...formData, timings: newData })
                              calculateTotalTime(day.day, index)
                            }}
                          />
                        </div>
                        <div className='col-4 pt-4'>
                          <label>Total Time:</label>
                          <span>{day.totalTime}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="ex1-tabs-5" role="tabpanel" aria-labelledby="ex1-tab-5">
                <div style={{ padding: "1rem" }}>
                  <div className='smtpData'>
                    <div className='row'>
                      <div className="col-md-6 mb-3">
                        <label>Mailer</label>
                        <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: 'flex' }}>
                          <label><MdOutlineIndeterminateCheckBox /></label>
                          <input
                            className='border-0 w-100'
                            style={{ marginLeft: "10px" }}
                            type="text"
                            value={formData?.smtp[0]?.mail_mailer}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                smtp: [{ ...formData.smtp[0], mail_mailer: e.target.value }],
                              })
                            }
                            placeholder="Mail Host Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label>Mail Encryption</label>
                        <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: 'flex' }}>
                          <label><MdOutlineIndeterminateCheckBox /></label>
                          <input
                            className='border-0 w-100'
                            style={{ marginLeft: "10px" }}
                            type="text"
                            value={formData?.smtp[0]?.mail_encryption}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                smtp: [{ ...formData.smtp[0], mail_encryption: e.target.value }],
                              })
                            }
                            placeholder="Mail Host Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label>Mail Host Name</label>
                        <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: 'flex' }}>
                          <label><MdOutlineIndeterminateCheckBox /></label>
                          <input
                            className='border-0 w-100'
                            style={{ marginLeft: "10px" }}
                            type="text"
                            value={formData?.smtp[0]?.mail_host}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                smtp: [{ ...formData.smtp[0], mail_host: e.target.value }],
                              })
                            }
                            placeholder="Mail Host Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label>Mail Port</label>
                        <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: 'flex' }}>
                          <label><MdOutlineIndeterminateCheckBox /></label>
                          <input
                            className='border-0 w-100'
                            style={{ marginLeft: "10px" }}
                            type="number"
                            placeholder='Mail Port'
                            value={formData?.smtp[0]?.mail_port}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                smtp: [{ ...formData.smtp[0], mail_port: e.target.value }],
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label>Mail User Name</label>
                        <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: 'flex' }}>
                          <label><MdOutlineIndeterminateCheckBox /></label>
                          <input
                            className='border-0 w-100'
                            style={{ marginLeft: "10px" }}
                            type="text"
                            placeholder='Mail User Name'
                            value={formData?.smtp[0]?.mail_username}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                smtp: [{ ...formData.smtp[0], mail_username: e.target.value }],
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label>Mail Password</label>
                        <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: 'flex' }}>
                          <label><MdOutlineIndeterminateCheckBox /></label>
                          <input
                            className='border-0 w-100'
                            style={{ marginLeft: "10px" }}
                            type="text"
                            placeholder='Mail Password'
                            value={formData?.smtp[0]?.mail_password}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                smtp: [{ ...formData.smtp[0], mail_password: e.target.value }],
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label>Mail From Address</label>
                        <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: 'flex' }}>
                          <label><MdOutlineIndeterminateCheckBox /></label>
                          <input
                            className='border-0 w-100'
                            style={{ marginLeft: "10px" }}
                            type="text"
                            placeholder='Mail From Address'
                            value={formData?.smtp[0]?.from_address}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                smtp: [{ ...formData.smtp[0], from_address: e.target.value }],
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label>Mail From Name</label>
                        <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: 'flex' }}>
                          <label><MdOutlineIndeterminateCheckBox /></label>
                          <input
                            className='border-0 w-100'
                            style={{ marginLeft: "10px" }}
                            type="text"
                            placeholder='Mail From Name'
                            value={formData?.smtp[0]?.from_name}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                smtp: [{ ...formData.smtp[0], from_name: e.target.value }],
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='m-3' style={{ display: "flex", justifyContent: "end", gap: "10px" }}>
            <button onClick={edit} className='btn btn-primary'>Update</button>
            <button className='btn btn-mute' onClick={handleClose}>Cancel</button>
          </div>
        </Box>
      </Modal>

    </>
  )
}

export default EditCompany