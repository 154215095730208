import React, { useEffect, useState } from 'react'
import { useData } from '../../ContextProvider/Context'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import { Progress } from 'antd';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    boxShadow: 24,
};
function Applicatan() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [userData, setsuerData] = useState({})
    const { jobDetailData, getJobDetailById, img_url, base_url, handleClick, setSnackbarValue } = useData()
    const params = useParams()
    useEffect(() => {
        getJobDetailById(params.id)
    }, [params.id])
    const [selectedRows, setSelectedRows] = useState([]);
    const handleRowSelect = (rowId) => {
        if (selectedRows.includes(rowId)) {
            setSelectedRows(selectedRows.filter(id => id !== rowId));
        } else {
            setSelectedRows([...selectedRows, rowId]);
        }
    };
    const updatestatus = (obj) => {
        fetch(`${base_url}update-stage`, {
            method: "POST",
            headers: {
                'Content-Type': "application/json",
                "Accept": 'application/json',
            },
            body: JSON.stringify(obj)
        })
            .then((res) => res.json())
            .then((data) => {
                getJobDetailById(params.id)
                handleClick()
                setSnackbarValue({ message: "Stage Update successfully ", color: "#2ECC71" });
            });
    }
    const [date, setdata] = useState(null)
    const handleSubmit = (obj) => {
        if (date) {
            updatestatus(obj)
            setdata(null)
        }
        else {
            handleClick()
            setSnackbarValue({ message: "Select Date and Time", color: "#DC4C64" });
        }
    }
    return (
        <div className='applicant'>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ height: "90vh" }} className='row'>
                        <div style={{ background: "#3498db", height: "100%" }} className='col-3'>
                            <div style={{ height: "150px", margin: "20px", width: "150px", border: "3px solid white", borderRadius: "50%", overflow: "hidden" }}>
                                <img src={img_url + userData?.profile} alt='...' />
                            </div>
                            <h6 style={{ color: "white", marginTop: "1rem" }}>
                                Name:<span>{userData?.name}</span>
                            </h6>
                            <h6 style={{ color: "white", marginTop: "1rem" }}>
                                Email:<span>{userData?.email}</span>
                            </h6>
                            <h6 style={{ color: "white", marginTop: "1rem" }}>
                                Phone:<span>{userData?.phone}</span>
                            </h6>
                            <h6 style={{ color: "white", marginTop: "1rem" }}>
                                Status:<span>{userData?.marital_status}</span>
                            </h6>
                            <h6 style={{ color: "white", marginTop: "1rem" }}>
                                CNIC:<span>{userData?.cnic}</span>
                            </h6>

                        </div>
                        <div className='col-9'>
                            <div style={{ margin: "1rem", borderBottom: "1px solid lightgray" }}>
                                <h5>Description</h5>
                                <p>{userData?.description}</p>
                            </div>
                            <div style={{ margin: "1rem", borderBottom: "1px solid lightgray" }}>
                                <h5>Education</h5>
                                <ul>
                                    <li style={{ marginBottom: "1rem" }}>
                                        <div className='row'>
                                            <div className='col-4'>
                                                <div style={{ padding: "0rem 1rem", borderRadius: "50px", width: "100px" }}>
                                                    <p style={{ padding: "0", margin: "0", textAlign: "center" }}>Field</p>
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <div style={{ padding: "0rem 1rem", borderRadius: "50px", width: "100px" }}>
                                                    <p style={{ padding: "0", margin: "0", textAlign: "center" }}>Education</p>

                                                </div>

                                            </div>
                                            <div className='col-4'>
                                                <div style={{ padding: "0rem 1rem", borderRadius: "50px", width: "100px" }}>
                                                    <p style={{ padding: "0", margin: "0", textAlign: "center" }}>Documents</p>

                                                </div>

                                            </div>
                                        </div>

                                    </li>
                                    {userData?.education_data?.map((item) => {
                                        return (
                                            <li>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <div style={{ padding: "0rem 1rem", borderRadius: "50px", background: "#3498dbff", width: "100px" }}>
                                                            <p style={{ padding: "0", margin: "0", textAlign: "center", color: "white" }}>{item.field}</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-4'>
                                                        <div style={{ padding: "0rem 1rem", borderRadius: "50px", background: "#3498dbff", width: "100px" }}>
                                                            <p style={{ padding: "0", margin: "0", textAlign: "center", color: "white" }}>{item.education}</p>

                                                        </div>

                                                    </div>

                                                    <div className='col-4'>
                                                        <div >
                                                            <a href={img_url + item?.document} target='_blank' download className='btn btn-primary'>View</a>


                                                        </div>

                                                    </div>

                                                </div>

                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div style={{ margin: "1rem", borderBottom: "1px solid lightgray" }}>
                                <h5>Experience</h5>
                                <ul>
                                    <li style={{ marginBottom: "1rem" }}>
                                        <div className='row'>
                                            <div className='col-4'>
                                                <div style={{ padding: "0rem 1rem", borderRadius: "50px", width: "100px" }}>
                                                    <p style={{ padding: "0", margin: "0", textAlign: "center" }}>Company</p>
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <div style={{ padding: "0rem 1rem", borderRadius: "50px", width: "100px" }}>
                                                    <p style={{ padding: "0", margin: "0", textAlign: "center" }}>Post</p>

                                                </div>

                                            </div>
                                            <div className='col-4'>
                                                <div style={{ padding: "0rem 1rem", borderRadius: "50px", width: "100px" }}>
                                                    <p style={{ padding: "0", margin: "0", textAlign: "center" }}>Period</p>

                                                </div>

                                            </div>
                                        </div>

                                    </li>
                                    {userData?.experience?.map((item) => {
                                        return (
                                            <li>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <div style={{ padding: "0rem 1rem", borderRadius: "50px", background: "#3498dbff", width: "100px" }}>
                                                            <p style={{ padding: "0", margin: "0", textAlign: "center", color: "white" }}>{item.company}</p>
                                                        </div>
                                                    </div>
                                                    <div className='col-4'>
                                                        <div style={{ padding: "0rem 1rem", borderRadius: "50px", background: "#3498dbff", width: "100px" }}>
                                                            <p style={{ padding: "0", margin: "0", textAlign: "center", color: "white" }}>{item.post}</p>

                                                        </div>

                                                    </div>
                                                    <div className='col-4'>
                                                        <div style={{ padding: "0rem 1rem", borderRadius: "50px", background: "#3498dbff", width: "100px" }}>
                                                            <p style={{ padding: "0", margin: "0", textAlign: "center", color: "white" }}>{item.period}</p>

                                                        </div>

                                                    </div>
                                                </div>

                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div style={{ margin: "1rem", borderBottom: "1px solid lightgray" }}>
                                <h5>Skills</h5>
                                <ul>
                                    {userData?.skills?.map((item) => {
                                        return (
                                            <li style={{ display: "flex", justifyContent: "space-between" }}>
                                                <p>{item.name}</p>
                                                <Progress percent={item.percentage} style={{ width: "300px" }} status="active" />

                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>

                    </div>
                </Box>
            </Modal>
            <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
                {/* <li className="nav-item" role="presentation">
                    <a className="nav-link active" id="ex1-tab-1" data-mdb-toggle="tab" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1" aria-selected="true">Applicant</a>
                </li> */}
                <li className="nav-item" role="presentation">
                    <a className="nav-link active" id="ex1-tab-2" data-mdb-toggle="tab" href="#ex1-tabs-2" role="tab" aria-controls="ex1-tabs-2" aria-selected="true">Application Received</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link" id="ex1-tab-3" data-mdb-toggle="tab" href="#ex1-tabs-3" role="tab" aria-controls="ex1-tabs-3" aria-selected="false">Short List</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link" id="ex1-tab-4" data-mdb-toggle="tab" href="#ex1-tabs-4" role="tab" aria-controls="ex1-tabs-4" aria-selected="true">Interview</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link" id="ex1-tab-5" data-mdb-toggle="tab" href="#ex1-tabs-5" role="tab" aria-controls="ex1-tabs-5" aria-selected="true">Interview Secsond</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link" id="ex1-tab-6" data-mdb-toggle="tab" href="#ex1-tabs-6" role="tab" aria-controls="ex1-tabs-6" aria-selected="true">Interview Final</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link" id="ex1-tab-7" data-mdb-toggle="tab" href="#ex1-tabs-7" role="tab" aria-controls="ex1-tabs-7" aria-selected="false">Hired</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link" id="ex1-tab-8" data-mdb-toggle="tab" href="#ex1-tabs-8" role="tab" aria-controls="ex1-tabs-8" aria-selected="false">Pending</a>
                </li>
            </ul>
            {/* Tabs navs */}
            {/* Tabs content */}
            <div className="tab-content" id="ex1-content">
                {/* <div className="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
                    <div style={{ marginTop: "1rem", minHeight: "60vh" }} className='card'>
                        <div className="card-body recruitment table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Gender</th>
                                        <th scope="col">Emergency Contact</th>
                                        <th scope="col">CNIC</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {jobDetailData?.job_applicants?.map((item, i) => {
                                        return (
                                            <tr onClick={() => handleRowSelect(item.id)} style={{ background: `${selectedRows.includes(item.id) ? 'lightgray' : ''}` }}>
                                                <th scope="row">{i + 1}</th>
                                                <td scope="col"
                                                    onClick={() => {
                                                        handleOpen()
                                                        setsuerData(item)
                                                    }} style={{ cursor: "pointer" }}
                                                >{item.name}</td>
                                                <td scope="col">{item.email}</td>
                                                <td scope="col">{item.phone}</td>
                                                <td scope="col">{item.gender}</td>
                                                <td scope="col">{item.emergency_contact}</td>

                                                <td scope="col">{item.cnic}</td>

                                            </tr>
                                        )
                                    })}


                                </tbody>
                            </table>

                        </div>
                    </div>
                </div> */}
                <div className="tab-pane fade show active" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
                    <div style={{ display: "flex", justifyContent: "end" }}>
                        <button disabled={selectedRows.length ? false : true} style={{ padding: ".5rem 1rem", background: "#3498db", color: "white", border: "none", borderRadius: "5px", marginRight: "1rem" }}
                            onClick={() => updatestatus({
                                id: selectedRows,
                                shortlisted: true,
                            })}
                        >Short Listed</button>
                        <button disabled={selectedRows.length ? false : true} style={{ padding: ".5rem 1rem", background: "#3498db", color: "white", border: "none", borderRadius: "5px", margin: "0 .5rem" }}
                            onClick={() => updatestatus({
                                id: selectedRows,
                                on_hold: true,
                            })}>Pending</button>
                    </div>
                    <div style={{ marginTop: "1rem", minHeight: "60vh" }} className='card'>
                        <div className="card-body recruitment table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Gender</th>
                                        <th scope="col">Emergency Contact</th>

                                        <th scope="col">CNIC</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {jobDetailData?.application_received?.map((item, i) => {
                                        return (
                                            <tr onClick={() => handleRowSelect(item.id)} style={{ background: `${selectedRows.includes(item.id) ? 'lightgray' : ''}` }}>
                                                <th scope="row">{i + 1}</th>
                                                <td scope="col" onClick={() => {
                                                    handleOpen()
                                                    setsuerData(item)
                                                }} style={{ cursor: "pointer" }}>{item.name}</td>
                                                <td scope="col">{item.email}</td>
                                                <td scope="col">{item.phone}</td>
                                                <td scope="col">{item.gender}</td>
                                                <td scope="col">{item.emergency_contact}</td>

                                                <td scope="col">{item.cnic}</td>
                                                {/* <td scope="col">{item.salary}</td>   */}

                                            </tr>
                                        )
                                    })}


                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="ex1-tabs-3" role="tabpanel" aria-labelledby="ex1-tab-3">
                    <div style={{ display: "flex", justifyContent: "end" }}>
                        <input onChange={(e) => setdata(e.target.value)} type='datetime-local' />
                        <button disabled={selectedRows.length ? false : true} style={{ padding: ".5rem 1rem", background: "#3498db", color: "white", border: "none", borderRadius: "5px", margin: "0 .3rem" }}
                            onClick={() => handleSubmit(
                                {
                                    id: selectedRows,
                                    interview: true,
                                    date: date?.split("T")[0],
                                    time: date?.split("T")[1],
                                }
                            )}

                        >Interview</button>
                        <button disabled={selectedRows.length ? false : true} style={{ padding: ".5rem 1rem", background: "#3498db", color: "white", border: "none", borderRadius: "5px", margin: "0 .5rem" }}
                            onClick={() => updatestatus({
                                id: selectedRows,
                                on_hold: true,
                            })}>Pending</button>
                        {/* <button disabled={selectedRows.length ? false : true} style={{ padding: ".5rem 1rem", background: "#3498db", color: "white", border: "none", borderRadius: "5px", margin: "0 .5rem" }}
                            onClick={() => updatestatus({
                                id: selectedRows,
                                hired: true,
                            })}

                        >Hired</button> */}
                    </div>
                    <div style={{ marginTop: "1rem", minHeight: "60vh" }} className='card'>
                        <div className="card-body recruitment table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Gender</th>
                                        <th scope="col">Emergency Contact</th>
                                        <th scope="col">CNIC</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {jobDetailData?.shortlisted?.map((item, i) => {
                                        return (
                                            <tr onClick={() => handleRowSelect(item.id)} style={{ background: `${selectedRows.includes(item.id) ? 'lightgray' : ''}` }}>
                                                <th scope="row">{i + 1}</th>
                                                <td scope="col" onClick={() => {
                                                    handleOpen()
                                                    setsuerData(item)
                                                }} style={{ cursor: "pointer" }}>{item.name}</td>
                                                <td scope="col">{item.email}</td>
                                                <td scope="col">{item.email}</td>
                                                <td scope="col">{item.gender}</td>
                                                <td scope="col">{item.emergency_contact}</td>

                                                <td scope="col">{item.cnic}</td>
                                                {/* <td scope="col">{item.salary}</td>   */}

                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="ex1-tabs-4" role="tabpanel" aria-labelledby="ex1-tab-4">
                    <div style={{ display: "flex", justifyContent: "end" }}>
                        <input onChange={(e) => setdata(e.target.value)} type='datetime-local' />
                        <button disabled={selectedRows.length > 0 ? false : true} style={{ padding: ".5rem 1rem", background: "#3498db", color: "white", border: "none", borderRadius: "5px", margin: "0 .5rem" }}
                            onClick={() => handleSubmit(
                                {
                                    id: selectedRows,
                                    sec_interview: true,
                                    sec_date: date?.split("T")[0],
                                    sec_time: date?.split("T")[1],
                                }
                            )}

                        >Interview Secsond</button>
                        <button disabled={selectedRows.length ? false : true} style={{ padding: ".5rem 1rem", background: "#3498db", color: "white", border: "none", borderRadius: "5px", margin: "0 .5rem" }}
                            onClick={() => updatestatus({
                                id: selectedRows,
                                on_hold: true,
                            })}>Pending</button>
                        <button disabled={selectedRows.length ? false : true} style={{ padding: ".5rem 1rem", background: "#3498db", color: "white", border: "none", borderRadius: "5px", margin: "0 .5rem" }}
                            onClick={() => updatestatus({
                                id: selectedRows,
                                hired: true,
                            })}

                        >Hired</button>

                    </div>
                    <div style={{ marginTop: "1rem", minHeight: "60vh" }} className='card'>
                        <div className="card-body recruitment table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Gender</th>
                                        <th scope="col">Emergency Contact</th>
                                        <th scope="col">CNIC</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {jobDetailData?.interview?.map((item, i) => {
                                        return (
                                            <tr onClick={() => handleRowSelect(item.id)} style={{ background: `${selectedRows.includes(item.id) ? 'lightgray' : ''}` }}>
                                                <th scope="row">{i + 1}</th>
                                                <td scope="col" onClick={() => {
                                                    handleOpen()
                                                    setsuerData(item)
                                                }} style={{ cursor: "pointer" }}>{item.name}</td>
                                                <td scope="col">{item.email}</td>
                                                <td scope="col">{item.email}</td>
                                                <td scope="col">{item.gender}</td>
                                                <td scope="col">{item.emergency_contact}</td>

                                                <td scope="col">{item.cnic}</td>
                                                {/* <td scope="col">{item.salary}</td>   */}

                                            </tr>
                                        )
                                    })}


                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="ex1-tabs-5" role="tabpanel" aria-labelledby="ex1-tab-5">
                    <div style={{ display: "flex", justifyContent: "end" }}>
                        <input onChange={(e) => setdata(e.target.value)} type='datetime-local' />
                        <button disabled={selectedRows.length > 0 ? false : true} style={{ padding: ".5rem 1rem", background: "#3498db", color: "white", border: "none", borderRadius: "5px", margin: "0 .5rem" }}
                            onClick={() => handleSubmit(
                                {
                                    id: selectedRows,
                                    final_interview: true,
                                    final_date: date?.split("T")[0],
                                    final_time: date?.split("T")[1],
                                }
                            )}

                        >Interview Final</button>
                        <button disabled={selectedRows.length ? false : true} style={{ padding: ".5rem 1rem", background: "#3498db", color: "white", border: "none", borderRadius: "5px", margin: "0 .5rem" }}
                            onClick={() => updatestatus({
                                id: selectedRows,
                                hired: true,
                            })}

                        >Hired</button>
                        <button disabled={selectedRows.length ? false : true} style={{ padding: ".5rem 1rem", background: "#3498db", color: "white", border: "none", borderRadius: "5px", margin: "0 .5rem" }}
                            onClick={() => updatestatus({
                                id: selectedRows,
                                on_hold: true,
                            })}>Pending</button>

                    </div>
                    <div style={{ marginTop: "1rem", minHeight: "60vh" }} className='card'>
                        <div className="card-body recruitment table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Gender</th>
                                        <th scope="col">Emergency Contact</th>
                                        <th scope="col">CNIC</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {jobDetailData?.sec_interview?.map((item, i) => {
                                        return (
                                            <tr onClick={() => handleRowSelect(item.id)} style={{ background: `${selectedRows.includes(item.id) ? 'lightgray' : ''}` }}>
                                                <th scope="row">{i + 1}</th>
                                                <td scope="col" onClick={() => {
                                                    handleOpen()
                                                    setsuerData(item)
                                                }} style={{ cursor: "pointer" }}>{item.name}</td>
                                                <td scope="col">{item.email}</td>
                                                <td scope="col">{item.email}</td>
                                                <td scope="col">{item.gender}</td>
                                                <td scope="col">{item.emergency_contact}</td>

                                                <td scope="col">{item.cnic}</td>
                                                {/* <td scope="col">{item.salary}</td>   */}

                                            </tr>
                                        )
                                    })}


                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="ex1-tabs-6" role="tabpanel" aria-labelledby="ex1-tab-6">
                    <div style={{ display: "flex", justifyContent: "end" }}>
                        <button disabled={selectedRows.length ? false : true} style={{ padding: ".5rem 1rem", background: "#3498db", color: "white", border: "none", borderRadius: "5px", margin: "0 .5rem" }}
                            onClick={() => updatestatus({
                                id: selectedRows,
                                hired: true,
                            })}

                        >Hired</button>
                        <button disabled={selectedRows.length ? false : true} style={{ padding: ".5rem 1rem", background: "#3498db", color: "white", border: "none", borderRadius: "5px", margin: "0 .5rem" }}
                            onClick={() => updatestatus({
                                id: selectedRows,
                                on_hold: true,
                            })}>Pending</button>
                    </div>
                    <div style={{ marginTop: "1rem", minHeight: "60vh" }} className='card'>
                        <div className="card-body recruitment table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Gender</th>
                                        <th scope="col">Emergency Contact</th>
                                        <th scope="col">CNIC</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {jobDetailData?.final_interview?.map((item, i) => {
                                        return (
                                            <tr onClick={() => handleRowSelect(item.id)} style={{ background: `${selectedRows.includes(item.id) ? 'lightgray' : ''}` }}>
                                                <th scope="row">{i + 1}</th>
                                                <td scope="col" onClick={() => {
                                                    handleOpen()
                                                    setsuerData(item)
                                                }} style={{ cursor: "pointer" }}>{item.name}</td>
                                                <td scope="col">{item.email}</td>
                                                <td scope="col">{item.email}</td>
                                                <td scope="col">{item.gender}</td>
                                                <td scope="col">{item.emergency_contact}</td>

                                                <td scope="col">{item.cnic}</td>
                                                {/* <td scope="col">{item.salary}</td>   */}

                                            </tr>
                                        )
                                    })}


                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="ex1-tabs-7" role="tabpanel" aria-labelledby="ex1-tab-7">
                    <div style={{ display: "flex", justifyContent: "end" }}>
                        {/* <button disabled style={{padding:".5rem 1rem",background:"#3498db",color:"white",border:"none",borderRadius:"5px"}}>Create Employee</button> */}
                    </div>
                    <div style={{ marginTop: "1rem", minHeight: "60vh" }} className='card'>
                        <div className="card-body recruitment table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Gender</th>
                                        <th scope="col">Emergency Contact</th>
                                        <th scope="col">CNIC</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {jobDetailData?.hired?.map((item, i) => {
                                        return (
                                            <tr onClick={() => handleRowSelect(item.id)} style={{ background: `${selectedRows.includes(item.id) ? 'lightgray' : ''}` }}>
                                                <th scope="row">{i + 1}</th>
                                                <td scope="col" onClick={() => {
                                                    handleOpen()
                                                    setsuerData(item)
                                                }} style={{ cursor: "pointer" }}>{item.name}</td>
                                                <td scope="col">{item.email}</td>
                                                <td scope="col">{item.email}</td>
                                                <td scope="col">{item.gender}</td>
                                                <td scope="col">{item.emergency_contact}</td>

                                                <td scope="col">{item.cnic}</td>
                                                {/* <td scope="col">{item.salary}</td>   */}

                                            </tr>
                                        )
                                    })}


                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="ex1-tabs-8" role="tabpanel" aria-labelledby="ex1-tab-8">
                    <div style={{ display: "flex", justifyContent: "end" }}>
                        <input onChange={(e) => setdata(e.target.value)} type='datetime-local' />
                        <button disabled={selectedRows.length ? false : true} style={{ padding: ".5rem 1rem", background: "#3498db", color: "white", border: "none", borderRadius: "5px", margin: "0 .5rem" }}
                            onClick={() => handleSubmit(
                                {
                                    id: selectedRows,
                                    interview: true,
                                    date: date?.split("T")[0],
                                    time: date?.split("T")[1],
                                }
                            )}

                        >Interview</button>
                    </div>
                    <div style={{ marginTop: "1rem", minHeight: "60vh" }} className='card'>
                        <div className="card-body recruitment table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Phone</th>
                                        <th scope="col">Gender</th>
                                        <th scope="col">Emergency Contact</th>
                                        <th scope="col">CNIC</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {jobDetailData?.on_hold?.map((item, i) => {
                                        return (
                                            <tr onClick={() => handleRowSelect(item.id)} style={{ background: `${selectedRows.includes(item.id) ? 'lightgray' : ''}` }}>
                                                <th scope="row">{i + 1}</th>
                                                <td scope="col" onClick={() => {
                                                    handleOpen()
                                                    setsuerData(item)
                                                }} style={{ cursor: "pointer" }}>{item.name}</td>
                                                <td scope="col">{item.email}</td>
                                                <td scope="col">{item.email}</td>
                                                <td scope="col">{item.gender}</td>
                                                <td scope="col">{item.emergency_contact}</td>

                                                <td scope="col">{item.cnic}</td>
                                                {/* <td scope="col">{item.salary}</td>   */}

                                            </tr>
                                        )
                                    })}


                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

            </div>
            {/* Tabs content */}
        </div>
    )
}

export default Applicatan