import React, { useEffect, useState } from 'react'
import { VscListFilter } from "react-icons/vsc";
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import { BarChart } from '@mui/x-charts/BarChart';
import { useData } from '../ContextProvider/Context';
import { useParams } from 'react-router-dom';



function AllProjectReporting() {
    const { base_url } = useData()
    const [servicecategory, setServiceCategory] = useState([])
    useEffect(() => {
        getServicesCategory()
        getServicesCategory1()
    }, [])
    const [xLabels1, setxLabels] = useState([])
    const [uData1, setuData] = useState([])
    // const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
    // const uData = uData1;
    // const xLabels = xLabels1
    const getServicesCategory1 = () => {
        fetch(`${base_url}completed-tasks `)
            .then((res) => res.json())
            .then((data) => {
                var array = []
                var array1 = []
                data?.map((item) => {
                    array.push(item?.project_name)
                    array1.push(item?.completed_count)
                })
                setuData(array1)
                setxLabels(array)
            });
    };
    const getServicesCategory = () => {
        fetch(`${base_url}tasks/counts`)
            .then((res) => res.json())
            .then((data) => {
                setServiceCategory(data);
            });
    };


    const data = [
        { value: Math.floor(servicecategory?.completed_percentage), label: 'Completed' },
        { value: Math.floor(servicecategory?.uncompleted_percentage), label: 'Uncompleted' },

    ];

    const size = {
        width: 400,
        height: 200,
    };

    const StyledText = styled('text')(({ theme }) => ({
        fill: theme.palette.text.primary,
        textAnchor: 'middle',
        dominantBaseline: 'central',
        fontSize: 20,
    }));

    return (
        <div>
            <div className='charts mt-4 container'>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='card p-4 d-flex align-items-center'>
                            <h4>
                                Completed Tasks
                            </h4>
                            <h1>{servicecategory?.completed_tasks}</h1>                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='card p-4 d-flex align-items-center'>
                            <h4>
                                Incomplete Tasks
                            </h4>
                            <h1>{servicecategory?.uncompleted_tasks}</h1>                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='card p-4 d-flex align-items-center'>
                            <h4>
                                Total Tasks
                            </h4>
                            <h1>{servicecategory?.total_tasks}</h1>                        </div>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-md-6'>
                        <div className='card'>
                            <div className='card-body'>
                                <h6 style={{ fontWeight: '600' }}>Completed tasks by project</h6>
                                {uData1.length && xLabels1.length && <BarChart
                                    width={500}
                                    height={300}
                                    series={[
                                        { data: uData1, label: 'Task', id: 'Complete Task' },
                                    ]}
                                    xAxis={[{ data: xLabels1, scaleType: 'band' }]}
                                />}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className=' card p-5'>
                            <div className='card-body'>
                                <h6 style={{ fontWeight: '600' }}>Task by completion status this month</h6>
                                <PieChart series={[{ data, innerRadius: 60 }]} {...size}>
                                    {/* <PieCenterLabel>13</PieCenterLabel> */}
                                </PieChart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllProjectReporting