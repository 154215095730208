import React from 'react'
import logo from '../Images/pngwing.com.png'
import { Link } from 'react-router-dom'
import { Box, TextField } from '@mui/material'
function    Reset_Password_email() {
    return (
        <>
            <div className="auth-wrapper">
                <div className="auth-content">
                    <div className="card">
                        <div className="row align-items-center text-center">
                            <div className="col-md-12">
                                <div className="card-body">
                                    <img src={logo} alt="" className="img-fluid mb-4" />
                                    <h4 className="mb-3 f-w-400" style={{color:"#37474f"}}>Reset your Password</h4>
                                    <div className="form-group mb-3">
                                        <Box
                                            component="form"
                                            sx={{
                                                '& > :not(style)': { m: 0, width: '100%' },
                                                '& label.MuiInputLabel-root': { fontSize: '14px' }, // Custom label style
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <TextField id="email" label="Email Address" variant="standard" />
                                        </Box>
                                    </div>
                                    <button className="btn btn-block btn-primary mb-4 w-100 has-ripple" style={{ borderRadius: "2px", fontSize: "15px", height: "43px" }}>Reset Password</button>
                                    <p className="mb-0 text-muted" style={{ fontSize: "14px" }}>Don’t have an account? <Link to={"/signin"} style={{ textDecoration: "none" }} className="f-w-400">Signup</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Reset_Password_email
