import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
// import img1 from '../Images/user/avatar-2.jpg'
import { CloudUploadOutlined, UserOutlined } from '@ant-design/icons';
import { Tabs, Menu } from 'antd';
import { useData } from '../ContextProvider/Context';
import { BiEdit } from 'react-icons/bi';
import { CiBookmarkCheck, CiCalendarDate, CiClock2, CiCreditCard1, CiHardDrive, CiLocationOn, CiMail, CiMemoPad, CiMoneyBill, CiPassport1, CiPhone, CiSettings } from 'react-icons/ci';
import { LuActivitySquare } from 'react-icons/lu';
import EditEmplyee from './EditEmplyee';
import { PiGraduationCapLight } from 'react-icons/pi';
import { TbUserStar } from 'react-icons/tb';
import DeleteEmployee from './DeleteEmployee';
import EmloyessTiming from './EmloyessTiming';
import EmplyeePerformenes from './EmplyeePerformenes';
import DownloadPDFButton from './DownloadPDFButton ';


function EmployeeProfile() {
    const { getEmployeesIndividual, permission, employeeIndividual, img_url, getEmpAttandance, empAttendance, getEmployeAttFilter } = useData();
    const params = useParams();
    const [emloyeeData, setEmployeesData] = useState({})
    useEffect(() => {
        getEmployeesIndividual(params.id)
        getEmpAttandance(params.id)
    }, [params.id])
    useEffect(() => {
        setEmployeesData(employeeIndividual?.data)

    }, [employeeIndividual])

    return (
        <>
            <div className="pcoded-main-container employeeProfileData">
                <div className="pcoded-content">
                    <div className="user-profile user-card">
                        <div className="card-body py-0">
                            <div className="user-about-block m-0">
                                <div className="row" style={{ padding: "1rem" }}>

                                    <div className='col-2'>
                                        <div style={{ height: "150px", width: "150px", borderRadius: "5px", position: "relative", overflow: "hidden" }}>
                                            <img src={employeeIndividual?.data?.profile ? img_url + employeeIndividual?.data?.profile : "https://newvintagewi.org/wp-content/uploads/2021/07/final-resized-male-image.jpg"} style={{ position: "absolute", width: "100%", height: "100%" }} alt='...' />
                                        </div>
                                    </div>
                                    <div className='col-6' style={{ padding: "0 1rem" }}>
                                        <div style={{ borderBottom: "1px solid lightgray" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <h5 style={{ color: "#1677FF", fontWeight: "bold" }}>{employeeIndividual?.data?.name}</h5>

                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <h6 style={{ color: "gray", fontSize: "13px", padding: ".3rem 1rem" }}> <CiBookmarkCheck style={{ fontSize: "24px", marginRight: ".5rem", color: "#2e70bc" }} />{employeeIndividual?.data?.designation?.title}</h6>
                                                <h6 style={{ color: "gray", fontSize: "13px", padding: ".3rem 1rem" }}> <CiMemoPad style={{ fontSize: "24px", marginRight: ".5rem", color: "#2e70bc" }} />{employeeIndividual?.data?.department?.department}</h6>
                                                <h6 style={{ display: "flex", alignItems: "center", color: "gray", fontSize: "13px", padding: ".3rem 1rem" }}><CiCalendarDate style={{ fontSize: "24px", marginRight: ".5rem", color: "#2e70bc" }} />{employeeIndividual?.data?.doj}</h6>
                                                <h6 style={{ display: "flex", alignItems: "center", color: "gray", fontSize: "13px", padding: ".3rem 1rem" }}><CiMoneyBill style={{ fontSize: "24px", marginRight: ".5rem", color: "#2e70bc" }} />{employeeIndividual?.data?.salary}</h6>
                                                <h6 style={{ display: "flex", alignItems: "center", color: "gray", fontSize: "13px", padding: ".3rem 1rem" }}>{employeeIndividual?.data?.status}</h6>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem" }}>
                                            <h6 style={{ color: "gray", fontSize: "13px" }}> <CiPhone style={{ fontSize: "24px", marginRight: ".5rem", color: "#2e70bc" }} />{employeeIndividual?.data?.phone}</h6>
                                            <h6 style={{ color: "gray", fontSize: "13px" }}><CiLocationOn style={{ fontSize: "24px", marginRight: ".5rem", color: "#2e70bc" }} />{employeeIndividual?.data?.address}</h6>
                                            <h6 style={{ color: "gray", fontSize: "13px" }}><CiMail style={{ fontSize: "24px", marginRight: ".5rem", color: "#2e70bc" }} />{employeeIndividual?.data?.email}</h6>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "end", marginTop: "1rem" }}>
                                            <DownloadPDFButton buttonText={"Download CV"} cv={employeeIndividual?.data?.cv} pdfUrl={img_url + employeeIndividual?.data?.cv} fileName={employeeIndividual?.data?.name} />
                                            {permission.includes("Bonus Add") && <Link to={`/admin/Create-commissions/${params.id}`} style={{ textDecoration: "none" }}>
                                                <button type="button" className="btn btn-primary" style={{ marginRight: "15px" }}>Create Commissions</button>

                                            </Link>}
                                            <EditEmplyee employeeData={emloyeeData} setEmployees={setEmployeesData} />
                                            <DeleteEmployee id={emloyeeData?.id} />

                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <EmplyeePerformenes performance={employeeIndividual?.data} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row employeeBody">
                        <div className="col-md-8 order-md-2">
                            <Tabs
                                defaultActiveKey="2"
                                items={[
                                    {
                                        label: (
                                            <span className='tabLabel'>
                                                <UserOutlined />
                                                Profile
                                            </span>
                                        ),
                                        key: "2",
                                        children: (
                                            <section>
                                                <div className="container">
                                                    <div className='row'>
                                                        <div className="col-12">
                                                            <div className="card mb-4">
                                                                <div className='card-header bg-light' style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                                                                    <div><h5 style={{ fontWeight: "600", fontSize: "1.1rem" }}>Personal details</h5></div>

                                                                </div>

                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}>Full Name</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{emloyeeData?.name}</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}>Gender</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{emloyeeData?.gender}</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}>Birth Date</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{emloyeeData?.dob}</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}>Martail  Status</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{emloyeeData?.maritalStatus}</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}>CNIC</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{emloyeeData?.cnic}</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}>Location</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{emloyeeData?.address}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-12">
                                                            <div className="card mb-4">
                                                                <div className='card-header bg-light' style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                                                                    <div><h5 style={{ fontWeight: "600", fontSize: "1.1rem" }}>Contact details</h5></div>

                                                                </div>

                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}>Email Address</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{employeeIndividual?.data?.email}</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}>Mobile Number</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{employeeIndividual?.data?.phone}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-12">
                                                            <div className="card mb-4">
                                                                <div className='card-header bg-light' style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                                                                    <div><h5 style={{ fontWeight: "600", fontSize: "1.1rem" }}>Others details</h5></div>

                                                                </div>

                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}>Designation</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{emloyeeData?.designation?.title}</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}>Departments</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{emloyeeData?.department?.department}</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}>Salary</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{employeeIndividual?.data?.salary}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-12">
                                                            <div className="card mb-4">
                                                                <div className='card-header bg-light' style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                                                                    <div><h5 style={{ fontWeight: "600", fontSize: "1.1rem" }}>Account details</h5></div>

                                                                </div>

                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}>Account Holder</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{emloyeeData?.account_holder}</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}>Account Number</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{emloyeeData?.account_number}</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />

                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}>IBAN</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{emloyeeData?.IBAN}</p>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div className="row">
                                                                        <div className="col-sm-3">
                                                                            <p className="mb-0" style={{ fontWeight: "600" }}>Bank Name</p>
                                                                        </div>
                                                                        <div className="col-sm-9">
                                                                            <p className="text-muted mb-0">{emloyeeData?.bank_name}</p>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                        )
                                    },
                                    {
                                        label: (
                                            <span className='tabLabel'>
                                                <TbUserStar style={{ fontSize: "20px", marginRight: "10px" }} />
                                                Experience
                                            </span>
                                        ),
                                        key: "3",
                                        children: (
                                            <section>
                                                <div className="container">
                                                    <div className='row'>
                                                        <div className="col-12">
                                                            <div className="card mb-4">
                                                                <div className='card-header bg-light' style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                                                                    <div><h5 style={{ fontWeight: "600", fontSize: "1.1rem" }}>Experiences</h5></div>
                                                                </div>

                                                                <div className="card-body">
                                                                    <div className="row mt-0" style={{ borderBottom: "1px solid lightgray" }}>
                                                                        <div className="col-sm-1">
                                                                            <p style={{ margin: ".8rem 0", fontWeight: "600" }}>Sr #</p>
                                                                        </div>
                                                                        <div className="col-sm-3">
                                                                            <p style={{ margin: ".8rem 0", fontWeight: "600" }}>Company</p>
                                                                        </div>
                                                                        <div className="col-sm-3">
                                                                            <p style={{ margin: ".8rem 0", fontWeight: "600" }}>Post</p>
                                                                        </div>
                                                                        <div className="col-sm-3">
                                                                            <p style={{ margin: ".8rem 0", fontWeight: "600" }}>Period</p>
                                                                        </div>

                                                                    </div>
                                                                    {emloyeeData?.experience?.map((exp, idx) => (
                                                                        <div key={idx} className="row" style={{ borderBottom: "1px solid lightgray" }}>
                                                                            <div className="col-sm-1">
                                                                                <p className="text-muted" style={{ margin: ".8rem 0" }}>{idx + 1}</p>
                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <p className="text-muted" style={{ margin: ".8rem 0" }}>{exp.company}</p>
                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <p className="text-muted" style={{ margin: ".8rem 0" }}>{exp.post}</p>
                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <p className="text-muted" style={{ margin: ".8rem 0" }}>{exp.period}</p>
                                                                            </div>

                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                        )
                                    },
                                    {
                                        label: (
                                            <span className='tabLabel'>
                                                <PiGraduationCapLight style={{ marginRight: "10px", fontSize: "20px" }} />
                                                Education
                                            </span>
                                        ),
                                        key: "4",
                                        children: (
                                            <section>
                                                <div className="container">
                                                    <div className='row'>
                                                        <div className="col-12">
                                                            <div className="card mb-4">
                                                                <div className='card-header bg-light' style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                                                                    <div><h5 style={{ fontWeight: "600", fontSize: "1.1rem" }}>Education</h5></div>

                                                                </div>

                                                                <div className="card-body">
                                                                    <div className="row mt-0" style={{ borderBottom: "1px solid lightgray" }}>
                                                                        <div className="col-sm-1">
                                                                            <p style={{ margin: ".8rem 0", fontWeight: "600" }}>Sr #</p>
                                                                        </div>
                                                                        <div className="col-sm-3">
                                                                            <p style={{ margin: ".8rem 0", fontWeight: "600" }}>Field</p>
                                                                        </div>
                                                                        <div className="col-sm-3">
                                                                            <p style={{ margin: ".8rem 0", fontWeight: "600" }}>Education</p>
                                                                        </div>
                                                                        <div className="col-sm-3">
                                                                            <p style={{ margin: ".8rem 0", fontWeight: "600" }}>Document</p>
                                                                        </div>

                                                                    </div>
                                                                    {emloyeeData?.education_data?.map((exp, idx) => (
                                                                        <div key={idx} className="row " style={{ borderBottom: "1px solid lightgray", padding: "10px" }}>
                                                                            <div className="col-sm-1">
                                                                                <p className="text-muted" style={{ margin: ".8rem 0" }}>{idx + 1}</p>
                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <p className="text-muted" style={{ margin: ".8rem 0" }}>{exp.field}</p>
                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <p className="text-muted" style={{ margin: ".8rem 0" }}>{exp.education}</p>
                                                                            </div>
                                                                            <div className="col-sm-3 d-flex align-items-center">
                                                                                <a href={img_url + exp?.document} target='_blank' download className='btn btn-primary'>View</a>
                                                                            </div>

                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        )
                                    },
                                    {
                                        label: (
                                            <span className='tabLabel'>
                                                <CiSettings style={{ fontSize: "20px", marginRight: "10px" }} />
                                                Skills
                                            </span>
                                        ),
                                        key: "5",
                                        children: (
                                            <section>
                                                <div className='card'>
                                                    <div className='card-body'>
                                                        <div className='container'>
                                                            {emloyeeData?.skills?.map((item) => {
                                                                return (
                                                                    <div className='row mb-3' key={item.id}>
                                                                        <div className='col-sm-4'>
                                                                            <h6>{item.name}</h6>
                                                                        </div>
                                                                        <div className='col-sm-8'>
                                                                            <div className="progress"
                                                                                style={{
                                                                                    height: '100%',
                                                                                    borderRadius: '10px',
                                                                                    border: '1px solid lightblue'
                                                                                }}
                                                                            >
                                                                                <div className="progress-bar progress-bar-success" role="progressbar" aria-valuenow={40}
                                                                                    aria-valuemin={0} aria-valuemax={100} style={{ width: `${item.percentage}%` }}>
                                                                                    {item.percentage}%
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}

                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        )
                                    },
                                    {
                                        label: (
                                            <span className='tabLabel'>
                                                <CiClock2 style={{ fontSize: "20px", marginRight: "10px" }} />
                                                Timings
                                            </span>
                                        ),
                                        key: "6",
                                        children: (
                                            <EmloyessTiming id={emloyeeData?.id} />
                                        )
                                    },
                                    {
                                        label: (
                                            <span className='tabLabel'>
                                                <CiCreditCard1 style={{ fontSize: "20px", marginRight: "10px" }} />
                                                Attendance Status
                                            </span>
                                        ),
                                        key: "7",
                                        children: (
                                            <section>
                                                <div className="container">
                                                    <div className='row'>
                                                        <div className="col-12">
                                                            <div className="card mb-4">
                                                                <div className='card-header bg-light' style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                                                                    <div><h5 style={{ fontWeight: "600", fontSize: "1.1rem" }}>Attendance</h5></div>
                                                                    <div>
                                                                        <select className='form-select' onChange={(e) => getEmployeAttFilter(params.id, e.target.value)}>
                                                                            <option hidden>Select Month</option>
                                                                            <option value=''>Month</option>
                                                                            <option value='01'>January</option>
                                                                            <option value='02'>Febuary</option>
                                                                            <option value='03'>March</option>
                                                                            <option value='04'>April</option>
                                                                            <option value='05'>May</option>
                                                                            <option value='06'>June</option>
                                                                            <option value='07'>July</option>
                                                                            <option value='08'>August</option>
                                                                            <option value='09'>September</option>
                                                                            <option value='10'>Octuber</option>
                                                                            <option value='11'>November</option>
                                                                            <option value='12'>December</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className="card-body scrollBar" style={{ overflowY: "scroll", height: "600px" }}>
                                                                    <div className="row mt-0" style={{ borderBottom: "1px solid lightgray" }}>
                                                                        <div className="col-sm-2">
                                                                            <p style={{ margin: ".8rem 0", fontWeight: "600" }}>Sr #</p>
                                                                        </div>
                                                                        <div className="col-sm-2">
                                                                            <p style={{ margin: ".8rem 0", fontWeight: "600" }}>Date</p>
                                                                        </div>
                                                                        <div className="col-sm-2">
                                                                            <p style={{ margin: ".8rem 0", fontWeight: "600" }}>Clock in</p>
                                                                        </div>
                                                                        <div className="col-sm-2">
                                                                            <p style={{ margin: ".8rem 0", fontWeight: "600" }}>Clock out</p>
                                                                        </div>
                                                                        <div className="col-sm-2">
                                                                            <p style={{ margin: ".8rem 0", fontWeight: "600" }}>Worked Hours</p>
                                                                        </div>
                                                                    </div>
                                                                    {empAttendance?.attendance_status?.length > 0 ? empAttendance?.attendance_status?.map((item, idx) => (
                                                                        <div key={idx} className="row" style={{ borderBottom: "1px solid lightgray" }}>
                                                                            <div className="col-sm-2">
                                                                                <p className="text-muted" style={{ margin: ".8rem 0" }}>{idx + 1}</p>
                                                                            </div>
                                                                            <div className="col-sm-2">
                                                                                <p className="text-muted" style={{ margin: ".8rem 0" }}>{item.date}</p>
                                                                            </div>
                                                                            <div className="col-sm-2">
                                                                                <p className="text-muted" style={{ margin: ".8rem 0" }}>{item.clock_in}</p>
                                                                            </div>
                                                                            <div className="col-sm-2">
                                                                                <p className="text-muted" style={{ margin: ".8rem 0" }}>{item.clock_out}</p>
                                                                            </div>
                                                                            <div className="col-sm-2">
                                                                                <p className="text-muted" style={{ margin: ".8rem 0" }}>{item.total_time}</p>
                                                                            </div>
                                                                        </div>
                                                                    )) : <div className="row" style={{ borderBottom: "1px solid lightgray" }}>
                                                                        <p style={{ textAlign: "center" }}>No Data Found</p>
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        )
                                    },
                                    // {
                                    //     label: (
                                    //         <span className='tabLabel'>
                                    //             <CiCreditCard1 style={{ fontSize: "20px", marginRight: "10px" }} />
                                    //             Performance
                                    //         </span>
                                    //     ),
                                    //     key: "8",
                                    //     children: (
                                    //         <section>
                                    //             <div className="container">
                                    //                 <div className='row'>
                                    //                     <div className="col-12">
                                    //                         <div className="card mb-4">
                                    //                             <div className='card-header bg-light' style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                                    //                                 <div><h5 style={{ fontWeight: "600", fontSize: "1.1rem" }}>Performance</h5></div>
                                    //                                 <div>
                                    //                                     <select className='form-select' onChange={(e) => getEmployeAttFilter(params.id, e.target.value)}>
                                    //                                         <option hidden>Select Duration</option>
                                    //                                         <option value=''>Duration</option>
                                    //                                         <option value='01'>Last Week</option>
                                    //                                         <option value='02'>Last Month</option>
                                    //                                     </select>
                                    //                                 </div>
                                    //                             </div>

                                    //                             <div className="card-body scrollBar" style={{ overflowY: "scroll", height: "600px" }}>
                                    //                                 <div className='row mb-3'>
                                    //                                     <div className='card'>
                                    //                                         <p style={{ fontWeight: "bold", fontSize: "15px", margin: "1rem 0rem !important" }}>current task</p>
                                    //                                     </div>
                                    //                                 </div>
                                    //                                 <div className="row mt-0" style={{ borderBottom: "1px solid lightgray" }}>
                                    //                                     <div className="col-sm-2">
                                    //                                         <p style={{ margin: ".8rem 0", fontWeight: "600" }}>Sr #</p>
                                    //                                     </div>
                                    //                                     <div className="col-sm-2">
                                    //                                         <p style={{ margin: ".8rem 0", fontWeight: "600" }}>Project</p>
                                    //                                     </div>
                                    //                                     <div className="col-sm-8">
                                    //                                         <p style={{ margin: ".8rem 0", fontWeight: "600" }}>Task</p>
                                    //                                     </div>
                                    //                                 </div>
                                    //                                 {empAttendance?.attendance_status?.length > 0 ? empAttendance?.attendance_status?.map((item, idx) => (
                                    //                                     <div key={idx} className="row" style={{ borderBottom: "1px solid lightgray" }}>
                                    //                                         <div className="col-sm-2">
                                    //                                             <p className="text-muted" style={{ margin: ".8rem 0" }}>{idx + 1}</p>
                                    //                                         </div>
                                    //                                         <div className="col-sm-2">
                                    //                                             <p className="text-muted" style={{ margin: ".8rem 0" }}>{item.date}</p>
                                    //                                         </div>
                                    //                                         <div className="col-sm-8">
                                    //                                             <p className="text-muted" style={{ margin: ".8rem 0" }}>{item.clock_in}</p>
                                    //                                         </div>
                                    //                                     </div>
                                    //                                 )) : <div className="row" style={{ borderBottom: "1px solid lightgray" }}>
                                    //                                     <p style={{ textAlign: "center" }}>No Data Found</p>
                                    //                                 </div>}
                                    //                             </div>
                                    //                         </div>
                                    //                     </div>
                                    //                 </div>
                                    //             </div>
                                    //         </section>
                                    //     )
                                    // },
                                ]}
                            />

                        </div>
                        <div className="col-md-4 order-md-1 css-dev-only">
                            <div className='headrow'>
                                <h1 className='heh1'> <LuActivitySquare /> Activity</h1>
                            </div>
                            <div className='col-12 scrollBar' style={{ height: "600px", overflowY: "scroll" }}>
                                {employeeIndividual?.logs?.map((items) => {
                                    const date = new Date(items.created_at);
                                    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                                    const formattedDay = daysOfWeek[date.getDay()];
                                    const formattedDate = `${formattedDay}, ${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
                                    const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
                                    return (
                                        <div className='activityLog card my-3 p-2' key={items.id}>
                                            <div style={{ display: "flex", gap: "10px", }}>
                                                <div>
                                                    <div style={{ height: "50px", width: "50px", border: "1px solid ", borderRadius: "50%" }}>
                                                        <img style={{ borderRadius: "50%" }} src={emloyeeData?.profile ? img_url + emloyeeData?.profile : "https://newvintagewi.org/wp-content/uploads/2021/07/final-resized-male-image.jpg"} height={"100%"} width={"100%"} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                        <div>
                                                            <h5 style={{ fontWeight: "bold", fontSize: "14px" }}> {emloyeeData?.name}</h5>
                                                        </div>
                                                        <div>
                                                            <p className='leads' style={{ marginBottom: "6px" }}>
                                                                {formattedDate} {formattedTime}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p className='leads mb-0'>{items.log}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default EmployeeProfile
