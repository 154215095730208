import React from 'react';
import { CiCircleMinus } from 'react-icons/ci'

import { MdOutlineIndeterminateCheckBox } from 'react-icons/md'
function Step6Component({ formData, setFormData, handleChange }) {

  return (
    <div>
      <div className='smtpData'>
        <div className='row'>
          <div className="col-md-6 mb-3">
            <label>Mailer</label>
            <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: 'flex' }}>
              <label><MdOutlineIndeterminateCheckBox /></label>
              <input
               id="daymy-4"
               name="daymy-4"
                className='border-0 w-100'
                style={{ marginLeft: "10px" }}
                type="text"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    smtp: [{ ...formData.smtp[0], mail_mailer: e.target.value }],
                  })
                }
                placeholder="Mail Host Name"
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <label>Mail Encrption</label>
            <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: 'flex' }}>
              <label><MdOutlineIndeterminateCheckBox /></label>
              <input
                id="daymy-5"
                name="daymy-5"
                className='border-0 w-100'
                style={{ marginLeft: "10px" }}
                type="text"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    smtp: [{ ...formData.smtp[0], mail_encryption: e.target.value }],
                  })
                }
                placeholder="Mail Host Name"
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <label>Mail Host Name</label>
            <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: 'flex' }}>
              <label><MdOutlineIndeterminateCheckBox /></label>
              <input
                id="daymy-6"
                name="daymy-6"
                className='border-0 w-100'
                style={{ marginLeft: "10px" }}
                type="text"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    smtp: [{ ...formData.smtp[0], mail_host: e.target.value }],
                  })
                }
                placeholder="Mail Host Name"
              />

            </div>
          </div>
          <div className="col-md-6 mb-3">
            <label>Mail Port</label>
            <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: 'flex' }}>
              <label><MdOutlineIndeterminateCheckBox /></label>
              <input
                id="daymy-7"
                name="daymy-7"
                className='border-0 w-100'
                style={{ marginLeft: "10px" }}
                type="number"
                placeholder='Mail Port'
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    smtp: [{ ...formData.smtp[0], mail_port: e.target.value }],
                  })
                }
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <label>Mail User Name</label>
            <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: 'flex' }}>
              <label><MdOutlineIndeterminateCheckBox /></label>
              <input
                id="daymy-8"
                name="daymy-8"
                className='border-0 w-100'
                style={{ marginLeft: "10px" }}
                type="text"
                placeholder='Mail User Name'
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    smtp: [{ ...formData.smtp[0], mail_username: e.target.value }],
                  })
                }
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <label>Mail Password</label>
            <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: 'flex' }}>
              <label><MdOutlineIndeterminateCheckBox /></label>
              <input
                id="daymy-9"
                name="daymy-9"
                className='border-0 w-100'
                style={{ marginLeft: "10px" }}
                type="text"
                placeholder='Mail Password'
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    smtp: [{ ...formData.smtp[0], mail_password: e.target.value }],
                  })
                }
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <label>Mail From Address</label>
            <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: 'flex' }}>
              <label><MdOutlineIndeterminateCheckBox /></label>
              <input
                id="daymy-10"
                name="daymy-10"
                className='border-0 w-100'
                style={{ marginLeft: "10px" }}
                type="text"
                placeholder='Mail From Address'
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    smtp: [{ ...formData.smtp[0], from_address: e.target.value }],
                  })
                }
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <label>Mail From Name</label>
            <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: 'flex' }}>
              <label><MdOutlineIndeterminateCheckBox /></label>
              <input
                id="daymy-11"
                name="daymy-11"
                className='border-0 w-100'
                style={{ marginLeft: "10px" }}
                type="text"
                placeholder='Mail From Name'
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    smtp: [{ ...formData.smtp[0], from_name: e.target.value }],
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step6Component;
