import { Alert, Snackbar } from '@mui/material'
import React, { useEffect } from 'react'
import { useData } from '../ContextProvider/Context';
function ShowAlert() {
    const { vertical,
        horizontal,
        open,
        snackbarValue,
        setState,
        state } = useData()
    const handleClose = () => {
        setState({ ...state, open: false });
    };
    useEffect(() => {
        setTimeout(function () {
            handleClose()
        }, 5000);
    }, [open])
    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={handleClose}
                key={vertical + horizontal}
            >
                <Alert
                    severity="success"
                    style={{
                        backgroundColor: snackbarValue?.color,
                        color: "white",
                    }}
                    onClose={handleClose}
                >
                    {snackbarValue?.message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default ShowAlert