import React from 'react'
import { useRef } from 'react';
import { useData } from '../ContextProvider/Context';
import { useParams, Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { RxCross1 } from 'react-icons/rx';
import { CiRead } from 'react-icons/ci';
import DownloadPDFButton from './DownloadPDFButton ';

function TaskView() {
    const { img_url, storeTaskComments, getTaskView, taskView, deleteTaskComment } = useData()
    const params = useParams()
    useEffect(() => {
        getTaskView(params.id)
    }, [])
    const emp_id = JSON.parse(localStorage.getItem('user_auth'))
    const initialPostCmnt = {
        taskId: params.id,
        empId: emp_id?.employee?.id,
        file: '',
        comment: ''
    };
    const initialPostCmnt1 = {
        taskId: params.id,
        userId: emp_id?.user?.id,
        file: '',
        comment: ''
    };

    const [postCmnt, setPostCmnt] = useState(emp_id?.employee ? initialPostCmnt : initialPostCmnt1);
    const fileInputRef = useRef(null);
    const handleFileInputChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64String = event.target.result;
                setPostCmnt({ ...postCmnt, file: base64String });
            };
            reader.readAsDataURL(file);
        }
    };
    const sendComment = () => {
        storeTaskComments(postCmnt);
        setPostCmnt(emp_id?.employee ? initialPostCmnt : initialPostCmnt1);
        fileInputRef.current.value = '';
    };

    const cancelComment = () => {
        setPostCmnt(emp_id?.employee ? initialPostCmnt : initialPostCmnt1);
        fileInputRef.current.value = '';
    };
    const deleteComment = (id, postCmnt) => {
        deleteTaskComment(id, postCmnt)
    }
    const [showMoreList, setShowMoreList] = useState([]);

    const toggleShowMore = (index) => {
        const newList = [...showMoreList];
        newList[index] = !newList[index];
        setShowMoreList(newList);
    };
    return (
        <>
            <div className='taskViewData'>
                <div className='row'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                            <li className="breadcrumb-item"><Link to={"/admin/projects"}>Projects</Link></li>
                            <li className="breadcrumb-item"><Link to={`/admin/projects/detail/${taskView?.task?.projectId}`}>Tasks</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Task Details </li>
                        </ol>
                    </nav>
                </div>
                <div className='card p-3'>
                    <div className='row'>
                        <div className='col-md-8 mb-3'>
                            <div className='row mt-3'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <h4 style={{ fontSize: "15px", fontWeight: "bold" }}>Comments</h4>
                                    </div>
                                    <div className='card-body scrollBar' style={{ height: "600px", overflowY: "scroll" }}>
                                        <div className='row m-0'>
                                            {taskView?.comments?.map((items, index) => {
                                                const date = new Date(items.created_at);
                                                const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                                                const formattedDay = daysOfWeek[date.getDay()];
                                                const formattedDate = `${formattedDay}, ${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
                                                const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
                                                const isLongComment = items.comment && items.comment?.length > 250;

                                                return (
                                                    <div className='card mb-2 p-2' key={items?.id}>
                                                        <div style={{ display: "flex", gap: "20px" }}>
                                                            <div style={{ display: "flex", gap: "10px", justifyContent: "space-between", width: "100%" }}>
                                                                <div style={{ display: "flex", gap: "10px" }}>
                                                                    <div>
                                                                        <div style={{ height: "50px", width: "50px", border: "1px solid ", borderRadius: "50%" }}>
                                                                            <img style={{ borderRadius: "50%" }} src={items?.userId ? img_url + items?.user?.profile : img_url + items?.employee?.profile} height={"100%"} width={"100%"} />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div style={{ display: "flex", gap: "10px", alignItems: "end" }}>
                                                                            <div>
                                                                                <h5 style={{ fontWeight: "bold", fontSize: "14px" }}>{items?.userId ? items?.user?.name : items?.employee?.name}</h5>
                                                                            </div>
                                                                            <div style={{ display: "flex", gap: "10px", alignItems: "baseline" }}>
                                                                                <p className='leads' style={{ marginBottom: "6px", fontSize: "12px" }}>{formattedDate} {formattedTime}</p>
                                                                                <div className='row'>
                                                                                    {items.file ? (
                                                                                        <Link to={img_url + items.file} download target={'_blank'}>
                                                                                            <CiRead style={{ fontSize: "20px", color: "black" }} /> <span style={{ color: "black" }}>file...</span>
                                                                                        </Link>
                                                                                    ) : null}

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <p className='leads mb-3'>
                                                                                {showMoreList[index] || !isLongComment ? items.comment : `${items.comment?.substring(0, 250)}`}
                                                                                {isLongComment && (
                                                                                    <p onClick={() => toggleShowMore(index)} style={{ color: "blue", cursor: "pointer" }}>
                                                                                        {showMoreList[index] ? "Show less" : "Show more"}
                                                                                    </p>
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    {emp_id?.user ? (
                                                                        <div className='userDeleteIcon'>
                                                                            <RxCross1 style={{ cursor: "pointer" }} onClick={() => deleteComment(items.id, postCmnt)} />
                                                                        </div>
                                                                    ) : (
                                                                        <div>
                                                                            {emp_id?.employee && (
                                                                                <div>
                                                                                    {items.empId === emp_id?.employee.id ? (
                                                                                        <div>
                                                                                            <RxCross1 style={{ cursor: "pointer" }} onClick={() => deleteComment(items.id, postCmnt)} />
                                                                                        </div>
                                                                                    ) : null}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                );
                                            })}

                                        </div>
                                        <div className='card p-3 my-3'>
                                            <div className="row mb-3">
                                                <textarea
                                                    className="onfocus scrollBar"
                                                    cols={80}
                                                    rows={4}
                                                    style={{ width: "100%" }}
                                                    value={postCmnt.comment}
                                                    onChange={(e) => setPostCmnt({ ...postCmnt, comment: e.target.value })}
                                                    placeholder="Write..."
                                                    id='testtAeas'
                                                    name='testtAeas'
                                                ></textarea>
                                                <input
                                                    type="file"
                                                    onChange={handleFileInputChange}
                                                    className="form-control onfocus xl_border"
                                                    ref={fileInputRef}
                                                />
                                            </div>
                                            <div className="row" style={{ display: "flex", gap: "20px" }}>
                                                <button
                                                    className="btn btn-primary"
                                                    style={{ width: "max-content" }}
                                                    onClick={sendComment}
                                                >
                                                    Send Comments
                                                </button>
                                                <button
                                                    className="btn btn-secondary"
                                                    style={{ width: "max-content" }}
                                                    onClick={cancelComment}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='headerProfile mb-3' style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                <div>
                                    <div style={{ height: "50px", width: "50px", border: "1px solid ", borderRadius: "50%" }}>
                                        <img style={{ borderRadius: "50%" }} src={img_url + taskView?.task?.employee?.profile} height={"100%"} width={"100%"} />
                                    </div>
                                </div>
                                <div className='mt-1'>
                                    <Link to={`/admin/employees/profile/${taskView?.task?.employee?.id}`} style={{ textDecoration: "none", color: "black" }} className='link_hover'>
                                        <h5 style={{ fontWeight: "bold", fontSize: "14px" }}> {taskView?.task?.employee?.name}</h5>
                                    </Link>
                                </div>
                            </div>
                            <DownloadPDFButton buttonText={"View File"} cv={taskView?.file[0]?.file} pdfUrl={img_url + taskView?.file[0]?.file} fileName={'File'} />

                            <div style={{ display: "flex", gap: "10px", marginTop: "1rem" }}>
                                <h4 style={{ fontSize: "15px", fontWeight: "bold" }}>Start Date:</h4>
                                <p>{taskView?.task?.startDate}</p>
                            </div>
                            <div style={{ display: "flex", gap: "10px" }}>
                                <h4 style={{ fontSize: "15px", fontWeight: "bold" }}>Total Time:</h4>
                                <p>{taskView?.task?.time}hr</p>
                            </div>
                            <div style={{ display: "flex", gap: "10px" }}>
                                <h4 style={{ fontSize: "15px", fontWeight: "bold" }}>Priority:</h4>
                                <p>
                                    {parseInt(taskView?.task?.priority) === 0
                                        ? "Low"
                                        : parseInt(taskView?.task?.priority) === 1
                                            ? "Medium"
                                            : parseInt(taskView?.task?.priority) === 2
                                                ? "High"
                                                : "Unknown Priority"}
                                </p>
                            </div>
                            <div>
                                <h4 style={{ fontSize: "15px", fontWeight: "bold" }}>Task Title</h4>
                                <p className='leads'>{taskView?.task?.title}</p>
                                <h4 style={{ fontSize: "15px", fontWeight: "bold" }}>Project Title</h4>
                                <p className='leads'>{taskView?.task?.project?.title}</p>
                                <h4 style={{ fontSize: "15px", fontWeight: "bold" }}>Description</h4>
                                <p className='leads'>{taskView?.task?.desc}</p>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default TaskView
