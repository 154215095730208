import React, { useEffect, useState } from 'react'
import { MdLocationPin, MdOutlineAttachEmail, MdOutlineDriveFileRenameOutline } from 'react-icons/md'
import { FaGraduationCap, FaPhoneAlt } from 'react-icons/fa'
import { LuImagePlus } from 'react-icons/lu'
import { LiaIndustrySolid } from 'react-icons/lia'
import { TbGitBranch } from 'react-icons/tb'
import { MdLocalFireDepartment, MdDateRange, MdManageAccounts, MdOutlineBloodtype } from 'react-icons/md'
import { SiCodesignal } from 'react-icons/si'
import { BsCashCoin, BsGenderAmbiguous, BsShieldLock } from 'react-icons/bs'
import { RiLockPasswordLine, RiAccountPinCircleLine } from 'react-icons/ri'
import { AiOutlineIdcard, AiOutlinePercentage } from 'react-icons/ai'
import { BiMessageDetail } from 'react-icons/bi'
import { CiBank, CiCalendarDate, CiCircleMinus, CiFileOn, CiForkAndKnife, CiGlobe, CiHome, CiLink, CiMedicalCross } from 'react-icons/ci'
import { GiSkills } from 'react-icons/gi'
import { VscSymbolField } from 'react-icons/vsc'
import { useData } from '../ContextProvider/Context'
import { useParams } from 'react-router-dom'
function AdminUserForm({ employeeData, setEmployees, submit }) {
  const params = useParams()
  const [selectedImage, setSelectedImage] = useState(null);
  const { getCompnayCrud, company, getBarnchById, departmentval, branch, getdepartmetById, getdseignationById, designarion, img_url, base_url } = useData();
  useEffect(() => {
    getCompnayCrud()
    getRoles()
  }, [])
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        setSelectedImage(URL.createObjectURL(file));
        setEmployees({ ...employeeData, profile: base64String });
      };
      reader.readAsDataURL(file);
    }
  };
  const handlefileChange = (file, i) => {

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        const data = [...employeeData.education_data]
        data[i].document = base64String
        setEmployees({ ...employeeData, education_data: data });
      };
      reader.readAsDataURL(file);
    }
  };
  const handleCv = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        setEmployees({ ...employeeData, cv: base64String });
      };
      reader.readAsDataURL(file);
    }
  }
  const addexperience = () => {
    var obj = {
      company: "",
      post: "",
      period: "",
    }
    setEmployees({ ...employeeData, experience: [...employeeData.experience, obj] });

  }
  const remove = (idx) => {
    var array = employeeData?.experience?.filter((item, i) => {
      if (idx !== i) {
        return item
      }
    })
    setEmployees({ ...employeeData, experience: array });

  }
  const addlink = () => {
    var obj = {
      title: "",
      link: ""
    }
    setEmployees({ ...employeeData, link_data: [...employeeData.link_data, obj] });

  }
  const removelink = (idx) => {
    var array = employeeData?.link_data?.filter((item, i) => {
      if (idx !== i) {
        return item
      }
    })
    setEmployees({ ...employeeData, link_data: array });

  }
  const addeducation = () => {
    var obj = {
      field: "",
      education: "",
      document: ""
    }
    setEmployees({ ...employeeData, education_data: [...employeeData.education_data, obj] });

  }
  const removeeducation = (idx) => {
    var array = employeeData?.education_data?.filter((item, i) => {
      if (idx !== i) {
        return item
      }
    })
    setEmployees({ ...employeeData, education_data: array });

  }
  const addskill = () => {
    var obj = {
      name: "",
      percentage: ""
    }
    setEmployees({ ...employeeData, skills: [...employeeData.skills, obj] });

  }
  const removeskill = (idx) => {
    var array = employeeData?.skills?.filter((item, i) => {
      if (idx !== i) {
        return item
      }
    })
    setEmployees({ ...employeeData, skills: array });

  }
  const [allRoles, setallRoles] = useState([])
  const getRoles = () => {
    fetch(`${base_url}roles`)
      .then((response) => response.json())
      .then((data) => {
        setallRoles(data);
      })
  }
  return (
    <div>
      <div className='AdminUserForm p-3'>
        <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
          <li className="nav-item" role="presentation">
            <a className="nav-link active" id="ex1-tab-1" data-mdb-toggle="tab" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1" aria-selected="true">Personal Information</a>
          </li>

          <li className="nav-item" role="presentation">
            <a className="nav-link" id="ex1-tab-2" data-mdb-toggle="tab" href="#ex1-tabs-2" role="tab" aria-controls="ex1-tabs-2" aria-selected="false">Account Detail</a>
          </li>
          <li className="nav-item" role="presentation">
            <a className="nav-link" id="ex1-tab-3" data-mdb-toggle="tab" href="#ex1-tabs-3" role="tab" aria-controls="ex1-tabs-3" aria-selected="false">Designation</a>
          </li>
          <li className="nav-item" role="presentation">
            <a className="nav-link" id="ex1-tab-4" data-mdb-toggle="tab" href="#ex1-tabs-4" role="tab" aria-controls="ex1-tabs-4" aria-selected="false">Kin Information</a>
          </li>
          <li className="nav-item" role="presentation">
            <a className="nav-link" id="ex1-tab-5" data-mdb-toggle="tab" href="#ex1-tabs-5" role="tab" aria-controls="ex1-tabs-5" aria-selected="false">Others</a>
          </li>
          <li className="nav-item" role="presentation">
            <a className="nav-link" id="ex1-tab-6" data-mdb-toggle="tab" href="#ex1-tabs-6" role="tab" aria-controls="ex1-tabs-6" aria-selected="false">Experience & Education</a>
          </li>
          <li className="nav-item" role="presentation">
            <a className="nav-link" id="ex1-tab-7" data-mdb-toggle="tab" href="#ex1-tabs-7" role="tab" aria-controls="ex1-tabs-7" aria-selected="false">Social Link</a>
          </li>
        </ul>
        <form onSubmit={submit}>
          <div className="tab-content" id="ex1-content">
            <div className="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
              <div className="step-content row">
                <div className='col-12'>
                  <div style={{ border: "none" }} className='row'>
                    <div className='col-12'>
                      <div style={{ border: '2px solid', height: "100px", borderRadius: "50%", width: "100px", position: "relative" }}>
                        <label style={{ border: "1px solid", borderRadius: "50%", width: "25px", height: "25px", background: "lightgray", color: "gray", position: "absolute", right: "0%", paddingLeft: ".2rem", paddingBottom: ".2rem" }} htmlFor="EmployeeLogo1"><LuImagePlus /></label>
                        <img style={{ width: "100%", height: "100%", borderRadius: "50%" }} src={selectedImage ? selectedImage : img_url + employeeData?.profile} />
                      </div>
                      <input
                        type="file"
                        id="EmployeeLogo1"
                        name="EmployeeLogo1"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>

                </div>
                <div className='col-6'>
                  <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }} htmlFor='EmployeeName'>Name:<span style={{ color: "red" }}>*</span></label>
                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><MdOutlineDriveFileRenameOutline /></label>
                      <input
                        type="text"
                        id="EmployeeName"
                        required
                        name="EmployeeName"
                        placeholder='Name'
                        value={employeeData?.name}
                        onChange={(e) => setEmployees({ ...employeeData, name: e.target.value })}
                      />
                    </div>
                  </div>

                </div>

                <div className='col-6'>
                  <label htmlFor='EmployeeEmail' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>
                    Email:<span style={{ color: "red" }}>*</span>
                  </label>

                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><MdOutlineAttachEmail /></label>

                      <input
                        type="email"
                        id="EmployeeEmail"
                        name="EmployeeEmail"
                        placeholder='Enter a valid email address'
                        value={employeeData?.email}
                        onChange={(e) => setEmployees({ ...employeeData, email: e.target.value })}
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                      />
                    </div>
                  </div>
                </div>

                <div className='col-6'>
                  <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }} htmlFor='EmployeePhone'>Phone:<span style={{ color: "red" }}>*</span></label>

                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><FaPhoneAlt /></label>
                      <input
                        type="number"
                        id="EmployeePhone"
                        name="EmployeePhone"
                        placeholder='Phone'
                        value={employeeData?.phone}
                        onChange={(e) => setEmployees({ ...employeeData, phone: e.target.value })}
                      />
                    </div>
                  </div>

                </div>
                <div className='col-6'>
                  <label htmlFor='trading_addr' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Temporary Address:<span style={{ color: "red" }}>*</span></label>

                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><MdLocationPin /></label>
                      <input
                        type="text"
                        id="trading_addr"
                        name="trading_addr"
                        placeholder='Temporary Address'

                        value={employeeData?.address}
                        onChange={(e) => setEmployees({ ...employeeData, address: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <label htmlFor='trading_addr2' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Password:<span style={{ color: "red" }}>*</span></label>
                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><RiLockPasswordLine /></label>
                      <input
                        type="text"
                        id="trading_addr2"
                        name="trading_addr2"
                        placeholder='Enter Password at least 8 characters'
                        value={employeeData?.password}
                        onChange={(e) => setEmployees({ ...employeeData, password: e.target.value })}
                      />
                    </div>
                  </div>
                </div>

                <div className='col-6'>
                  <label htmlFor='EmployeeAddress3' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Permanent Address:<span style={{ color: "red" }}>*</span></label>
                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><MdLocationPin /></label>
                      <input
                        type='text'
                        id="EmployeeAddress3"
                        name="EmployeeAddress3"
                        placeholder="Permanent Address"
                        value={employeeData?.permanent_address}
                        onChange={(e) => setEmployees({ ...employeeData, permanent_address: e.target.value })}
                      />
                    </div>
                  </div>

                </div>
                <div className='col-6'>
                  <label htmlFor='EmployeeAddress4' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Date of Birth:<span style={{ color: "red" }}>*</span></label>
                  <div className='row mt-1'>
                    <div className='col-12 d-flex'>
                      <label>< MdDateRange /></label>
                      <input style={{ width: '100%' }}
                        type='date'
                        id="EmployeeAddress4"
                        name="EmployeeAddress4"
                        placeholder="D.o.B"
                        value={employeeData?.dob}
                        onChange={(e) => setEmployees({ ...employeeData, dob: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <label htmlFor="EmployeeAddress5" style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Gender:<span style={{ color: "red" }}>*</span></label>
                  <div className='row mt-1'>
                    <div className='col-12'>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <label><BsGenderAmbiguous /></label>
                        <select className='form-control border-0' id='EmployeeAddress5' value={employeeData?.gender}
                          onChange={(e) => setEmployees({ ...employeeData, gender: e.target.value })}>
                          <option hidden>Select Gender</option>
                          <option value={'Male'}>Male</option>
                          <option value={'Female'}>Female</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }} htmlFor="EmployeeAddress6">ID:<span style={{ color: "red" }}>*</span></label>
                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><AiOutlineIdcard /></label>
                      <input
                        type='number'
                        id="EmployeeAddress6"
                        name="EmployeeAddress6"
                        placeholder="ID"
                        value={employeeData?.cnic}
                        onChange={(e) => setEmployees({ ...employeeData, cnic: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <label htmlFor="EmployeeAddress7" style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Nationality:<span style={{ color: "red" }}>*</span></label>
                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><AiOutlineIdcard /></label>
                      <input
                        type='text'
                        id="EmployeeAddress7"
                        name="EmployeeAddress7"
                        placeholder="Nationality"
                        value={employeeData?.nationality}
                        onChange={(e) => setEmployees({ ...employeeData, nationality: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                {employeeData?.java_id ? "" : <div className='col-6'>
                  <label htmlFor="EmployeeAddress8" style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Java ID:</label>

                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><AiOutlineIdcard /></label>
                      <input
                        type='number'
                        id="EmployeeAddress8"
                        name="EmployeeAddress8"
                        placeholder="Java ID"
                        value={employeeData?.java_id}
                        onChange={(e) => setEmployees({ ...employeeData, java_id: e.target.value })}
                      />
                    </div>
                  </div>
                </div>}
                <div className='col-6'>
                  <label htmlFor="EmployeeAddress9" style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Marital Status:<span style={{ color: "red" }}>*</span></label>
                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><MdLocationPin /></label>
                      <input
                        type='text'
                        id="EmployeeAddress9"
                        name="EmployeeAddress9"
                        placeholder="Marital Status"
                        value={employeeData?.maritalStatus}
                        onChange={(e) => setEmployees({ ...employeeData, maritalStatus: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
              <div className="step-content row">
                <div className='col-6'>
                  <label htmlFor="EmployeeName11" style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Account Holder:<span style={{ color: "red" }}>*</span></label>
                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><MdManageAccounts /></label>
                      <input
                        type="text"
                        id="EmployeeName11"
                        name="EmployeeName11"
                        placeholder='Account Holder'
                        value={employeeData?.account_holder}
                        onChange={(e) => setEmployees({ ...employeeData, account_holder: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <label htmlFor='EmployeeName22' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Account Number:<span style={{ color: "red" }}>*</span></label>
                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><RiAccountPinCircleLine /></label>
                      <input
                        type='number'
                        id="EmployeeName22"
                        name="EmployeeName22"
                        placeholder='Account Number'
                        value={employeeData?.account_number}
                        onChange={(e) => setEmployees({ ...employeeData, account_number: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <label htmlFor="EmployeeEmail23" style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>IBAN:<span style={{ color: "red" }}>*</span></label>
                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><CiBank /></label>
                      <input
                        type="number"
                        id="EmployeeEmail23"
                        name="EmployeeEmail23"
                        placeholder='IBAN'
                        value={employeeData?.IBAN}
                        onChange={(e) => setEmployees({ ...employeeData, IBAN: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <label htmlFor='EmployeePhone24' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Bank Name:<span style={{ color: "red" }}>*</span></label>
                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><CiBank /></label>
                      <input
                        type="text"
                        id="EmployeePhone24"
                        name="EmployeePhone24"
                        placeholder='Bank Name'
                        value={employeeData?.bank_name}
                        onChange={(e) => setEmployees({ ...employeeData, bank_name: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="ex1-tabs-3" role="tabpanel" aria-labelledby="ex1-tab-3">
              <div className=" row">
                <div className='row'>
                  <div className='col-6 mb-3'>
                    <label htmlFor='companySelection' style={{ fontSize: '14px', }}>Company:<span style={{ color: "red" }}>*</span></label>
                    <div className='selectBox'>
                      <label><LiaIndustrySolid /></label>
                      <select id='companySelection' name="companySelection" style={{ border: 'none', width: '100%', fontSize: '14px', fontSize: '14px' }}
                        onChange={(e) => {
                          getBarnchById(e.target.value)
                          setEmployees({ ...employeeData, companyId: e.target.value })
                        }}
                      >
                        <option value="">All Company</option>
                        {company?.data?.map((item) => {
                          return (
                            <>{item?.deleteStatus == 0 &&
                              <option value={item.id}>{item.name}</option>

                            }
                            </>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <div className='col-6 mb-3'>
                    <label htmlFor='branchSelection' style={{ fontSize: '14px', }}>Branch:<span style={{ color: "red" }}>*</span></label>
                    <div className='selectBox'>
                      <label><TbGitBranch /></label>
                      <select id='branchSelection' name='branchSelection' style={{ border: 'none', width: '100%', fontSize: '14px' }}
                        onChange={(e) => {
                          getdepartmetById(e.target.value)
                          setEmployees({ ...employeeData, branchId: e.target.value })

                        }}>
                        <option value="">Branch</option>
                        {branch?.data?.map((item) => {
                          return (
                            <option value={item.id}>{item.branch}</option>

                          )
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6 mb-3'>
                    <label style={{ fontSize: '14px', }} htmlFor='departmemtSelection'>Department:<span style={{ color: "red" }}>*</span></label>
                    <div className='selectBox'>
                      <label><MdLocalFireDepartment /></label>
                      <select id='departmemtSelection' style={{ border: 'none', width: '100%', fontSize: '14px' }}
                        onChange={(e) => {
                          getdseignationById(e.target.value)
                          setEmployees({ ...employeeData, departmentId: e.target.value })

                        }}
                      >
                        <option value="">Department</option>
                        {departmentval?.map((item) => {
                          return (
                            <option value={item.id}>{item.department}</option>

                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <div className='col-6 mb-3'>
                    <label style={{ fontSize: '14px', }} htmlFor='desigantionSlect'>Designation:<span style={{ color: "red" }}>*</span></label>
                    <div className='selectBox'>
                      <label><SiCodesignal /></label>
                      <select id='desigantionSlect' style={{ border: 'none', width: '100%', fontSize: '14px' }}
                        onChange={(e) => {
                          setEmployees({ ...employeeData, designationId: e.target.value })
                        }}
                      >
                        <option value="">Designation</option>
                        {designarion?.map((item) => {
                          return (
                            <option value={item.id}>{item.title}</option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6 mb-3'>
                    <label htmlFor='rolSelection' style={{ fontSize: '14px', }}>Role:<span style={{ color: "red" }}>*</span></label>
                    <div className='inputBox gap-2'>
                      <label><BsShieldLock /></label>
                      <select id='rolSelection' style={{ border: 'none', width: '100%', fontSize: '14px' }}
                        onChange={(e) => {

                          setEmployees({ ...employeeData, role_id: e.target.value })

                        }}
                      >
                        <option value="">Assing Role</option>
                        {allRoles?.map((item) => {
                          return (
                            <option value={item.id}>{item.name}</option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <div className='col-6 mb-3'>
                    <label htmlFor='saleryInout' style={{ fontSize: '14px', }}>Salary:<span style={{ color: "red" }}>*</span></label>
                    <div className='inputBox gap-2'>
                      <label><BsCashCoin /></label>
                      <input
                        id="saleryInout"
                        value={employeeData?.salary}
                        type='number'
                        onChange={(e) => {

                          setEmployees({ ...employeeData, salary: e.target.value })

                        }}
                        placeholder='Salary' style={{ border: 'none', width: '100%', fontSize: '14px' }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="ex1-tabs-4" role="tabpanel" aria-labelledby="ex1-tab-4">
              <div className="step-content row">
                <div className='col-6'>
                  <label htmlFor="EmployeeName25" style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Kin Name:</label>

                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><MdOutlineDriveFileRenameOutline /></label>
                      <input
                        type="text"
                        id="EmployeeName25"
                        name="EmployeeName25"
                        placeholder='Kin Name'
                        value={employeeData?.kin_name}
                        onChange={(e) => setEmployees({ ...employeeData, kin_name: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <label htmlFor="EmployeePhone26" style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Kin Phone:</label>

                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><FaPhoneAlt /></label>

                      <input
                        type="number"
                        id="EmployeePhone26"
                        name="EmployeePhone26"
                        placeholder='Kin Phone'

                        value={employeeData?.kin_phone}
                        onChange={(e) => setEmployees({ ...employeeData, kin_phone: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <label htmlFor="EmployeeName27" style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Next-to-Kin Name:</label>

                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><MdOutlineDriveFileRenameOutline /></label>
                      <input
                        type="text"
                        id="EmployeeName27"
                        name="EmployeeName27"
                        placeholder='Next-to-Kin Name'
                        value={employeeData?.next_to_kin_name}
                        onChange={(e) => setEmployees({ ...employeeData, next_to_kin_name: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <label htmlFor="EmployeeEmail28" style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Next-to-Kin Phone:</label>

                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><FaPhoneAlt /></label>
                      <input
                        type="number"
                        id="EmployeeEmail28"
                        name="EmployeeEmail28"
                        placeholder='Next-to-Kin Phone'
                        value={employeeData?.next_to_kin_phone}
                        onChange={(e) => setEmployees({ ...employeeData, next_to_kin_phone: e.target.value })}
                      />
                    </div>
                  </div>
                </div>

                <div className='col-6'>
                  <label htmlFor="trading_addr29" style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Kin Address:</label>

                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><MdLocationPin /></label>
                      <input
                        type='text'
                        id="trading_addr29"
                        name="trading_addr29"
                        placeholder='Kin Address'
                        value={employeeData?.kin_address}
                        onChange={(e) => setEmployees({ ...employeeData, kin_address: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <label htmlFor="trading_addr30" style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Next-to-Kin Address:</label>

                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><MdLocationPin /></label>
                      <input
                        id="trading_addr30"
                        name="trading_addr30"
                        placeholder='Next-to-Kin Address'
                        type='text'
                        value={employeeData?.next_to_kin_address}
                        onChange={(e) => setEmployees({ ...employeeData, next_to_kin_address: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <label htmlFor="EmployeeAddress31" style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Emergency Number:</label>

                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><FaPhoneAlt /></label>
                      <input
                        type='number'
                        id="EmployeeAddress32"
                        name="EmployeeAddress33"
                        placeholder="Emergency Number"
                        value={employeeData?.emergency_contact}
                        onChange={(e) => setEmployees({ ...employeeData, emergency_contact: e.target.value })}
                      />
                    </div>
                  </div>

                </div>
                <div className='col-6'>
                  <label htmlFor="EmployeeAddress34" style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Emergency Contact Name:</label>

                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><FaPhoneAlt /></label>
                      <input
                        type='text'
                        id="EmployeeAddress34"
                        name="EmployeeAddress34"
                        placeholder="Emergency Contact Name"
                        value={employeeData?.emergency_contact_name}
                        onChange={(e) => setEmployees({ ...employeeData, emergency_contact_name: e.target.value })}
                      />
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="ex1-tabs-5" role="tabpanel" aria-labelledby="ex1-tab-5">
              <div className="step-content row">
                <div className='col-6'>
                  <label htmlFor='EmployeeName36' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Date of Joining:<span style={{ color: "red" }}>*</span></label>

                  <div className='row mt-1'>
                    <div className='col-12 d-flex'>
                      <label><MdDateRange /></label>
                      <input style={{ width: '100%' }}
                        type="date"
                        id="EmployeeName36"
                        name="EmployeeName36"
                        placeholder='Date of Joining'
                        value={employeeData?.doj}
                        min={new Date().toISOString().split('T')[0]}
                        onChange={(e) => setEmployees({ ...employeeData, doj: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <label htmlFor='EmployeeName37' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Blood Group:</label>

                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><MdOutlineBloodtype /></label>
                      <input
                        type="text"
                        id="EmployeeName37"
                        name="EmployeeName37"
                        placeholder='Blood Group'
                        value={employeeData?.blood_grp}
                        onChange={(e) => setEmployees({ ...employeeData, blood_grp: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <label htmlFor='EmployeeEmail38' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Medical History:</label>

                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><CiMedicalCross /></label>
                      <input
                        type="text"
                        id="EmployeeEmail38"
                        name="EmployeeEmail38"
                        placeholder='Medical History'
                        value={employeeData?.medical_history}
                        onChange={(e) => setEmployees({ ...employeeData, medical_history: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <label htmlFor='EmployeePhone39' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Note:</label>

                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><BiMessageDetail /></label>
                      <input
                        id="EmployeePhone39"
                        name="EmployeePhone39"
                        placeholder='Note'
                        type='text'
                        value={employeeData?.note}
                        onChange={(e) => setEmployees({ ...employeeData, note: e.target.value })}
                      />
                    </div>
                  </div>
                </div>

                <div className='col-6'>
                  <label htmlFor='EmployeeEmail40' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Meal Allowance:</label>

                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><CiForkAndKnife /></label>
                      <input
                        type="number"
                        id="EmployeeEmail40"
                        name="EmployeeEmail40"
                        placeholder='Meal Allowance (optional)'
                        value={employeeData?.meal_allowance}
                        onChange={(e) => setEmployees({ ...employeeData, meal_allowance: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <label htmlFor='EmployeePhone41' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Home Rent Allowance:</label>

                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><CiHome /></label>
                      <input
                        id="EmployeePhone41"
                        name="EmployeePhone41"
                        placeholder='Home Rent Allowance (optional)'
                        type='number'
                        value={employeeData?.house_rent_allowance}
                        onChange={(e) => setEmployees({ ...employeeData, house_rent_allowance: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <label htmlFor='EmployeePhone42' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Travel Allowance:</label>

                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><CiHome /></label>
                      <input
                        id="EmployeePhone42"
                        name="EmployeePhone42"
                        placeholder='Travel Allowance (optional)'
                        type='number'

                        value={employeeData?.travel_allowance}
                        onChange={(e) => setEmployees({ ...employeeData, travel_allowance: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <label htmlFor='travelCOnditionSelector' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Travel Allowance:</label>

                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><CiHome /></label>
                      <select style={{ border: 'none', width: '90%', fontSize: '14px', outline: "none" }}
                        id='travelCOnditionSelector'
                        onChange={(e) => {
                          setEmployees({ ...employeeData, travel_condition: e.target.value })
                        }}
                      >

                        <option hidden>Select Travel Allowance</option>
                        <option value="Per KM">Per KM</option>
                        <option value="Fixed">Fixed</option>
                        <option value="Percetnage">Percetnage</option>

                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="ex1-tabs-6" role="tabpanel" aria-labelledby="ex1-tab-6">
              <div className="step-content row">
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <button className='btn btn-primary mt-1' style={{ padding: "8px 16px", marginRight: "1rem", marginTop: "10px" }} onClick={addeducation}>Add Education</button>
                </div>
                {employeeData?.education_data?.map((item, i) => {
                  return (
                    <>
                      {i > 0 && <div style={{ display: "flex", justifyContent: "end" }}>
                        <button className='btn btn-primary' style={{ padding: "8px 16px", marginRight: "1rem", marginTop: "10px" }} onClick={() => removeeducation(i)}><CiCircleMinus /></button>

                      </div>}
                      <div className='col-4'>
                        <label htmlFor='EmployeeName43' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Field:<span style={{ color: "red" }}>*</span></label>

                        <div className='row'>
                          <div className='col-12'>
                            <label><VscSymbolField /></label>
                            <input
                              type="text"
                              id="EmployeeName43"
                              name="EmployeeName43"
                              placeholder='Field'
                              value={item?.field}
                              onChange={(e) => {
                                const data = [...employeeData.education_data]
                                data[i].field = e.target.value
                                setEmployees({ ...employeeData, education_data: data })
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-4'>
                        <label htmlFor='EmployeeName44' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Education:<span style={{ color: "red" }}>*</span></label>

                        <div className='row'>
                          <div className='col-12'>
                            <label><FaGraduationCap /></label>
                            <input
                              type="text"
                              id="EmployeeName44"
                              name="EmployeeName44"
                              placeholder='Education'
                              value={item?.education}
                              onChange={(e) => {
                                const data = [...employeeData.education_data]
                                data[i].education = e.target.value
                                setEmployees({ ...employeeData, education_data: data })
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-4'>
                        <label htmlFor='education46' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Documents:<span style={{ color: "red" }}>*</span></label>

                        <div className='row'>
                          <div className='col-12'>
                            <label><CiFileOn /></label>
                            <input
                              type="file"
                              id="education46"
                              name="education46"
                              placeholder='Education'
                              onChange={(e) => handlefileChange(e.target.files[0], i)}
                            />
                          </div>
                        </div>
                      </div>

                    </>
                  )
                })}


                <div style={{ display: "flex", justifyContent: "end" }}>
                  <button className='btn btn-primary mt-3' style={{ padding: "8px 16px", marginRight: "1rem", marginTop: "10px" }} onClick={addexperience}>Add Experience</button>
                </div>
                {employeeData?.experience?.map((item, i) => {
                  return (
                    <>
                      {i > 0 && <div style={{ display: "flex", justifyContent: "end" }}>
                        <button className='btn btn-primary' style={{ padding: "8px 16px", marginRight: "1rem", marginTop: "10px" }} onClick={() => remove(i)}><CiCircleMinus /></button>

                      </div>}
                      <div className='col-4'>
                        <label htmlFor='EmployeeName47' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Company Name:</label>

                        <div className='row'>
                          <div className='col-12'>
                            <label><LiaIndustrySolid /></label>
                            <input
                              type="text"
                              id="EmployeeName47"
                              name="EmployeeName47"
                              placeholder='Company Name'
                              value={item?.company}
                              onChange={(e) => {
                                const data = [...employeeData.experience]
                                data[i].company = e.target.value
                                setEmployees({ ...employeeData, experience: data })
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-4'>
                        <label htmlFor='EmployeeName48' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Designation:</label>

                        <div className='row'>
                          <div className='col-12'>
                            <label><SiCodesignal /></label>
                            <input
                              type="text"
                              id="EmployeeName48"
                              name="EmployeeName48"
                              placeholder='Designation'
                              value={item?.post}
                              onChange={(e) => {
                                const data = [...employeeData.experience]
                                data[i].post = e.target.value
                                setEmployees({ ...employeeData, experience: data })
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-4'>
                        <label htmlFor='EmployeeName49' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Time Period:</label>

                        <div className='row'>
                          <div className='col-12'>
                            <label><CiCalendarDate /></label>
                            <input
                              type="text"
                              id="EmployeeName49"
                              name="EmployeeName49"
                              placeholder='Period'
                              value={item?.period}
                              onChange={(e) => {
                                const data = [...employeeData.experience]
                                data[i].period = e.target.value
                                setEmployees({ ...employeeData, experience: data })
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })}
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <button className='btn btn-primary mt-3' style={{ padding: "8px 16px", marginRight: "1rem", marginTop: "10px" }} onClick={addskill}>Add Skills</button>
                </div>
                {employeeData?.skills?.map((item, i) => {
                  return (
                    <>
                      <div className='mt-2' style={{ display: "flex", justifyContent: "end" }}>
                        <button className='btn btn-primary' style={{ padding: "8px 16px", marginRight: "1rem", marginTop: "10px" }} onClick={() => removeskill(i)}><CiCircleMinus /></button>

                      </div>
                      <div className='col-6'>
                        <label htmlFor='EmployeeName50' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Skill Name:<span style={{ color: "red" }}>*</span></label>

                        <div className='row mt-1'>
                          <div className='col-12'>
                            <label><GiSkills /></label>
                            <input
                              type="text"
                              id="EmployeeName50"
                              name="EmployeeName50"
                              placeholder='Name'
                              value={item?.name}

                              onChange={(e) => {
                                const data = [...employeeData.skills]
                                data[i].name = e.target.value
                                setEmployees({ ...employeeData, skills: data })
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-6'>
                        <label htmlFor='EmployeeName51' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Progress:<span style={{ color: "red" }}>*</span></label>

                        <div className='row mt-1'>
                          <div className='col-12'>
                            <label><AiOutlinePercentage /></label>
                            <input
                              type="number"
                              id="EmployeeName51"
                              name="EmployeeName51"
                              placeholder='Percentage'
                              value={item?.percentage}

                              onChange={(e) => {
                                const data = [...employeeData.skills]
                                data[i].percentage = e.target.value
                                setEmployees({ ...employeeData, skills: data })
                              }}
                            />
                          </div>
                        </div>
                      </div>


                    </>
                  )
                })}
                <div className='col-6'>
                  <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>CV:<span style={{ color: "red" }}>*</span></label>

                  <div className='row mt-1'>
                    <div className='col-12'>
                      <label><CiHome /></label>
                      <input   onChange={handleCv} type='file' />
                    </div>
                  </div>
                </div>
                <div className='col-12 mt-5'>
                  <label htmlFor='EmployeeName52' style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Description</label>
                  <div className='row'>
                    <div className='col-12 d-flex align-items-baseline gap-2'>
                      <label><BiMessageDetail /></label>
                      <textarea
                        style={{
                          height: '120px',
                          border: 'none',
                          fontSize: '13px',
                          width: '100%'
                        }}
                        type="text"
                        id="EmployeeName52"
                        name="EmployeeName52"
                        placeholder='Description'
                        value={employeeData?.desc}
                        onChange={(e) => setEmployees({ ...employeeData, desc: e.target.value })}
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="tab-pane fade" id="ex1-tabs-7" role="tabpanel" aria-labelledby="ex1-tab-7">
              <div style={{ display: "flex", justifyContent: "end" }}>
                <button className='btn btn-primary' style={{ padding: "8px 16px", marginRight: "1rem", marginTop: "10px" }} onClick={addlink}>Add Link</button>
              </div>
              <div style={{ alignContent: "start" }} className="step-content row">

                {employeeData?.link_data?.map((item, i) => {
                  return (
                    <>
                      {i > 2 && <div style={{ display: "flex", justifyContent: "end", height: "40px" }} className='col-12'>
                        <button className='btn btn-primary' style={{ padding: "8px 16px", marginRight: "1rem", marginTop: "10px" }} onClick={() => removelink(i)}><CiCircleMinus /></button>
                      </div>}
                      <div className='col-6'>
                        <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Social Name:</label>

                        <div className='row'>
                          <div className='col-12'>
                            <label><CiGlobe /></label>
                            <input
                              type="text"
                              id="EmployeeName53"
                              name="EmployeeName53"
                              placeholder='Name'
                              value={item.title}
                              onChange={(e) => {
                                const data = [...employeeData.link_data]
                                data[i].title = e.target.value
                                setEmployees({ ...employeeData, link_data: data })
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-6'>
                        <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Link Name:</label>

                        <div className='row'>
                          <div className='col-12'>
                            <label><CiLink /></label>
                            <input
                              type="text"
                              id="EmployeeName54"
                              name="EmployeeName54"
                              placeholder='Link'
                              onChange={(e) => {
                                const data = [...employeeData.link_data]
                                data[i].link = e.target.value
                                setEmployees({ ...employeeData, link_data: data })
                              }}
                            />
                          </div>
                        </div>
                      </div>

                    </>
                  )
                })}


              </div>

            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AdminUserForm