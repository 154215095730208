import React, { useEffect, useState } from 'react';
import { useData } from '../ContextProvider/Context';
import { Link, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import AdminUserForm from '../Components/AdminUserForm'
import { CiRead } from 'react-icons/ci';
import { Snackbar, Alert } from '@mui/material';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  bgcolor: 'background.paper',
  boxShadow: 24,
};
function Employee() {
  const params = useParams()
  const { getEmployees, employees, handleClick, base_url, img_url,
    setSnackbarValue, emplyeeResponse, createEmploye, company, getCompnayCrud, getBranch, branch, department, getDepartments, getFilterEmployees, designationData, getdesignation, currentDate } = useData()

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setEmployees({
      companyId: params.id ? params.id : "",
      branchId: "",
      name: "",
      phone: "",
      address: "",
      gender: "",
      maritalStatus: "",
      cnic: "",
      dob: "",
      email: "",
      departmentId: "",
      designationId: "",
      doj: new Date().toISOString().split('T')[0],
      password: "",
      salary: "",
      permanent_address: "",
      kin_address: "",
      blood_grp: "",
      emergency_contact: '',
      medical_history: "",
      next_to_kin_name: "",
      account_holder: "",
      bank_name: "",
      account_number: "",
      travel_allowance: "",
      IBAN: "",
      profile: "",
      meal_allowance: "",
      travel_condition: "",
      house_rent_allowance: "",
      nationality: "",
      cv: "",
      java_id: "",
      note: "",
      kin_name: "",
      kin_phone: "",
      next_to_kin_phone: "",
      next_to_kin_address: "",
      experience: [{
        company: "",
        post: "",
        period: "",
      }],
      desc: "",
      link_data: [{
        title: "Linkedin",
        link: ""
      },
      {
        title: "Facebook",
        link: ""
      },
      {
        title: "Twitter",
        link: ""
      }
      ],
      education_data: [
        {
          field: "",
          education: "",
          document: ""
        }
      ],
      skills: [
        {
          name: "",
          percentage: ""
        }
      ],
      role_id: ''
    })
    setOpen(false)
  };
  useEffect(() => {
    getEmployees()
    getCompnayCrud()
    getBranch()
    getDepartments()
    getdesignation()
  }, [])
  useEffect(() => {
    if (emplyeeResponse?.message == "Employee created successfully") {
      handleClick()
      setSnackbarValue({ message: emplyeeResponse?.message, color: "#2ECC71" });
      handleClose()
      getEmployees()

      setEmployees({
        companyId: params.id ? params.id : "",
        branchId: "",
        name: "",
        phone: "",
        address: "",
        gender: "",
        maritalStatus: "",
        cnic: "",
        dob: "",
        email: "",
        departmentId: "",
        designationId: "",
        doj: new Date().toISOString().split('T')[0],
        password: "",
        salary: "",
        permanent_address: "",
        kin_address: "",
        blood_grp: "",
        emergency_contact: '',
        emergency_contact_name: '',
        medical_history: "",
        next_to_kin_name: "",
        account_holder: "",
        bank_name: "",
        account_number: "",
        travel_allowance: "",
        IBAN: "",
        profile: "",
        travel_condition: "",
        nationality: "",
        cv: "",
        note: "",
        java_id: "",
        kin_name: "",
        kin_phone: "",
        next_to_kin_phone: "",
        next_to_kin_address: "",
        experience: [{
          company: "",
          post: "",
          period: "",
        }],
        desc: "",
        link_data: [{
          title: "LinkedIn",
          link: ""
        },
        {
          title: "Facebook",
          link: ""
        },
        {
          title: "Twitter",
          link: ""
        }
        ],
        education_data: [
          {
            field: "",
            education: "",
            document: ""
          }
        ],
        skills: [
          {
            name: "",
            percentage: ""
          }
        ],
        role_id: ''


      })
    }
    else if (emplyeeResponse?.message) {
      handleClick()
      setSnackbarValue({ message: emplyeeResponse?.message, color: "#DC4C64" });

    }
  }, [emplyeeResponse])
  const [employeeData, setEmployees] = useState(
    {
      companyId: params.id ? params.id : "",
      branchId: "",
      name: "",
      phone: "",
      address: "",
      gender: "",
      maritalStatus: "",
      cnic: "",
      dob: "",
      email: "",
      departmentId: "",
      designationId: "",
      doj: new Date().toISOString().split('T')[0],
      password: "",
      salary: "",
      permanent_address: "",
      kin_address: "",
      blood_grp: "",
      emergency_contact: '',
      medical_history: "",
      next_to_kin_name: "",
      account_holder: "",
      bank_name: "",
      account_number: "",
      travel_allowance: "",
      IBAN: "",
      profile: "",
      meal_allowance: "",
      travel_condition: "",
      house_rent_allowance: "",
      nationality: "",
      cv: "",
      java_id: "",
      note: "",
      kin_name: "",
      kin_phone: "",
      next_to_kin_phone: "",
      next_to_kin_address: "",
      experience: [{
        company: "",
        post: "",
        period: "",
      }],
      desc: "",
      link_data: [{
        title: "Linkedin",
        link: ""
      },
      {
        title: "Facebook",
        link: ""
      },
      {
        title: "Twitter",
        link: ""
      }
      ],
      education_data: [
        {
          field: "",
          education: "",
          document: ""
        }
      ],
      skills: [
        {
          name: "",
          percentage: ""
        }
      ],
      role_id: ''
    })
  const [date, setDate] = useState({
    company: '',
    branch: '',
    department: '',
    designation: '',
    startDate: '',
    endDate: '',
    status: ''
  });

  const handleCompanyChange = (e) => {
    const newCompany = e.target.value;
    setDate((prevDate) => ({ ...prevDate, company: newCompany }));
    if (newCompany === "") {
      filterResults("", date.branch, date.department, date.designation, date.startDate, date.endDate);
    } else {
      filterResults(newCompany, date.branch, date.department, date.designation, date.startDate, date.endDate);
    }
  };

  const handelBranchChange = (e) => {
    const newBranch = e.target.value;
    setDate((prevDate) => ({ ...prevDate, branch: newBranch }));
    filterResults(date.company, newBranch, date.department, date.designation, date.startDate, date.endDate);
  };

  const handelDepartmentChange = (e) => {
    const newDepartment = e.target.value;
    setDate((prevDate) => ({ ...prevDate, department: newDepartment }));
    filterResults(date.company, date.branch, newDepartment, date.designation, date.startDate, date.endDate);
  };

  const handelDesignationChange = (e) => {
    const newDesignation = e.target.value;
    setDate((prevDate) => ({ ...prevDate, designation: newDesignation }));
    filterResults(date.company, date.branch, date.department, newDesignation, date.startDate, date.endDate);
  };
  const state = {
    open: false,
    vertical: 'bottom',
    horizontal: 'right',
  }
  const { vertical, horizontal } = state;
  const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleSubmit = (e) => {
    e.preventDefault();
    if (employeeData.profile.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Profile is required.');
    } else if (employeeData.name.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Name field is required.');
    } else if (employeeData.email.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Email field is required.');
    } else if (!emailRegex.test(employeeData.email.trim())) {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Please enter a valid email address.');
    } else if (employeeData.phone.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Phone field is required.');
    } else if (employeeData.address.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Temporary Address field is required.');
    } else if (employeeData.password.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Password field is required.');
    } else if (employeeData.password.trim().length < 8) {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Password should be at least 8 characters.');
    } else if (employeeData.permanent_address.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Permanent Address field is required.');
    } else if (employeeData.dob.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Date of Birth field is required.');
    } else if (employeeData.gender.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Gender field is required.');
    } else if (employeeData.cnic.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('CNIC ID field is required.');
    } else if (employeeData.nationality.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Nationality field is required.');
    } else if (employeeData.maritalStatus.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Marital Status field is required.');
    } else if (employeeData.account_holder.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Account Holder field is required.');
    } else if (employeeData.account_number.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Account Number field is required.');
    } else if (employeeData.IBAN.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('IBAN field is required.');
    } else if (employeeData.bank_name.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Bank Name field is required.');
    } else if (employeeData.companyId.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Company field is required.');
    } else if (employeeData.branchId.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Branch field is required.');
    } else if (employeeData.departmentId.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Department field is required.');
    } else if (employeeData.designationId.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Designation field is required.');
    } else if (employeeData.role_id.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Role field is required.');
    } else if (employeeData.salary.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Salary field is required.');
    } else if (employeeData.doj.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Date of Joining field is required.');
    } else if (employeeData.education_data.some(education => education.field.trim() === '' || education.education.trim() === '' || education.document.trim() === '')) {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Education fields are required.');
    } else if (employeeData.skills.some(skill => skill.name.trim() === '' || skill.percentage.trim() === '')) {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Skills fields are required.');
    } else {
      setAnyFieldEmpty(false);
      createEmploye(employeeData);
    }
  };
  const handleCloseSnackbar = () => {
    setAnyFieldEmpty(false);
  };
  const getJavaId = () => {
    fetch(`${base_url}next-java-id`)
      .then((res) => res.json())
      .then((data) => {
        setEmployees({ ...employeeData, java_id: data?.java_id })
        handleOpen()
      });
  }
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = params.id
    ? employees?.data?.filter(item => item?.company?.id == params.id) || []
    : employees?.data || [];

  const totalPages = Math.ceil(currentItems?.length / itemsPerPage);

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const paginationControls = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationControls.push(
      <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
        {i}
      </button>
    );
  }
  const filterResults = (company, branch, department, designation, startDate, endDate, status) => {
    getFilterEmployees(company, branch, department, designation, startDate, endDate, status);
    handlePageChange(1)
  };

  return (
    <>
      <div className='employeeData'>
        <div className="row " style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Employees</li>
              </ol>
            </nav>
          </div>
          <div className='d-flex justify-content-end mb-2 gap-2'>
            <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
              <select
                id="companySelect"
                disabled={params.id ? true : false}
                className='form-select' style={{ height: "34px", fontSize: "14px" }} value={date.company} onChange={handleCompanyChange}>
                <option value="">Select Company</option>
                {company?.data?.map((item) => {
                  return (
                    <div key={item.id}>{item?.deleteStatus == 0 &&
                      <option value={item.name} key={item.id}>
                        {item.name}
                      </option>
                    }
                    </div>
                  )
                })}
              </select>
              <select id="branchSelect" className='form-select' style={{ height: "34px", fontSize: "14px" }} value={date.branch} onChange={handelBranchChange}>
                <option value="">Select Branch</option>
                {branch?.data?.map((item) => (
                  <option value={item.branch} key={item.id}>
                    {item.branch}
                  </option>
                ))}
              </select>
              <select id="departmentSelect" className='form-select' style={{ height: "34px", fontSize: "14px" }} value={date.department} onChange={handelDepartmentChange}>
                <option value="">Select Department</option>
                {department?.data?.map((item) => (
                  <option value={item.department} key={item.id}>
                    {item.department}
                  </option>
                ))}
              </select>
              <select id="designationSelect" className='form-select' style={{ height: "34px", fontSize: "14px" }} value={date.designation} onChange={handelDesignationChange}>
                <option value="">Select Designation</option>
                {designationData?.map((item) => (
                  <option value={item.title} key={item.id}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <button className='btn btn-primary' onClick={getJavaId} style={{ padding: "8px 16px" }}>Add</button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className='scrollBar' style={{ height: "600px", overflowY: "scroll" }}>
          <AdminUserForm submit={handleSubmit} employeeData={employeeData} setEmployees={setEmployees} />
          <div style={{ display: "flex", justifyContent: "end", padding: "1rem" }}>
            <button className='btn btn-primary' onClick={(e) => {
              handleSubmit(e)
            }} style={{ padding: "8px 16px", marginRight: "1rem" }}>Create</button>
            <button className='btn btn-primary' style={{ padding: "8px 16px", marginRight: "1rem" }} onClick={handleClose}>Cancel</button>
          </div>
        </Box>
      </Modal>
      <hr />
      <div className='row'>
        <div>
          <div className='card'>
            <div className="card-body recruitment table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Emp ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Company</th>
                    <th scope="col">Branch</th>
                    <th scope="col">Department</th>
                    <th scope="col">Designation</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Salary</th>
                    <th scope="col">Screen Shots</th>
                  </tr>
                </thead>
                <tbody>
                  {params.id ? currentItems
                    .slice(indexOfFirstItem, indexOfLastItem)?.map((item, i) => {
                      return (
                        <>
                          {item?.company?.id == params.id &&
                            <tr key={item.id}>
                              <th scope="row">
                                {item.java_id}
                              </th>
                              <td scope="col">
                                <Link to={`/admin/employees/profile/${item.id}`} style={{ textDecoration: "none", color: "black" }} className='link_hover'>
                                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <div style={{ height: "30px", width: "30px", borderRadius: "50%" }}>
                                      <img style={{ borderRadius: "50%" }} src={item.profile !== null ? img_url + item.profile : "https://newvintagewi.org/wp-content/uploads/2021/07/final-resized-male-image.jpg"} width={"100%"} height={"100%"} />
                                    </div>
                                    <div>
                                      {item.name}
                                    </div>
                                  </div>
                                </Link>
                              </td>
                              <td>{item.email}</td>
                              <td>{item.company?.name}</td>
                              <td>{item.branch?.branch}</td>
                              <td>{item.department?.department}</td>
                              <td>{item.designation?.title}</td>
                              <td>{item.phone}</td>
                              <td>{item.salary}</td>
                              <td>
                                <button className='btn btn-primary p-1'>
                                  <Link to={`/admin/employee/${item.id}`} style={{ textDecoration: "none", color: "white" }} >
                                    <CiRead style={{ fontSize: "20px" }} />
                                  </Link>
                                </button>
                              </td>
                            </tr>}
                        </>
                      )
                    }) :
                    currentItems
                      .slice(indexOfFirstItem, indexOfLastItem)?.map((item, i) => {
                        return (
                          <tr key={item.id}>
                            <th scope="row">
                              {item.java_id}
                            </th>
                            <td scope="col">
                              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                <div style={{ height: "30px", width: "30px", borderRadius: "50%" }}>
                                  <img style={{ borderRadius: "50%" }} src={img_url + item.profile} width={"100%"} height={"100%"} />
                                </div>
                                <div>
                                  <Link to={`/admin/employees/profile/${item.id}`} style={{ textDecoration: "none", color: "black" }} className='link_hover'>
                                    {item.name}
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td>{item.email}</td>
                            <td>{item.company?.name}</td>
                            <td>{item.branch?.branch}</td>
                            <td>{item.department?.department}</td>
                            <td>{item.designation?.title}</td>
                            <td>{item.phone}</td>
                            <td>{item.salary}</td>
                            <td>
                              <button className='btn btn-primary p-1'>
                                <Link to={`/admin/employee/${item.id}`} style={{ textDecoration: "none", color: "white" }} >
                                  <CiRead style={{ fontSize: "20px" }} />
                                </Link>
                              </button>
                            </td>
                          </tr>
                        )
                      })
                  }
                </tbody>
              </table>
              <div className="pagination-controls">{paginationControls}</div>
            </div>
          </div>
        </div>
      </div >
      <Snackbar
        open={anyFieldEmpty}
        autoHideDuration={2000}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          severity="error"
          style={{
            backgroundColor: '#8f2018',
            color: "white",
          }}
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </>
  );
}

export default Employee;