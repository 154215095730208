import React, { useEffect } from 'react';
import { useData } from '../ContextProvider/Context';
import logo from '../Images/pngwing.com.png'
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import WizardForm from './CompanyCreate/WizardForm';
import EditCompany from './Editcompany/EditCompany';
import { Modal } from '@mui/material';
import { CiRead, CiTrash } from 'react-icons/ci'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};
function Company() {
    const { company, getCompnayCrud, permission, deleteCompany, img_url } = useData();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    useEffect(() => {
        getCompnayCrud()
    }, []);
    let count = 1
    const [currentPage, setCurrentPage] = React.useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const visibleCompanies = company?.data?.filter(item => item?.deleteStatus === 0) || [];

    const currentCompanies = visibleCompanies.slice(indexOfFirstItem, indexOfLastItem);

    const paginationControls = [];
    for (let i = 1; i <= Math.ceil(visibleCompanies.length / itemsPerPage); i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => setCurrentPage(i)}>
                {i}
            </button>
        );
    }

    return (
        <>
            <div className='departmentData'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Company</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='d-flex justify-content-end mb-2 gap-2'>
                        <div>
                            {permission.includes("Company Create") && <button className='btn btn-primary' onClick={handleOpen} style={{ padding: ".5rem 1rem" }}>Add</button>}
                        </div>
                    </div>
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} style={{ height: "600px", overflowY: "scroll" }} className='scrollBar'>
                        <WizardForm handleClose={handleClose} />

                    </Box>
                </Modal>

                <hr />
                <div className='row'>
                    <div>
                        <div className='card'>
                            <div className="card-body">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Company</th>
                                            <th scope="col">Phone</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Action</th>
                                            {permission.includes("Company Setting View") && <th scope="col">Acc/Setting</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentCompanies?.map((item, idx) => {
                                            return (
                                                <React.Fragment key={idx}>
                                                    {item?.deleteStatus == 0 && <tr key={item.id}>
                                                        <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                        <td>
                                                            <Link
                                                                className='link linkk link_hover'
                                                                to={`/admin/company_setting/company/detail/${item.id}`}
                                                            >
                                                                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                                    <div style={{ height: "30px", width: "30px", borderRadius: "50%" }}>
                                                                        <img style={{ borderRadius: "50%" }} src={img_url + item.logo ? img_url + item.logo : logo} width={"100%"} height={"100%"} />
                                                                    </div>
                                                                    <div>
                                                                        {item.name}
                                                                    </div>
                                                                </div>
                                                            </Link>

                                                        </td>
                                                        <td>
                                                            {item.phone}
                                                        </td>
                                                        <td>
                                                            {item.email}
                                                        </td>
                                                        <td>
                                                            {permission.includes("Company Delete") && <button
                                                                className="btn p-1 m-2 btn-danger"
                                                                onClick={() => deleteCompany(item.id)}
                                                            >
                                                                <CiTrash style={{ fontSize: "20px" }} />
                                                            </button>}
                                                            {permission.includes("Company Edit") && <EditCompany style={{ fontSize: "20px" }} value={item} />}
                                                        </td>
                                                        {permission.includes("Company Setting View") && <td>
                                                            <Link to={`/admin/company-setting/${item.id}`}>
                                                                <button className='btn btn-warning p-2' >
                                                                    <CiRead style={{ fontSize: "20px" }} />
                                                                </button>
                                                            </Link>
                                                        </td>}
                                                    </tr>}
                                                </React.Fragment>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div className="pagination-controls">{paginationControls}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Company;
