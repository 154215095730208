import React, { useEffect, useState } from 'react'
import { useData } from '../ContextProvider/Context'
import { Link } from 'react-router-dom'
import { CiCalendarDate, CiEdit, CiTrash } from 'react-icons/ci'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { BsCashCoin } from 'react-icons/bs'
import AddComition from './AddComition'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 1,
};
function CreateCamition() {
  const { base_url, permission, handleClick,
    setSnackbarValue } = useData()
  const [data, setdata] = useState()
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    getBonus()
    getBonusemp()
  }, [])
  const getBonus = () => {
    fetch(`${base_url}commissions`)
      .then((res) => res.json())
      .then((data) => {
        setdata(data?.commissions);
      });
  }
  const [empData, setempData] = useState()
  const getBonusemp = () => {
    fetch(`${base_url}empBonusData`)
      .then((res) => res.json())
      .then((data) => {
        setempData(data);
      });
  }
  console.log(data);
  const [edit, setedit] = useState()
  const handleEdit = () => {

    fetch(`${base_url}commissions/upsert`, {
      method: "POST",
      headers: {
        'Content-Type': "application/json",
        "Accept": 'application/json',

      },
      body: JSON.stringify({
        id: edit?.id,
        emp_id: edit?.employee?.id,
        amount: edit?.amount,
        month: edit?.month

      })
    })
      .then((res) => res.json())
      .then((data) => {
        handleClick()
        setSnackbarValue({ message: "Commissions Update successfully", color: "#2ECC71" });
        getBonus()
        handleClose()
      });


  }
  const handleDelete = (edit) => {

    fetch(`${base_url}deletecommission/${edit?.id}`, {
      method: "DELETE",

    })
      .then((res) => res.json())
      .then((data) => {
        getBonus()
        handleClick()
        setSnackbarValue({ message: "Commission Delete successfully", color: "#2ECC71" });
      });

  }
  console.log(edit);
  return (
    <div className='services'>
      <div className='expense-header d-flex justify-content-between align-items-baseline'>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Commissions</li>
          </ol>
        </nav>
        {permission.includes("Bonus Add") && <AddComition getBonus={getBonus} />}
      </div>
      <div className='card'>
        <div className="card-body recruitment table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Emaployee</th>
                <th scope="col">email</th>
                <th scope="col">Amount</th>
                <th scope="col">Commissions Date</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>

              {data?.map((item, i) => {
                return (
                  <tr>
                    <th scope="row">{i + 1}</th>
                    <td scope="col">
                      {item.employee?.name}
                    </td>
                    <td scope="col">{item?.employee?.email}</td>
                    <td scope="col">{item?.amount}</td>
                    <td scope="col">{item?.month}</td>
                    <td scope="col">{item?.status == 0 ? "Pending" : "Clear"}</td>
                    <td scope="col">
                      {item.status == 0 ? <>
                        {permission.includes("Bonus Edit") && <button
                          onClick={() => {
                            handleOpen()
                            setedit(item)
                          }}
                          style={{ background: "none", border: "none", marginLeft: "10px" }}><CiEdit style={{ fontSize: "25px" }} /></button>}
                        {permission.includes("Bonus Delete") && <button onClick={() => handleDelete(item)} style={{ background: "none", border: "none", marginLeft: "10px" }}>
                          <CiTrash style={{ fontSize: "25px" }} />
                        </button>}
                      </> : "-"}

                    </td>

                  </tr>
                )
              })}


            </tbody>
          </table>

        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div id="modal-modal-description" sx={{ mt: 2 }}>
            <div className='container'>
              <div className='inputFields'>

                <div className='row'>

                  <div className='col-6'>
                    <div className='inputBox mt-3'>
                      <label htmlFor="dateInput"><CiCalendarDate /></label>
                      <input
                        id="dateInput"
                        value={edit?.month}
                        onChange={(e) => {
                          setedit({ ...edit, month: e.target.value })
                        }}
                        placeholder='date' type='date'
                      ></input>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='inputBox mt-3'>
                      <label htmlFor="amountInput"><BsCashCoin /></label>
                      <input
                        id="amountInput"
                        value={edit?.amount}

                        onChange={(e) => {
                          setedit({ ...edit, amount: e.target.value })
                        }}
                        placeholder='Amount' type='number'
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-4'>
              <div className='modalBtns d-flex justify-content-end gap-2'>
                <button onClick={() => handleEdit()} className='btn btn-primary'>Edit</button>
                <button onClick={handleClose} className='btn btn-secondary'>Cancel</button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

    </div>
  )
}

export default CreateCamition