import { JitsiMeeting } from '@jitsi/react-sdk';
import React, { useEffect, useRef } from 'react';

const JitsiMeet = () => {
    const containerRef = useRef(null);

    function generateRandomRoomName(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }
      
    const randomRoomName = generateRandomRoomName(8);

    useEffect(() => {
        const domain = 'meet.jit.si';
        const options = {
          roomName: randomRoomName,
          parentNode: containerRef.current,
          interfaceConfigOverwrite: {
            filmStripOnly: false,
          },
          configOverwrite: {
            enableDesktopSharing: true, 
          },
        };
    }, [randomRoomName]);

    return (
        <div ref={containerRef}>
            <JitsiMeeting
                domain="meet.jit.si"
                roomName={randomRoomName}
                configOverwrite={{
                    startWithAudioMuted: true,
                    disableModeratorIndicator: true,
                    startScreenSharing: true,
                    enableEmailInStats: false
                }}
                interfaceConfigOverwrite={{
                    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true
                }}
                userInfo={{
                    displayName: 'YOUR_USERNAME'
                }}
                onApiReady={(externalApi) => {
                }}
                getIFrameRef={(iframeRef) => { iframeRef.style.height = '80vh'; }}
            />
        </div>
    );
};

export default JitsiMeet;
