import React, { useEffect, useState } from 'react';
import { useData } from '../ContextProvider/Context';
import { Link, useParams } from 'react-router-dom';
import { Alert, Box, Snackbar } from '@mui/material';
import { Modal, Form, Input, Progress } from 'antd';
import { MdOutlinePriceChange } from 'react-icons/md'
import { LuSubtitles } from 'react-icons/lu'
import { CiEdit, CiTrash } from 'react-icons/ci';
import { Autocomplete, Chip, TextField } from '@mui/material';
function Projects() {
    const params = useParams()
    const { getAllCrudData, allCrudData, deleteProjectData, updateProjectData, projects, getProjects, createProject, employeeID, getdseignationById, designarion, getEmploayeeByid, getCompnayCrud, getBarnchById, getdepartmetById, company, branch, departmentval, permission, handleClick, setSnackbarValue } = useData();
    const emp_id = JSON.parse(localStorage.getItem('user_auth'))
    const [searchQuery, setSearchQuery] = useState('');
    const [isCreateNewEmployee, setIsCreateNewEmployee] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [selectedDesignation, setSelectedDesignation] = useState(null);

    const filteredCompanies = allCrudData?.companies?.filter(item => item.deleteStatus === 0);

    const filteredBranches = allCrudData?.branches?.filter(item => item.companyId === selectedCompany);

    const filteredDepartments = allCrudData?.department?.filter(item => item.branchId === selectedBranch);

    const filteredDesignation = allCrudData?.designations?.filter(item => item.departmentId === selectedDepartment);

    const filteredEmployee = allCrudData?.employees?.filter(item => item.designationId === selectedDesignation);

    useEffect(() => {
        getProjects();
        getCompnayCrud()
        getAllCrudData();
    }, []);
    const showCreateNewEmployee = () => {
        setIsCreateNewEmployee(true);
    };

    const handleCancel = () => {
        setIsCreateNewEmployee(false);
        setProjetcData({
            companyId: "",
            branchId: "",
            departmentId: "",
            title: "",
            price: "",
            status: 0,
            startDate: new Date().toISOString().split('T')[0],
            user_id: emp_id?.user?.id,
            employee_id: emp_id?.employee?.id,
            desc: "",
            deadLine: "",
            users: [],
            designationId: "",

        })
    };
    const [projectData, setProjetcData] = useState({
        companyId: "",
        branchId: "",
        departmentId: "",
        title: "",
        price: "",
        status: 0,
        startDate: new Date().toISOString().split('T')[0],
        desc: "",
        deadLine: "",
        users: [],
        designationId: "",
        user_id: emp_id?.user?.id,
        employee_id: emp_id?.employee?.id,


    })
    const handleSelectChanges = (event, newValue) => {
        const selectedEmployeeIds = newValue.map(employee => employee.id);

        setProjetcData((prevData) => ({
            ...prevData,
            users: selectedEmployeeIds,
        }));

    };
    const isFormValid = () => {
        for (const key in projectData) {
            if (projectData[key] === '') {
                return false;
            }
        }
        return true;
    }
    const state = {
        open: false,
        vertical: 'bottom',
        horizontal: 'right',
    }
    const { vertical, horizontal } = state;
    const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const handleCreate = () => {
        if (projectData?.companyId?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Company is required.');
        } else if (projectData?.branchId?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Branch is required.');
        } else if (projectData?.departmentId?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Department is required.');
        } else if (projectData?.designationId?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Designation is required.');
        } else if (projectData?.title?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Title is required.');
        } else if (projectData?.price?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Price is required.');
        } else if (!projectData?.users || projectData.users.length === 0) {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Users are required.');
        } else if (projectData?.startDate?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Start Date is required.');
        } else if (!projectData?.deadLine || projectData?.deadLine?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('End Date is required.');
        } else {
            createProject(projectData)
            handleCancel()
        }
    }
    const handleUpdate = () => {
        if (projectData?.price?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Price is required.');
        } else if (projectData?.startDate?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Start Date is required.');
        } else if (!projectData?.deadLine || projectData?.deadLine?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('End Date is required.');
        } else {
            updateProjectData(projectData)
            handleCancel()
        }
    }
    const handleCloseSnackbar = () => {
        setAnyFieldEmpty(false);
    };
    const [subBtn, setBtn] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = params.id
        ? projects?.projects?.filter(item => item?.company?.id == params.id) || []
        : projects?.projects || [];

    const totalPages = Math.ceil(currentItems?.length / itemsPerPage);

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    return (
        <>
            <div className='projectData'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Projects</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='d-flex justify-content-end mb-2 gap-2'>

                        <div>
                            {permission.includes("Projects Create") && <button className='btn btn-primary' style={{ padding: "8px 16px" }} onClick={showCreateNewEmployee}>Add</button>}
                        </div>
                        <div>

                            <Link to={'/admin/reporting'}>
                                <button className='btn btn-primary' style={{ padding: "8px 16px" }}  >Reporting</button>
                            </Link>

                        </div>
                    </div>
                </div>
                <Modal
                    title="Add New Project"
                    visible={isCreateNewEmployee}
                    onCancel={handleCancel}
                    width={1000}
                    footer={null}
                >
                    <Form name="edit_personal_details">
                        <div className='card p-3 h-100' style={{ borderRadius: "10px" }}>
                            <div className='row'>
                                {!projectData?.id &&
                                    <>
                                        <div className='col-6 p-2'>
                                            <div className='form-group'>
                                                <label>Company<span style={{ color: "red" }}>*</span></label>
                                                <div className='selectBox' style={{ position: 'relative' }}>
                                                    <select
                                                        style={{
                                                            border: "none",
                                                            width: '100%',
                                                            height: '30px'
                                                        }}
                                                        value={projectData.companyId}
                                                        disabled={params.id ? true : false}

                                                        onChange={(e) => {
                                                            setProjetcData({ ...projectData, companyId: e.target.value })
                                                            setSelectedCompany(parseInt(e.target.value))
                                                        }}
                                                    >
                                                        <option hidden>Select a company</option>
                                                        {filteredCompanies?.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6 p-2'>
                                            <div className='form-group'>
                                                <label>Branch<span style={{ color: "red" }}>*</span></label>
                                                <div className='selectBox' style={{ position: 'relative' }}>
                                                    <select
                                                        style={{
                                                            border: "none",
                                                            width: '100%',
                                                            height: '30px'
                                                        }}
                                                        value={projectData.branchId}
                                                        onChange={(e) => {
                                                            setProjetcData({ ...projectData, branchId: e.target.value })
                                                            setSelectedBranch(parseInt(e.target.value))
                                                        }
                                                        }>
                                                        <option hidden>Select a branch</option>
                                                        {selectedCompany && (
                                                            <>
                                                                {filteredBranches?.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.branch}
                                                                    </option>
                                                                ))}
                                                            </>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6 p-2'>
                                            <div className='form-group'>
                                                <label>Department<span style={{ color: "red" }}>*</span></label>
                                                <div className='selectBox' style={{ position: 'relative' }}>
                                                    <select
                                                        style={{
                                                            border: "none",
                                                            width: '100%',
                                                            height: '30px'
                                                        }}
                                                        value={projectData.departmentId}
                                                        onChange={(e) => {
                                                            setProjetcData({ ...projectData, departmentId: e.target.value })
                                                            setSelectedDepartment(parseInt(e.target.value))
                                                        }}>
                                                        <option hidden>Select a department</option>
                                                        {selectedBranch && (
                                                            <>
                                                                {filteredDepartments?.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.department}
                                                                    </option>
                                                                ))}
                                                            </>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6 p-2'>
                                            <div className='form-group'>
                                                <label>Designation<span style={{ color: "red" }}>*</span></label>
                                                <div className='selectBox' style={{ position: 'relative' }}>
                                                    <select
                                                        style={{
                                                            border: "none",
                                                            width: '100%',
                                                            height: '30px'
                                                        }}
                                                        value={projectData.designationId}
                                                        onChange={(e) => {
                                                            setProjetcData({ ...projectData, designationId: e.target.value })
                                                            setSelectedDesignation(parseInt(e.target.value))
                                                        }}>
                                                        <option hidden>Select a Designation</option>
                                                        {selectedDepartment && (
                                                            <>
                                                                {filteredDesignation?.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.title}
                                                                    </option>
                                                                ))}
                                                            </>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className='col-6 p-2'>
                                    <div className="mb-2" style={{ height: "48px" }}>
                                        <div className="form-group ">
                                            <label>Title<span style={{ color: "red" }}>*</span></label>
                                            <div className='inputBox'>
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        width: '100%'
                                                    }}
                                                    component="form"
                                                    sx={{
                                                        '& > :not(style)': { m: 0 },
                                                        '& label.MuiInputLabel-root': { fontSize: '14px' },
                                                    }}
                                                    noValidate
                                                    autoComplete="off"

                                                >
                                                    <label><LuSubtitles /></label>
                                                    <Input
                                                        style={{
                                                            border: 'none'
                                                        }}
                                                        id="title1"
                                                        placeholder="Title"
                                                        variant="standard"
                                                        value={projectData.title}
                                                        onChange={(e) => setProjetcData({ ...projectData, title: e.target.value })}
                                                    />
                                                </Box>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6 p-2'>
                                    <div className="mb-2" style={{ height: "48px" }}>
                                        <div className="form-group">
                                            <label>Price<span style={{ color: "red" }}>*</span></label>
                                            <div className='inputBox'>
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        width: '100%'
                                                    }}
                                                    component="form"
                                                    sx={{
                                                        '& > :not(style)': { m: 0 },
                                                        '& label.MuiInputLabel-root': { fontSize: '14px' },
                                                    }}
                                                    noValidate
                                                    autoComplete="off"

                                                >
                                                    <label><MdOutlinePriceChange /></label>
                                                    <Input
                                                        style={{
                                                            border: 'none',
                                                        }}
                                                        id="price"
                                                        type='number'
                                                        placeholder="Price"
                                                        variant="standard"
                                                        value={projectData.price}
                                                        onChange={(e) => setProjetcData({ ...projectData, price: e.target.value })}
                                                    // required
                                                    />
                                                </Box>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!projectData?.id && filteredEmployee && (
                                    <div className='col-6 p-2'>
                                        <div className='form-group'>
                                            <div>
                                                <label>Employees<span style={{ color: "red" }}>*</span></label>
                                                <Autocomplete
                                                    multiple
                                                    options={filteredEmployee}
                                                    getOptionLabel={(option) => option?.name || ''}
                                                    value={filteredEmployee?.filter(employee => projectData?.users?.includes(employee.id))}
                                                    onChange={handleSelectChanges}
                                                    renderInput={(params) => (
                                                        <TextField {...params} variant="standard" />
                                                    )}
                                                    renderTags={(value, getTagProps) =>
                                                        value.map((option, index) => (
                                                            <Chip
                                                                key={index}
                                                                label={option?.name || ''}
                                                                {...getTagProps({ index })}
                                                                onDelete={() => {
                                                                    const newSelectedEmployeeIds = projectData.users.filter(id => id !== option.id);
                                                                    setProjetcData((prevData) => ({
                                                                        ...prevData,
                                                                        users: newSelectedEmployeeIds,
                                                                    }));
                                                                }}
                                                            />
                                                        ))
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className='col-6 p-2'>
                                    <div className='form-group'>
                                        <label>Start Date<span style={{ color: "red" }}>*</span></label>
                                        <div className='inputBox' style={{ position: 'relative' }}>
                                            <input
                                                type="date"
                                                id="startData2"
                                                className="dateInput"
                                                style={{
                                                    height: "30px",
                                                    border: "none",
                                                    borderBottom: "none",
                                                    borderRadius: "0",
                                                    width: '100%',
                                                    fontSize: '14px',
                                                    padding: '10px 0',
                                                }}
                                                placeholder="Start Date"
                                                value={projectData.startDate}
                                                min={new Date().toISOString().split('T')[0]}
                                                onChange={(e) => setProjetcData({ ...projectData, startDate: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6 p-2'>
                                    <div className='form-group '>
                                        <label>End Date<span style={{ color: "red" }}>*</span></label>
                                        <div className='inputBox' style={{ position: 'relative' }}>
                                            <input
                                                id="startData3"
                                                type="date"
                                                style={{
                                                    height: "30px",
                                                    border: "none",
                                                    borderBottom: "none",
                                                    borderRadius: "0",
                                                    width: '100%',
                                                    fontSize: '14px',
                                                    padding: '10px 0',
                                                }}
                                                className="dateInput"
                                                value={projectData.deadLine}
                                                onChange={(e) => setProjetcData({ ...projectData, deadLine: e.target.value })}
                                                min={projectData.startDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6 p-2 inputFields'>
                                    <div className='form-group'>
                                        <label>Description</label>
                                        <div className='inputBox' style={{ position: 'relative' }}>
                                            <input
                                                id="startData4"
                                                style={{
                                                    border: "none",
                                                    width: '100%',
                                                    height: '30px'
                                                }}
                                                value={projectData.desc}
                                                placeholder='Description'
                                                onChange={(e) => {
                                                    setProjetcData({ ...projectData, desc: e.target.value })

                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Form.Item>
                            <button
                                className='btn btn-primary mt-3'
                                type="primary"
                                htmlType="submit"
                                disabled={subBtn}
                                onClick={() => projectData?.id ? handleUpdate() : handleCreate()}
                            >
                                {projectData?.id ? 'Update' : 'Submit'}
                            </button>

                        </Form.Item>
                    </Form>
                </Modal>
                <hr />
                <div className='card'>
                    <div className='card-body recruitment table-responsive'>
                        <table className="table projectTable">
                            <thead style={{ textAlign: 'center' }}>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Start Date</th>
                                    <th scope="col">Deadline</th>
                                    <th scope="col">Status</th>
                                    {permission.includes("Projects Edit") || permission.includes("Projects Delete") &&
                                        <th scope="col">Action</th>
                                    }
                                    <th scope="col">Progress</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                {params.id ? currentItems
                                    .slice(indexOfFirstItem, indexOfLastItem)?.map((items, ind) => {

                                        return (
                                            <div>
                                                {params.id == items.companyId && <tr key={items.id}>
                                                    <th scope="row">{indexOfFirstItem + ind + 1}</th>
                                                    <td>
                                                        <p className="text-dark" style={{ margin: ".5rem", display: "flex", alignItems: "center", gap: "5px" }}>
                                                            {permission.includes("Projects View") ? (
                                                                <Link className='link linkk link_hover' to={`/admin/projects/detail/${items.id}`}>
                                                                    {items.title.length > 20 ? `${items.title.slice(0, 20)}...` : items.title}
                                                                </Link>
                                                            ) : (
                                                                items.title.length > 20 ? `${items.title.slice(0, 20)}...` : items.title
                                                            )}
                                                        </p>

                                                    </td>
                                                    <td>
                                                        <p className="text-dark" style={{ margin: ".5rem", display: "flex", alignItems: "center", gap: "5px" }}>
                                                            {items.price}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p className="text-dark" style={{ margin: ".5rem", display: "flex", alignItems: "center", justifyContent: "center", gap: "5px" }}>
                                                            {items.startDate}
                                                        </p>
                                                    </td>
                                                    <td style={{ padding: '13px !important' }}>
                                                        {items.deadLine}
                                                    </td>
                                                    <td>
                                                        {items.performance?.percentage_completed == 0 && <p style={{
                                                            borderRadius: "30px",
                                                            padding: ".1rem .5rem",
                                                            background: "#ff000070",
                                                            margin: ".5rem",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            gap: "5px",
                                                            textAlign: "center",
                                                            color: "white"
                                                        }}>
                                                            Pending
                                                        </p>}
                                                        {items.performance?.percentage_completed > 0 && items.performance?.percentage_completed < 100 ? <p style={{
                                                            borderRadius: "30px",
                                                            padding: ".1rem .5rem",
                                                            background: "#ffd222",
                                                            margin: ".5rem",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            gap: "5px",
                                                            textAlign: "center",
                                                            color: "white"
                                                        }}>
                                                            In Progress
                                                        </p> : ""}
                                                        {items.performance?.percentage_completed == 100 && <p style={{
                                                            borderRadius: "30px",
                                                            padding: ".1rem .5rem",
                                                            background: "green",
                                                            margin: ".5rem",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            gap: "5px",
                                                            textAlign: "center",
                                                            color: "white"
                                                        }}>
                                                            Complete
                                                        </p>}
                                                    </td>

                                                    <td>
                                                        {permission.includes("Projects Edit") && <button className="btn p-1 m-2 btn-primary" onClick={() => {
                                                            showCreateNewEmployee()
                                                            setProjetcData(items)
                                                        }}><CiEdit style={{ fontSize: "20px" }} /></button>}
                                                        {permission.includes("Projects Delete") && <button className="btn p-1 m-2 btn-danger" onClick={() => deleteProjectData(items.id)}><CiTrash style={{ fontSize: "20px" }} /></button>}
                                                    </td>
                                                    <td>
                                                        <p className="text-dark" style={{ padding: ".1rem .5rem", margin: ".5rem" }}>
                                                            <Progress percent={Math.floor(items?.performance?.percentage_completed)} status="active" />
                                                        </p>
                                                    </td>
                                                </tr>}
                                            </div>

                                        )
                                    }) : currentItems
                                        .slice(indexOfFirstItem, indexOfLastItem)?.map((items, ind) => {

                                            return (
                                                <tr key={items.id}>
                                                    <th scope="row">{indexOfFirstItem + ind + 1}</th>
                                                    <td>
                                                        <p className="text-dark" style={{ margin: ".5rem", display: "flex", alignItems: "center", gap: "5px" }}>
                                                            {permission.includes("Projects View") ? (
                                                                <Link className='link linkk link_hover' to={`/admin/projects/detail/${items.id}`}>
                                                                    {items.title.length > 20 ? `${items.title.slice(0, 20)}...` : items.title}
                                                                </Link>
                                                            ) : (
                                                                items.title.length > 20 ? `${items.title.slice(0, 20)}...` : items.title
                                                            )}
                                                        </p>

                                                    </td>
                                                    <td>
                                                        <p className="text-dark" style={{ margin: ".5rem", display: "flex", alignItems: "center", gap: "5px" }}>
                                                            {items.price}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p className="text-dark" style={{ margin: ".5rem", display: "flex", alignItems: "center", justifyContent: "center", gap: "5px" }}>
                                                            {items.startDate}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        {items.deadLine}
                                                    </td>
                                                    <td>
                                                        {items.performance?.percentage_completed == 0 && <p style={{
                                                            borderRadius: "30px",
                                                            padding: ".1rem .5rem",
                                                            background: "#ff000070",
                                                            margin: ".5rem",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            gap: "5px",
                                                            textAlign: "center",
                                                            color: "white"
                                                        }}>
                                                            Pending
                                                        </p>}
                                                        {items.performance?.percentage_completed > 0 && items.performance?.percentage_completed < 100 ? <p style={{
                                                            borderRadius: "30px",
                                                            padding: ".1rem .5rem",
                                                            background: "#ffd222",
                                                            margin: ".5rem",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            gap: "5px",
                                                            textAlign: "center",
                                                            color: "white"
                                                        }}>
                                                            In Progress
                                                        </p> : ""}
                                                        {items.performance?.percentage_completed == 100 && <p style={{
                                                            borderRadius: "30px",
                                                            padding: ".1rem .5rem",
                                                            background: "green",
                                                            margin: ".5rem",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            gap: "5px",
                                                            textAlign: "center",
                                                            color: "white"
                                                        }}>
                                                            Complete
                                                        </p>}
                                                    </td>

                                                    <td>
                                                        {permission.includes("Projects Edit") && <button className="btn p-1 m-2 btn-primary" onClick={() => {
                                                            showCreateNewEmployee()
                                                            setProjetcData(items)
                                                        }}><CiEdit style={{ fontSize: "20px" }} /></button>}
                                                        {permission.includes("Projects Delete") && <button className="btn p-1 m-2 btn-danger" onClick={() => deleteProjectData(items.id)}><CiTrash style={{ fontSize: "20px" }} /></button>}
                                                    </td>
                                                    <td>
                                                        <p className="text-dark" style={{ padding: ".1rem .5rem", margin: ".5rem" }}>
                                                            <Progress percent={Math.floor(items?.performance?.percentage_completed)} status="active" />
                                                        </p>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                            </tbody>
                        </table>
                        <div className="pagination-controls">{paginationControls}</div>
                    </div>
                </div>
            </div >
            <Snackbar
                open={anyFieldEmpty}
                autoHideDuration={2000}
                anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}
            >
                <Alert
                    severity="error"
                    style={{
                        backgroundColor: '#8f2018',
                        color: "white",
                    }}
                    onClose={handleCloseSnackbar}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default Projects;
