import React, { useEffect, useRef, useState } from 'react';
import logo from '../Images/pngwing.com.png'
import { Layout, Menu, theme } from 'antd';
import { Box, TextField } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import Avatar from '@mui/material/Avatar';
import Menu1 from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import { LuUser } from 'react-icons/lu';
import { BsChatDots } from 'react-icons/bs'
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import Employee from './Employee';
import AllDepartments from './AllDepartments';
import Designation from './Designation';
import EmployeeProfile from './EmployeeProfile';
import { AiOutlinePoweroff } from 'react-icons/ai';
import Projects from './Projects';
import ProjectDetail from './ProjectDetail';
import Company from './Company';
import Branch from './Branch';
import CompanyProfile from './CompanyProfile';
import Transfer from './Transfer';
import SubMenu from 'antd/es/menu/SubMenu';
import Assets from '../Components/Account/Account/Assets'
import AssetsCategory from '../Components/Account/Account/AssetsCategory'
import BalanceSheet from '../Components/Account/Account/BalanceSheet'
import Expance from '../Components/Account/Account/Expance'
import ExpanceCategory from '../Components/Account/Account/ExpanceCategory'
import ExpanceSubCategory from '../Components/Account/Account/ExpanceSubCategory'
import GeneralLedger from '../Components/Account/Account/GeneralLedger'
import CompanyTable from '../Components/Account/Account/CompanyTable'
import CompanyTable1 from '../Components/Account/Account/CompanyTable1'
import CompanyTable2 from '../Components/Account/Account/CompanyTable2'
import CompanyTable3 from '../Components/Account/Account/CompanyTable3'
import Vendor from '../Components/Account/Account/Vendor'
import VendorVeiw from '../Components/Account/Account/VendorVeiw'
import Invoice from '../Components/Account/Account/Invoice'
import ProfitAndLose from '../Components/Account/Account/ProfitAndLose'
import PurchaseAssets from '../Components/Account/Account/PurchaseAssets'
import Revenue from '../Components/Account/Account/Revenue'
import Services from '../Components/Account/Account/Services'
import ServicesCategory from '../Components/Account/Account/ServicesCategory'
import TrialBalance from '../Components/Account/Account/TrialBalance'
import { FcAdvertising, FcCurrencyExchange, FcSms, FcTodoList } from 'react-icons/fc'
import JitsiMeet from './JitsiMeet';
import ChatApp from './ChatApp';
import { CiLogin, CiDesktop, CiDollar, CiMail, CiMedicalClipboard, CiMoneyBill, CiShop } from 'react-icons/ci';
import Payroll from '../Components/Payroll'
import Cv from '../Components/Cv'
import JobList from './JobCreate/JobList';
import Applicatan from './JobCreate/Applicatan';
import CreateBouns from './CreateBouns';
import Email from '../Components/Email/Email';
import CreateTemplate from '../Components/Email/CreateTemplate'
import CreateEmail from '../Components/Email/CreateEmail'
import CreateRolls from './Rolls&permission/CreateRolls';
import { useData } from '../ContextProvider/Context';
import TaskView from './TaskView';
import ShowScreenShorts from './ShowScreenShorts';
import Leave from './Leave';
import AccountData from '../Components/Account/Account/AccountData'
import Client from './Account/Account/Client';
import Supplier from './Account/Account/Supplier';
import EqualityLiability from './Account/Account/EqualityLiability'
import { CiSettings, CiBookmark, CiBank, CiGrid41, CiUser, CiGrid31 } from 'react-icons/ci'
import AccountView from './Account/Account/AccountView';
import SupplierView from './Account/Account/SupplierVeiw';
import ClientView from './Account/Account/ClientView';
import Dashboard from './Dashboard';
import img1 from '../Images/office-building.png'
import img2 from '../Images/franchising.png'
import img3 from '../Images/department (1).png'
import img4 from '../Images/ui-design.png'
import img6 from '../Images/employee (1).png'
import img5 from '../Images/team.png'
import img7 from '../Images/wages.png'
import img9 from '../Images/money-transfer.png'
import img10 from '../Images/budget.png'
import img11 from '../Images/allocation.png'
import img12 from '../Images/technical-support.png'
import img13 from '../Images/invoice.png'
import img14 from '../Images/revenue.png'
import img15 from '../Images/financial-profit.png'
import img16 from '../Images/supplier.png'
import img17 from '../Images/handshake.png'
import img18 from '../Images/balance-sheet.png'
import img19 from '../Images/ledger.png'
import img20 from '../Images/justice.png'
import img21 from '../Images/options.png'
import img22 from '../Images/categories.png'
import img23 from '../Images/shareholders.png'
import img24 from '../Images/briefcase.png'
import img25 from '../Images/user.png'
import img26 from '../Images/categories (1).png'
import img27 from '../Images/customer-service.png'
import img32 from '../Images/annousment.jpg'
import img28 from '../Images/liquidation.png'
import img29 from '../Images/chat.png'
import img30 from '../Images/gmail.png'
import img31 from '../Images/web-chat.png'
import ShowAlert from './ShowAlert';
import { PiAppWindowLight } from 'react-icons/pi';
import InvoiceView from './Account/Account/InvoiceView';
import HiringContract from './Account/HiringContract';
import CompanyPolise from './Account/CompanyPolise';
import UserForm from './UserForm';
import CreateCamition from './CreateCamition';
import AddComition from './AddComition';
import CompanyAddPage from './CompanyAddPage';
import { GoDotFill, GoGoal } from 'react-icons/go'
import TimeAgoMessage from './TimeAgoMessage';
import { Cancel } from '@mui/icons-material';
import AllProjectReporting from './AllProjectReporting';
import Goal from './Goal'
import GoalView from './GoalView'
import CompanyAnoucment from './CompanyAnoucment';
import AnnouncementCreate from './AnnouncementCreate';
import Badge from '@mui/material/Badge';
import Smtp from './Smtp';
import Vendor1 from './Account/Account/Vendor1';
import AttandanceShow from './AttandanceShow';
import AttandanceRegister from './AttandanceRegister';

const { Header, Sider, Content } = Layout;
function AdminComponent() {
    const { getNotification, notifications, permission, company_id, setLogin, base_url } = useData()
    useEffect(() => {
        getNotification();
        const intervalId = setInterval(() => {
            getNotification();
        }, 300000);
        return () => clearInterval(intervalId);
    }, []);

    const [collapsed, setCollapsed] = useState(true);
    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [bellAnchorEl, setBellAnchorEl] = React.useState(null);
    const emp_id = JSON.parse(localStorage.getItem('user_auth'))

    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const navigate = useNavigate()
    const handleCancelClick = () => {
        setSearchModalOpen(false);
    };

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const bellMenuOpen = Boolean(bellAnchorEl);
    const handleBellClick = (event) => {
        setBellAnchorEl(event.currentTarget);
        // getNotification()
        setNewNotification(0)
        localStorage.setItem("notification_count", 0)

    };
    const handleBellClose = () => {
        setBellAnchorEl(null);
    };

    const [openKeys, setOpenKeys] = useState([]);

    const onOpenChange = (keys) => {
        setOpenKeys(keys);
    };
    const updataStatus = (id) => {
        fetch(`${base_url}notifications/${id}`, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ is_seen: true }),
        })
            .then((res) => res.json())
            .then((data) => {
                getNotification()
            });
    }

    const isCompanySettingsOpen = openKeys.includes('company-settings');
    const user = JSON.parse(localStorage.getItem("user_auth"))?.user?.role == "user" ? false : true
    const wellcomeName = JSON.parse(localStorage.getItem("user_auth"))
    const [newNotification, setNewNotification] = useState(0)
    function notificationsLabel(count) {
        if (count === 0) {
            return 'no notifications';
        }
        if (count > 99) {
            return 'more than 99 notifications';
        }
        return `${count} notifications`;
    }
    useEffect(() => {
        let count = 0
        const localNotification = localStorage.getItem("notification_count") ? localStorage.getItem("notification_count") : 0
        notifications?.map((item) => {
            if (item?.is_seen == 0 && item.empId == emp_id?.employee?.id) {
                count++
            }
        })
        if (count > localNotification) {
            localStorage.setItem("notification_count", count)
            setNewNotification(count)
        }
    }, [notifications])


    return (
        <>
            <div className="indexContainer">
                <div
                    className={`searchTopModal bg-white ${searchModalOpen ? 'open' : ''}`}
                    style={{
                        height: '64px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        top: searchModalOpen ? '0' : '-200px',
                    }}
                >
                    <Box style={{ width: "90%", height: "64px" }}>
                        <TextField
                            id="standard-search"
                            placeholder="Search field"
                            type="search"
                            variant="standard"
                            style={{ width: "90%", height: "100%", display: "flex", justifyContent: "center" }}
                        />
                    </Box>
                    <Box>
                        <Cancel onClick={handleCancelClick} style={{ cursor: 'pointer' }} />
                    </Box>
                </div>
                <Layout>
                    <Sider trigger={null} collapsible collapsed={collapsed} id="hallloid" style={{ height: '100vh' }}>
                        <div className='sideheader' style={{ height: '64px', background: '#fff', display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <div className='sideLogo' style={{ height: "40px", width: "39px" }}>
                                <img src={logo} alt='' height={'100%'} width={'100%'} />
                            </div>
                        </div>
                        <div className='sideMenu'>
                            <Menu
                                onClick={({ key }) => {
                                    if (key === "/signin") {
                                        setLogin(null)
                                        localStorage.removeItem('user_auth');
                                        navigate("/signin");
                                    }
                                }}
                                openKeys={openKeys}
                                onOpenChange={onOpenChange}
                                mode='inline'
                            >
                                {permission.includes("Dashboard") && <Menu.Item key="/dashboard" icon={<CiGrid41 style={{ fontSize: "20px" }} />}>
                                    <Link className="link linkHover linkActive" to={"/admin/dashboard"}>
                                        <span className='text-white'>
                                            Dashboard
                                        </span>
                                    </Link>
                                </Menu.Item>}
                                {permission.includes("Employee View") && <Menu.Item key="/employees" icon={<CiUser style={{ fontSize: "20px" }} />}>
                                    <Link className="link linkHover linkActive" to={company_id ? `/admin/employees/${company_id}` : "/admin/employees"}>
                                        <span className='text-white'>
                                            Employees
                                        </span>
                                    </Link>
                                </Menu.Item>}
                                {permission.includes("Projects View") && <Menu.Item key="/projects" icon={<CiGrid31 style={{ fontSize: "20px" }} />}>
                                    <Link className="link linkHover linkActive" to={company_id ? `/admin/projects/${company_id}` : "/admin/projects"}>
                                        <span className='text-white'>
                                            Projects
                                        </span>
                                    </Link>
                                </Menu.Item>}
                                {user && <SubMenu className='settingsDropdown' icon={<CiSettings style={{ fontSize: "20px" }} />}>
                                    <div style={{ width: "400px", height: "84vh", padding: "1rem", background: "#c1c1c1", display: "flex", position: "absolute", top: "-8.2rem", alignItems: "center", flexDirection: "column", }}>
                                        <div className='row w-100'>
                                            <div className='card text-align my-3' style={{ padding: "7px" }}>
                                                <p style={{ fontSize: "14px", fontWeight: "bold", paddingTop: ".8rem", textAlign: "center" }}>
                                                    Settings
                                                </p>
                                            </div>
                                            {permission.includes("Company View") && <div className='col-md-4 w-33'>
                                                {<Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/company_setting/company/${company_id}` : "/admin/company_setting/company"}>
                                                    <div className='card appCard' >
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img1} />
                                                        </div>
                                                        <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Company
                                                        </Menu.Item>
                                                    </div>
                                                </Link>}
                                            </div>}
                                            {permission.includes("Branch View") && <div className='col-md-4 w-33'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/company_setting/branch/${company_id}` : "/admin/company_setting/branch"}>
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img2} />
                                                        </div>
                                                        <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Branch
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            {permission.includes("Department View") && <div className='col-md-4 w-33'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/company_setting/departments/${company_id}` : "/admin/company_setting/departments"} >
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img3} />
                                                        </div>
                                                        <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Departments
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                        </div>
                                        <div className='row w-100 mt-3'>
                                            {permission.includes("Designation View") && <div className='col-md-4 w-33'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/company_setting/designation/${company_id}` : "/admin/company_setting/designation"} >
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img4} />
                                                        </div>                                                        <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Designation
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            {permission.includes("Create Rolls & Permissions") && <div className='col-md-4 w-33'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={'/admin/create_Rolls-Premission'}>
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img5} />
                                                        </div>                                                          <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Create Roles
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            {/* <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={"/admin/accounts/banking/Services"}>
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img12} />
                                                        </div>                                                          <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Services
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={"/admin/accounts/banking/ServicesCategory"}>
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img27} />
                                                        </div>                                                          <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Services Cate
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div> */}
                                            {permission.includes("Company Policy") && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={"/admin/company-policies"}>
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img27} />
                                                        </div>
                                                        <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Company Policy
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            {permission.includes("Company Announcement") && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={"/admin/announcement-create"}>
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img style={{ width: "23px" }} src={img32} />
                                                        </div>
                                                        <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Company Announcement
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                        </div>
                                    </div>
                                </SubMenu>}
                                {user && <SubMenu className='hrDropdwon' icon={<CiBookmark style={{ fontSize: "20px" }} />} >
                                    <div style={{ width: "400px", height: "84vh", padding: "1rem", background: "#c1c1c1", display: "flex", position: "absolute", top: "-11rem", alignItems: "center", flexDirection: "column", }}>
                                        <div className='row w-100'>
                                            <div className='card text-align my-3' style={{ padding: "7px" }}>
                                                <p style={{ fontSize: "14px", fontWeight: "bold", paddingTop: ".8rem", textAlign: "center" }}>Human Resources</p>
                                            </div>
                                            {permission.includes('Payroll') && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={"/admin/payroll"}>
                                                    <div className='card appCard' >
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img7} />
                                                        </div>
                                                        <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Payroll
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            {permission.includes('Job Post') && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={"/admin/dashboard/chat/recruitment"}>
                                                    <div className='card appCard' >
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img24} />
                                                        </div>
                                                        <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Job Post
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={"/admin/dashboard/chat/message"}>
                                                    <div className='card appCard' >
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img29} />
                                                        </div>
                                                        <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Chat
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={"/admin/create_email"}>
                                                    <div className='card appCard' >
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img30} />
                                                        </div>
                                                        <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Email
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={"/admin/dashboard/chat/meeting"}>
                                                    <div className='card appCard' >
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img31} />
                                                        </div>
                                                        <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Meeting
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>
                                            {permission.includes('Register') && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={"/admin/register"}>
                                                    <div className='card appCard' >
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img31} />
                                                        </div>
                                                        <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Register
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                        </div>
                                    </div>
                                </SubMenu>}
                                {/* {permission.includes('Account All') && <SubMenu className='accountsDropdown' title="Accounts" icon={<CiBank style={{ fontSize: "20px" }} />} key="/accounts">
                                    <div className='accounDrop' style={{ width: "400px", height: "84vh", padding: "1rem", background: "#c1c1c1", display: "flex", position: "absolute", top: "-13.7rem", alignItems: "center", flexDirection: "column", overflowY: "scroll" }}>
                                        <div className='row w-100'>
                                            <div className='card text-align my-3' style={{ padding: "7px" }}>
                                                <p style={{ fontSize: "14px", fontWeight: "bold", paddingTop: ".8rem", textAlign: "center" }}>Accounts</p>
                                            </div>
                                            {permission.includes("Accounts") && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/accounts/banking/accounts/${company_id}` : "/admin/accounts/banking/account"}>
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img25} />
                                                        </div>
                                                        <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Account
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            {permission.includes("Equity Liability") && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/accounts/banking/equalityLiability/${company_id}` : "/admin/accounts/banking/equalityLiability"}>
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img23} />
                                                        </div>
                                                        <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Equity Liability
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            {permission.includes("Transfer") && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/accounts/banking/transfer/${company_id}` : "/admin/accounts/banking/transfer"}>
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img9} />
                                                        </div>                                                          <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Transfer
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            {permission.includes("Expense Category") && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/accounts/banking/expanceCategory/${company_id}` : "/admin/accounts/banking/expanceCategory"} >
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img21} />
                                                        </div>                                                           <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Expense Cate
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            {permission.includes("Expense Subcategory") && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/accounts/banking/expanceSubCategory/${company_id}` : "/admin/accounts/banking/expanceSubCategory"}>
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img22} />
                                                        </div>                                                           <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Expense SubCate
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            {permission.includes("Expense") && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/accounts/banking/expance/${company_id}` : "/admin/accounts/banking/expance"}>
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img10} />
                                                        </div>
                                                        <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Expense
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            {permission.includes("Asset Category") && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/accounts/banking/assetsCategory/${company_id}` : "/admin/accounts/banking/assetsCategory"}>
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img26} />
                                                        </div>                                                          <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Assets Cate
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            {permission.includes("Assets") && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/accounts/banking/assets/${company_id}` : "/admin/accounts/banking/assets"}>
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img11} />
                                                        </div>                                                          <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Assets
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            {permission.includes("Purchase Assets") && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/accounts/banking/PurchaseAssets/${company_id}` : "/admin/accounts/banking/PurchaseAssets"} >
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img28} />
                                                        </div>                                                          <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Purchase Assets
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            {permission.includes("Services Category") && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/accounts/banking/ServicesCategory/${company_id}` : "/admin/accounts/banking/ServicesCategory"} >
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img27} />
                                                        </div>                                                          <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Services Cate
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            {permission.includes("Services") && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/accounts/banking/Services/${company_id}` : "/admin/accounts/banking/Services"}>
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img12} />
                                                        </div>                                                          <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Services
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            {permission.includes("Clients") && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/accounts/banking/clients/${company_id}` : "/admin/accounts/banking/client"} >
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img17} />
                                                        </div>                                                          <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Customer
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            {permission.includes("Suppliers") && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/accounts/banking/suppliers/${company_id}` : "/admin/accounts/banking/supplier"}>
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img16} />
                                                        </div>                                                         <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Supplier
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/accounts/banking/vendors/${company_id}` : "/admin/accounts/banking/vendor"} >
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img16} />
                                                        </div>
                                                        <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Vendor
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>
                                            {permission.includes("Invoice") && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/accounts/banking/Invoices/${company_id}` : "/admin/accounts/banking/Invoice"}>
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img13} />
                                                        </div>
                                                        <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Invoice
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            {permission.includes("Revenue") && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/accounts/banking/Revenue/${company_id}` : "/admin/accounts/banking/Revenue"}>
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img14} />
                                                        </div>
                                                        <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Revenue
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            {permission.includes("General Ledger") && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/accounts/banking/GeneralLedgers/${company_id}` : "/admin/accounts/banking/GeneralLedger"}>
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img19} />
                                                        </div>
                                                        <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            General Ledger
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            {permission.includes("Trail Balance") && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/accounts/banking/BalanceSheets/${company_id}` : "/admin/accounts/banking/BalanceSheet"} >
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img18} />
                                                        </div>
                                                        <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Balance Sheet
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            {permission.includes("Balance Sheet") && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/accounts/banking/TrialBalances/${company_id}` : "/admin/accounts/banking/TrialBalance"}>
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img20} />
                                                        </div>
                                                        <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Trail Balance
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}
                                            {permission.includes("Profit & Loss") && <div className='col-md-4 w-33 mb-3'>
                                                <Link className="link linkActive" style={{ color: "black" }} to={company_id ? `/admin/accounts/banking/ProfitAndLoses/${company_id}` : "/admin/accounts/banking/ProfitAndLose"}>
                                                    <div className='card appCard'>
                                                        <div style={{ paddingTop: "11px" }}>
                                                            <img src={img15} />
                                                        </div>                                                          <Menu.Item className='linkHover' style={{ lineHeight: "28px", color: "black", margin: "0", padding: "0", textAlign: "center", fontSize: "12px", fontWeight: "bold" }}>
                                                            Profit & Loss
                                                        </Menu.Item>
                                                    </div>
                                                </Link>
                                            </div>}

                                        </div>
                                    </div>
                                </SubMenu >} */}
                                <Menu.Item key="/signin" icon={<AiOutlinePoweroff style={{ fontSize: "20px" }} />}>
                                    Sign out
                                </Menu.Item>
                            </Menu >
                        </div >
                    </Sider >

                    <Layout>
                        <Header style={{ padding: "0 1rem", background: colorBgContainer, display: "flex", justifyContent: "space-between" }}>
                            <div className='headerContent'>
                                <div className='topSearchBar'>
                                    <div style={{ paddingLeft: "5px" }}>
                                        <SearchOutlinedIcon style={{ cursor: 'pointer', color: "#65676b" }} />
                                    </div>
                                    <div>
                                        <input id="searchInput" type="search" placeholder='Search' />
                                    </div>
                                </div>
                            </div>
                            <div className='headerContent' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {permission.includes("Emails") && <Link to={company_id ? `/admin/create_email/${company_id}` : "/admin/create_email"}>
                                    <CiMail style={{ fontSize: "35px", color: "rgb(90,90,90)", border: "1px solid", borderRadius: "50%", padding: ".3rem .4rem", marginRight: "40px", cursor: "pointer" }} title='Email' />
                                </Link>}
                                {permission.includes("Project View") && <Link to={"/admin/dashboard/chat/meeting"}>
                                    <CiMedicalClipboard style={{ fontSize: "35px", color: "rgb(90,90,90)", border: "1px solid", borderRadius: "50%", padding: ".3rem .4rem", marginRight: "40px", cursor: "pointer" }} title='Project' />
                                </Link>}
                                {user && <Link to={"/admin/dashboard/chat/meeting"}>
                                    <CiDesktop style={{ fontSize: "35px", color: "rgb(90,90,90)", border: "1px solid", borderRadius: "50%", padding: ".3rem .4rem", marginRight: "40px", cursor: "pointer" }} title='Video Chat' />
                                </Link>}
                                {/* {permission.includes("Balance Sheet") && <Link to={"/admin/accounts/banking/BalanceSheet"}>
                                    <CiDollar style={{ fontSize: "35px", color: "rgb(90,90,90)", border: "1px solid", borderRadius: "50%", padding: ".3rem .4rem", marginRight: "40px", cursor: "pointer" }} title='Balance Sheet' />
                                </Link>}
                                {permission.includes("Balance Invoice") && <Link to={"/admin/accounts/banking/Invoice"}>
                                    <CiMoneyBill style={{ fontSize: "35px", color: "rgb(90,90,90)", border: "1px solid", borderRadius: "50%", padding: ".3rem .4rem", marginRight: "40px", cursor: "pointer" }} title='Invoice' />
                                </Link>} */}
                                {permission.includes("Leave") && <Link to={"/admin/leave"}>
                                    <CiLogin style={{ fontSize: "35px", color: "rgb(90,90,90)", border: "1px solid", borderRadius: "50%", padding: ".3rem .4rem", marginRight: "40px", cursor: "pointer" }} title='leave' />
                                </Link>}
                                {permission.includes("Job Post") && <Link to={"/admin/dashboard/chat/recruitment"}>
                                    <CiShop style={{ fontSize: "35px", color: "rgb(90,90,90)", border: "1px solid", borderRadius: "50%", padding: ".3rem .4rem", marginRight: "40px", cursor: "pointer" }} title='Job Post' />
                                </Link>}
                                {user && <Link to={"/admin/goal"}>
                                    <GoGoal style={{ fontSize: "35px", color: "rgb(90,90,90)", border: "1px solid", borderRadius: "50%", padding: ".3rem .4rem", marginRight: "40px", cursor: "pointer" }} title='Goal' />
                                </Link>}

                            </div>
                            <div className='headerWidgets d-flex'>
                                <div style={{ marginRight: "1rem" }}>
                                    <p style={{ fontSize: "15px", fontWeight: "bold" }}>Welcome! <span style={{ fontWeight: "normal" }}>{wellcomeName?.user?.name ? wellcomeName?.user?.name : wellcomeName?.employee?.name}</span></p>
                                </div>
                                <div className='headerUserBell '>
                                    {user && <Link to={"/admin/dashboard/chat/message"} className='hedIcon' style={{ marginRight: "20px", background: "#e4e6eb", borderRadius: "50%", color: "#65676b", height: "35px", width: "35px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <BsChatDots style={{ cursor: "pointer" }} />
                                    </Link>}
                                    {user && <div className='hedIcon' style={{ background: "#e4e6eb", borderRadius: "50%", color: "#65676b", height: "35px", width: "35px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <IconButton aria-label={notificationsLabel(newNotification)}>
                                            <Badge badgeContent={newNotification} color="secondary">

                                                <NotificationsNoneOutlinedIcon style={{ cursor: "pointer" }} onClick={handleBellClick} />
                                            </Badge>
                                        </IconButton>

                                    </div>}
                                    {user && <div>
                                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }} >
                                            <IconButton
                                                onClick={handleClick}
                                                size="small"
                                                sx={{ ml: 2 }}
                                                aria-controls={open ? 'account-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                                className='usericonHover'
                                            >
                                                <Avatar sx={{ width: 35, height: 35, background: "#e4e6eb" }} className='hedIcon'>
                                                    <LuUser style={{ cursor: "pointer", color: "#65676b" }} />
                                                </Avatar>
                                            </IconButton>
                                        </Box>
                                        <Menu1
                                            anchorEl={anchorEl}
                                            id="account-menu"
                                            open={open}
                                            onClose={handleClose}
                                            onClick={handleClose}
                                            PaperProps={{
                                                elevation: 0,
                                                sx: {
                                                    overflow: 'visible',
                                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                    mt: 1.5,
                                                    '& .MuiAvatar-root': {
                                                        width: 32,
                                                        height: 32,
                                                        ml: -0.5,
                                                        mr: 1,
                                                    },
                                                    '&:before': {
                                                        content: '""',
                                                        display: 'block',
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 14,
                                                        width: 10,
                                                        height: 10,
                                                        bgcolor: 'background.paper',
                                                        transform: 'translateY(-50%) rotate(45deg)',
                                                        zIndex: 0,
                                                    },
                                                },
                                            }}
                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                        >
                                            <MenuItem onClick={handleClose}>
                                                <a href='https://wholesaleshopping.tv/desktop/hrm-1.0.exe' target='_blank' style={{ textDecoration: "none", color: "black", display: "flex", alignItems: "center" }}>
                                                    <ListItemIcon>
                                                        <PiAppWindowLight fontSize="20px" />
                                                    </ListItemIcon>
                                                    Desktop Application
                                                </a>

                                            </MenuItem>
                                        </Menu1>
                                    </div>}
                                </div>
                                {user && <Menu1
                                    anchorEl={bellAnchorEl}
                                    open={bellMenuOpen}
                                    onClose={handleBellClose}
                                    onClick={handleBellClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            background: isCompanySettingsOpen ? 'red' : 'inherit',
                                            left: "900"
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <Box>
                                        <div className='card'>
                                            <div className='card-body'>
                                                <div className='card_header'>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }} className='notiheader'>
                                                        <h5>Notification</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ width: "350px", height: "70vh", borderRadius: "10px", overflowY: "scroll", overflowX: "hidden", padding: "0 1rem" }} className='notiheaderLinks'>
                                                {notifications?.map((item) => {
                                                    return (
                                                        <React.Fragment key={item.id}>
                                                            {item.empId === emp_id?.employee?.id ? (
                                                                <>
                                                                    {item.title == "Message" && <div onClick={() => {
                                                                        updataStatus(item?.id)
                                                                    }} className='row my-2' style={{ padding: ".5rem", background: "lightgray", borderRadius: "10px", cursor: "pointer" }}>
                                                                        <div style={{ padding: " 0" }} className='col-2'>
                                                                            <div style={{ background: "#E4EBE6", height: "50px", width: "50px", borderRadius: "50%", overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                                <FcSms style={{ fontSize: "25px" }} />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ display: "flex" }} className='col-9'>
                                                                            <div>

                                                                                <h6 style={{ fontWeight: "bold", margin: "0" }}>Message <TimeAgoMessage created_at={item?.created_at} /></h6>
                                                                                <p style={{ margin: "0" }}>{item.message}</p>
                                                                            </div>
                                                                            <div className="col-1" style={{ display: "flex", justifyContent: "center", alignContent: "space-between" }} >
                                                                                {item?.is_seen == 0 ? <GoDotFill style={{ color: "#3498DB" }} /> : ""}
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                                    {item.title == "Task" && <div onClick={() => {
                                                                        updataStatus(item?.id)
                                                                        navigate("/admin/projects")
                                                                    }} className='row my-2' style={{ padding: ".5rem", background: "lightgray", borderRadius: "10px", cursor: "pointer" }}>
                                                                        <div style={{ padding: " 0" }} className='col-2'>
                                                                            <div style={{ background: "#E4EBE6", height: "50px", width: "50px", borderRadius: "50%", overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                                <FcTodoList style={{ fontSize: "25px" }} />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ display: "flex" }} className='col-9'>
                                                                            <div>

                                                                                <h6 style={{ fontWeight: "bold", margin: "0" }}>Message <TimeAgoMessage created_at={item?.created_at} /></h6>
                                                                                <p style={{ margin: "0" }}>{item.message}</p>
                                                                            </div>
                                                                            <div className="col-1" style={{ display: "flex", justifyContent: "center", alignContent: "space-between" }} >
                                                                                {item?.is_seen == 0 ? <GoDotFill style={{ color: "#3498DB" }} /> : ""}
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                                    {item.title == "Project" && <div onClick={() => {
                                                                        updataStatus(item?.id)
                                                                        navigate("/admin/projects")
                                                                    }} className='row my-2' style={{ padding: ".5rem", background: "lightgray", borderRadius: "10px", cursor: "pointer" }}>
                                                                        <div style={{ padding: " 0" }} className='col-2'>
                                                                            <div style={{ background: "#E4EBE6", height: "50px", width: "50px", borderRadius: "50%", overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                                <FcTodoList style={{ fontSize: "25px" }} />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ display: "flex" }} className='col-9'>
                                                                            <div>

                                                                                <h6 style={{ fontWeight: "bold", margin: "0", whiteSpace: "nowrap" }}>Message <TimeAgoMessage created_at={item?.created_at} /></h6>
                                                                                <p style={{ margin: "0" }}>{item.message}</p>
                                                                            </div>
                                                                            <div className="col-1" style={{ display: "flex", justifyContent: "center", alignContent: "space-between" }} >
                                                                                {item?.is_seen == 0 ? <GoDotFill style={{ color: "#3498DB" }} /> : ""}

                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                                    {item.title == "Salary" && <div onClick={() => {
                                                                        updataStatus(item?.id)
                                                                    }} className='row my-2' style={{ padding: ".5rem", background: "lightgray", borderRadius: "10px", cursor: "pointer" }}>
                                                                        <div style={{ padding: " 0" }} className='col-2'>
                                                                            <div style={{ background: "#E4EBE6", height: "50px", width: "50px", borderRadius: "50%", overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                                <FcCurrencyExchange style={{ fontSize: "25px" }} />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ display: "flex" }} className='col-9'>
                                                                            <div>

                                                                                <h6 style={{ fontWeight: "bold", margin: "0" }}>Message</h6>
                                                                                <p style={{ margin: "0" }}>{item.message}</p>
                                                                            </div>
                                                                            <div>
                                                                                {item?.is_seen == 0 ? <GoDotFill style={{ color: "#3498DB" }} /> : ""}
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {item.title === "Announcement" && (
                                                                        <Link to={`/admin/announcement/${item.announcementId}`}>
                                                                            <div onClick={() => {
                                                                                updataStatus(item?.id);
                                                                            }} className='row my-2' style={{ padding: ".5rem", background: "lightgray", borderRadius: "10px", cursor: "pointer" }}>
                                                                                <div style={{ padding: " 0" }} className='col-2'>
                                                                                    <div style={{ background: "#E4EBE6", height: "50px", width: "50px", borderRadius: "50%", overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                                        <FcAdvertising style={{ fontSize: "25px" }} />
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ display: "flex" }} className='col-9'>
                                                                                    <div>
                                                                                        <h6 style={{ fontWeight: "bold", margin: "0" }}>Message</h6>
                                                                                        <p style={{ margin: "0" }}>{item.message}</p>
                                                                                    </div>
                                                                                    <div>
                                                                                        {item?.is_seen === 0 ? <GoDotFill style={{ color: "#3498DB" }} /> : ""}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    )}
                                                                </>
                                                            )}
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </Box>
                                </Menu1>}
                            </div>
                        </Header>
                        <Content
                            style={{
                                padding: 24,
                                minHeight: 280,
                                background: " #F0F2F5",
                                wordWrap: "break-word"
                            }}
                            className='contentOverflow'
                        >

                            <Routes>
                                <Route path='/dashboard' element={<Dashboard />} />
                                <Route path='/dashboard/:id' element={<Dashboard />} />
                                <Route path='/settings' element={<div>Settings</div>} />
                                <Route path='/payroll' element={<Payroll />} />
                                <Route path='/employees' element={<Employee />} />
                                <Route path='/employees/:id' element={<Employee />} />
                                <Route path='/employees/profile/:id' element={<EmployeeProfile />} />
                                <Route path='/projects' element={<Projects />} />
                                <Route path='/projects/:id' element={<Projects />} />
                                <Route path='/projects/detail/:id' element={<ProjectDetail />} />
                                <Route path='/company_setting/departments' element={<AllDepartments />} />
                                <Route path='/company_setting/departments/:id' element={<AllDepartments />} />
                                <Route path='/company_setting/designation' element={<Designation />} />
                                <Route path='/company_setting/designation/:id' element={<Designation />} />
                                <Route path='/company_setting/company' element={<Company />} />
                                <Route path='/company_setting/company/:id' element={<Company />} />
                                <Route path='/company_setting/company/detail/:id' element={<CompanyProfile />} />
                                <Route path='/company_setting/branch' element={<Branch />} />
                                <Route path='/company_setting/branch/:id' element={<Branch />} />
                                <Route path='/accounts/banking/account' element={<AccountData />} />
                                <Route path='/accounts/banking/accounts/:id' element={<AccountData />} />
                                <Route path='/accounts/banking/account/:id' element={<AccountView />} />
                                <Route path='/accounts/banking/transfer' element={<Transfer />} />
                                <Route path='/accounts/banking/transfer/:id' element={<Transfer />} />
                                <Route path='/accounts/banking/expance' element={<Expance />} />
                                <Route path='/accounts/banking/expance/:id' element={<Expance />} />
                                <Route path='/accounts/banking/expanceCategory' element={<ExpanceCategory />} />
                                <Route path='/accounts/banking/expanceCategory/:id' element={<ExpanceCategory />} />
                                <Route path='/accounts/banking/expanceSubCategory' element={<ExpanceSubCategory />} />
                                <Route path='/accounts/banking/expanceSubCategory/:id' element={<ExpanceSubCategory />} />
                                <Route path='/accounts/banking/assets' element={<Assets />} />
                                <Route path='/accounts/banking/assets/:id' element={<Assets />} />
                                <Route path='/accounts/banking/assetsCategory' element={<AssetsCategory />} />
                                <Route path='/accounts/banking/assetsCategory/:id' element={<AssetsCategory />} />
                                <Route path='/accounts/banking/PurchaseAssets' element={<PurchaseAssets />} />
                                <Route path='/accounts/banking/PurchaseAssets/:id' element={<PurchaseAssets />} />
                                <Route path='/accounts/banking/Services' element={<Services />} />
                                <Route path='/accounts/banking/Services/:id' element={<Services />} />
                                <Route path='/accounts/banking/ServicesCategory' element={<ServicesCategory />} />
                                <Route path='/accounts/banking/ServicesCategory/:id' element={<ServicesCategory />} />
                                <Route path='/accounts/banking/Invoice' element={<Invoice />} />
                                <Route path='/accounts/banking/Invoices/:id' element={<Invoice />} />
                                <Route path='/accounts/banking/Invoice/:id' element={<InvoiceView />} />
                                <Route path='/accounts/banking/Revenue' element={<Revenue />} />
                                <Route path='/accounts/banking/Revenue/:id' element={<Revenue />} />
                                <Route path='/accounts/banking/GeneralLedger/:id' element={<GeneralLedger />} />
                                <Route path='/accounts/banking/GeneralLedger' element={<CompanyTable />} />
                                <Route path='/accounts/banking/GeneralLedgers/:id' element={<CompanyTable />} />
                                <Route path='/accounts/banking/BalanceSheet/:id' element={<BalanceSheet />} />
                                <Route path='/accounts/banking/BalanceSheet' element={<CompanyTable1 />} />
                                <Route path='/accounts/banking/BalanceSheets/:id' element={<CompanyTable1 />} />
                                <Route path='/accounts/banking/TrialBalance/:id' element={<TrialBalance />} />
                                <Route path='/accounts/banking/TrialBalance' element={<CompanyTable2 />} />
                                <Route path='/accounts/banking/TrialBalances/:id' element={<CompanyTable2 />} />
                                <Route path='/accounts/banking/ProfitAndLose/:id' element={<ProfitAndLose />} />
                                <Route path='/accounts/banking/ProfitAndLose' element={<CompanyTable3 />} />
                                <Route path='/accounts/banking/ProfitAndLoses/:id' element={<CompanyTable3 />} />
                                <Route path='/dashboard/chat/meeting' element={<JitsiMeet />} />
                                <Route path='/dashboard/chat/message' element={<ChatApp />} />
                                <Route path='/accounts/banking/vendor' element={<Vendor1 />} />
                                <Route path='/accounts/banking/vendors/:id' element={<Vendor1 />} />
                                <Route path='/accounts/banking/vendor/:id' element={<VendorVeiw />} />
                                <Route path='/dashboard/chat/recruitment' element={<JobList />} />
                                <Route path='/user-data' element={<Cv />} />
                                <Route path='/dashboard/chat/applicant/:id' element={<Applicatan />} />
                                <Route path='/Create-Bouns' element={<CreateBouns />} />
                                <Route path='/Create-commissions/:id' element={<CreateCamition />} />
                                <Route path='/Add-commissions' element={<AddComition />} />
                                <Route path='/dashboard/email' element={<Email />} />
                                <Route path='/create_email_template/:id' element={<CreateTemplate />} />
                                <Route path='/create_email' element={<CreateEmail />} />
                                <Route path='/create_email/:id' element={<CreateEmail />} />
                                <Route path='/create_Rolls-Premission' element={<CreateRolls />} />
                                <Route path='/task/:id' element={<TaskView />} />
                                <Route path='/leave' element={<Leave />} />
                                <Route path='/employee/:id' element={<ShowScreenShorts />} />
                                <Route path='/applicant-form/:id' element={<UserForm />} />
                                <Route path='/accounts/banking/client' element={<Client />} />
                                <Route path='/accounts/banking/clients/:id' element={<Client />} />
                                <Route path='/accounts/banking/client/:id' element={<ClientView />} />
                                <Route path='/accounts/banking/supplier' element={<Supplier />} />
                                <Route path='/accounts/banking/suppliers/:id' element={<Supplier />} />
                                <Route path='/accounts/banking/supplier/:id' element={<SupplierView />} />
                                <Route path='/accounts/banking/equalityLiability' element={<EqualityLiability />} />
                                <Route path='/accounts/banking/equalityLiability/:id' element={<EqualityLiability />} />
                                <Route path='/hiring-contract' element={<HiringContract />} />
                                <Route path='/company-policies' element={<CompanyPolise />} />
                                <Route path='/company-setting' element={<CompanyAddPage />} />
                                <Route path='/company-setting/:id' element={<CompanyAddPage />} />
                                <Route path='/reporting' element={<AllProjectReporting />} />
                                <Route path='/goal' element={<Goal />} />
                                <Route path='/goal/:id' element={<GoalView />} />
                                <Route path='/announcement/:id' element={<CompanyAnoucment />} />
                                <Route path='/announcement-create' element={<AnnouncementCreate />} />
                                <Route path='/announcement-create' element={<Smtp />} />
                                <Route path='/register' element={<AttandanceRegister />} />
                            </Routes>
                        </Content>
                        <ShowAlert />
                    </Layout>
                </Layout >
            </div >
        </>
    );
}

export default AdminComponent;
