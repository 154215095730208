import React, { useEffect, useState } from 'react'
import { useData } from '../../../ContextProvider/Context'
import { Link, useParams } from 'react-router-dom'
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { CiImport } from 'react-icons/ci'
function GeneralLedger() {
    const params = useParams()
    const { getLedgerdata, ledgerData, getFilterLedger } = useData()
    useEffect(() => {
        getLedgerdata(params.id)
    }, [])
    const [date, setdate] = useState({
        companyId: params.id,
        startDate: "",
        endddate: ""
    })
    const handleDownloadCsv = () => {
        const csv = Papa.unparse(ledgerData?.transactions);

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'GeneralLedger.csv');
    };
    const [selectedDescription, setSelectedDescription] = useState('');
    const [showDescriptionOverlay, setShowDescriptionOverlay] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentSuplierDetails = ledgerData?.transactions?.slice(indexOfFirstItem, indexOfLastItem) || [];

    const totalPages = Math.ceil((ledgerData?.transactions?.length || 0) / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    return (
        <div>
            <div className='services'>
                <div className='expense-header d-flex justify-content-between align-items-baseline'>
                    <div className='inp-buttons d-flex gap-2 w-100' style={{ alignItems: "end", justifyContent: "space-between" }}>
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">General Ledger</li>
                                </ol>
                            </nav>
                        </div>
                        <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                            <div>
                                <button onClick={handleDownloadCsv} className='btn btn-primary' style={{ display: "flex", alignItems: "end", gap: "10px" }}><CiImport style={{ fontSize: "20px" }} />Export</button>
                            </div>
                            <div className='startdate'>
                                <label style={{ fontSize: "10px", fontWeight: "bold" }}>Select Start Date</label> <br />
                                <input type='date' name='start' onChange={(e) => setdate({ ...date, startDate: e.target.value })} className='form-control' />
                            </div>
                            <div className='startdate'>
                                <label style={{ fontSize: "10px", fontWeight: "bold" }}>Select End Date</label> <br />
                                <input type='date' name='end' onChange={(e) => setdate({ ...date, endddate: e.target.value })} className='form-control' />
                            </div>
                            <button onClick={() => {
                                handlePageChange(1)
                                getFilterLedger(date.startDate, date.endddate, date.companyId)
                            }} className='btn btn-primary'>Search</button>
                        </div>
                    </div>
                </div>
                <hr />
                <div className='card'>
                    <div className='card-body table-responsive recruitment'>
                        <table class="table w-100">
                            <thead style={{ textAlign: 'center' }}>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Account</th>
                                    <th scope="col">Description</th>
                                    <th scope="col" className='text-center'>Debit</th>
                                    <th scope="col" className='text-center'>Credit</th>
                                    <th scope="col">Total Balance</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                {currentSuplierDetails?.map((item, idx) => {
                                    const dateParts = item.date?.split(" ")
                                    const truncatedDescription = item.description.slice(0, 20);
                                    const isDescriptionTruncated = item.description.length > 20;

                                    return (
                                        <tr>
                                            <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                            <td>{dateParts[0]}</td>
                                            <td>{item?.account?.bank_name}</td>
                                            <td>
                                                {isDescriptionTruncated ? (
                                                    <div
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedDescription(item.description);
                                                            setShowDescriptionOverlay(true);
                                                        }}
                                                    >
                                                        {truncatedDescription}...
                                                    </div>
                                                ) : (
                                                    item.description
                                                )}
                                            </td>
                                            {showDescriptionOverlay && (
                                                <div className="overlayy" style={{ background: "rgba(0,0,0,1)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                    <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                        <p style={{ color: "white" }}>{selectedDescription}</p>
                                                    </div>
                                                </div>
                                            )}
                                            <td className='text-center'><span style={{ padding: "5px", borderRadius: "30px", background: "green", color: "white" }}>{item.debit?.toLocaleString() ? item.debit?.toLocaleString() : '0'}</span></td>
                                            <td className='text-center'><span style={{ padding: "5px", borderRadius: "30px", background: "red", color: "white" }}>{item.credit?.toLocaleString() ? item.credit?.toLocaleString() : '0'}</span></td>
                                            <td>{item.balance?.toLocaleString()}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div>
                            {paginationControls.map((button, index) => (
                                <React.Fragment key={index}>{button}</React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default GeneralLedger
