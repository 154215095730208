import React from 'react'
import { useData } from '../../../ContextProvider/Context'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { CiMail, CiLocationOn, CiPhone } from 'react-icons/ci'
import html2pdf from 'html2pdf.js';
import { useRef } from 'react'
import { useState } from 'react'
import { useReactToPrint } from 'react-to-print'

function InvoiceView() {
    const { getInvoiceIndData, invoiceIndiData, img_url } = useData()
    const params = useParams()
    const cardRef = useRef(null);
    useEffect(() => {
        getInvoiceIndData(params.id)
    }, [])
    useEffect(() => {
        if (cardRef.current) {
            handleDownloadPDF();
        }
    }, [cardRef]);

    const [pdfGenerating, setPdfGenerating] = useState(false);

    const clientData = invoiceIndiData?.invoices?.client
    const addressParts = [
        clientData?.ship_address,
        clientData?.ship_city,
        clientData?.ship_province,
        clientData?.ship_country,
        clientData?.ship_postal,
    ];

    const shipping_address = addressParts.some((part) => part === null)
        ? "N/A"
        : addressParts.filter((part) => part !== null).join(" ");


    const handleDownloadPDF = () => {
        if (pdfGenerating) {
            return;
        }

        setPdfGenerating(true);

        setTimeout(() => {
            const card = cardRef.current;

            const pdfOptions = {
                margin: 10,
                filename: 'invoice.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };

            if (card) {
                html2pdf().from(card).set(pdfOptions).outputPdf((pdf) => {
                    const blob = new Blob([pdf], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'invoice.pdf';
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);

                });
            } else {
                console.error('Card element not found.');
            }

            setPdfGenerating(false);
        }, 0);
    };
    const contentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => contentRef.current,
    });


    return (
        <div>
            <div className="card" ref={contentRef}>
                <div className="card-body">
                    <div className="container mb-5 mt-3">
                        <div className="d-flex align-items-baseline" style={{ justifyContent: "space-between" }}>
                            <div>
                                <p style={{ color: '#7e8d9f', fontSize: '20px' }}>Invoice</p>
                            </div>
                            <div>
                                <div style={{ height: "50px", width: "50px", borderRadius: "50%" }}>
                                    <img src={img_url + invoiceIndiData?.invoices?.company?.logo} width={"100%"} height={"100%"} alt='' style={{ borderRadius: "50%" }} />
                                </div>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-primary text-capitalize"
                                    style={{ backgroundColor: '#60bdf3' }}
                                    onClick={handlePrint}
                                >
                                    Download PDF
                                </button>
                            </div>
                        </div>
                        <hr />
                        <div className="container">
                            <div className="col-md-12">
                                <div className="text-center" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>

                                    <p className="p-0 m-0">{invoiceIndiData?.invoices?.company?.name}</p>
                                    <p className="p-0 m-0">{invoiceIndiData?.invoices?.company?.address}</p>
                                    <p className="p-0 m-0">{invoiceIndiData?.invoices?.company?.phone} </p>
                                    <p className="p-0 m-0">{invoiceIndiData?.invoices?.company?.email} </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8">
                                    <ul className="list-unstyled">
                                        <li className="text-muted">To: <span style={{ color: '#5d9fc5' }}>{invoiceIndiData?.invoices?.client?.name}</span></li>
                                        <li className="text-muted"><CiPhone /> {invoiceIndiData?.invoices?.client?.mobile}</li>
                                        <li className="text-muted"><CiLocationOn /> {shipping_address ? shipping_address : "N/A"}</li>
                                        <li className="text-muted"><CiMail /> {invoiceIndiData?.invoices?.client?.email}</li>
                                    </ul>
                                </div>
                                <div className="col-xl-4">
                                    <p className="text-muted">Invoice</p>
                                    <ul className="list-unstyled">
                                        <li className="text-muted"><i className="fas fa-circle" style={{ color: '#84B0CA' }} /> <span className="fw-bold">ID:</span>{invoiceIndiData?.invoices?.invoice_no}</li>
                                        <li className="text-muted"><i className="fas fa-circle" style={{ color: '#84B0CA' }} /> <span className="fw-bold">Creation Date: </span>{invoiceIndiData?.invoices?.invoice_date}</li>
                                        <li className="text-muted"><i className="fas fa-circle" style={{ color: '#84B0CA' }} /> <span className="me-1 fw-bold">Status:</span><span className="badge bg-warning text-black fw-bold">
                                            {invoiceIndiData?.invoices?.status}</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row my-2 mx-1 justify-content-center">
                                <table className="table table-striped table-borderless">
                                    <thead style={{ backgroundColor: '#84B0CA' }} className="text-white">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Service Name</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Tax</th>
                                            <th scope="col">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>{invoiceIndiData?.invoices?.service?.name}</td>
                                            <td style={{ maxWidth: '150px' }}>{invoiceIndiData?.invoices?.service?.description}</td>
                                            <td>{invoiceIndiData?.invoices?.service?.tax}</td>
                                            <td>{invoiceIndiData?.invoices?.service?.amount}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-xl-12" style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                                    <p className="text-black float-start"><span className="text-black me-3"> Total Amount</span><span style={{ fontSize: '25px' }}>{invoiceIndiData?.invoices?.service?.amount}</span></p>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-xl-12">
                                    <h4>Message:</h4>
                                    <p style={{ background: "#fafafa", padding: "1rem", minHeight: "200px" }}>
                                        {invoiceIndiData?.invoices?.message}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default InvoiceView
