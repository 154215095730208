import React, { useEffect } from 'react'
import Signin_form from '../Components/Signin_form'
import { useNavigate } from 'react-router-dom'

function Signin() {
  const navigate=useNavigate()
  const login=JSON.parse(localStorage.getItem("user_auth"))
  useEffect(()=>{
    if(login?.token){
   
      navigate("/admin/dashboard")
    }
},[])
  return (
    <>
      <Signin_form/>
    </>
  )
}

export default Signin
