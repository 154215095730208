import React, { useRef, useState } from 'react'
import JoditEditor from 'jodit-react'
import { useData } from '../../ContextProvider/Context';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import { CiRead } from 'react-icons/ci';
function HiringContract() {
    const { base_url, handleClick, setSnackbarValue, emailTemplate } = useData()
    const editor = useRef(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [contract, setContract] = useState({ content: "" })
    const handleContentChange = (e) => {
        setContract({ content: e });
    }
    const handleSubmit = async () => {


        fetch(`${base_url}company_policy`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(contract)
        })
            .then((res) => res.json())
            .then((data) => {
                handleClick();
                setSnackbarValue({ message: "Contract Created", color: "#2ECC71" });
            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }
    function createMarkup(content) {
        return { __html: content };
    }
    const openDrawer = () => {
        setIsDrawerOpen(true);
    };
    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Hiring Contract</li>
                        </ol>
                    </nav>
                </div>
                <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
                    <Box
                        sx={{ width: 250 }}
                        role="presentation"
                        onClick={() => setIsDrawerOpen(false)}
                        onKeyDown={() => setIsDrawerOpen(false)}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            gap: "10px"
                        }}
                        className="scrollBar"
                    >
                        <h3 style={{ fontWeight: "bold", margin: "1rem 0", padding: "1rem", fontSize: "17px" }}>See Policies</h3>

                        {emailTemplate?.map((item) => (
                            <div key={item.id}>
                                <div style={{ display: "flex", justifyContent: "space-around", marginBottom: "6px" }}>
                                    <button
                                        className='btn btn-primary'>
                                        Edit
                                    </button>
                                </div>
                                <button
                                    style={{ height: "200px", width: "200px", overflow: "hidden" }}
                                    dangerouslySetInnerHTML={createMarkup(item.content)}
                                />
                                <p className="mt-3 text-center" style={{ fontWeight: "bold" }}>{item.type}</p>

                            </div>
                        ))}
                    </Box>
                </Drawer>
                <div className='d-flex justify-content-end mb-2 gap-2'>
                    <div>
                        <button
                            onClick={openDrawer}
                            className='btn btn-primary cursorPointer'
                            style={{ padding: ".5rem 1rem" }}
                        ><span style={{ fontWeight: "bold", cursor: "pointer" }}><CiRead style={{ fontSize: "20px" }} /></span>
                        </button>
                    </div>
                    <div>
                        <button className='btn btn-primary' onClick={handleSubmit} style={{ padding: ".5rem 1rem" }}>Add</button>
                    </div>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>

                    <JoditEditor
                        ref={editor}
                        value={contract?.content}
                        onChange={handleContentChange}
                    />
                </div>
            </div>
        </>
    )
}

export default HiringContract