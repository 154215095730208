import React from 'react'
import { MdPayment } from 'react-icons/md'
import { BsFillImageFill, BsGenderAmbiguous, BsTelephoneOutbound, BsChatSquareText } from 'react-icons/bs'
import { LuActivitySquare } from 'react-icons/lu'
import { PiStudentBold } from 'react-icons/pi'
import { TbCirclesRelation } from 'react-icons/tb'
import { AiOutlineIdcard } from 'react-icons/ai'
import { HiOutlineMail, HiOutlineDocumentAdd } from 'react-icons/hi'
import { MdOutlineFolderSpecial, MdOutlineDriveFileRenameOutline } from 'react-icons/md'
import { Link } from 'react-router-dom'


function Cv() {
    return (
        <>
            <div className='container cvTempelate mt-5'>
                <div className='row'>
                    <div className='expense-header d-flex justify-content-between align-items-baseline'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Create Job</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-4'>
                        <div className='inputFields'>
                            <div className='inputBox mt-3'>
                                <label for='img'><BsFillImageFill /></label>
                                <label >Profile Image</label>
                                <input id='img' type='file' style={{ display: 'none', marginLeft: '2px' }}></input>
                            </div>
                            <div className='mt-3 inputBox'>
                                <label><LuActivitySquare /></label>
                                <input placeholder='Hobby'></input>
                            </div>
                            <div className='mt-3 inputBox'>
                                <label><BsGenderAmbiguous /></label>
                                <input placeholder='Gender'></input>
                            </div>
                            <div className='mt-3 inputBox'>
                                <label><MdOutlineFolderSpecial /></label>
                                <input placeholder='Speciality'></input>
                            </div>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='inputFields'>
                            <div className='inputBox mt-3'>
                                <label><MdOutlineDriveFileRenameOutline /></label>
                                <input placeholder='First Name'></input>
                            </div>
                            <div className='mt-3 inputBox'>
                                <label><PiStudentBold /></label>
                                <input placeholder='Education'></input>
                            </div>
                            <div className='mt-3 inputBox'>
                                <label><TbCirclesRelation /></label>
                                <input type='text' placeholder='Marital Status'></input>
                            </div>
                            <div className='mt-3 inputBox'>
                                <label><AiOutlineIdcard /></label>
                                <input type='number' placeholder='CNIC'></input>
                            </div>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='inputFields'>
                            <div className='inputBox mt-3'>
                                <label><MdOutlineDriveFileRenameOutline /></label>
                                <input placeholder='Last Name'></input>
                            </div>
                            <div className='mt-3 inputBox'>
                                <label><HiOutlineMail /></label>
                                <input placeholder='Email'></input>
                            </div>
                            <div className='mt-3 inputBox'>
                                <label><BsTelephoneOutbound /></label>
                                <input placeholder='Phone No.'></input>
                            </div>
                            <div className='mt-3 inputBox'>
                                <label></label>
                                <input type='date' placeholder='D.o.B'></input>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-6'>
                        <div className='textarea gap-2'>
                            <label><BsChatSquareText /></label>
                            <textarea placeholder='Description'
                                style={{
                                    border: 'none',
                                    width: '100%',
                                    outline: 'none',
                                }}>
                            </textarea>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='inputFileds'>
                            <div className='inputBox justify-content-between gap-2' style={{ height: '70px' }}>
                                <div>
                                    <label><HiOutlineDocumentAdd /></label>
                                    <label for='doc' style={{ color: 'gray' }}>Documents</label>
                                    <input
                                        id='doc'
                                        type='file'
                                        style={{
                                            border: 'none',
                                            width: '100%',
                                            display: 'none'
                                        }}
                                        placeholder='Documents'
                                    ></input>
                                </div>
                                <div className='btn btn-danger'
                                    style={{
                                        padding: '10px',
                                        fontSize: '12px'
                                    }}
                                >
                                    Add More
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-10'>

                    </div>
                    <div className='col-2 d-flex justify-content-end mt-3'>
                        <button className='btn btn-primary'
                            style={{

                            }}
                        >Submit</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cv
