import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import InputContainer from "./InputContainer";
import List from "./List";
import { useData } from "../../ContextProvider/Context";
import { useParams } from "react-router-dom";



export default function Home() {
  const {data,onDragEnd,getTaskStatusNew}=useData()
  const parms=useParams()
  useEffect(()=>{
    getTaskStatusNew(parms?.id)
  },[])

  return (
  
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="app" type="list" direction="horizontal">
          {(provided) => (
            <div
              className="wrapper"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {data?.map((listId, index) => {
                // const list = data.lists[listId];

                return <List list={listId} key={index} index={index}
                draggable={index+1==data?.length?true:false}
                
                />;
              })}
              <div>
                <InputContainer type="list" />
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
   
  );
}
