import React from 'react';

function Step4Component({ formData, handleChange, setFormData }) {

  const calculateTotalTime = (day, idx) => {
    const dayData = formData.timings.find((item) => item.day === day);

    if (dayData && dayData.startTime && dayData.endTime) {
      const startMinutes = parseInt(dayData.startTime.split(':')[0]) * 60 + parseInt(dayData.startTime.split(':')[1]);
      const endMinutes = parseInt(dayData.endTime.split(':')[0]) * 60 + parseInt(dayData.endTime.split(':')[1]);

      const totalMinutes = endMinutes - startMinutes;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      const newdata = [...formData.timings]
      newdata[idx].totalTime = `${parseInt(hours)}`
      setFormData({ ...formData, timings: newdata })
      return `${hours} hours and ${minutes} minutes`;
    }

    return 'Not specified';
  };

  return (
    <div>

      <div className="timing-form create_compant_timing">
        {formData?.timings?.map((day, index) => (
          <div className='row align-items-center mb-1'>
            <div className='col-2'>
              <p>{day.day}</p>
            </div>
            <div className='col-3'>
              <label>Start Time</label>
              <input type='time'
              id="daymy-1"
              name="daymy-1"
                value={day.startTime}
                onChange={(e) => {
                  const newData = [...formData.timings]
                  newData[index].startTime = e.target.value
                  setFormData({ ...formData, timings: newData })
                  calculateTotalTime(day.day, index)
                }}
              />
            </div>
            <div className='col-3'>
              <label>End Time</label>
              <input type='time'
              id="daymy-2"
              name="daymy-2"
                value={day.endTime}
                onChange={(e) => {
                  const newData = [...formData.timings]
                  newData[index].endTime = e.target.value

                  setFormData({ ...formData, timings: newData })
                  calculateTotalTime(day.day, index)
                }}
              />
            </div>
            <div className='col-3'>
              <label>Total Time:</label>

              <span>{day.totalTime}hr</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Step4Component;
