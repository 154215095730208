import React, { useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom';
import { useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
import { CiRead } from 'react-icons/ci';
import { useData } from '../ContextProvider/Context';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Modal } from '@mui/material';
import { Form } from 'antd';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
};

function GoalView() {
    const params = useParams()
    const { handleClick, setSnackbarValue, img_url, projects, getProjects, updateGoalsData, getGoalsDataInd, goalsDataInd, getGoalsCommentData, goalsCommentData, postGoalsComments, deleteGoalsComment } = useData()
    useEffect(() => {
        getProjects()
        getGoalsDataInd(params?.id)
        getGoalsCommentData(params?.id)
    }, [])
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    useEffect(() => {
        setUpdataData({
            title: goalsDataInd?.title,
            project_ids: goalsDataInd?.projects?.map((item) => item.project_id),
            description: goalsDataInd?.description,
            time_period: goalsDataInd?.time_period,
            user_id: goalsDataInd?.owner?.id,
            members: goalsDataInd?.members,
            overall_completion_percentage: goalsDataInd?.overall_completion_percentage
        })
    }, [goalsDataInd])
    const initialProjects = Array.isArray(goalsDataInd?.projects) ? goalsDataInd?.projects : [];
    const emp_id = JSON.parse(localStorage.getItem('user_auth'))
    const [updataData, setUpdataData] = useState({
        title: '',
        project_ids: [],
        description: '',
        time_period: goalsDataInd?.time_period,
        user_id: goalsDataInd?.owner?.id,
        members: []
    })
    const fileInputRef = useRef(null);
    const initialPostCmnt = {
        id: params?.id,
        comment: '',
        file_path: null,
        user_id: emp_id?.user?.id,
    };
    const initialPostCmnt1 = {
        id: params?.id,
        comment: '',
        file_path: null,
        emp_id: emp_id?.employee?.id,
    };
    const [postCmnt, setPostCmnt] = useState(emp_id?.employee ? initialPostCmnt1 : initialPostCmnt);
    const handleFileInputChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64String = event.target.result;
                setPostCmnt({ ...postCmnt, file_path: base64String });
            };
            reader.readAsDataURL(file);
        }
    };
    const sendComment = () => {
        postGoalsComments(postCmnt);
        setPostCmnt(emp_id?.employee ? initialPostCmnt1 : initialPostCmnt)
        fileInputRef.current.value = '';
    };
    const cancelComment = () => {
        setPostCmnt(emp_id?.employee ? initialPostCmnt1 : initialPostCmnt)
        fileInputRef.current.value = '';
    };
    const isFormValid = () => {
        for (const key in updataData) {
            if (updataData[key] === '') {
                return false;
            }
        }
        return true;
    };
    const handleSubmitGoal = (e) => {
        if (isFormValid()) {
            e.preventDefault();
            updateGoalsData(updataData, params?.id)
            handleClose()
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }

    }

    const [showMoreList, setShowMoreList] = useState([]);

    const toggleShowMore = (index) => {
        const newList = [...showMoreList];
        newList[index] = !newList[index];
        setShowMoreList(newList);
    };
    return (
        <>
            <div className='taskViewData'>
                <div className='row'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                            <li className="breadcrumb-item"><Link to={"/admin/goal"}>Goals</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Detail </li>
                        </ol>
                    </nav>
                </div>
                <div className='card p-3'>
                    <div className='row'>
                        <div className='col-12 mb-3'>
                            <div className='row mt-3'>
                                <div className='p-3'>
                                    <div className='row mb-3'>
                                        <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "baseline" }}>
                                            <p className='mb-3' style={{ fontWeight: "bold" }}>Title</p>
                                            <button className='btn btn-primary' onClick={handleOpen}>Edit</button>
                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                                style={{ padding: "1rem" }}
                                            >
                                                <Box sx={style} style={{ padding: "1rem" }}>
                                                    <div className='mb-3'><h3 style={{ fontWeight: "bold", fontSize: "15px" }}>Edit Goal</h3></div>
                                                    <Form name="add_designation">
                                                        <div className="row">
                                                            <div className="col-md-6 mb-2">
                                                                <div className='form-group'>
                                                                    <label htmlFor='titlee'>Title</label>
                                                                    <input
                                                                        className='form-control'
                                                                        type='text'
                                                                        id='titlee'
                                                                        value={updataData?.title}
                                                                        onChange={(e) => setUpdataData({ ...updataData, title: e.target.value })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6 mb-2'>
                                                                <label htmlFor='desccc'>Description</label>
                                                                <input
                                                                    className='form-control'
                                                                    type='text'
                                                                    id='desccc'
                                                                    value={updataData?.description}
                                                                    onChange={(e) => setUpdataData({ ...updataData, description: e.target.value })}
                                                                />

                                                            </div>
                                                            <div className="col-md-12 mb-2">
                                                                <div className="form-group">
                                                                    <label htmlFor="company">Projects</label>
                                                                    <Autocomplete
                                                                        multiple
                                                                        id="company"
                                                                        options={projects?.projects || []}
                                                                        value={updataData?.project_ids?.map(projectId => projects?.projects?.find(project => project.id === projectId))}
                                                                        getOptionLabel={(option) => option.title}
                                                                        onChange={(event, newValue) => {
                                                                            const selectedProjectIds = newValue?.map(project => project.id);
                                                                            setUpdataData({ ...updataData, project_ids: selectedProjectIds });
                                                                        }}
                                                                        style={{ width: '100%', marginTop: '10px' }}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params} label="Select Projects" />
                                                                        )}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Form.Item>
                                                            <button className='btn btn-primary mt-3' style={{ marginRight: "10px" }} type="primary" onClick={handleSubmitGoal}>
                                                                Update
                                                            </button>
                                                            <button className='btn btn-danger mt-3' type="primary" onClick={handleClose}>
                                                                Cancel
                                                            </button>
                                                        </Form.Item>
                                                    </Form>
                                                </Box>
                                            </Modal>
                                        </div>
                                        <div>
                                            <p style={{ fontSize: "15px", fontWeight: "bold" }}>{updataData?.title}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <h5 style={{ fontWeight: "bold", fontSize: "14px" }}> Owner</h5>
                                        <div className='headerProfile mb-3' style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                            <div>
                                                <div style={{ height: "50px", width: "50px", border: "1px solid ", borderRadius: "50%" }}>
                                                    <img style={{ borderRadius: "50%" }} src={img_url + goalsDataInd?.owner?.profile} height={"100%"} width={"100%"} />
                                                </div>
                                            </div>
                                            <div className='mt-1'>
                                                <h5 style={{ fontWeight: "bold", fontSize: "14px" }}> {goalsDataInd?.owner?.name}</h5>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", gap: "10px" }}>
                                            <h4 style={{ fontSize: "15px", fontWeight: "bold" }}>Time Period:</h4>
                                            <p>{goalsDataInd?.time_period}</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row' style={{ display: "flex", justifyContent: "center" }}>
                                        <div className='card p-3 w-75'>
                                            <div>
                                                <div>
                                                    <h3 style={{ fontWeight: "bold", fontSize: "14px" }}>{goalsDataInd?.overall_completion_percentage} / 100%</h3>
                                                </div>
                                            </div>
                                            <div className='row mb-3'>
                                                <div className="progress"
                                                    style={{
                                                        height: '100%',
                                                        borderRadius: '5px',
                                                        padding: '0'
                                                    }}
                                                >
                                                    <div className="progress-bar progress-bar-success" role="progressbar" aria-valuenow='percentage'
                                                        aria-valuemin={0} aria-valuemax={100} style={{ width: `${updataData?.overall_completion_percentage}` }}>
                                                        {updataData?.overall_completion_percentage}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div style={{ display: "flex", alignItems: "center", gap: "10px", justifyContent: "space-evenly" }}>
                                                    <div>
                                                        <div>
                                                            <img width={"50%"} alt='' src={'https://d3ki9tyy5l5ruj.cloudfront.net/obj/bafc8fe649de44ec52f53678e75c6b85e750457f/Project-Icon-96px@2X.png'} />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p>Use projects to automatically update this goal's progress.</p>
                                                        <div className='my-3'>
                                                            {goalsDataInd?.projects?.map((item) => (
                                                                <div key={item.project_id} style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", width: "100%" }}>
                                                                    <div style={{ width: "70%" }} >
                                                                        <p>
                                                                            <Link to={`/admin/projects/detail/${item.project_id}`} className='link_hover' style={{ color: "inherit" }}>
                                                                                {item.project_name}
                                                                            </Link>
                                                                        </p>
                                                                    </div>
                                                                    <div style={{ display: "flex", gap: "10px", justifyContent: "space-between", alignItems: "center", width: "30%" }}>
                                                                        <div className="progress"
                                                                            style={{
                                                                                height: '8px',
                                                                                width: '100%',
                                                                                borderRadius: '10px',
                                                                                padding: '0'
                                                                            }}
                                                                        >
                                                                            <div className="progress-bar progress-bar-success" role="progressbar" aria-valuenow='percentage'
                                                                                aria-valuemin={0} aria-valuemax={100} style={{ width: `${parseFloat(item.completion_percentage) || 0}%` }}>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ margin: "1rem 0" }}>
                                            <h2 style={{ fontWeight: "bold", fontSize: "16px" }}>Description</h2>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <p style={{ fontSize: '15px', }}>
                                                    {updataData?.description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mb-3'>
                                        <h4 style={{ fontSize: "15px", fontWeight: "bold" }}>Comments</h4>
                                    </div>
                                    <div className='row'>
                                        <div className='card-body'>
                                            <div className='row m-0 scrollBar' style={{ height: "300px", overflowY: "scroll" }}>
                                                <div>
                                                    {goalsCommentData?.map((items, index) => {
                                                        const date = new Date(items.created_at);
                                                        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                                                        const formattedDay = daysOfWeek[date.getDay()];
                                                        const formattedDate = `${formattedDay}, ${date.getFullYear()} - ${(date.getMonth() + 1).toString().padStart(2, '0')} -${date.getDate().toString().padStart(2, '0')} `;
                                                        const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} `;
                                                        const isLongComment = items.comment && items.comment?.length > 250;
                                                        return (
                                                            <div className='card mb-2 p-2' key={index}>
                                                                <div style={{ display: "flex", gap: "20px" }}>
                                                                    <div style={{ display: "flex", gap: "10px", justifyContent: "space-between", width: "100%" }}>
                                                                        <div style={{ display: "flex", gap: "10px" }}>
                                                                            <div>
                                                                                <div style={{ height: "50px", width: "50px", border: "1px solid ", borderRadius: "50%" }}>
                                                                                    <img style={{ borderRadius: "50%" }} src={items?.user_id ? img_url + items?.user?.profile : img_url + items?.employee?.profile} height={"100%"} width={"100%"} />
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div style={{ display: "flex", gap: "10px", alignItems: "end" }}>
                                                                                    <div>
                                                                                        <h5 style={{ fontWeight: "bold", fontSize: "14px" }}>{items?.user_id ? items?.user?.name : items?.employee?.name}</h5>
                                                                                    </div>
                                                                                    <div style={{ display: "flex", gap: "10px", alignItems: "baseline" }}>
                                                                                        <p className='leads' style={{ marginBottom: "6px", fontSize: "12px" }}>{formattedDate} {formattedTime}</p>
                                                                                        <div className='row'>
                                                                                            {items.file_path ? (
                                                                                                <Link to={img_url + items.file_path} download target={'_blank'}>
                                                                                                    <CiRead style={{ fontSize: "20px", color: "black" }} /> <span style={{ color: "black" }}>file...</span>
                                                                                                </Link>
                                                                                            ) : null}

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    {showMoreList[index] || !isLongComment ? items.comment : `${items.comment?.substring(0, 250)}`}
                                                                                    {isLongComment && (
                                                                                        <p onClick={() => toggleShowMore(index)} style={{ color: "blue", cursor: "pointer" }}>
                                                                                            {showMoreList[index] ? "Show less" : "Show more"}
                                                                                        </p>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            {emp_id?.user ? (
                                                                                <div className='userDeleteIcon'>
                                                                                    <RxCross1 style={{ cursor: "pointer" }} onClick={() => deleteGoalsComment(items.id, postCmnt)} />
                                                                                </div>
                                                                            ) : (
                                                                                <div>
                                                                                    {emp_id?.employee && (
                                                                                        <div>
                                                                                            {items.emp_id === emp_id.employee.id ? (
                                                                                                <div>
                                                                                                    <RxCross1 style={{ cursor: "pointer" }} onClick={() => deleteGoalsComment(items.id, postCmnt)} />
                                                                                                </div>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            <div className='card p-3 my-3'>
                                                <div className="row mb-3">
                                                    <textarea
                                                        className="onfocus scrollBar"
                                                        cols={80}
                                                        id='texttatea'
                                                        rows={4}
                                                        style={{ width: "100%" }}
                                                        value={postCmnt.comment}
                                                        onChange={(e) => setPostCmnt({ ...postCmnt, comment: e.target.value })}
                                                        placeholder="Write..."
                                                    ></textarea>
                                                    {/* <label for='comment' style={{ cursor: 'pointer', border: '1px solid grey', borderRadius: '5px', width: 'max-content', padding: '10px' }}>Choose File</label> */}
                                                    <input
                                                        id='comment'
                                                        type="file"
                                                        onChange={handleFileInputChange}
                                                        className="form-control onfocus"
                                                        ref={fileInputRef}
                                                    />
                                                </div>
                                                <div className="row" style={{ display: "flex", gap: "20px" }}>
                                                    <button
                                                        className="btn btn-primary"
                                                        style={{ width: "max-content" }}
                                                        onClick={sendComment}
                                                    >
                                                        Send Comments
                                                    </button>
                                                    <button
                                                        className="btn btn-secondary"
                                                        style={{ width: "max-content" }}
                                                        onClick={cancelComment}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div >
        </>
    )
}

export default GoalView
