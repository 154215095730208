import JoditEditor from 'jodit-react'
import React, { useEffect, useRef, useState } from 'react'
import { useData } from '../../ContextProvider/Context';
import { useParams } from 'react-router-dom';

function CreateTemplate() {
    const params = useParams()
    const { handleClick, setSnackbarValue, getEmailTemplateIndividualData, emailTemplateIndividualData, updateEmailTemplate } = useData()
    useEffect(() => {
        getEmailTemplateIndividualData(params.id)
    }, [])

    const editor = useRef(null);
    const [obj, setObj] = useState({
        id: params.id,
        type: emailTemplateIndividualData?.type,
        content: '',
        subject: ''
    })

    const handleContentChange = (e) => {
        setObj({ ...obj, content: e });
    }
    const isFormValid = () => {
        for (const key in obj) {
            if (obj[key] === '') {
                return false;
            }
        }
        return true;
    };
    const handleSubmit = () => {
        if (isFormValid()) {
            updateEmailTemplate(obj)
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }
    return (
        <div>
            <div className='card p-3'>
                <div className='row'>
                    <div className='col-12 mb-3'>
                        <label>Enter Title</label>
                        <input type='text' disabled value={emailTemplateIndividualData?.type} placeholder='Title' className='form-control' />
                    </div>
                    <div className='col-12 mb-3'>
                        <label>Enter Subject</label>
                        <input type='text' onChange={(e) => setObj({ ...obj, subject: e.target.value })} placeholder='Subject' className='form-control' />
                    </div>
                </div>
                <div className='emailSummerNoteArea'>
                    <JoditEditor
                        ref={editor}
                        value={emailTemplateIndividualData?.content}
                        onChange={handleContentChange}
                    />
                </div>
                <div className='row my-3' style={{ display: "flex", justifyContent: "end" }}>
                    <button className='btn btn-success' style={{ width: "max-content" }} onClick={handleSubmit} type='submit'>
                        Save Template
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CreateTemplate
