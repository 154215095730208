import React, { useEffect, useState } from 'react';
import { useData } from '../ContextProvider/Context';
import { Link, useParams } from 'react-router-dom';
import { CiEdit, CiTrash } from 'react-icons/ci';
import { Box, Modal } from '@mui/material';
import { Form } from 'antd';
import { Snackbar, Alert } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
};
function Branch() {
    const params = useParams()
    const { company, getBranch, branch, delbarch, editBrachCompany, getCompnayCrud, storeBranchData, permission } = useData();
    useEffect(() => {
        getCompnayCrud()
        getBranch()
    }, []);
    const [obj, setObj] = useState({
        companyId: params.id ? params.id : "",
        branch: ''
    })
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setObj({
            companyId: params.id ? params.id : "",
            branch: ''
        })
        setOpen(false)
    };
    const [state, setState] = React.useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'right',
    });
    const { vertical, horizontal } = state;
    const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const handleAddBranch = () => {
        if (obj?.companyId?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Company is required.');
        } else if (obj?.branch?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Branch is required.');
        } else {
            storeBranchData(obj)
            handleClose()
        }
    };
    const handleCloseSnackbar = () => {
        setAnyFieldEmpty(false);
    };
    const handleupdateBranch = () => {
        if (obj?.companyId?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Company is required.');
        } else if (obj?.branch?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Branch is required.');
        } else {
            editBrachCompany(obj, obj?.id)
            handleClose()
            setObj({
                companyId: params.id ? params.id : "",
                branch: ''
            })
        }
    };
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = params.id
        ? branch?.data?.filter(item => item?.company?.id == params.id) || []
        : branch?.data || [];

    const totalPages = Math.ceil(currentItems.length / itemsPerPage);

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }

    return (
        <>
            <div className='departmentData'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Branch</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='d-flex justify-content-end mb-2 gap-2'>
                        <div>
                            {permission.includes("Branch Create") && <button className='btn btn-primary' onClick={handleOpen} style={{ padding: ".5rem 1rem" }}>Add</button>}
                        </div>
                    </div>
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{ padding: "1rem" }}
                >
                    <Box sx={style} style={{ padding: "1rem" }}>
                        <Form name="add_designation">
                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label htmlFor="company">Company<span style={{ color: "red" }}>*</span></label>
                                        <select
                                            id="company"
                                            style={{ width: '100%' }}
                                            className='form-select'
                                            disabled={params.id ? true : false}
                                            value={obj.companyId}
                                            onChange={(e) => setObj({ ...obj, companyId: e.target.value })}
                                        >
                                            <option hidden>Select Company</option>
                                            {company?.data?.map((item) => {
                                                return (
                                                    <React.Fragment key={item.id}>{item?.deleteStatus == 0 &&
                                                        <option value={item.id} key={item.id}>
                                                            {item.name}
                                                        </option>
                                                    }
                                                    </React.Fragment>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label htmlFor="companyName"> Branch<span style={{ color: "red" }}>*</span></label>
                                        <input
                                            type='text'
                                            id="branch"
                                            label="Branch"
                                            variant="standard"
                                            className='form-control'
                                            value={obj.branch}
                                            onChange={(e) => setObj({ ...obj, branch: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Form.Item>
                                <button className='btn btn-primary mt-3' type="primary" htmlType="submit" onClick={() => {
                                    obj?.id ? handleupdateBranch() : handleAddBranch()
                                }}>
                                    {obj?.id ? 'Update' : 'Submit'}
                                </button>
                            </Form.Item>
                        </Form>
                    </Box>
                </Modal>
                <hr />
                <div className='row'>
                    <div>
                        <div className='card'>
                            <div className="card-body">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Branch</th>
                                            <th scope="col">Company</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {params.id ? currentItems
                                            .slice(indexOfFirstItem, indexOfLastItem)?.map((item, idx) => {
                                                const globalIndex = idx + (currentPage - 1) * itemsPerPage + 1;

                                                return (
                                                    <>

                                                        {item?.company?.id == params.id && <tr key={item.id}>
                                                            <th scope="row">{globalIndex}</th>
                                                            <td>
                                                                <Link
                                                                    className='link linkk'
                                                                >
                                                                    {item.branch}
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                {item?.company?.name}
                                                            </td>
                                                            <td>
                                                                {permission.includes("Branch Edit") && <button
                                                                    className="btn p-1 m-2 btn-primary"
                                                                    onClick={() => {
                                                                        setObj(item)
                                                                        handleOpen()
                                                                    }}
                                                                >
                                                                    {<CiEdit style={{ fontSize: "20px" }} />}
                                                                </button>}
                                                                {permission.includes("Branch Delete") && <button
                                                                    className="btn p-1 m-2 btn-danger"
                                                                    onClick={() => delbarch(item.id)}
                                                                >
                                                                    {<CiTrash style={{ fontSize: "20px" }} />}
                                                                </button>}
                                                            </td>
                                                        </tr>
                                                        }
                                                    </>
                                                )
                                            }) :
                                            currentItems
                                                .slice(indexOfFirstItem, indexOfLastItem)?.map((item, idx) => {
                                                    const globalIndex = idx + (currentPage - 1) * itemsPerPage + 1;

                                                    return (
                                                        <tr key={item.id}>
                                                            <th scope="row">{globalIndex}</th>
                                                            <td>
                                                                <Link
                                                                    className='link linkk'
                                                                >
                                                                    {item.branch}
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                {item?.company?.name}
                                                            </td>
                                                            <td>
                                                                {permission.includes("Branch Edit") && <button
                                                                    className="btn p-1 m-2 btn-primary"
                                                                    onClick={() => {
                                                                        setObj(item)
                                                                        handleOpen()
                                                                    }}
                                                                >
                                                                    {<CiEdit style={{ fontSize: "20px" }} />}
                                                                </button>}
                                                                {permission.includes("Branch Delete") && <button
                                                                    className="btn p-1 m-2 btn-danger"
                                                                    onClick={() => delbarch(item.id)}
                                                                >
                                                                    {<CiTrash style={{ fontSize: "20px" }} />}
                                                                </button>}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                    </tbody>
                                </table>
                                <div className="pagination-controls">{paginationControls}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar
                open={anyFieldEmpty}
                autoHideDuration={2000}
                anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}
            >
                <Alert
                    severity="error"
                    style={{
                        backgroundColor: '#8f2018',
                        color: "white",
                    }}
                    onClose={handleCloseSnackbar}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default Branch;
