import React, { useEffect, useState } from 'react'
import { VscListFilter } from "react-icons/vsc";
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import { BarChart } from '@mui/x-charts/BarChart';
import { useData } from '../ContextProvider/Context';
import { useParams } from 'react-router-dom';

const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
// const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
const xLabels = [
    'Page A',
    'Page B',
    'Page C',
    'Page D',
    'Page E',
    'Page F',
    'Page G',
];

const data = [
    { value: 5, label: 'A' },
    { value: 10, label: 'B' },
    { value: 15, label: 'C' },
    { value: 20, label: 'D' },
];

const size = {
    width: 300,
    height: 200,
};

const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 20,
}));
function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (
        <StyledText x={left + width / 2} y={top + height / 2}>
            {children}
        </StyledText>
    );
}

function GraphandCharts() {
    const params =useParams()
    const{base_url}=useData()
    const [servicecategory, setServiceCategory] = useState([])
    useEffect(()=>{
        getServicesCategory()
    },[])
    const getServicesCategory = () => {
        fetch(`${base_url}tasks/counts`)
            .then((res) => res.json())
            .then((data) => {
                setServiceCategory(data);
            });
    };
    console.log(servicecategory);
    return (
        <div>
            <div className='charts mt-4 container'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='card p-4 d-flex align-items-center'>
                            <h4>
                                Compeleted Tasks
                            </h4>
                            <h1>4</h1>
                            <small><VscListFilter /> 1 filter</small>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='card p-4 d-flex align-items-center'>
                            <h4>
                                Incompelete Tasks
                            </h4>
                            <h1>9</h1>
                            <small><VscListFilter /> 1 filter</small>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='card p-4 d-flex align-items-center'>
                            <h4>
                                Total Tasks
                            </h4>
                            <h1>13</h1>
                            <small><VscListFilter /> 1 filter</small>
                        </div>
                    </div>
                    <div className='col-md-3'>
                    <div className='card p-4 d-flex align-items-center'>
                    <PieChart series={[{ data, innerRadius: 40 }]} margin={{ right: 5 }}
        width={150}
        height={140}
        legend={{ hidden: true }}>
                                    <PieCenterLabel>13</PieCenterLabel>
                                </PieChart>
</div>
                    </div>
                </div>
               
            </div>
        </div>
    )
}

export default GraphandCharts
