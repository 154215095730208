import React, { useEffect, useState } from 'react'
import { useData } from '../../../ContextProvider/Context'
import { Link, useParams } from 'react-router-dom'
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { CiImport } from 'react-icons/ci'
function TrialBalance() {
    const params = useParams()
    const { getTrailBalanceData, trialBalanceData, getFilterTrailBalance } = useData()
    useEffect(() => {
        getTrailBalanceData(params.id)
    }, [])
    const [date, setDate] = useState({
        month: ''
    })
    const handleDownloadCsv = () => {
        const csv = Papa.unparse([trialBalanceData]);

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'TrailBalance.csv');
    };
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentTrailBalance = trialBalanceData?.data?.slice(indexOfFirstItem, indexOfLastItem) || [];

    const totalPages = Math.ceil((trialBalanceData?.data?.length || 0) / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    return (
        <div>
            <div className='services'>
                <div className='expense-header'>
                    <div className='inp-buttons w-100' style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Trail Balance</li>
                                </ol>
                            </nav>
                        </div>
                        <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                            <div>
                                <button onClick={handleDownloadCsv} className='btn btn-primary' style={{ display: "flex", alignItems: "end", gap: "10px" }}><CiImport style={{ fontSize: "20px" }} />Export</button>
                            </div>
                            {/* <div>
                                <label style={{ fontSize: "10px", fontWeight: "bold" }}>Select Month</label><br />
                                <select
                                    className="form-select"
                                    onChange={(e) => {
                                        const newMonth = e.target.value
                                        setDate({ ...date, month: e.target.value })
                                        getFilterTrailBalance(newMonth)

                                    }}>
                                    <option hidden>Month</option>
                                    <option value=''>month</option>
                                    <option value='2023-01'>January</option>
                                    <option value='2023-02'>Febuary</option>
                                    <option value='2023-03'>March</option>
                                    <option value='2023-04'>April</option>
                                    <option value='2023-05'>May</option>
                                    <option value='2023-06'>June</option>
                                    <option value='2023-07'>July</option>
                                    <option value='2023-08'>August</option>
                                    <option value='2023-09'>September</option>
                                    <option value='2023-10'>October</option>
                                    <option value='2023-11'>November</option>
                                    <option value='2023-12'>December</option>
                                </select>
                            </div> */}
                        </div>
                    </div>
                </div>
                <hr />
                <div className='card'>
                    <div className='card-body table-responsive recruitment'>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Account</th>
                                    <th scope="col">Debit</th>
                                    <th scope="col">Credit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentTrailBalance?.map((item, idx) => {
                                    return (
                                        <tr>
                                            <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                            <td>{item.account_name}</td>
                                            <td>{item.total_debit?.toLocaleString()}</td>
                                            <td>{item.total_credit?.toLocaleString()}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div>
                            {paginationControls.map((button, index) => (
                                <React.Fragment key={index}>{button}</React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrialBalance
