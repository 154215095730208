import React, { useEffect, useState } from 'react'
import { useData } from '../../../ContextProvider/Context'
import { Link, useParams } from 'react-router-dom'
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { CiImport } from 'react-icons/ci'

function ClientView() {
    const params = useParams()
    const { getClientIndiData, clientIndiData, getFilterClientIndi } = useData()
    useEffect(() => {
        getClientIndiData(params.id)
    }, [])
    const handleDownloadCsv = () => {
        const csv = Papa.unparse(clientIndiData?.Client_detail);

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'ClientDetail.csv');
    };
    const [date, setdate] = useState({
        id: params.id,
        startDate: "",
        endDate: ""
    });
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentClientDetails = clientIndiData?.Client_detail?.slice(indexOfFirstItem, indexOfLastItem) || [];

    const totalPages = Math.ceil((clientIndiData?.Client_detail?.length || 0) / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }

    return (
        <>
            <div classname="row " style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                            <li className="breadcrumb-item"><Link to={"/admin/accounts/banking/client"}>Customer</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Detail</li>
                        </ol>
                    </nav>
                </div>
                <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                    <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                        <div className='startdate'>
                            <label style={{ fontWeight: "bold" }}>Select Start Date</label> <br />
                            <input type='date' name='start' onChange={(e) => setdate({ ...date, startDate: e.target.value })} className='form-control' />
                        </div>
                        <div className='startdate'>
                            <label style={{ fontWeight: "bold" }}>Select End Date</label> <br />
                            <input type='date' name='end' onChange={(e) => setdate({ ...date, endDate: e.target.value })} className='form-control' />
                        </div>
                        <button onClick={() => {
                            handlePageChange(1)
                            getFilterClientIndi(date.id, date.startDate, date.endDate)
                        }} className='btn btn-primary'>Search</button>
                    </div>
                    <div>
                        <button onClick={handleDownloadCsv} className='btn btn-primary' style={{ display: "flex", alignItems: "end", gap: "10px" }}><CiImport style={{ fontSize: "20px" }} />Export</button>
                    </div>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='card'>
                    <div className='card-body table-responsive recruitment'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Customer</th>
                                    <th scope="col">Address</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentClientDetails.length > 0 ? (
                                    currentClientDetails.map((item, idx) => {
                                        const client = item.client;
                                        const addressParts = [];
                                        if (client?.bill_address) {
                                            addressParts.push(client.bill_address);
                                        }
                                        if (client?.bill_city) {
                                            addressParts.push(client.bill_city);
                                        }
                                        if (client?.bill_province) {
                                            addressParts.push(client.bill_province);
                                        }
                                        if (client?.bill_country) {
                                            addressParts.push(client.bill_country);
                                        }
                                        if (client?.bill_postal) {
                                            addressParts.push(client.bill_postal);
                                        }

                                        const bill_address = addressParts.join(' ');
                                        const ship_addressParts = [];

                                        if (client?.ship_address) {
                                            ship_addressParts.push(client.ship_address);
                                        }
                                        if (client?.ship_city) {
                                            ship_addressParts.push(client.ship_city);
                                        }
                                        if (client?.ship_province) {
                                            ship_addressParts.push(client.ship_province);
                                        }
                                        if (client?.ship_country) {
                                            ship_addressParts.push(client.ship_country);
                                        }
                                        if (client?.ship_postal) {
                                            ship_addressParts.push(client.ship_postal);
                                        }

                                        const ship_address = ship_addressParts.join(' ');

                                        return (
                                            <tr key={idx}>
                                                <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                <td>{client?.name}</td>
                                                <td>{bill_address || ship_address || '-'}</td>
                                                <td>{item.amount ? item.amount : '-'}</td>
                                                <td>{item.date ? item.date?.split(' ')[0] : '-'}</td>
                                                <td>{item.description ? item.description : '-'}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="6" style={{ textAlign: "center" }}>
                                            No Data
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div>
                            {paginationControls.map((button, index) => (
                                <React.Fragment key={index}>{button}</React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default ClientView
