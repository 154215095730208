import React from 'react';
import { BiLinkAlt } from 'react-icons/bi'
import { LuDelete, LuImagePlus } from 'react-icons/lu';

function Step2Component({ formData, setFormData }) {

  const addSocialChannel = () => {
    const newData = [...formData.socialLinks]
    newData.push({ icon: '', link: '' })
    setFormData({ ...formData, socialLinks: newData });
  };

  const removeSocialChannel = (index) => {
    const updatedChannels = [...formData.socialLinks];
    updatedChannels.splice(index, 1);
    setFormData({ ...formData, socialLinks: updatedChannels });
  };

  return (
    <div className="step-content row">
      <div className='mb-2' style={{ display: "flex", justifyContent: "end" }}>
        <button style={{ padding: ".5rem 1rem", border: "none", color: "white", background: "#007bff", borderRadius: "5px" }} onClick={addSocialChannel}>Add More</button>
      </div>
      {formData?.socialLinks.map((channel, index) => (
        <div style={{ alignItems: "center", width: "98%" }} key={index} className='row mb-3'>
          <div className='col-2'>
            <div style={{ height: "80px", width: "80px", borderRadius: "50%", border: "1px solid", position: "relative" }}>
              <label style={{ border: "1px solid", borderRadius: "50%", width: "25px", height: "25px", background: "lightgray", color: "gray", position: "absolute", right: "0%", paddingLeft: ".2rem", paddingBottom: ".2rem" }} htmlFor={`channelLogo${index}`}><LuImagePlus /></label>
              <img style={{ width: "100%", height: "100%", borderRadius: "50%" }} src={channel?.icon ? URL.createObjectURL(channel?.icon) : ""} />
              <input
                type="file"
                id={`channelLogo${index}`}
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => {
                  const newData = [...formData.socialLinks]
                  newData[index].icon = e.target.files[0]
                  setFormData({ ...formData, socialLinks: newData })
                }
                }
              />
            </div>
          </div>
          <div className='col-4'>
            <label>Link<span style={{ color: "red" }}>*</span></label>
            <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: 'flex' }}>
              <label htmlFor={`socialLinks${index}`}><BiLinkAlt /></label>
              <input
                type="text"
                id={`socialLinks${index}`}
                placeholder='Channel Link'

                value={channel.link}
                onChange={(e) => {
                  const newData = [...formData.socialLinks]
                  newData[index].link = e.target.value
                  setFormData({ ...formData, socialLinks: newData })
                }
                }
              />
            </div>
          </div>
          <div className='col-2'>
            {index > 0 && (
              <button style={{ padding: "0rem 1rem", border: "none", color: "white", background: "#007bff", borderRadius: "5px" }} onClick={() => removeSocialChannel(index)}>
                <LuDelete />
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Step2Component;
