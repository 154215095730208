import React, { useState } from 'react';
import { useData } from '../ContextProvider/Context';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

function ShowScreenShorts() {
    const { getEmployeesScreenShorts, screenshot, img_url } = useData();
    const params = useParams();
    useEffect(() => {
        getEmployeesScreenShorts(params.id);
    }, []);

    const [showImageOverlay, setShowImageOverlay] = useState(false);
    const [selectedImageURL, setSelectedImageURL] = useState('');

    return (
        <div>
            <div className='card p-3'>
                <div className='row p-3'>
                    <h4 className='text-center mb-3'>Screenshots</h4>
                    {screenshot?.data?.length > 0 ? (
                        screenshot.data.map((item, index) => (
                            <div className='col-md-3 mb-3' key={index}>
                                <div style={{ height: "100%", width: "100%", overflow: "hidden", objectFit: "scale-down" }}>
                                    <img
                                        src={img_url + item.image}
                                        height={"100%"}
                                        width={"100%"}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setSelectedImageURL(img_url + item.image);
                                            setShowImageOverlay(true);
                                        }}
                                    />
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="text-center">No screenshots available.</div>
                    )}
                </div>
            </div>

            {showImageOverlay && (
                <div className="overlayy" style={{ background: "rgba(0,0,0,0.38)" }} onClick={() => setShowImageOverlay(false)}>
                    <img
                        src={selectedImageURL}
                        alt="Full-Screen Image"
                        style={{ maxHeight: '100%', maxWidth: '100%' }}
                    />
                </div>
            )}
        </div>
    );
}

export default ShowScreenShorts;
