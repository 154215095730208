import React, { useEffect, useState } from 'react'
import { BiCategory, BiUser, BiMessageDetail } from 'react-icons/bi'
import { TbCategory2 } from 'react-icons/tb'
import { MdPayment } from 'react-icons/md'
import { RiSecurePaymentLine, RiAccountPinCircleLine } from 'react-icons/ri'
import { BsCashCoin, BsFillImageFill } from 'react-icons/bs'
import { VscReferences, VscGroupByRefType } from 'react-icons/vsc'
import { GrServices } from 'react-icons/gr'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useData } from '../../../ContextProvider/Context'
import { Link, useParams } from 'react-router-dom'
import { CiBoxList, CiCalendar, CiMoneyBill, CiPhone, CiTrash } from 'react-icons/ci'
import { Alert, Snackbar } from '@mui/material';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


function Services() {
    const params = useParams()
    const { getServicesFIlterCom, accountsData, getAccountsData, deleteServiceData, handleClick, setSnackbarValue, getServicesData, servicesData, img_url, storeServiceData, getServicesCategory, servicecategory, getFilterServices } = useData()
    useEffect(() => {
        getServicesData()
        getServicesCategory()
        getAccountsData()
    }, [])
    const [selectedImage, setSelectedImage] = useState(null)
    const [selectedCompany, setSelectedCompany] = useState(params?.id || null)
    const filteredCompanies = accountsData?.company?.filter(item => item.deleteStatus === 0);
    const filteredCate = accountsData?.servicecategory?.filter(item => item.company_id == (params.id ? params.id : selectedCompany));

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setObj({
            company_id: params.id ? params.id : selectedCompany,
            name: '',
            sku: '',
            type: '',
            amount: '',
            image: '',
            date: new Date().toISOString().split('T')[0],
            description: '',
            tax: ''
        });
        setSelectedImage(null)
        const imgBox = document.getElementById('image-box');
        imgBox.src = '';
        setOpen(false)
    };
    const [obj, setObj] = useState({
        company_id: params.id ? params.id : selectedCompany,
        category_id: '',
        name: '',
        sku: '',
        type: '',
        amount: '',
        image: '',
        date: new Date().toISOString().split('T')[0],
        description: '',
        tax: ''
    })

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64URL = event.target.result;
                const parts = base64URL.split(',');
                const base64ImageData = parts[1];
                setObj({
                    ...obj,
                    image: base64ImageData,
                });
                const imgBox = document.getElementById('image-box');
                imgBox.src = base64URL;
            };
            reader.readAsDataURL(file);
        }
    };
    const clearFields = () => {
        setObj({
            category_id: '',
            name: '',
            sku: '',
            type: '',
            amount: '',
            image: '',
            date: '',
            description: '',
            tax: ''
        });

        setSelectedImage(null);
        const imgBox = document.getElementById('image-box');
        imgBox.src = '';
    };

    const isFormValid = () => {
        for (const key in obj) {
            if (key !== "sku" && key !== "tax" && obj[key] === '') {
                return false;
            }
        }
        return true;
    };
    const state = {
        open: false,
        vertical: 'bottom',
        horizontal: 'right',
    }
    const { vertical, horizontal } = state;
      const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
      const [snackbarMessage, setSnackbarMessage] = useState('');
   
        const handleSubmit = (e) => {
           e && e.preventDefault();
            if (!obj?.name) {
              setAnyFieldEmpty(true);
              setSnackbarMessage("name is required.");
            } else if (!obj?.company_id) {
              setAnyFieldEmpty(true);
              setSnackbarMessage(" company is required.");
            } else if (!obj?.sku) {
              setAnyFieldEmpty(true);
              setSnackbarMessage("SKU is Required.");
            } else if (!obj?.category_id) {
              setAnyFieldEmpty(true);
              setSnackbarMessage("Category is required.");
            } else if (!obj?.tax) {
              setAnyFieldEmpty(true);
              setSnackbarMessage("Tax is required.");
            } 
            else if (!obj?.type) {
                setAnyFieldEmpty(true);
                setSnackbarMessage("type is required.");
              } 
              else if (!obj?.date) {
                setAnyFieldEmpty(true);
                setSnackbarMessage("Date is required.");
              } 
              else if (!obj?.amount) {
                setAnyFieldEmpty(true);
                setSnackbarMessage("Amount is required.");
              } 
              else if (!obj?.image) {
                setAnyFieldEmpty(true);
                setSnackbarMessage("image is required.");
              } 
              else if (!obj?.description) {
                setAnyFieldEmpty(true);
                setSnackbarMessage("description is required.");
              } 
            else {
              handleClose();
              storeServiceData(obj);
              setObj({
                company_id: params.id ? params.id : selectedCompany,
                category_id: '',
                name: '',
                sku: '',
                type: '',
                amount: '',
                image: '',
                date: new Date().toISOString().split('T')[0],
                description: '',
                tax: ''
              });
            }
          };
          const handleCloseSnackbar = () => {
            setAnyFieldEmpty(false);
        };
      
    const [data, setData] = useState({
        name: ''
    })
    const [showImageOverlay, setShowImageOverlay] = useState(false);
    const [selectedImageURL, setSelectedImageURL] = useState('');
    const [selectedDescription, setSelectedDescription] = useState('');
    const [showDescriptionOverlay, setShowDescriptionOverlay] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const visibleServices = params.id
        ? servicesData?.services?.filter(item => item?.company?.id == params.id) || []
        : servicesData?.services || [];

    const currentServices = visibleServices?.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(visibleServices.length / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    return (
        <>
            <div className='services'>
                <div className='expense-header d-flex justify-content-between align-items-baseline'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Service</li>
                        </ol>
                    </nav>
                    <div className='inp-buttons d-flex gap-2' style={{ alignItems: "end" }}>
                        <div>
                            <select disabled={params.id ? true : false} className='form-select' onChange={(e) => {
                                handlePageChange(1)
                                getServicesFIlterCom(e.target.value)
                            }}>
                                <option value="">Select a company</option>
                                {filteredCompanies?.map((item) => (
                                    <option key={item.id} value={item.name}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='clientName'>
                            <select className='form-select' onChange={(e) => {
                                const selectedCategory = e.target.value;
                                setData({ ...data, name: selectedCategory });
                                getFilterServices(selectedCategory);
                                handlePageChange(1)
                            }}>
                                <option hidden>All Category</option>
                                <option value=''>All Category</option>
                                {servicecategory?.data?.map((item) => {
                                    return (
                                        <option value={item.name} key={item.id}>{item.name}</option>
                                    )
                                })}
                            </select>

                        </div>
                        <button type="button" className="btn btn-primary" onClick={handleOpen}>Add</button>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style} style={{ height: "600px", overflowY: "scroll" }} className='scrollBar'>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Add
                                </Typography>
                                <div id="modal-modal-description" sx={{ mt: 2 }}>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='inputFields'>
                                                <label>Name</label>
                                                <div className='inputBox'>
                                                    <label><GrServices /></label>
                                                    <input type='text' placeholder='Name' onChange={(e) => setObj({ ...obj, name: e.target.value })}></input>
                                                </div>
                                                <label>SKU</label>
                                                <div className='inputBox'>
                                                    <label><CiBoxList /></label>
                                                    <input type='text' placeholder='Sku' onChange={(e) => setObj({ ...obj, sku: e.target.value })}></input>
                                                </div>
                                                <label>Tax</label>
                                                <div className='inputBox'>
                                                    <label><CiMoneyBill /></label>
                                                    <input type='number' placeholder='Tax' onChange={(e) => setObj({ ...obj, tax: e.target.value })}></input>
                                                </div>
                                                <label>Date</label>
                                                <div className='inputBox'>
                                                    <label><CiCalendar /></label>
                                                    <input type='date' placeholder='Payment Date' value={obj?.date} onChange={(e) => setObj({ ...obj, date: e.target.value })}></input>
                                                </div>
                                                <label>Screenshot</label>
                                                <div className='inputBox'>
                                                    <div className='image-upload' style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                                        <label for='inp'>
                                                            <BsFillImageFill /> <span style={{ fontSize: '14px' }}>Screenshot</span>
                                                        </label>
                                                        <input
                                                            id='inp'
                                                            style={{ display: 'none' }}
                                                            type='file'
                                                            onChange={handleImageChange}
                                                        />
                                                        <img id="image-box" style={{ width: '30px', height: '30px' }} src={selectedImage ? URL.createObjectURL(selectedImage) : ''} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='inputFields'>
                                                <label>Company</label>
                                                <div className='selectBox'>
                                                    <select disabled={params.id ? true : false} onChange={(e) => {
                                                        setSelectedCompany(parseInt(e.target.value));
                                                        setObj({
                                                            ...obj,
                                                            company_id: parseInt(e.target.value)
                                                        });
                                                    }}>
                                                        <option value="">Select a company</option>
                                                        {filteredCompanies?.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <label>Category</label>
                                                <div className='selectBox'>
                                                    <label><BiCategory /></label>
                                                    <select onChange={(e) => setObj({ ...obj, category_id: e.target.value })}>
                                                        <option hidden>Select Category</option>
                                                        {filteredCate?.map((item) => {
                                                            return (
                                                                <option key={item.id} value={`${item.id}`}>{item.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                <label>Type</label>
                                                <div className='selectBox'>
                                                    <label><VscGroupByRefType /></label>
                                                    <select onChange={(e) => setObj({ ...obj, type: e.target.value })} >
                                                        <option hidden>Type</option>
                                                        <option value="Service">Service</option>
                                                        <option value="Product">Product</option>
                                                    </select>
                                                </div>
                                                <label>Amount</label>
                                                <div className='inputBox'>
                                                    <label><CiMoneyBill /></label>
                                                    <input type='number' placeholder='Amount' onChange={(e) => setObj({ ...obj, amount: e.target.value })}></input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label>Description</label>
                                            <div className='textarea'>
                                                <label><BiMessageDetail /></label>
                                                <textarea placeholder='Description' onChange={(e) => setObj({ ...obj, description: e.target.value })}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='modalBtns d-flex justify-content-end gap-2'>
                                            <button className='btn btn-primary' onClick={() => {
                                                handleSubmit()
                                                handleClose()
                                            }}>Submit</button>
                                            <button className='btn btn-secondary' onClick={handleClose}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </div>
                </div>
                <hr />
                <div className='card'>
                    <div className='card-body table-responsive recruitment'>
                        <table class="table w-100">
                            <thead style={{ textAlign: 'center' }}>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Category</th>
                                    <th scope="col">SKU</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Screenshot</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                {params?.id ? currentServices?.map((item, idx) => {
                                    const truncatedDescription = item.description.slice(0, 20);
                                    const isDescriptionTruncated = item.description.length > 20;
                                    return (
                                        <>
                                            {item?.company?.id == params?.id && <tr key={item.idx}>
                                                <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                <td>{item.company?.name}</td>
                                                <td>{item.date}</td>
                                                <td>{item.name}</td>
                                                <td>{item?.category_id?.name}</td>
                                                <td>{item.sku ? item.sku : '-'}</td>
                                                <td>{item.type}</td>
                                                <td>
                                                    {isDescriptionTruncated ? (
                                                        <div
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setSelectedDescription(item.description);
                                                                setShowDescriptionOverlay(true);
                                                            }}
                                                        >
                                                            {truncatedDescription}...
                                                        </div>
                                                    ) : (
                                                        item.description
                                                    )}
                                                </td>
                                                <td style={{ display: "flex", justifyContent: "center" }}>
                                                    <div style={{ width: '30px', height: '30px' }}>
                                                        <img style={{ width: '100%', height: '100%', cursor: "pointer" }}
                                                            onClick={() => {
                                                                setSelectedImageURL(img_url + item.image);
                                                                setShowImageOverlay(true);
                                                            }}
                                                            src={img_url + item.image} alt='' />
                                                    </div>
                                                </td>
                                                {showDescriptionOverlay && (
                                                    <div className="overlayy" style={{ background: "rgba(0,0,0,1)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                        <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                            <p style={{ color: "white" }}>{selectedDescription}</p>
                                                        </div>
                                                    </div>
                                                )}
                                                <td><span>{item.amount}</span></td>
                                                <td>
                                                    <button onClick={() => deleteServiceData(item.id)} className='btn btn-danger p-1'>
                                                        <CiTrash style={{ fontSize: "20px" }} />
                                                    </button>
                                                </td>
                                            </tr>}
                                        </>
                                    )
                                }) : currentServices?.map((item, idx) => {
                                    const truncatedDescription = item.description.slice(0, 20);
                                    const isDescriptionTruncated = item.description.length > 20;
                                    return (
                                        <tr key={item.idx}>
                                            <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                            <td>{item.company?.name}</td>
                                            <td>{item.date}</td>
                                            <td>{item.name}</td>
                                            <td>{item?.category_id?.name}</td>
                                            <td>{item.sku ? item.sku : '-'}</td>
                                            <td>{item.type}</td>
                                            <td>
                                                {isDescriptionTruncated ? (
                                                    <div
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedDescription(item.description);
                                                            setShowDescriptionOverlay(true);
                                                        }}
                                                    >
                                                        {truncatedDescription}...
                                                    </div>
                                                ) : (
                                                    item.description
                                                )}
                                            </td>
                                            <td style={{ display: "flex", justifyContent: "center" }}>
                                                <div style={{ width: '30px', height: '30px' }}>
                                                    <img style={{ width: '100%', height: '100%', cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedImageURL(img_url + item.image);
                                                            setShowImageOverlay(true);
                                                        }}
                                                        src={img_url + item.image} alt='' />
                                                </div>
                                            </td>
                                            {showDescriptionOverlay && (
                                                <div className="overlayy" style={{ background: "rgba(0,0,0,1)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                    <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                        <p style={{ color: "white" }}>{selectedDescription}</p>
                                                    </div>
                                                </div>
                                            )}
                                            <td><span>{item.amount}</span></td>
                                            <td>
                                                <button onClick={() => deleteServiceData(item.id)} className='btn btn-danger p-1'>
                                                    <CiTrash style={{ fontSize: "20px" }} />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                                {showImageOverlay && (
                                    <div className="overlayy" style={{ background: "rgba(0,0,0,0.38)" }} onClick={() => setShowImageOverlay(false)}>
                                        <img
                                            src={selectedImageURL}
                                            alt="Full-Screen Image"
                                            style={{ maxHeight: '100%', maxWidth: '100%' }}
                                        />
                                    </div>
                                )}
                            </tbody>
                        </table>
                        <div className="pagination-controls">{paginationControls}</div>
                    </div>
                </div>
                <Snackbar
                open={anyFieldEmpty}
                autoHideDuration={2000}
                anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}
            >
                <Alert
                    severity="error"
                    style={{
                        backgroundColor: '#8f2018',
                        color: "white",
                    }}
                    onClose={handleCloseSnackbar}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            </div>
        </>
    )
}


export default Services
