import React, { useState, useEffect } from 'react';

const TimeAgoMessage = ({ created_at }) => {
  const [timeAgo, setTimeAgo] = useState('');

  useEffect(() => {
    const getTimeDifference = () => {
      const currentTime = new Date();
      const createdAtDate = new Date(created_at);
      const differenceInSeconds = Math.floor((currentTime - createdAtDate) / 1000);

      if (differenceInSeconds < 60) {
        setTimeAgo('just now');
      } else if (differenceInSeconds < 3600) {
        const minutes = Math.floor(differenceInSeconds / 60);
        setTimeAgo(`${minutes} minute${minutes > 1 ? 's' : ''} ago`);
      } else if (differenceInSeconds < 86400) {
        const hours = Math.floor(differenceInSeconds / 3600);
        setTimeAgo(`${hours} hour${hours > 1 ? 's' : ''} ago`);
      } else {
        const days = Math.floor(differenceInSeconds / 86400);
        setTimeAgo(`${days} day${days > 1 ? 's' : ''} ago`);
      }
    };

    getTimeDifference();

    const interval = setInterval(getTimeDifference, 60000);
    return () => clearInterval(interval);
  }, [created_at]);

  return <span style={{fontSize:"11px"}}>{timeAgo}</span>;
};

export default TimeAgoMessage;
