import React from 'react'
import SignUp_form from '../Components/SignUp_form'

function Signup() {
  return (
    <>
      <SignUp_form/>
    </>
  )
}

export default Signup
