import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { CiEdit, CiTrash } from 'react-icons/ci';
import { useData } from '../../ContextProvider/Context';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};
function CreateRolls() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setRolls({
      name: "",
      permission: [],
    })
    setSelectedOptions([])
    setOpen(false)
  };
  const [selectedOptions, setSelectedOptions] = useState([]);
  const options = [
    { label: 'Dashboard', value: 'Dashboard' },
    { label: 'Employee View', value: 'Employee View' },
    { label: 'Employee Profile', value: 'Employee Profile' },
    { label: 'Employee Create', value: 'Employee Create' },
    { label: 'Employee Edit', value: 'Employee Edit' },
    { label: 'Employee Delete', value: 'Employee Delete' },
    { label: 'Projects View', value: 'Projects View' },
    { label: 'Overview View', value: 'Overview View' },
    { label: 'Task View', value: 'Task View' },
    { label: 'Task Create', value: 'Task Create' },
    { label: 'Files View', value: 'Files View' },
    { label: 'Files Create', value: 'Files Create' },
    { label: 'Kanban', value: 'Kanban' },
    { label: 'Company Create', value: 'Company Create' },
    { label: 'Company View', value: 'Company View' },
    { label: 'Company Edit', value: 'Company Edit' },
    { label: 'Company Delete', value: 'Company Delete' },
    { label: 'Company Setting View', value: 'Company Setting View' },
    { label: 'Branch Create', value: 'Branch Create' },
    { label: 'Branch View', value: 'Branch View' },
    { label: 'Branch Edit', value: 'Branch Edit' },
    { label: 'Branch Delete', value: 'Branch Delete' },
    { label: 'Department Create', value: 'Department Create' },
    { label: 'Department View', value: 'Department View' },
    { label: 'Department Edit', value: 'Department Edit' },
    { label: 'Department Delete', value: 'Department Delete' },
    { label: 'Designation Create', value: 'Designation Create' },
    { label: 'Designation View', value: 'Designation View' },
    { label: 'Designation Edit', value: 'Designation Edit' },
    { label: 'Designation Delete', value: 'Designation Delete' },
    { label: 'Create Rolls & Permissions', value: 'Create Rolls & Permissions' },
    { label: 'Accounts', value: 'Accounts' },
    { label: 'Transfer', value: 'Transfer' },
    { label: 'Expense', value: 'Expense' },
    { label: 'Expense Category', value: 'Expense Category' },
    { label: 'Expense Subcategory', value: 'Expense Subcategory' },
    { label: 'Assets', value: 'Assets' },
    { label: 'Asset Category', value: 'Asset Category' },
    { label: 'Purchase Assets', value: 'Purchase Assets' },
    { label: 'Services', value: 'Services' },
    { label: 'Services Category', value: 'Services Category' },
    { label: 'Invoice', value: 'Invoice' },
    { label: 'Revenue', value: 'Revenue' },
    { label: 'General Ledger', value: 'General Ledger' },
    { label: 'Trail Balance', value: 'Trail Balance' },
    { label: 'Balance Sheet', value: 'Balance Sheet' },
    { label: 'Profit & Loss', value: 'Profit & Loss' },
    { label: 'Equity Liability', value: 'Equity Liability' },
    { label: 'Clients', value: 'Clients' },
    { label: 'Suppliers', value: 'Suppliers' },
    { label: 'Payrolls', value: 'Payrolls' },
    { label: 'Emails', value: 'Emails' },
    { label: 'Account All', value: 'Account All' },
    { label: 'Bonus Create', value: 'Bonus Create' },
    { label: 'Bonus Edit', value: 'Bonus Edit' },
    { label: 'Bonus Delete', value: 'Bonus Delete' },
    { label: 'Leave', value: 'Leave' },
    { label: 'Balance Invoice', value: 'Balance Invoice' },
    { label: 'Projects Delete', value: 'Projects Delete' },
    { label: 'Projects Create', value: 'Projects Create' },
    { label: 'Projects Edit', value: 'Projects Edit' },
    { label: 'Payroll', value: 'Payroll' },
    { label: 'Job Post', value: 'Job Post' },
    { label: 'Vendors', value: 'Vendors' },
    { label: 'Company Announcement', value: 'Company Announcement' },
    { label: 'Company Policy', value: 'Company Policy' },
    { label: "Leave Approved", value: "Leave Approved" },
    { label: "Register", value: "Register" },
    { label: "key Resources", value: "key Resources" },
    // { label: '', value: '' },
    // Add more options as needed
  ];
  useEffect(() => {
    getRoles()
  }, [])
  const [dynamicOptions, setDynamicOptions] = useState([]);
  const { base_url, handleClick,
    setSnackbarValue } = useData()
  const [dataRolls, setRolls] = useState(
    {
      name: "",
      permission: [],
    })
  const updateDynamicOptions = (inputValue) => {
    const newOptions = options.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setDynamicOptions(newOptions);
  };

  const handleSelectionChange = (event, newValue) => {
    setSelectedOptions(newValue);
    var array = []
    newValue?.map((item) => {
      array.push(item.value)
    })

    setRolls({ ...dataRolls, permission: array })
  };
  const create = () => {
    if (dataRolls.name.trim() === '') {
      handleClick();
      setSnackbarValue({ message: "Name field is required.", color: "#DC4C64" });
      return;
    }

    if (dataRolls.permission.length === 0) {
      handleClick();
      setSnackbarValue({ message: "Permissions are required.", color: "#DC4C64" });
      return;
    }

    // If name and permissions are not empty, make the API call
    fetch(`${base_url}roles`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(dataRolls),
    })
      .then((response) => response.json())
      .then((data) => {
        getRoles();
        handleClick();
        handleClose()
        setSnackbarValue({ message: "Roles created successfully", color: "#2ECC71" });
      })
      .catch((error) => {
        console.error("Error creating roles:", error);
        handleClick();
        setSnackbarValue({ message: "Error creating roles. Please try again.", color: "#DC4C64" });
      });
  }

  const [allRoles, setallRoles] = useState([])
  const getRoles = () => {
    fetch(`${base_url}roles`)
      .then((response) => response.json())
      .then((data) => {
        setallRoles(data);
      })
  }
  const editRoles = () => {
    if (dataRolls.name.trim() === '') {
      handleClick();
      setSnackbarValue({ message: "Name field is required.", color: "#DC4C64" });
      return;
    }

    if (dataRolls.permission.length === 0) {
      handleClick();
      setSnackbarValue({ message: "Permissions are required.", color: "#DC4C64" });
      return;
    }
    fetch(`${base_url}roles/${dataRolls.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',

      },
      body: JSON.stringify(dataRolls),
    })
      .then((response) => response.json())
      .then((data) => {
        getRoles()
        handleClose()
        handleClick()
        setSnackbarValue({ message: "Roles Update successfully", color: "#2ECC71" });
      })
  }
  const deleteRoles = (id) => {
    fetch(`${base_url}roles/${id}`, {
      method: 'DELETE',

    })
      .then((response) => response.json())
      .then((data) => {
        getRoles()
        handleClick()
        setSnackbarValue({ message: "Roles Delete successfully", color: "#2ECC71" });
      })
  }
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentRoles = allRoles?.slice(indexOfFirstItem, indexOfLastItem);

  const paginationControls = [];
  for (let i = 1; i <= Math.ceil(allRoles?.length / itemsPerPage); i++) {
    paginationControls.push(
      <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => setCurrentPage(i)}>
        {i}
      </button>
    );
  }
  return (
    <>
      <div className='employeeData'>
        <div className="row " style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Roles&Permissions</li>
              </ol>
            </nav>
          </div>
          <div className='d-flex justify-content-end mb-2 gap-2'>
            <div>
              <button className='btn btn-primary' onClick={handleOpen} style={{ padding: "8px 16px" }}>Add</button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <div style={{ borderRadius: "5px" }} className='row'>
            <div style={{ display: "flex", alignItems: "center" }} className='col-2'>
              <label>Roles:<span style={{ color: "red" }}>*</span></label>
            </div>
            <div className='col-10'>
              <input value={dataRolls?.name}
                onChange={(e) => setRolls({ ...dataRolls, name: e.target.value })} style={{ background: "none", border: "1px solid ligthgray ", width: "80%", borderRadius: "5px", padding: "1rem" }} />
            </div>
          </div>
          <div style={{ height: "50vh", overflowY: "auto", overflowX: "hidden" }} >
            <div className='row'>

              <div style={{ display: "flex", alignItems: "center" }} className='col-2'>
                <label>Permissions:<span style={{ color: "red" }}>*</span></label>
              </div>
              <div className='col-10'>
                <Autocomplete
                  className='w-75'
                  multiple
                  id="multi-select-chip"
                  options={dynamicOptions}
                  getOptionLabel={(option) => option.label}
                  value={selectedOptions}
                  onInputChange={(event, newInputValue) => {
                    updateDynamicOptions(newInputValue);
                  }}
                  onChange={handleSelectionChange}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="Select Options" />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option.label}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                />
              </div>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "end", padding: "1rem" }}>
            <button className='btn btn-primary' onClick={() => {
              dataRolls?.id ? editRoles() : create()
            }} style={{ padding: "8px 16px", marginRight: "1rem" }}>{dataRolls?.id ? "Update" : "Create"}</button>
            <button className='btn btn-primary' style={{ padding: "8px 16px", marginRight: "1rem" }} onClick={handleClose}>Cancel</button>
          </div>
        </Box>
      </Modal>
      <hr />
      <div className='row'>
        <div>
          <div className='card'>
            <div className="card-body recruitment table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Role</th>
                    <th scope="col">Total Permissions</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentRoles?.map((item, idx) => (
                    <tr key={item.id || idx}>
                      <th scope="row">{indexOfFirstItem + idx + 1}</th>
                      <td scope="col">{item.name}</td>
                      <td scope="col">{item.permission?.length}</td>
                      <td scope="col">
                        <button
                          onClick={() => {
                            var array = [];
                            item?.permission?.map(permission => {
                              var obj = { label: permission, value: permission };
                              array.push(obj);
                            });
                            setSelectedOptions(array);
                            setRolls(item);
                            handleOpen();
                          }}
                          className="btn p-1 m-2 btn-primary"
                        >
                          <CiEdit style={{ fontSize: "20px" }} />
                        </button>
                        <button
                          className="btn p-1 m-2 btn-danger"
                          onClick={() => deleteRoles(item.id)}
                        >
                          <CiTrash style={{ fontSize: "20px" }} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination-controls">{paginationControls}</div>

            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default CreateRolls