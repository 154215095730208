import React, { useEffect, useState } from 'react';
import { useData } from '../ContextProvider/Context';
import { Link, useParams } from 'react-router-dom';
import { CiEdit, CiTrash } from 'react-icons/ci';
import { Form } from 'antd';
import { Alert, Box, Modal, Snackbar } from '@mui/material';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
function AllDepartments() {
  const params = useParams()
  const { company, delDepartment, editDepartment, getCompnayCrud, branch, department, getDepartments, getBarnchById, storeDepartmentData, permission } = useData();
  const [obj, setObj] = useState({
    companyId: params.id ? params.id : "",
    branchId: "",
    department: ""
  })
  useEffect(() => {
    getCompnayCrud()
    getDepartments()
  }, [])
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setObj({
      companyId: params.id ? params.id : "",
      branchId: "",
      department: ""
    })
  };
  const state = {
    open: false,
    vertical: 'bottom',
    horizontal: 'right',
  }
  const { vertical, horizontal } = state;
  const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleAddDepartment = () => {
    if (obj?.companyId?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Company is required.');
    } else if (obj?.branchId?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Branch is required.');
    } else if (obj?.department?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Department is required.');
    } else {
      storeDepartmentData(obj)
      handleClose()
    }
  }
  const handleupdateBranch = () => {
    const department = obj?.department.trim();
    if (!department) {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Department is required.');
    } else {
      editDepartment(obj, obj?.id);
      handleClose();
    }
  };

  const handleCloseSnackbar = () => {
    setAnyFieldEmpty(false);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = params.id
    ? department?.data?.filter(item => item?.company?.id == params.id) || []
    : department?.data || [];

  const totalPages = Math.ceil(currentItems.length / itemsPerPage);

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const paginationControls = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationControls.push(
      <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
        {i}
      </button>
    );
  }
  return (
    <>
      <div className='obj'>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
          <div>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Departments</li>
              </ol>
            </nav>
          </div>
          <div className='d-flex justify-content-end mb-2 gap-2'>

            <div>
              {permission.includes("Department Create") && <button className='btn btn-primary' onClick={handleOpen} style={{ padding: ".5rem 1rem" }}>Add</button>}
            </div>
          </div>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Form name="add_department">
              <div className="row">
                <div className="col-4 mb-2">
                  <div className="form-group">
                    <label htmlFor="companyName">Company<span style={{ color: "red" }}>*</span></label>
                    <select
                      placeholder="Select Company"
                      style={{ width: '100%' }}
                      disabled={params.id ? true : false}
                      className='form-select'
                      value={obj.companyId}
                      id='input1'
                      name='input1'
                      onChange={(e) => {
                        setObj({ ...obj, companyId: e.target.value })
                        getBarnchById(e.target.value)
                      }
                      }
                    >
                      <option hidden>Select Company</option>
                      {company?.data?.map((item) => {

                        return (
                          <React.Fragment key={item.id}>{item?.deleteStatus == 0 &&
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          }
                          </React.Fragment>
                        )
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-4 mb-2">
                  <div className="form-group">
                    <label htmlFor="branchName">Branch<span style={{ color: "red" }}>*</span></label>
                    <select
                      placeholder="Select Branch"
                      style={{ width: '100%' }}
                      value={obj.branchId}
                      id='input2'
                      name='input2'
                      onChange={(e) => {
                        setObj({ ...obj, branchId: e.target.value })
                      }
                      }
                      className='form-select'
                      disabled={!branch}
                    >
                      <option hidden>Select Branch</option>

                      {branch?.data?.map((b) => (
                        <option key={b.id} value={b.id}>
                          {b.branch}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-4 mb-2">
                  <div className="form-group">
                    <label htmlFor="departmentName">Department<span style={{ color: "red" }}>*</span></label>
                    <input
                      type='text'
                      id="department"
                      name="department"
                      label="New Department"
                      variant="standard"
                      value={obj.department}
                      className='form-control'
                      onChange={(e) => {
                        setObj({ ...obj, department: e.target.value })
                      }
                      }
                    />
                  </div>
                </div>
              </div>
              <Form.Item>
                <button className='btn btn-primary mt-3' type="primary" onClick={() => {
                  obj?.id ? handleupdateBranch() : handleAddDepartment()
                }}>
                  {obj.id ? 'Update' : 'Submit'}
                </button>
              </Form.Item>
            </Form>
          </Box>
        </Modal>


        <hr />
        <div className='row'>
          <div>
            <div className='card'>
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Department</th>
                      <th scope="col">Branch</th>
                      <th scope="col">Company</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {params.id ? currentItems
                      .slice(indexOfFirstItem, indexOfLastItem)?.map((item, idx) => {
                        return (
                          <React.Fragment key={idx}>{params.id == item.company.id &&
                            <tr key={item.id}>
                              <th scope="row">{indexOfFirstItem + idx + 1}</th>
                              <td>
                                <Link
                                  className='link linkk'
                                >
                                  {item.department}
                                </Link>
                              </td>
                              <td>
                                {item.branch.branch}
                              </td>
                              <td>
                                {item.company.name}
                              </td>
                              <td>
                                {permission.includes("Department Edit") && <button
                                  className="btn p-1 m-2 btn-primary"
                                  onClick={() => {
                                    handleOpen()
                                    setObj(item)
                                  }}
                                >
                                  <CiEdit style={{ fontSize: "20px" }} />
                                </button>}
                                {permission.includes("Department Delete") && <button
                                  className="btn p-1 m-2 btn-danger"
                                  onClick={() => delDepartment(item.id)}
                                >
                                  <CiTrash style={{ fontSize: "20px" }} />
                                </button>}
                              </td>
                            </tr>
                          }</React.Fragment>

                        )
                      }) :
                      currentItems
                        .slice(indexOfFirstItem, indexOfLastItem)?.map((item, idx) => {
                          return (
                            <tr key={item.id}>
                              <th scope="row">{indexOfFirstItem + idx + 1}</th>
                              <td>
                                <Link
                                  className='link linkk'
                                >
                                  {item.department}
                                </Link>
                              </td>
                              <td>
                                {item.branch.branch}
                              </td>
                              <td>
                                {item.company.name}
                              </td>
                              <td>
                                {permission.includes("Department Edit") && <button
                                  className="btn p-1 m-2 btn-primary"
                                  onClick={() => {
                                    handleOpen()
                                    setObj(item)
                                  }}
                                >
                                  <CiEdit style={{ fontSize: "20px" }} />
                                </button>}
                                {permission.includes("Department Delete") && <button
                                  className="btn p-1 m-2 btn-danger"
                                  onClick={() => delDepartment(item.id)}
                                >
                                  <CiTrash style={{ fontSize: "20px" }} />
                                </button>}
                              </td>
                            </tr>
                          )
                        })
                    }
                  </tbody>
                </table>
                <div className="pagination-controls">{paginationControls}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={anyFieldEmpty}
        autoHideDuration={2000}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          severity="error"
          style={{
            backgroundColor: '#8f2018',
            color: "white",
          }}
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AllDepartments;
