import React, { useEffect, useState } from 'react'
import { GrServices } from 'react-icons/gr'
import { CiEdit, CiRead, CiTrash } from 'react-icons/ci'
import { PiThumbsUpLight } from 'react-icons/pi'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useData } from '../ContextProvider/Context';
import { Link } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


function Leave() {
    const { getLeaveData, leaveData, storeLeavedata, updateLeaveData, deleteLeaveData, storeLeaveStatus, permission } = useData()
    useEffect(() => {
        getLeaveData()
    }, [])
    const emp_id = JSON.parse(localStorage.getItem('user_auth'))

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setObj(
            {
                date: new Date().toISOString().split('T')[0],
                startDate: new Date().toISOString().split('T')[0],
                endDate: '',
                status: '',
                reason: '',
                type: '',
                leave: '',
                hours: '',
                file: '',
                empId: emp_id?.employee?.id,
                count: ''
            }
        )
        setOpen(false)
    };
    const [obj, setObj] = useState({
        date: new Date().toISOString().split('T')[0],
        startDate: new Date().toISOString().split('T')[0],
        endDate: '',
        status: '',
        reason: '',
        type: '',
        leave: '',
        hours: '',
        file: '',
        empId: emp_id?.employee?.id,
        count: ''
    })

    const state = {
        open: false,
        vertical: 'bottom',
        horizontal: 'right',
    }
    const { vertical, horizontal } = state;
    const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const handleSubmit = () => {
        if (obj?.type?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Type is required.');
        } else if (obj?.leave?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Duration Type is required.');
        } else if (obj?.reason?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Reason is required.');
        } else {
            storeLeavedata(obj)
            handleClose()
        }
    }
    // const handleSubmit = () => {
    //     storeLeavedata(obj)
    // }
    const handleEditSubmit = () => {
        if (obj?.type?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Type is required.');
        } else if (obj?.leave?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Duration Type is required.');
        } else if (obj?.reason?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Reason is required.');
        } else {
            // storeLeavedata(obj)
            updateLeaveData(obj)
            handleClose()
        }

    };
    const handleCloseSnackbar = () => {
        setAnyFieldEmpty(false);
    };
    const [selectedDescription, setSelectedDescription] = useState('');
    const [showDescriptionOverlay, setShowDescriptionOverlay] = useState(false);
    console.log(obj)
    return (
        <>
            <div className='expenseCategory'>
                <div className='expense-header d-flex justify-content-between align-items-baseline'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Leave</li>
                        </ol>
                    </nav>
                    <div className='inp-buttons d-flex gap-2'>

                        <button type="button" className="btn btn-primary" onClick={handleOpen}>Add</button>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style} style={{ height: "500px", overflowY: "scroll" }} className='scrollBar'>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Add
                                </Typography>
                                <div id="modal-modal-description" sx={{ mt: 2 }}>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='inputFields mb-3'>
                                                <label htmlFor='typeSlect'>Type<span style={{ color: "red" }}>*</span></label>
                                                <div className='inputBox mt-3'>
                                                    <label><GrServices /></label>
                                                    <select id='typeSlect' value={obj.type} onChange={(e) => setObj({ ...obj, type: e.target.value })}>
                                                        <option value=''>Select Type</option>
                                                        <option value='casual'>Casual Leave</option>
                                                        <option value='maternity'>Maternity Leave</option>
                                                        <option value='paternity'>Paternity Leave</option>
                                                        <option value='sick'>Sick Leave</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='inputFields mb-3'>
                                                <label htmlFor='durationSlect'>Duration<span style={{ color: "red" }}>*</span></label>
                                                <div className='inputBox mt-3'>
                                                    <label><GrServices /></label>
                                                    <select id='durationSlect' value={obj.leave} onChange={(e) => setObj({ ...obj, leave: e.target.value })}>
                                                        <option value=''>Select Type</option>
                                                        <option value='single'>Single Day</option>
                                                        <option value='multiple'>Multiple Days</option>
                                                        <option value='hours'>Hours</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {obj.leave === 'single' || obj.leave === 'single' ? (
                                                <div className='singleDay'>
                                                    <div className='inputFields mb-3'>
                                                        <label htmlFor='forDate'>Date<span style={{ color: "red" }}>*</span></label>
                                                        <div className='inputBox mt-3'>
                                                            <label><GrServices /></label>
                                                            <input id='forDate' value={obj.date} min={new Date().toISOString().split('T')[0]} placeholder='Date' type='date' onChange={(e) => setObj({ ...obj, date: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className='inputFields mb-3'>
                                                        <label htmlFor='forReason'>Reason<span style={{ color: "red" }}>*</span></label>
                                                        <div className='inputBox mt-3'>
                                                            <label><GrServices /></label>
                                                            <input id='forReason' value={obj.reason} placeholder='Reason' type='text' onChange={(e) => setObj({ ...obj, reason: e.target.value })} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {obj.leave === 'multiple' || obj.leave === 'multiple' ? (
                                                <div className='multipledays'>
                                                    <label htmlFor='multiDate'>Start Date<span style={{ color: "red" }}>*</span></label>
                                                    <div className='inputFields mb-3'>
                                                        <label><GrServices /></label>
                                                        <div className='inputBox mt-3'>
                                                            <input id='multiDate' value={obj.startDate} min={new Date().toISOString().split('T')[0]} placeholder='Start Date' type='date' onChange={(e) => setObj({ ...obj, startDate: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <label htmlFor='multiEndDate'>End Date<span style={{ color: "red" }}>*</span></label>
                                                    <div className='inputFields mb-3'>
                                                        <label><GrServices /></label>
                                                        <div className='inputBox mt-3'>
                                                            <input id='multiEndDate' min={obj?.startDate} value={obj.endDate} placeholder='End Date' type='date' onChange={(e) => setObj({ ...obj, endDate: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className='inputFields mb-3'>
                                                        <label htmlFor='mutiReasone'>Reason<span style={{ color: "red" }}>*</span></label>
                                                        <div className='inputBox mt-3'>
                                                            <label><GrServices /></label>
                                                            <input id='mutiReasone' value={obj.reason} placeholder='Reason' type='text' onChange={(e) => setObj({ ...obj, reason: e.target.value })} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {obj.leave === 'hours' || obj.leave === 'hours' ? (
                                                <div className='hours'>
                                                    <label htmlFor='forHoursDate'>Date<span style={{ color: "red" }}>*</span></label>
                                                    <div className='inputFields mb-3'>
                                                        <label><GrServices /></label>
                                                        <div className='inputBox mt-3'>
                                                            <input id='forHoursDate' value={obj.date} min={new Date().toISOString().split('T')[0]} placeholder='Date' type='date' onChange={(e) => setObj({ ...obj, date: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <label htmlFor='forHours'>Hours<span style={{ color: "red" }}>*</span></label>
                                                    <div className='inputFields mb-3'>
                                                        <label><GrServices /></label>
                                                        <div className='inputBox mt-3'>
                                                            <select id='forHours' value={obj.hours} onChange={(e) => setObj({ ...obj, hours: e.target.value })}>
                                                                <option value=''>Select Hours</option>
                                                                <option value='1'>01</option>
                                                                <option value='2'>02</option>
                                                                <option value='3'>03</option>
                                                                <option value='4'>04</option>
                                                                <option value='5'>05</option>
                                                                <option value='6'>06</option>
                                                                <option value='7'>07</option>
                                                                <option value='8'>08</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='inputFields mb-3'>
                                                        <label htmlFor='forHoursReason'>Reason<span style={{ color: "red" }}>*</span></label>
                                                        <div className='inputBox mt-3'>
                                                            <label><GrServices /></label>
                                                            <input id='forHoursReason' value={obj.reason} placeholder='Reason' type='text' onChange={(e) => setObj({ ...obj, reason: e.target.value })} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}

                                            <div>
                                                <input
                                                    className='form-control border'
                                                    type='file'
                                                    id='forInoutFile'
                                                    onChange={(e) => {
                                                        const file = e.target.files[0];
                                                        if (file) {
                                                            const reader = new FileReader();
                                                            reader.onload = (event) => {
                                                                const base64String = event.target.result;
                                                                const parts = base64String
                                                                setObj({ ...obj, file: parts });
                                                            };
                                                            reader.readAsDataURL(file);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='modalBtns d-flex justify-content-end gap-2'>
                                            <button className='btn btn-primary' onClick={() => {
                                                obj?.id ? handleEditSubmit() : handleSubmit()
                                            }}>{obj?.id ? "Update" : "Submit"}</button>
                                            <button className='btn btn-secondary' onClick={handleClose}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </div>
                </div>
                <hr />
                <div className='card'>
                    <div className='card-body table-responsive recruitment'>
                        <table className="table w-100">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Employee</th>
                                    <th scope="col">Submiited Date</th>
                                    <th scope="col">Start Date</th>
                                    <th scope="col">End Date</th>
                                    <th scope="col">Reason</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">leave for</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leaveData?.data?.map((item, indx) => {
                                    const truncatedDescription = item.reason?.slice(0, 20);
                                    const isDescriptionTruncated = item.reason?.length > 20;
                                    return (
                                        <tr key={item.id}>
                                            <th scope="row">{indx + 1}</th>
                                            <td>{item.employee?.name}</td>
                                            <td>{item.date ? item.date : '-'}</td>
                                            <td>{item.startDate ? item.startDate : '-'}</td>
                                            <td>{item.endDate ? item.endDate : '-'}</td>
                                            <td>
                                                {isDescriptionTruncated ? (
                                                    <div
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedDescription(item.reason);
                                                            setShowDescriptionOverlay(true);
                                                        }}
                                                    >
                                                        {truncatedDescription}...
                                                    </div>
                                                ) : (
                                                    item.reason
                                                )}
                                            </td>
                                            {showDescriptionOverlay && (
                                                <div className="overlayy" style={{ background: "rgba(0,0,0,1)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                    <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                        <p style={{ color: "white" }}>{selectedDescription}</p>
                                                    </div>
                                                </div>
                                            )}
                                            <td>{item.type}</td>
                                            <td>
                                                {item.leave}
                                            </td>
                                            <td>
                                                {parseInt(item?.status) === 0
                                                    ? <span style={{ color: "red" }}>Pending</span>
                                                    : parseInt(item?.status) === 1
                                                        ? <span style={{ color: "green" }}>Approved</span>
                                                        : "Unknown"}
                                            </td>

                                            <td>
                                                {/* <button className="btn p-1 m-2 btn-primary" onClick={() => {
                                                    handleOpen()
                                                    setObj(item)
                                                }}>
                                                    <CiEdit style={{ fontSize: "20px" }} />
                                                </button> */}
                                                <button className="btn p-1 m-2 btn-danger" onClick={() => deleteLeaveData(item.id)}>
                                                    <CiTrash style={{ fontSize: "20px" }} />
                                                </button>
                                                {permission.includes("Leave Approved") && <button
                                                    className="btn p-1 m-2 btn-mute"
                                                    onClick={() => {
                                                        if (item.status == 0) {
                                                            storeLeaveStatus(item.id, {
                                                                status: 1
                                                            })
                                                        }
                                                        else {
                                                            storeLeaveStatus(item.id, {
                                                                status: 0
                                                            })
                                                        }
                                                    }}
                                                >
                                                    <PiThumbsUpLight style={{ fontSize: '20px' }} />
                                                </button>}
                                            </td>
                                        </tr>
                                    );
                                })}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Snackbar
                open={anyFieldEmpty}
                autoHideDuration={2000}
                anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}
            >
                <Alert
                    severity="error"
                    style={{
                        backgroundColor: '#8f2018',
                        color: "white",
                    }}
                    onClose={handleCloseSnackbar}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Leave
