import React, { useEffect, useState } from 'react';
import { useData } from '../ContextProvider/Context';

function EmloyessTiming({ id }) {
  const { base_url, getEmployeesIndividual } = useData()
  const [formData, setFormData] = useState({
    timings: [
      { empId: id, day: 'Monday', startTime: '', endTime: '', totalTime: 0 },
      { empId: id, day: 'Tuesday', startTime: '', endTime: '', totalTime: 0 },
      { empId: id, day: 'Wednesday', startTime: '', endTime: '', totalTime: 0 },
      { empId: id, day: 'Thursday', startTime: '', endTime: '', totalTime: 0 },
      { empId: id, day: 'Friday', startTime: '', endTime: '', totalTime: 0 },
      { empId: id, day: 'Saturday', startTime: '', endTime: '', totalTime: 0 },
      { empId: id, day: 'Sunday', startTime: '', endTime: '', totalTime: 0 },
    ],
  })
  const calculateTotalTime = (day, idx) => {

    const dayData = formData.timings.find((item) => item.day === day);

    if (dayData && dayData.startTime && dayData.endTime) {
      const startMinutes = parseInt(dayData.startTime.split(':')[0]) * 60 + parseInt(dayData.startTime.split(':')[1]);
      const endMinutes = parseInt(dayData.endTime.split(':')[0]) * 60 + parseInt(dayData.endTime.split(':')[1]);

      const totalMinutes = endMinutes - startMinutes;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      const newdata = [...formData.timings]
      newdata[idx].totalTime = `${hours}`
      setFormData({ ...formData, timings: newdata })
      return `${hours} hours and ${minutes} minutes`;
    }

    return 'Not specified';
  };
  const customTime = () => {
    fetch(`${base_url}empTiming`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',

      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        getEmployeesIndividual(id)

      })
      .catch((error) => {
        console.log('Error:', error)
      });
  }
  useEffect(() => {
    customTimeget()
  }, [])
  const customTimeget = () => {
    fetch(`${base_url}empTiming/${id}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        const array = data?.data.length ? data?.data : formData?.timings
        setFormData({ ...formData, timings: array })

      })
      .catch((error) => {
        console.log('Error:', error)
      });
  }

  return (
    <div>

      <div className="timing-form create_compant_timing card" style={{ padding: "2rem 0" }}>
        {formData?.timings?.map((day, index) => (
          <div className='row' style={{ marginTop: "1rem" }}>
            <div className='col-2' style={{ textAlign: "end" }}>
              <input type='text'
                value={day.day}
                style={{ border: "none", paddingLeft: "2rem", background: "transparent" }}
                disabled
              />
            </div>
            <div className='col-3'>
              <label>Start Time</label>
              <input type='time'
                value={day.startTime}
                onChange={(e) => {
                  const newData = [...formData.timings]
                  newData[index].startTime = e.target.value
                  setFormData({ ...formData, timings: newData })
                  calculateTotalTime(day.day, index)
                }}
              />
            </div>
            <div className='col-3'>
              <label>End Time</label>
              <input type='time'
                value={day.endTime}
                onChange={(e) => {
                  const newData = [...formData.timings]
                  newData[index].endTime = e.target.value

                  setFormData({ ...formData, timings: newData })
                  calculateTotalTime(day.day, index)
                }}
              />
            </div>
            <div className='col-2' style={{ border: "1px solid lightgray", borderRadius: "5px", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "2px" }}>
              <label>Total Time:</label>

              <span>{day.totalTime}hr</span>
            </div>
          </div>

        ))}
        <div style={{ padding: "1rem", display: "flex", justifyContent: "end" }}>
          <button className='btn btn-primary' onClick={customTime} style={{ padding: "8px 16px", marginRight: "1rem" }}>Save Changes</button>
        </div>
      </div>
    </div>
  );
}

export default EmloyessTiming;
