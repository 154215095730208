import React, { useEffect, useState } from 'react'
import { MdLocationPin, MdOutlineAttachEmail, MdOutlineLocationCity, MdOutlineDriveFileRenameOutline } from 'react-icons/md'
import { FaPhoneAlt , FaGraduationCap } from 'react-icons/fa'
import { LuImagePlus} from 'react-icons/lu'
import { LiaIndustrySolid } from 'react-icons/lia'
import {  MdDateRange, MdOutlineBloodtype } from 'react-icons/md'
import { SiCodesignal } from 'react-icons/si'
import { BsGenderAmbiguous } from 'react-icons/bs'
import { RiLockPasswordLine} from 'react-icons/ri'
import { AiOutlineIdcard,AiOutlinePercentage } from 'react-icons/ai'
import { BiMessageDetail } from 'react-icons/bi'
import { CiCalendarDate, CiCircleMinus, CiFileOn, CiGlobe, CiHome, CiLink, CiMedicalCross  } from 'react-icons/ci'
import { GiSkills} from 'react-icons/gi'
import { VscSymbolField} from 'react-icons/vsc'
import { useParams } from 'react-router-dom'
import { useData } from '../ContextProvider/Context'
function UserForm() {
    const params=useParams()
    const userId=JSON.parse(localStorage.getItem('user_auth'))
    const {base_url,handleClick,setSnackbarValue}=useData()
    
    const [employeeData, setEmployees] = useState(
        {
        userId:userId?.user?.id,
          name: userId?.user?.name,
          phone: userId?.user?.phone,
          address: userId?.user?.address,
          gender: userId?.user?.gender,
          maritalStatus: userId?.user?.maritalStatus,
          cnic: userId?.user?.cnic,
          dob: userId?.user?.dob,
          email: userId?.user?.email,
          password: userId?.user?.password,
          permanent_address: userId?.user?.permanent_address,
          kin_address: userId?.user?.kin_address,
          blood_grp: userId?.user?.blood_grp,
          emergency_contact: userId?.user?.emergency_contact,
          medical_history: userId?.user?.medical_history,
          next_to_kin_name: userId?.user?.next_to_kin_name,
          profile: userId?.user?.profile,
          kin_name: userId?.user?.kin_name,
          kin_phone: userId?.user?.kin_phone,
          next_to_kin_phone: userId?.user?.next_to_kin_phone,
          next_to_kin_address: userId?.user?.next_to_kin_address,
          experience:userId?.user?.experience?userId?.user?.experience:[],
          desc: userId?.user?.desc,
          nationality:"",
          cv:"",
          emergency_contact_name: '',
          link_data:userId?.user?.link_data?userId?.user?.link_data: [{
            title: "LinkedIn",
            link: ""
          },
          {
            title: "Facebook",
            link: ""
          },
          {
            title: "Twitter",
            link: ""
          }
        ],
          education_data:userId?.user?.education_data?userId?.user?.education_data: [
            {
              field: "",
              education: "",
              document: ""
            }
          ],
          skills:userId?.user?.skills?userId?.user?.skills:[
            {
              name: "",
              percentage: ""
            }
          ],
          jobId:params.id

    
    
        })
      
        const [selectedImage, setSelectedImage] = useState(null);

        const handleImageChange = (e) => {
          const file = e.target.files[0];
          if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
              const base64String = event.target.result;
              setSelectedImage(URL.createObjectURL(file)); 
              setEmployees({ ...employeeData, profile: base64String.split(",")[1] }); 
            };
            reader.readAsDataURL(file);
          }
        };
        const handlefileChange = (file, i) => {
      
          if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
              const base64String = event.target.result;
              const data = [...employeeData.education_data]
              data[i].document = base64String.split(",")[1]
              setEmployees({ ...employeeData, education_data: data }); 
            };
            reader.readAsDataURL(file);
          }
        };
        const addexperience = () => {
          var obj = {
            company: "",
            post: "",
            period: "",
          }
          setEmployees({ ...employeeData, experience: [...employeeData.experience, obj] }); 
      
        }
        const remove = (idx) => {
          var array = employeeData?.experience?.filter((item, i) => {
            if (idx !== i) {
              return item
            }
          })
          setEmployees({ ...employeeData, experience: array }); 
      
        }
        const addlink = () => {
          var obj = {
            title: "",
            link: ""
          }
          setEmployees({ ...employeeData, link_data: [...employeeData.link_data, obj] }); 
      
        }
        const removelink = (idx) => {
          var array = employeeData?.link_data?.filter((item, i) => {
            if (idx !== i) {
              return item
            }
          })
          setEmployees({ ...employeeData, link_data: array }); 
      
        }
        const addeducation = () => {
          var obj = {
            field: "",
            education: "",
            document: ""
          }
          setEmployees({ ...employeeData, education_data: [...employeeData.education_data, obj] }); 
      
        }
        const removeeducation = (idx) => {
          var array = employeeData?.education_data?.filter((item, i) => {
            if (idx !== i) {
              return item
            }
          })
          setEmployees({ ...employeeData, education_data: array }); 
      
        }
        const addskill = () => {
          var obj = {
            name: "",
            percentage: ""
          }
          setEmployees({ ...employeeData, skills: [...employeeData.skills, obj] }); 
      
        }
        const removeskill = (idx) => {
          var array = employeeData?.skills?.filter((item, i) => {
            if (idx !== i) {
              return item
            }
          })
          setEmployees({ ...employeeData, skills: array }); 
      
        }
        const userUpdate = () => {
            fetch(`${base_url}applicant-update`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(employeeData),
            })
                .then((res) => res.json())
                .then((data) => {
                    handleClick();
                    setSnackbarValue({ message: "Form Submit successfully", color: "#2ECC71" });
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        };
        const handleCv=(e)=>{
          const file = e.target.files[0];
          if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
              const base64String = event.target.result;
              setEmployees({ ...employeeData, cv: base64String}); 
            };
            reader.readAsDataURL(file);
          }
        }
    return (
        <>
        <div className='expense-header d-flex justify-content-between align-items-baseline'>
        <div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active" aria-current="page">Application</li>
                </ol>
            </nav>
        </div>
        <div>
            <button onClick={userUpdate} className='btn btn-primary' style={{display:"flex",alignItems:"end",gap:"10px"}}>Submit</button>
        </div>
    </div>
        <div className='card'>

        <div className='card-body'>
        <div className='AdminUserForm p-3'>
        <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
          <li className="nav-item" role="presentation">
            <a className="nav-link active" id="ex1-tab-1" data-mdb-toggle="tab" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1" aria-selected="true">Personal Information</a>
          </li>
          <li className="nav-item" role="presentation">
            <a className="nav-link" id="ex1-tab-4" data-mdb-toggle="tab" href="#ex1-tabs-4" role="tab" aria-controls="ex1-tabs-4" aria-selected="false">Kin Information</a>
          </li>
          <li className="nav-item" role="presentation">
            <a className="nav-link" id="ex1-tab-5" data-mdb-toggle="tab" href="#ex1-tabs-5" role="tab" aria-controls="ex1-tabs-5" aria-selected="false">Others</a>
          </li>
          <li className="nav-item" role="presentation">
            <a className="nav-link" id="ex1-tab-6" data-mdb-toggle="tab" href="#ex1-tabs-6" role="tab" aria-controls="ex1-tabs-6" aria-selected="false">Experience & Education</a>
          </li>
          <li className="nav-item" role="presentation">
            <a className="nav-link" id="ex1-tab-7" data-mdb-toggle="tab" href="#ex1-tabs-7" role="tab" aria-controls="ex1-tabs-7" aria-selected="false">Social Link</a>
          </li>
        </ul>
        <div className="tab-content" id="ex1-content">
          <div className="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1" style={{paddingBottom:"1rem"}}>
            <div  style={{ height: "60vh", overflowY: "scroll",paddingBottom:"1rem" }} className="step-content row">
              <div className='col-12'>
                <div style={{ border: "none" }} className='row'>
                  <div className='col-12'>
                    <div style={{ border: '2px solid', height: "100px", borderRadius: "50%", width: "100px", position: "relative" }}>
                      <label style={{ border: "1px solid", borderRadius: "50%", width: "25px", height: "25px", background: "lightgray", color: "gray", position: "absolute", right: "0%", paddingLeft: ".2rem", paddingBottom: ".2rem" }} htmlFor="EmployeeLogo"><LuImagePlus /></label>
                      <img style={{ width: "100%", height: "100%", borderRadius: "50%" }} src={selectedImage ? selectedImage : ""} />
                    </div>
                    <input
                      type="file"
                      id="EmployeeLogo"
                      name="EmployeeLogo"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleImageChange}   
                    />
                  </div>
                </div>

              </div>
              <div className='col-6'>
                <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Name:</label>
                <div className='row mt-1'>
                  <div className='col-12'>
                    <label><MdOutlineDriveFileRenameOutline /></label>
                    <input
                      type="text"
                      id="EmployeeName"
                      name="EmployeeName"
                      placeholder='Name'
                      value={employeeData?.name}
                      onChange={(e) => setEmployees({ ...employeeData, name: e.target.value })}
                    />
                  </div>
                </div>

              </div>

              <div className='col-6'>
                <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Email:</label>

                <div className='row mt-1'>
                  <div className='col-12'>
                    <label htmlFor="EmployeeEmail"><MdOutlineAttachEmail /></label>

                    <input
                      type="email"
                      id="EmployeeEmail"
                      name="EmployeeEmail"
                      placeholder='Email'
                      value={employeeData?.email}
                      onChange={(e) => setEmployees({ ...employeeData, email: e.target.value })}
                    />
                  </div>
                </div>

              </div>
              <div className='col-6'>
                <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Phone:</label>

                <div className='row mt-1'>
                  <div className='col-12'>
                    <label htmlFor="EmployeePhone"><FaPhoneAlt /></label>
                    <input
                      type="number"
                      id="EmployeePhone"
                      name="EmployeePhone"
                      placeholder='Phone'
                      value={employeeData?.phone}
                      onChange={(e) => setEmployees({ ...employeeData, phone: e.target.value })}
                    />
                  </div>
                </div>

              </div>
              <div className='col-6'>
                <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Temporary Address:</label>

                <div className='row mt-1'>
                  <div className='col-12'>
                    <label htmlFor="trading_addr"><MdLocationPin /></label>

                    <input
                      type="text"
                      id="trading_addr"
                      name="trading_addr"
                      placeholder='Temporary Address'

                      value={employeeData?.address}
                      onChange={(e) => setEmployees({ ...employeeData, address: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Password:</label>

                <div className='row mt-1'>
                  <div className='col-12'>
                    <label htmlFor="trading_addr"><RiLockPasswordLine /></label>
                    <input
                      // type="password"
                      id="trading_addr"
                      name="trading_addr"
                      placeholder='Enter Password'
                      value={employeeData?.password}
                      onChange={(e) => setEmployees({ ...employeeData, password: e.target.value })}
                    />
                  </div>
                </div>
              </div>

              <div className='col-6'>
                <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Permanent Address:</label>

                <div className='row mt-1'>
                  <div className='col-12'>
                    <label htmlFor="EmployeeAddress"><MdLocationPin /></label>


                    <input
                      type="text"
                      id="EmployeeAddress"
                      name="EmployeeAddress"
                      placeholder="Permanent Address"
                      value={employeeData?.permanent_address}
                      onChange={(e) => setEmployees({ ...employeeData, permanent_address: e.target.value })}
                    />
                  </div>
                </div>

              </div>
              <div className='col-6'>
                <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Date of Birth:</label>

                <div className='row mt-1'>
                  <div className='col-12 d-flex'>
                    <label>< MdDateRange /></label>


                    <input style={{ width: '100%' }}
                      type='date'
                      id="EmployeeAddress"
                      name="EmployeeAddress"
                      placeholder="D.o.B"
                      value={employeeData?.dob}
                      onChange={(e) => setEmployees({ ...employeeData, dob: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Gender:</label>

                <div className='row mt-1'>
                  <div className='col-12'>
                    <label htmlFor="EmployeeAddress"><BsGenderAmbiguous /></label>
                    <input
                      type="text"
                      id="EmployeeAddress"
                      name="EmployeeAddress"
                      placeholder="Gender"
                      value={employeeData?.gender}
                      onChange={(e) => setEmployees({ ...employeeData, gender: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>ID:</label>

                <div className='row mt-1'>
                  <div className='col-12'>
                    <label htmlFor="EmployeeAddress"><AiOutlineIdcard /></label>
                    <input
                      id="EmployeeAddress"
                      name="EmployeeAddress"
                      type="number"
                      placeholder="ID"
                      value={employeeData?.cnic}
                      onChange={(e) => setEmployees({ ...employeeData, cnic: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Nationality:</label>

                <div className='row mt-1'>
                  <div className='col-12'>
                    <label htmlFor="EmployeeAddress"><AiOutlineIdcard /></label>
                    <input
                      id="EmployeeAddress"
                      name="EmployeeAddress"
                      type="text"
                      placeholder="Nationality"
                      value={employeeData?.nationality}
                      onChange={(e) => setEmployees({ ...employeeData, nationality: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Marital Status:</label>

                <div className='row mt-1'>
                  <div className='col-12'>
                    <label htmlFor="EmployeeAddress"><MdLocationPin /></label>
                    <input
                      id="EmployeeAddress"
                      type="text"
                      name="EmployeeAddress"
                      placeholder="Marital Status"
                      value={employeeData?.maritalStatus}
                      onChange={(e) => setEmployees({ ...employeeData, maritalStatus: e.target.value })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="tab-pane fade" id="ex1-tabs-4" role="tabpanel" aria-labelledby="ex1-tab-4">
            <div className="step-content row">
              <div className='col-6'>
                <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Kin Name:</label>

                <div className='row mt-1'>
                  <div className='col-12'>
                    <label htmlFor="EmployeeName"><MdOutlineDriveFileRenameOutline /></label>
                    <input
                      type="text"
                      id="EmployeeName"
                      name="EmployeeName"
                      placeholder='Kin Name'
                      value={employeeData?.kin_name}
                      onChange={(e) => setEmployees({ ...employeeData, kin_name: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Kin Phone:</label>

                <div className='row mt-1'>
                  <div className='col-12'>
                    <label htmlFor="EmployeePhone"><FaPhoneAlt /></label>

                    <input
                      type="number"
                      id="EmployeePhone"
                      name="EmployeePhone"
                      placeholder='Kin Phone'

                      value={employeeData?.kin_phone}
                      onChange={(e) => setEmployees({ ...employeeData, kin_phone: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Next-to-Kin Name:</label>

                <div className='row mt-1'>
                  <div className='col-12'>
                    <label htmlFor="EmployeeName"><MdOutlineDriveFileRenameOutline /></label>
                    <input
                      type="text"
                      id="EmployeeName"
                      name="EmployeeName"
                      placeholder='Next-to-Kin Name'
                      value={employeeData?.next_to_kin_name}
                      onChange={(e) => setEmployees({ ...employeeData, next_to_kin_name: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Next-to-Kin Phone:</label>

                <div className='row mt-1'>
                  <div className='col-12'>
                    <label htmlFor="EmployeeEmail"><FaPhoneAlt /></label>
                    <input
                      type="number"
                      id="EmployeeEmail"
                      name="EmployeeEmail"
                      placeholder='Next-to-Kin Phone'
                      value={employeeData?.next_to_kin_phone}
                      onChange={(e) => setEmployees({ ...employeeData, next_to_kin_phone: e.target.value })}
                    />
                  </div>
                </div>
              </div>

              <div className='col-6'>
                <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Kin Address:</label>

                <div className='row mt-1'>
                  <div className='col-12'>
                    <label htmlFor="trading_addr"><MdLocationPin /></label>
                    <input
                      id="trading_addr"
                      name="trading_addr"
                      placeholder='Kin Address'
                      type="text"

                      value={employeeData?.kin_address}
                      onChange={(e) => setEmployees({ ...employeeData, kin_address: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Next-to-Kin Address:</label>

                <div className='row mt-1'>
                  <div className='col-12'>
                    <label htmlFor="trading_addr"><MdLocationPin /></label>
                    <input
                      id="trading_addr"
                      name="trading_addr"
                      placeholder='Next-to-Kin Address'
                      type="text"

                      value={employeeData?.next_to_kin_address}
                      onChange={(e) => setEmployees({ ...employeeData, next_to_kin_address: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Emergency Number:</label>

                <div className='row mt-1'>
                  <div className='col-12'>
                    <label htmlFor="EmployeeAddress"><FaPhoneAlt /></label>
                    <input
                      type='number'
                      id="EmployeeAddress"
                      name="EmployeeAddress"
                      placeholder="Emergency Number"
                      value={employeeData?.emergency_contact}
                      onChange={(e) => setEmployees({ ...employeeData, emergency_contact: e.target.value })}
                    />
                  </div>
                </div>

              </div>
              <div className='col-6'>
                <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Emergency Contact Name:</label>

                <div className='row mt-1'>
                  <div className='col-12'>
                    <label htmlFor="EmployeeAddress"><FaPhoneAlt /></label>
                    <input
                      type='text'
                      id="EmployeeAddress"
                      name="EmployeeAddress"
                      placeholder="Emergency Contact Name"
                      value={employeeData?.emergency_contact_name}
                      onChange={(e) => setEmployees({ ...employeeData, emergency_contact_name: e.target.value })}
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="ex1-tabs-5" role="tabpanel" aria-labelledby="ex1-tab-5">
            <div className="step-content row">
           
              <div className='col-6'>
              <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Blood Group:</label>

                <div className='row mt-1'>
                  <div className='col-12'>
                    <label><MdOutlineBloodtype /></label>
                    <input
                      type="text"
                      id="EmployeeName"
                      name="EmployeeName"
                      placeholder='Blood Group'
                      value={employeeData?.blood_grp}
                      onChange={(e) => setEmployees({ ...employeeData, blood_grp: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className='col-6'>
              <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Medical History:</label>

                <div className='row mt-1'>
                  <div className='col-12'>
                    <label><CiMedicalCross /></label>
                    <input
                      type="text"
                      id="EmployeeEmail"
                      name="EmployeeEmail"
                      placeholder='Medical History'
                      value={employeeData?.medical_history}
                      onChange={(e) => setEmployees({ ...employeeData, medical_history: e.target.value })}
                    />
                  </div>
                </div>
              </div>
             
              <div className='col-6'>
              <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>CV:</label>

                <div className='row mt-1'>
                  <div className='col-12'>
                    <label><CiHome /></label>
                   <input onChange={handleCv} type='file'/>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
          <div className="tab-pane fade" id="ex1-tabs-6" role="tabpanel" aria-labelledby="ex1-tab-6">
            <div style={{ height: "60vh", overflowY: "scroll" }} className="step-content row">
              <div style={{ display: "flex", justifyContent: "end" }}>
                <button className='btn btn-primary mt-1' style={{ padding: "8px 16px", marginRight: "1rem", marginTop: "10px" }} onClick={addeducation}>Add Education</button>
              </div>
              {employeeData?.education_data?.map((item, i) => {
                return (
                  <>
                    {i > 0 && <div style={{ display: "flex", justifyContent: "end" }}>
                      <button className='btn btn-primary' style={{ padding: "8px 16px", marginRight: "1rem", marginTop: "10px" }} onClick={() => removeeducation(i)}><CiCircleMinus /></button>

                    </div>}
                    <div className='col-4'>
                    <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Field:</label>

                      <div className='row'>
                        <div className='col-12'>
                          <label><VscSymbolField /></label>
                          <input
                            type="text"
                            id="EmployeeName"
                            name="EmployeeName"
                            placeholder='Field'
                            value={item?.field}
                            onChange={(e) => {
                              const data = [...employeeData.education_data]
                              data[i].field = e.target.value
                              setEmployees({ ...employeeData, education_data: data })
                            }}
                          // value={formData?.name}
                          // onChange={(e) => handleChange('name', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-4'>
                    <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Education:</label>

                      <div className='row'>
                        <div className='col-12'>
                          <label><FaGraduationCap /></label>
                          <input
                            type="text"
                            id="EmployeeName"
                            name="EmployeeName"
                            placeholder='Education'
                            value={item?.education}
                            onChange={(e) => {
                              const data = [...employeeData.education_data]
                              data[i].education = e.target.value
                              setEmployees({ ...employeeData, education_data: data })
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-4'>
                    <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Documents:</label>

                      <div className='row'>
                        <div className='col-12'>
                          <label htmlFor='education'><CiFileOn /></label>
                          <input
                            type="file"
                            id="education"
                            name="EmployeeName"
                            placeholder='Education'
                            onChange={(e) => handlefileChange(e.target.files[0], i)}
                          />
                        </div>
                      </div>
                    </div>

                  </>
                )
              })}


              <div style={{ display: "flex", justifyContent: "end" }}>
                <button className='btn btn-primary mt-3' style={{ padding: "8px 16px", marginRight: "1rem", marginTop: "10px" }} onClick={addexperience}>Add Experience</button>
              </div>
              {employeeData?.experience?.map((item, i) => {
                return (
                  <>
                    {i > 0 && <div style={{ display: "flex", justifyContent: "end" }}>
                      <button className='btn btn-primary' style={{ padding: "8px 16px", marginRight: "1rem", marginTop: "10px" }} onClick={() => remove(i)}><CiCircleMinus /></button>

                    </div>}
                    <div className='col-4'>
                    <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Company Name:</label>

                      <div className='row'>
                        <div className='col-12'>
                          <label><LiaIndustrySolid /></label>
                          <input
                            type="text"
                            id="EmployeeName"
                            name="EmployeeName"
                            placeholder='Company Name'
                            value={item?.company}
                            onChange={(e) => {
                              const data = [...employeeData.experience]
                              data[i].company = e.target.value
                              setEmployees({ ...employeeData, experience: data })
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-4'>
                    <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Designation:</label>

                      <div className='row'>
                        <div className='col-12'>
                          <label><SiCodesignal /></label>
                          <input
                            type="text"
                            id="EmployeeName"
                            name="EmployeeName"
                            placeholder='Designation'
                            value={item?.post}
                            onChange={(e) => {
                              const data = [...employeeData.experience]
                              data[i].post = e.target.value
                              setEmployees({ ...employeeData, experience: data })
                            }}
                          // value={formData?.name}
                          // onChange={(e) => handleChange('name', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-4'>
                    <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Time Period:</label>

                      <div className='row'>
                        <div className='col-12'>
                          <label><CiCalendarDate /></label>
                          <input
                            type="text"
                            id="EmployeeName"
                            name="EmployeeName"
                            placeholder='Period'
                            value={item?.period}
                            onChange={(e) => {
                              const data = [...employeeData.experience]
                              data[i].period = e.target.value
                              setEmployees({ ...employeeData, experience: data })
                            }}
                          // value={formData?.name}
                          // onChange={(e) => handleChange('name', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )
              })}
              <div style={{ display: "flex", justifyContent: "end" }}>
                <button className='btn btn-primary mt-3' style={{ padding: "8px 16px", marginRight: "1rem", marginTop: "10px" }} onClick={addskill}>Add Skills</button>
              </div>
              {employeeData?.skills?.map((item, i) => {
                return (
                  <>
                    <div className='mt-2' style={{ display: "flex", justifyContent: "end" }}>
                      <button className='btn btn-primary' style={{ padding: "8px 16px", marginRight: "1rem", marginTop: "10px" }} onClick={() => removeskill(i)}><CiCircleMinus /></button>

                    </div>
                    <div className='col-6'>
                    <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Company Name:</label>

                      <div className='row mt-1'>
                        <div className='col-12'>
                          <label><GiSkills /></label>
                          <input
                            type="text"
                            id="EmployeeName"
                            name="EmployeeName"
                            placeholder='Name'
                            value={item?.name}

                            onChange={(e) => {
                              const data = [...employeeData.skills]
                              data[i].name = e.target.value
                              setEmployees({ ...employeeData, skills: data })
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-6'>
                    <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Progress:</label>

                      <div className='row mt-1'>
                        <div className='col-12'>
                          <label><AiOutlinePercentage /></label>
                          <input
                            type="number"
                            id="EmployeeName"
                            name="EmployeeName"
                            placeholder='Percentage'
                            value={item?.percentage}

                            onChange={(e) => {
                              const data = [...employeeData.skills]
                              data[i].percentage = e.target.value
                              setEmployees({ ...employeeData, skills: data })
                            }}
                          />
                        </div>
                      </div>
                    </div>

                  </>
                )
              })}
              <div className='col-12 mt-5'>
                <div className='row'>
                  <div className='col-12 d-flex align-items-baseline gap-2'>
                    <label><BiMessageDetail /></label>
                    <textarea
                      style={{
                        height: '120px',
                        border: 'none',
                        fontSize: '13px',
                        width: '100%'
                      }}
                      type="text"
                      id="EmployeeName"
                      name="EmployeeName"
                      placeholder='Description'
                      value={employeeData?.desc}
                      onChange={(e) => setEmployees({ ...employeeData, desc: e.target.value })}
                    />
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="tab-pane fade" id="ex1-tabs-7" role="tabpanel" aria-labelledby="ex1-tab-7">
            <div style={{ display: "flex", justifyContent: "end" }}>
              <button className='btn btn-primary' style={{ padding: "8px 16px", marginRight: "1rem", marginTop: "10px" }} onClick={addlink}>Add Link</button>
            </div>
            <div style={{ height: "60vh", overflowY: "scroll", alignContent: "start" }} className="step-content row">

              {employeeData?.link_data?.map((item, i) => {
                return (
                  <>
                    {i > 0 && <div style={{ display: "flex", justifyContent: "end", height: "40px" }} className='col-12'>
                      <button className='btn btn-primary' style={{ padding: "8px 16px", marginRight: "1rem", marginTop: "10px" }} onClick={() => removelink(i)}><CiCircleMinus /></button>
                    </div>}
                    <div className='col-6'>
                    <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Social Name:</label>

                      <div className='row'>
                        <div className='col-12'>
                          <label><CiGlobe /></label>
                          <input
                            type="text"
                            id="EmployeeName"
                            name="EmployeeName"
                            placeholder='Name'
                            value={item.title}
                            onChange={(e) => {
                              const data = [...employeeData.link_data]
                              data[i].title = e.target.value
                              setEmployees({ ...employeeData, link_data: data })
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-6'>
                    <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Link Name:</label>

                      <div className='row'>
                        <div className='col-12'>
                          <label><CiLink /></label>
                          <input
                            type="text"
                            id="EmployeeName"
                            name="EmployeeName"
                            placeholder='Link'
                            value={item.link}
                            onChange={(e) => {
                              const data = [...employeeData.link_data]
                              data[i].link = e.target.value
                              setEmployees({ ...employeeData, link_data: data })
                            }}
                          // value={formData?.name}
                          // onChange={(e) => handleChange('name', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                  </>
                )
              })}


            </div>

          </div>
        </div>

      </div>
        </div>
        </div>
        </>
    )
}

export default UserForm
