import React, { useEffect, useState } from 'react'
import { useData } from '../../../ContextProvider/Context'
import { Box, Button, Modal, Typography } from '@mui/material'
import { CiTrash, CiEdit } from 'react-icons/ci'
import { RiAccountPinCircleLine, RiSecurePaymentLine } from 'react-icons/ri'
import { Link, useParams } from 'react-router-dom'
import { Alert, Snackbar } from '@mui/material';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function Supplier() {
    const params = useParams()
    const { getSupplierFilterCom, accountsData, getAccountsData, handleClick, setSnackbarValue, supplierData, getSupplierData, storeSupplierData, updateSupplierData, deleteSupplierData, getFilterSupplier } = useData()
    useEffect(() => {
        getSupplierData()
        getAccountsData()
    }, [])
    const [selectedCompany, setSelectedCompany] = useState(null);
    const filteredCompanies = accountsData?.company?.filter(item => item.deleteStatus === 0);
    const [name, setName] = useState({
        name: ''
    })
    const [obj, setObj] = useState(
        {
            company_id: params.id ? params.id : selectedCompany,
            name: '',
            email: '',
            mobile: '',
            other: '',
            bill_address: '',
            bill_city: '',
            bill_province: '',
            bill_country: '',
            bill_postal: '',
            note: '',
            attachment: '',
            payment_method: '',
            sales: '',
            language: '',
            term: '',
            tax: '',
            date: new Date().toISOString().split('T')[0],
        }
    )
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        clearFields()
        setOpen(false)
    };
    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const base64URL = event.target.result;
                const parts = base64URL.split(',');
                const base64Data = parts[1];

                setObj({
                    ...obj,
                    attachment: base64Data,
                });
            };

            reader.readAsDataURL(file);
            if (file.type.startsWith('image/')) {
            } else {
            }
        }
    };
    const clearFields = () => {
        setObj({
            company_id: params.id ? params.id : selectedCompany,
            name: '',
            email: '',
            mobile: '',
            other: '',
            bill_address: '',
            bill_city: '',
            bill_province: '',
            bill_country: '',
            bill_postal: '',
            note: '',
            attachment: '',
            payment_method: '',
            sales: '',
            language: '',
            term: '',
            tax: '',
            date: new Date().toISOString().split('T')[0],
        });
    };
    const isFormValid = () => {
        for (const key in obj) {

            if (key !== "ship_address" && key !== "ship_city" && key !== "ship_province" && key !== "ship_country" && key !== "ship_postal" && key !== "note" && key !== "attachment" && key !== "payment_method" && key !== "sales" && key !== "language" && key !== "term" && key !== "tax" && key !== "opening_balance" && key !== "date" && obj[key] === '') {
                return false;
            }
        }
        return true;
    };
    const state = {
        open: false,
        vertical: 'bottom',
        horizontal: 'right',
    }
    const { vertical, horizontal } = state;
      const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
      const [snackbarMessage, setSnackbarMessage] = useState('');
    const handleSubmit = (e) => {
        e && e.preventDefault();
        if (!obj?.company_id) {
          setAnyFieldEmpty(true);
          setSnackbarMessage("Company is required.");
        } else if (!obj?.mobile) {
          setAnyFieldEmpty(true);
          setSnackbarMessage(" Mobile is required.");
        } else if (!obj?.name) {
          setAnyFieldEmpty(true);
          setSnackbarMessage("name is Required.");
        } else if (!obj?.other) {
          setAnyFieldEmpty(true);
          setSnackbarMessage("other is required.");
        } else if (!obj?.email) {
          setAnyFieldEmpty(true);
          setSnackbarMessage("email is required.");
        } 
        else if (!obj?.bill_address) {
            setAnyFieldEmpty(true);
            setSnackbarMessage("bill_address is required.");
          } 
          else if (!obj?.bill_province) {
            setAnyFieldEmpty(true);
            setSnackbarMessage("bill_province is required.");
          } 
          else if (!obj?.bill_city) {
            setAnyFieldEmpty(true);
            setSnackbarMessage("bill_city is required.");
          } 
          else if (!obj?.bill_country) {
            setAnyFieldEmpty(true);
            setSnackbarMessage("bill_country is required.");
          } 
        else if (!obj?.bill_postal) {
            setAnyFieldEmpty(true);
            setSnackbarMessage("bill_postal is required.");
          } 
        //   else if (!obj?.sales) {
        //     setAnyFieldEmpty(true);
        //     setSnackbarMessage("sales is required.");
        //   } 
        
           
        else {
          handleClose();
          storeSupplierData(obj);
          setObj({
            company_id: params.id ? params.id : selectedCompany,
            name: '',
            email: '',
            mobile: '',
            other: '',
            bill_address: '',
            bill_city: '',
            bill_province: '',
            bill_country: '',
            bill_postal: '',
            note: '',
            attachment: '',
            payment_method: '',
            sales: '',
            language: '',
            term: '',
            tax: '',
            date: new Date().toISOString().split('T')[0],
          });
        }
      };
      const handleCloseSnackbar = () => {
        setAnyFieldEmpty(false);
    };
      
    const handleUpdateSubmit = (e) => {
        e && e.preventDefault();
        if (!obj?.company_id) {
          setAnyFieldEmpty(true);
          setSnackbarMessage("Company is required.");
        } else if (!obj?.mobile) {
          setAnyFieldEmpty(true);
          setSnackbarMessage(" Mobile is required.");
        } else if (!obj?.name) {
          setAnyFieldEmpty(true);
          setSnackbarMessage("name is Required.");
        } else if (!obj?.other) {
          setAnyFieldEmpty(true);
          setSnackbarMessage("other is required.");
        } else if (!obj?.email) {
          setAnyFieldEmpty(true);
          setSnackbarMessage("email is required.");
        } 
        else if (!obj?.bill_address) {
            setAnyFieldEmpty(true);
            setSnackbarMessage("bill_address is required.");
          } 
          else if (!obj?.bill_province) {
            setAnyFieldEmpty(true);
            setSnackbarMessage("bill_province is required.");
          } 
          else if (!obj?.bill_city) {
            setAnyFieldEmpty(true);
            setSnackbarMessage("bill_city is required.");
          } 
          else if (!obj?.bill_country) {
            setAnyFieldEmpty(true);
            setSnackbarMessage("bill_country is required.");
          } 
        else if (!obj?.bill_postal) {
            setAnyFieldEmpty(true);
            setSnackbarMessage("bill_postal is required.");
          } 
          else if (!obj?.sales) {
            setAnyFieldEmpty(true);
            setSnackbarMessage("sales is required.");
          } 
        
           
        else {
          handleClose();
          updateSupplierData(obj.id, obj);
          setObj({
            company_id: params.id ? params.id : selectedCompany,
            name: '',
            email: '',
            mobile: '',
            other: '',
            bill_address: '',
            bill_city: '',
            bill_province: '',
            bill_country: '',
            bill_postal: '',
            note: '',
            attachment: '',
            payment_method: '',
            sales: '',
            language: '',
            term: '',
            tax: '',
            date: new Date().toISOString().split('T')[0],
          });
        }
      };
           
         
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const visibleSupplier = params.id
        ? supplierData?.filter(item => item?.company?.id == params.id) || []
        : supplierData || [];

    const currentSupplier = visibleSupplier?.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(visibleSupplier.length / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }


    return (
        <div>
            <div className='services'>
                <div className='expense-header d-flex justify-content-between align-items-baseline'>
                    <div className='inp-buttons d-flex gap-2 w-100' style={{ alignItems: "end", justifyContent: "space-between" }}>
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Suppliers</li>
                                </ol>
                            </nav>
                        </div>
                        <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                            <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                                <div>
                                    <label style={{ fontWeight: "bold", fontSize: "10px" }}>Company:</label> <br />
                                    <select disabled={params.id ? true : false} className='form-select' onChange={(e) => {
                                        handlePageChange(1)
                                        getSupplierFilterCom(e.target.value)
                                    }}>
                                        <option value=""> Select Company</option>
                                        {filteredCompanies?.map((item) => (
                                            <option key={item.id} value={item.name}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='clientName'>
                                    <label style={{ fontWeight: "bold", fontSize: "10px" }}>Search Name:</label> <br />
                                    <input
                                        type='text'
                                        name='name'
                                        value={name.name}
                                        onChange={(e) => {
                                            setName({ name: e.target.value });
                                            getFilterSupplier(e.target.value);
                                            handlePageChange(1)
                                        }}
                                        className='form-control'
                                        placeholder='Search By Name'
                                    />
                                </div>
                            </div>
                            <button type="button" className="btn btn-primary" onClick={handleOpen}>Add</button>
                        </div>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <Box sx={style} style={{ height: "600px", overflowY: "scroll" }} className='scrollBar'>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Add
                                </Typography>
                                <div id="modal-modal-description" sx={{ mt: 2 }} style={{ padding: "1rem" }}>
                                    <div className="row">
                                        <h5 style={{ fontWeight: "bold", fontSize: "15px" }} className='my-3'>Basic Info</h5>
                                        <div className="col-6">
                                            <div className="inputFields">
                                                <div className='selectBox'>
                                                    <label><RiAccountPinCircleLine /></label>
                                                    <select disabled={params.id ? true : false} value={obj?.company_id} onChange={(e) => {
                                                        setSelectedCompany(parseInt(e.target.value));
                                                        setObj({
                                                            ...obj,
                                                            company_id: parseInt(e.target.value)
                                                        });
                                                    }}>
                                                        <option value="">Select a company</option>
                                                        {filteredCompanies?.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="inputBox mt-3">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter Name"
                                                        value={obj?.name}
                                                        onChange={(e) => setObj({ ...obj, name: e.target.value })}
                                                    />
                                                </div>
                                                <div className="inputBox mt-3">
                                                    <input
                                                        type="email"
                                                        placeholder="Enter Email"
                                                        value={obj?.email}
                                                        onChange={(e) => setObj({ ...obj, email: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="inputFields">
                                                <div className="inputBox mt-3">
                                                    <input
                                                        type="number"
                                                        placeholder="Enter Number"
                                                        value={obj?.mobile}
                                                        onChange={(e) => setObj({ ...obj, mobile: e.target.value })}
                                                    />
                                                </div>
                                                <div className="inputBox mt-3">
                                                    <input
                                                        type="number"
                                                        placeholder="Enter Other Number"
                                                        value={obj?.other}
                                                        onChange={(e) => setObj({ ...obj, other: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <h5 style={{ fontWeight: "bold", fontSize: "15px" }} className='my-3'>Billing Address</h5>
                                        <div className="col-6">
                                            <div className="inputFields">
                                                <div className="inputBox mt-3">
                                                    <input
                                                        type="text"
                                                        value={obj?.bill_address}
                                                        placeholder="Street Address"
                                                        onChange={(e) => setObj({ ...obj, bill_address: e.target.value })}
                                                    />
                                                </div>
                                                <div className="inputBox mt-3">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter City"
                                                        value={obj?.bill_city}
                                                        onChange={(e) => setObj({ ...obj, bill_city: e.target.value })}
                                                    />
                                                </div>
                                                <div className="inputBox mt-3">
                                                    <input
                                                        type="number"
                                                        placeholder="Enter Postal Code"
                                                        value={obj?.bill_postal}
                                                        onChange={(e) => setObj({ ...obj, bill_postal: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="inputFields">
                                                <div className="inputBox mt-3">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter Province"
                                                        value={obj?.bill_province}
                                                        onChange={(e) => setObj({ ...obj, bill_province: e.target.value })}
                                                    />
                                                </div>
                                                <div className="inputBox mt-3">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter Country"
                                                        value={obj?.bill_country}
                                                        onChange={(e) => setObj({ ...obj, bill_country: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row mt-3">
                                        <h5 style={{ fontWeight: "bold", fontSize: "15px" }} className='my-3'>Shipping Address</h5>
                                        <div className="col-6">
                                            <div className="inputFields">
                                                <div className="inputBox mt-3">
                                                    <input
                                                        type="text"
                                                        placeholder="Street Address"
                                                        value={obj?.ship_address}
                                                        onChange={(e) => setObj({ ...obj, ship_address: e.target.value })}
                                                    />
                                                </div>
                                                <div className="inputBox mt-3">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter City"
                                                        value={obj?.ship_city}
                                                        onChange={(e) => setObj({ ...obj, ship_city: e.target.value })}
                                                    />
                                                </div>
                                                <div className="inputBox mt-3">
                                                    <input
                                                        type="number"
                                                        placeholder="Enter Postal Code"
                                                        value={obj?.ship_postal}
                                                        onChange={(e) => setObj({ ...obj, ship_postal: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="inputFields">
                                                <div className="inputBox mt-3">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter Province"
                                                        value={obj?.ship_province}
                                                        onChange={(e) => setObj({ ...obj, ship_province: e.target.value })}
                                                    />
                                                </div>
                                                <div className="inputBox mt-3">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter Country"
                                                        value={obj?.ship_country}
                                                        onChange={(e) => setObj({ ...obj, ship_country: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="row mt-3">
                                        <h5 style={{ fontWeight: "bold", fontSize: "15px" }} className='my-3'>Note & Attachment</h5>
                                        <div className="col-12">
                                            <div className="inputFields">
                                                <div className="inputBox mt-3">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter Note"
                                                        value={obj?.note}
                                                        onChange={(e) => setObj({ ...obj, note: e.target.value })}
                                                    />
                                                </div>
                                                <div className="inputBox mt-3">
                                                    <input
                                                        type="file"
                                                        accept="*/*" // Accept any file type
                                                        onChange={handleFileChange}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <h5 style={{ fontWeight: "bold", fontSize: "15px" }} className='my-3'>Payment</h5>
                                        <div className="col-6">
                                            <div className="inputFields">
                                                <div className="inputBox mt-3">
                                                    <select value={obj?.sales} onChange={(e) => setObj({ ...obj, sales: e.target.value })}>
                                                        <option value="" disabled>Select Payment Method</option>
                                                        <option value="Cash">Cash</option>
                                                        <option value="Check">Check</option>
                                                        <option value="Credit Card">Credit Card</option>
                                                        <option value="Debit Card">Debit Card</option>
                                                        <option value="Direct Debit">Direct Debit</option>
                                                    </select>
                                                </div>
                                                <div className="inputBox mt-3">
                                                    <select value={obj?.payment_method} onChange={(e) => setObj({ ...obj, payment_method: e.target.value })}>
                                                        <option value="">Select Delivery Method</option>
                                                        <option value="Use Company Default" defaultValue="Use Company Default">Use Company Default</option>
                                                        <option value="Print Later">Print Later</option>
                                                        <option value="Send Later">Send Later</option>
                                                        <option value="None">None</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="inputFields">
                                                <div className="inputBox mt-3">
                                                    <select value={obj?.term} onChange={(e) => setObj({ ...obj, term: e.target.value })}>
                                                        <option value="">Terms</option>
                                                        <option value="Due on Receipt">Due on Receipt</option>
                                                        <option value="Net 15">Net 15</option>
                                                        <option value="Net 30">Net 30</option>
                                                        <option value="Net 60">Net 60</option>
                                                    </select>
                                                </div>
                                                <div className="inputBox mt-3">
                                                    <select value={obj?.language} onChange={(e) => setObj({ ...obj, language: e.target.value })}>
                                                        <option value="">Select Language</option>
                                                        <option value="English" defaultValue="English">English</option>
                                                        <option value="Spanish">Spanish</option>
                                                        <option value="Italian">Italian</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <h5 style={{ fontWeight: "bold", fontSize: "15px" }} className='my-3'>Additional Info</h5>
                                        <div className="col-6">
                                            <div className="inputFields">
                                                <div className="inputBox mt-3">
                                                    <input type="date" value={obj?.date} onChange={(e) => setObj({ ...obj, date: e.target.value })} className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="inputFields">
                                                <div className="inputBox mt-3">
                                                    <input type="number" value={obj?.tax} onChange={(e) => setObj({ ...obj, tax: e.target.value })} placeholder="Sales Tax registration" className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="modalBtns d-flex justify-content-end gap-2">
                                            <button className="btn btn-primary" onClick={() => {
                                                obj?.id ? handleUpdateSubmit() : handleSubmit()
                                            }}>
                                                {obj?.id ? 'Update' : 'Submit'}
                                            </button>
                                            <button className="btn btn-secondary" onClick={handleClose}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Modal>

                    </div>
                </div>
                <hr />
                <div className='card'>
                    <div className='card-body table-responsive recruitment'>
                        <table class="table">
                            <thead style={{ textAlign: 'center' }}>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Bill Address</th>
                                    <th scope="col">Phone Number</th>
                                    <th scope="col">Alternate Number</th>
                                    <th scope="col">Opening  Balance</th>
                                    <th scope="col">Tax</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                {params?.id ? currentSupplier?.map((item, idx) => {
                                    const address = item.bill_address + " " + item.bill_city + " " + item.bill_province + " " + item.bill_country + " " + item.bill_postal ? item.bill_address + " " + item.bill_city + " " + item.bill_province + " " + item.bill_country + " " + item.bill_postal : "-"
                                    const addressParts = [
                                        item.ship_address,
                                        item.ship_city,
                                        item.ship_province,
                                        item.ship_country,
                                        item.ship_postal
                                    ];
                                    const ship_address = addressParts.filter(part => part !== null).join(' ');
                                    const date = item.date?.split(" ")
                                    return (
                                        <>
                                            {item?.company?.id == params?.id && <tr key={item.idx}>
                                                <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                <td>{item.company?.name}</td>
                                                <td>
                                                    <Link to={`/admin/accounts/banking/supplier/${item.id}`} style={{ textDecoration: "none", color: "black" }} className='link_hover'>
                                                        {item.name}
                                                    </Link>
                                                </td>
                                                <td>{item.email}</td>
                                                <td>{address.slice(0, 20)}</td>
                                                <td>{item.mobile}</td>
                                                <td>{item.other}</td>
                                                <td>{item.opening_balance ? item.opening_balance : "-"}</td>
                                                <td>{item.tax ? item.tax : "-"}</td>
                                                <td>
                                                    <button className="btn btn-primary p-1 m-2" onClick={() => {
                                                        setObj(item)
                                                        handleOpen()
                                                    }}>
                                                        <CiEdit style={{ fontSize: "20px" }} />
                                                    </button>
                                                    <button className='btn btn-danger p-1 m-2' onClick={() => deleteSupplierData(item.id)}>
                                                        <CiTrash style={{ fontSize: "20px" }} />
                                                    </button>
                                                </td>
                                            </tr>}
                                        </>

                                    )
                                }) : currentSupplier?.map((item, idx) => {
                                    const address = item.bill_address + " " + item.bill_city + " " + item.bill_province + " " + item.bill_country + " " + item.bill_postal ? item.bill_address + " " + item.bill_city + " " + item.bill_province + " " + item.bill_country + " " + item.bill_postal : "-"
                                    const addressParts = [
                                        item.ship_address,
                                        item.ship_city,
                                        item.ship_province,
                                        item.ship_country,
                                        item.ship_postal
                                    ];
                                    const ship_address = addressParts.filter(part => part !== null).join(' ');
                                    const date = item.date?.split(" ")
                                    return (
                                        <tr key={item.idx}>
                                            <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                            <td>{item.company?.name}</td>
                                            <td>
                                                <Link to={`/admin/accounts/banking/supplier/${item.id}`} style={{ textDecoration: "none", color: "black" }}>
                                                    {item.name}
                                                </Link>
                                            </td>
                                            <td>{item.email}</td>
                                            <td>{address.slice(0, 20)}</td>
                                            <td>{item.mobile}</td>
                                            <td>{item.other}</td>
                                            <td>{item.opening_balance ? item.opening_balance : "-"}</td>
                                            <td>{item.tax ? item.tax : "-"}</td>
                                            <td>
                                                <button className="btn btn-primary p-1 m-2" onClick={() => {
                                                    setObj(item)
                                                    handleOpen()
                                                }}>
                                                    <CiEdit style={{ fontSize: "20px" }} />
                                                </button>
                                                <button className='btn btn-danger p-1 m-2' onClick={() => deleteSupplierData(item.id)}>
                                                    <CiTrash style={{ fontSize: "20px" }} />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div className="pagination-controls">{paginationControls}</div>
                    </div>
                </div>
            </div>
            <Snackbar
                open={anyFieldEmpty}
                autoHideDuration={2000}
                anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}
            >
                <Alert
                    severity="error"
                    style={{
                        backgroundColor: '#8f2018',
                        color: "white",
                    }}
                    onClose={handleCloseSnackbar}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div >

    )
}

export default Supplier
