import React, { useEffect, useRef, useState } from 'react';
import { Tabs, Tooltip } from 'antd';
import { debounce } from 'lodash';
import { useData } from '../ContextProvider/Context';
import { Link, useParams } from 'react-router-dom';
import Board from '@asseinfo/react-kanban'
import '@asseinfo/react-kanban/dist/styles.css'
import { Progress, Space } from 'antd';
import { CiCirclePlus, CiClock2, CiFileOn, CiLink } from 'react-icons/ci';
import { GrUserWorker } from 'react-icons/gr';
import { BiTimeFive } from 'react-icons/bi';
import { MdOutlineLowPriority } from 'react-icons/md';
import { LuSubtitles } from 'react-icons/lu'
import { BsFillImageFill, BsChatSquareText } from 'react-icons/bs'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextArea from 'antd/es/input/TextArea';
import { RxCross1 } from 'react-icons/rx';
import { CiRead, CiEdit, CiTrash } from 'react-icons/ci';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { IoAddCircleOutline } from 'react-icons/io5';
import JoditEditor from 'jodit-react';
import { FaRegAddressCard } from 'react-icons/fa';
import GraphandCharts from './GraphandCharts'
import { FcTodoList } from 'react-icons/fc';
import Home from './Kanban/Home';
import DownloadPDFButton from './DownloadPDFButton ';
import { Alert, IconButton, Menu, Snackbar } from '@mui/material';
const { TabPane } = Tabs;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName?.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px'
};

function ProjectDetail() {
    const theme = useTheme();
    const params = useParams();
    const [personName, setPersonName] = React.useState([]);
    const [open1, setOpen1] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const [ColleboraterData, setColleborater] = useState()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open2, setOpen2] = React.useState(null);
    const open5 = Boolean(anchorEl);
    const handleClick1 = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose5 = () => {
        setAnchorEl(null);
    };
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);
    const [open4, setOpen4] = React.useState(false);
    const handleOpen4 = () => setOpen4(true);
    const handleClose4 = () => setOpen4(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setTaskData({
            title: "",
            empId: "",
            desc: "",
            startDate: new Date().toISOString().split('T')[0],
            file: "",
            priority: "",
            time: "",
            projectId: params?.id,
            user_id: emp_id?.user?.id,
            employee_id: emp_id?.employee?.id,
        })
        setOpen(false)
    };
    const [randomHours, setRandomHours] = useState([]);
    const { deleteTaskFileData, updateProjectData, getAllCrudData, allCrudData, storeTaskFileData, updateTaskFileData, getTaskFileData, base_url, taskFileData, projectIndividual, updateTaskStatus, createTask, getProjectsIndividual, permission, storeProjectComments, img_url, deleteProjectComment, updateTaskData, deleteTaskData, handleClick, setSnackbarValue } = useData();
    const [projectIndividualStatus, setProjectIndividualStatus] = useState(null);
    const emp_id = JSON.parse(localStorage.getItem('user_auth'))
    const [time, settime] = useState("")
    useEffect(() => {
        getProjectsIndividual(params.id);
        getTaskFileData(params.id)
        let totalTime = Math.floor(Math.random() * 60 + 10)
        settime(totalTime)
        getAllCrudData();
        getKeyResource()
    }, []);
    useEffect(() => {
        const generateRandomHours = () => {
            const hours = [];
            for (let i = 0; i < 10; i++) {
                const randomHour = (Math.random() * 24).toFixed(2); // Generates random hours as strings
                hours.push(parseFloat(randomHour)); // Convert to numbers
            }
            setRandomHours(hours);
        };
        generateRandomHours();
    }, []);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedBranch1, setSelectedBranch1] = useState(null);
    useEffect(() => {
        const filteredBranches = allCrudData?.branches?.filter(item => item.companyId === projectIndividual?.data?.companyId);
        setSelectedBranch(filteredBranches)
    }, [allCrudData])

    const filteredEmployee = allCrudData?.employees?.filter(item => item.branchId === selectedBranch1);

    const [taskData, setTaskData] = useState({
        title: "",
        empId: "",
        desc: "",
        startDate: new Date().toISOString().split('T')[0],
        file: "",
        priority: "",
        projectId: params?.id,
        time: "",
        user_id: emp_id?.user?.id,
        employee_id: emp_id?.employee?.id,
    })
    const state = {
        open: false,
        vertical: 'bottom',
        horizontal: 'right',
    }
    const { vertical, horizontal } = state;
    const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const handleUpdateTask = () => {
        if (taskData?.empId?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Employee is required.');
        } else if (taskData?.priority?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Priority is required.');
        } else if (taskData?.title?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Title is required.');
        } else if (taskData?.startDate?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Start Date is required.');
        } else if (taskData?.time?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Time is required.');
        } else if (taskData?.desc?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Description is required.');
        } else {
            updateTaskData(taskData)
            handleClose()
        }
    }
    const handleCreateTask = () => {
        if (taskData?.empId?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Employee is required.');
        } else if (taskData?.priority?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Priority is required.');
        } else if (taskData?.title?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Title is required.');
        } else if (taskData?.startDate?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Start Date is required.');
        } else if (taskData?.time?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Time is required.');
        } else if (taskData?.desc?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Description is required.');
        } else {
            createTask(taskData, params.id)
            handleClose()
        }
    }
    const handleCloseSnackbar = () => {
        setAnyFieldEmpty(false);
    };
    const isFormValid = () => {
        for (const key in taskData) {
            if (key !== "file" && taskData[key] === '') {
                return false; // Return false if any field is empty
            }
        }
        return true; // Return true if all fields have values
    };
    const [emailError, setEmailError] = useState('');
    const validateEmail = (email) => {
        if (/[^0-9]/.test(email)) {
            setEmailError("Invalid input. Please enter a valid hours.")
        }
        else {
            // Show an error message
            setEmailError("")

            // alert('Invalid input. Please enter a valid decimal number.');
        }
    };
    const initialPostCmnt = {
        projectId: params.id,
        userId: emp_id?.user?.id,
        file: '',
        comment: ''
    };
    const initialPostCmnt1 = {
        projectId: params.id,
        empId: emp_id?.employee?.id,
        file: '',
        comment: ''
    };
    const [postCmnt, setPostCmnt] = useState(emp_id?.employee ? initialPostCmnt1 : initialPostCmnt);
    const fileInputRef = useRef(null);
    const handleFileInputChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64String = event.target.result;
                setPostCmnt({ ...postCmnt, file: base64String });
            };
            reader.readAsDataURL(file);
        }
    };
    const sendComment = () => {
        storeProjectComments(postCmnt);
        setPostCmnt(emp_id?.employee ? initialPostCmnt1 : initialPostCmnt);
        fileInputRef.current.value = '';
    };
    const cancelComment = () => {
        setPostCmnt(emp_id?.employee ? initialPostCmnt1 : initialPostCmnt);
        fileInputRef.current.value = '';
    };
    const deleteComment = (id, postCmnt) => {
        deleteProjectComment(id, postCmnt)
    }
    const [open3, setOpen3] = React.useState(false);
    const handleOpen3 = (item) => {
        setOpen3(true);
    };
    const handleClose3 = () => {
        setOpen3(false)
        setTaskFileData({
            projectId: params.id,
            desc: '',
            file: null
        })
    };
    const [taskFilData, setTaskFileData] = useState({
        projectId: params.id,
        desc: '',
        file: null,
        emp_id: emp_id?.employee?.id,
        user_id: emp_id?.user?.id
    })
    const handleCreateFile = () => {
        if (taskFilData?.desc?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Description is required.');
        } else if (!taskFilData?.file) {
            setAnyFieldEmpty(true);
            setSnackbarMessage('File is required.');
        } else {
            storeTaskFileData(taskFilData, params.id);
            handleClose3();
        }
    };
    const handleUpdateFile = () => {
        if (taskFilData?.desc?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Description is required.');
        } else if (!taskFilData?.file) {
            setAnyFieldEmpty(true);
            setSnackbarMessage('File is required.');
        } else {
            updateTaskFileData(taskFilData, params.id);
            handleClose3();
        }
    };

    const handleProjectFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            convertFileToBase64(file)
                .then(base64String => {
                    setTaskFileData({
                        ...taskFilData,
                        file: base64String
                    });
                })
                .catch(error => {
                    console.error('Error converting file to base64:', error);
                });
        }
    };

    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };
    const [showImageOverlay, setShowImageOverlay] = useState(false);
    const [selectedImageURL, setSelectedImageURL] = useState('');
    const [selectedDescription, setSelectedDescription] = useState('');
    const [showDescriptionOverlay, setShowDescriptionOverlay] = useState(false);

    const [data, setData] = useState({ user: [] })

    const editorRef = useRef(null);
    const [keyResource, setkeyResource] = useState({
        link: "",
        link_title: "",
        project_id: params.id,
        description: "",
        title: "",
        emp_id: emp_id?.employee?.id,
        user_id: emp_id?.user?.id

    })
    const debouncedHandleContentChange = debounce((content) => {
        setkeyResource({ ...keyResource, description: content });
    }, 1000);

    const handleContentChange = (e) => {
        debouncedHandleContentChange(e);
    };
    const getKeyResource = () => {
        fetch(`${base_url}key-resources/${params.id}`, {

        })
            .then((res) => res.json())
            .then((data) => {
                setkeyResource(data[0])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const addKeyResource = () => {
        let obj = {
            link: keyResource?.link,
            link_title: keyResource?.link_title,
            project_id: params.id,
            description: keyResource?.description,
            title: keyResource?.title,
            image: keyResource?.image

        }
        fetch(`${base_url}key-resources`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(obj),
        })
            .then((res) => {

            })
            .then((data) => {
                getKeyResource()

                handleClick();
                setSnackbarValue({ message: "key Resource created successfully", color: "#2ECC71" });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    function createMarkup(content) {
        return { __html: content };
    }
    const updateKeyResource = (value) => {
        fetch(`${base_url}key-resources/${value?.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(value),
        })
            .then((res) => {

            })
            .then((data) => {
                getKeyResource()

                handleClick();
                setSnackbarValue({ message: "key Resource Update successfully", color: "#2ECC71" });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const handleChangeKey = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64String = event.target.result;
                setkeyResource({ ...keyResource, image: base64String });

            };
            reader.readAsDataURL(file);
        }
    }
    const [projectData, setProjetcData] = useState({
        users: [],
        project_id: params.id,
        emp_id: emp_id?.employee?.id,
        user_id: emp_id?.user?.id
    })
    useEffect(() => {
        var array = []
        projectIndividual?.data?.users?.map((item) => {
            array.push(item.id)
        })
        setProjetcData({ ...projectData, users: array })
    }, [projectIndividual])
    const addColleborater = () => {
        fetch(`${base_url}project-users/update`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(projectData),
        })
            .then((res) => {

            })
            .then((data) => {
                getProjectsIndividual(params.id);
                handleClose1()

                handleClick();
                setSnackbarValue({ message: "Users Update successfully", color: "#2ECC71" });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const removeColleborater = (project_id, empId) => {
        fetch(`${base_url}colaberateDelete`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                project_id: project_id,
                empId: empId,
                emp_id: emp_id?.employee?.id,
                user_id: emp_id?.user?.id

            }),
        })
            .then((res) => {

            })
            .then((data) => {
                getProjectsIndividual(params.id);
                handleClose1()

                handleClick();
                setSnackbarValue({ message: "Users Update successfully", color: "#2ECC71" });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    const [showMoreList, setShowMoreList] = useState([]);
    const toggleShowMore = (index) => {
        const newList = [...showMoreList];
        newList[index] = !newList[index];
        setShowMoreList(newList);
    };
    const [subBtn, setBtn] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const visibleTasks = projectIndividual?.data?.tasks?.filter(item => item.deleteStatus === 0) || [];

    const currentTasks = visibleTasks?.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(visibleTasks?.length / itemsPerPage);

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button key={i} className='btn btn-primary p-2' style={{ margin: "0px 5px" }} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    const [currentPagep, setCurrentPagep] = useState(1);
    const itemsPerPagep = 10;

    const indexOfLastItemp = currentPagep * itemsPerPagep;
    const indexOfFirstItemp = indexOfLastItemp - itemsPerPagep;

    const visibleTaskFiles = taskFileData?.data?.filter(item => true) || [];


    const currentTaskFiles = visibleTaskFiles?.slice(indexOfFirstItemp, indexOfLastItemp);
    const totalPagesp = Math.ceil(visibleTaskFiles.length / itemsPerPagep);

    const handlePageChangep = page => {
        setCurrentPagep(page);
    };

    const paginationControlsp = [];
    for (let i = 1; i <= totalPagesp; i++) {
        paginationControlsp.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChangep(i)}>
                {i}
            </button>
        );
    }

    return (
        <div className='projectDetailData'>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item" key="home">
                            <Link to={"/admin/dashboard"}>Admin</Link>
                        </li>
                        <li className="breadcrumb-item" key="projects">
                            <Link to={"/admin/projects"}>Project</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page" key="projectView">
                            Project View
                        </li>
                    </ol>
                </nav>
                <Stack direction="row" spacing={1}>
                    {projectIndividual ? projectIndividual?.data?.users?.map((item) => {
                        return (
                            <React.Fragment key={item.id}>
                                <Tooltip title="Colleborater settings">
                                    <IconButton
                                        onClick={(e) => {
                                            handleClick1(e)
                                            setColleborater(item)
                                        }}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={open5 ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open5 ? 'true' : undefined}
                                    >
                                        <Avatar alt="Remy Sharp" src={img_url + item.profile} />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open5}
                                    onClose={handleClose5}
                                    onClick={handleClose5}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&:before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem onClick={handleClose5}>
                                        <Avatar alt="Remy Sharp" src={img_url + ColleboraterData?.profile} />
                                        <Link to={`/admin/employees/profile/${ColleboraterData?.id}`}>
                                            {ColleboraterData?.name}
                                        </Link>
                                    </MenuItem>
                                    <MenuItem onClick={() => removeColleborater(params?.id, ColleboraterData?.id)}>
                                        <Avatar /> Remove Colleborater
                                    </MenuItem>
                                </Menu>

                            </React.Fragment>
                        )
                    }) : ""}
                    <div onClick={handleOpen1} style={{ cursor: "pointer" }}>
                        <Avatar alt="Cindy Baker" src="https://cdn-icons-png.flaticon.com/512/1828/1828817.png" />
                    </div>
                </Stack>
            </div>
            <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='row'>
                        <div className='col-6 p-2'>

                            <div className='form-group'>
                                <label>Branch</label>
                                <div className='selectBox' style={{ position: 'relative' }}>
                                    <select
                                        style={{
                                            border: "none",
                                            width: '100%',
                                            height: '30px'
                                        }}
                                        value={selectedBranch1}
                                        onChange={(e) => {
                                            setProjetcData({ ...projectData, branchId: e.target.value })
                                            setSelectedBranch1(parseInt(e.target.value))
                                        }
                                        }>
                                        <option hidden>Select a branch</option>

                                        {selectedBranch?.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.branch}
                                            </option>
                                        ))}

                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className='col-6 p-2'>
                            <div className='form-group'>
                                {/* <FormControl sx={{ m: 1, width: "100%" }}>
                                        <InputLabel id="demo-multiple-name-label">Name</InputLabel>
                                        <Select
                                            labelId="demo-multiple-name-label"
                                            id="demo-multiple-name"
                                            multiple
                                            value={personName}
                                            onChange={handleChange}
                                            input={<OutlinedInput label="Name" />}
                                            MenuProps={MenuProps}
                                        >
                                            {filteredEmployee.map((item) => (
                                                <MenuItem
                                                    key={item.id}
                                                    value={item.id}
                                                    // style={getStyles(item.name, personName, theme)}
                                                >
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl> */}
                                <label>Employees</label>
                                <div className='selectBox' style={{ position: 'relative' }}>

                                    <select
                                        style={{
                                            border: "none",
                                            width: '100%',
                                            height: '30px'
                                        }}
                                        // multiple
                                        // value={projectData.users}
                                        onChange={(e) => {
                                            if (projectData?.users.includes(parseInt(e.target.value))) {
                                                handleClick()
                                                setSnackbarValue({ message: "The employee is already involved in this project.", color: "#DC4C64" })
                                                setBtn(true)
                                            }
                                            else {
                                                setBtn(false)
                                                const user = projectData?.users
                                                user.push(e.target.value)
                                                setProjetcData({ ...projectData, users: user })
                                            }

                                        }}
                                    >

                                        <option hidden>Select Employee</option>

                                        {selectedBranch1 && (
                                            <>
                                                {filteredEmployee?.map((item) => (
                                                    <option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: "1rem", display: "flex", justifyContent: "end" }}>
                            <button onClick={handleClose1} className='btn mx-2'>Cancel</button>
                            <button disabled={subBtn} onClick={() => addColleborater(projectData)} className='btn btn-primary'>submit</button>

                            {/* <button style={{ marginRight: "1rem" }} className='btn d-flex align-items-center gap-1 ' onClick={handleOpen}><span style={{ fontSize: "12px" }}>Edit Profile</span> <CiCirclePlus style={{ fontSize: "20px", fontWeight: "600" }} className='text-primary' /></button> */}

                        </div>
                    </div>
                </Box>
            </Modal>
            <Tabs defaultActiveKey="1">
                <TabPane tab='Overview' key="1">
                    <div className='overviewData'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='row mb-3'>
                                    <div className='col-md-6 mb-3'>
                                        <div className=' card progressCard border h-100'>
                                            <div className='progressCircle'>
                                                <Space wrap>
                                                    <Progress type="circle" percent={Math.floor(projectIndividual?.performance?.percentage_completed)} />
                                                </Space>
                                            </div>
                                            <div className='validity'>
                                                <div style={{ alignItems: "center", display: "flex", gap: "20px", borderTop: "1px solid lightgray", paddingTop: "14px" }}>
                                                    <p style={{ fontWeight: "bold" }} >Start Date:</p>
                                                    <p>{projectIndividual?.data?.startDate}</p>
                                                </div>
                                                <div style={{ alignItems: "center", display: "flex", gap: "20px", borderTop: "1px solid lightgray", paddingTop: "14px" }}>
                                                    <p style={{ fontWeight: "bold" }} >Deadline:</p>
                                                    <p>{projectIndividual?.data?.deadLine}</p>
                                                </div>
                                                <div style={{ alignItems: "center", display: "flex", gap: "20px", borderTop: "1px solid lightgray", paddingTop: "14px" }}>
                                                    <p style={{ fontWeight: "bold" }} >Title:</p>
                                                    <p>{projectIndividual?.data?.title}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 mb-3'>
                                        <div className='card h-100' style={{ borderRadius: "10px" }}>
                                            <div className='col-12 h-100' style={{ display: "flex", justifyContent: "space-around", alignItems: "center", padding: "1rem 0", flexDirection: "column", gap: "30px" }}>
                                                <div>
                                                    <CiClock2 style={{ fontSize: "10rem", color: "lightgray" }} />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                    <h6 style={{ fontSize: "2rem", fontWeight: "bold" }}>
                                                        {projectIndividual?.performance?.total_hours_completed}
                                                    </h6>
                                                    <p>Total hours worked</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 scrollBar' style={{ height: "600px", overflowY: 'scroll' }}>
                                        <h5 style={{ fontSize: "14px", fontWeight: "bold" }} >Activity</h5>
                                        {projectIndividual?.logs?.map((items) => {
                                            const date = new Date(items.created_at);
                                            const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                                            const formattedDay = daysOfWeek[date.getDay()];
                                            const formattedDate = `${formattedDay}, ${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
                                            const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
                                            return (
                                                <div className='activityLog card my-3 p-2' key={items.id}>
                                                    <div style={{ display: "flex", gap: "10px", }}>
                                                        <div>
                                                            <div style={{ height: "50px", width: "50px", borderRadius: "50%" }}>
                                                                <img style={{ borderRadius: "50%" }} src={items?.employee ? img_url + items?.employee?.profile : img_url + items?.user?.profile} height={"100%"} width={"100%"} />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                                <div>
                                                                    <h5 style={{ fontWeight: "bold", fontSize: "14px" }}>{items.employee?.name ? items.employee?.name : items.user?.name}</h5>
                                                                </div>
                                                                <div>
                                                                    <p className='leads' style={{ marginBottom: "6px" }}>
                                                                        {formattedDate} {formattedTime}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p className='leads mb-0'>{items.log}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='col-12'>
                                    <div className='card'>
                                        <div className='card-header'>
                                            <h4 style={{ fontSize: "15px", fontWeight: "bold" }}>Comments</h4>
                                        </div>
                                        <div className='card-body'>
                                            <div className='row m-0 scrollBar' style={{ maxHeight: "600px", overflowY: 'scroll' }}>
                                                {projectIndividual?.ProjectComment?.map((items, index) => {
                                                    const date = new Date(items.created_at);
                                                    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                                                    const formattedDay = daysOfWeek[date.getDay()];
                                                    const formattedDate = `${formattedDay}, ${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
                                                    const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
                                                    const isLongComment = items.comment && items.comment?.length > 250;
                                                    return (
                                                        <div className='card mb-2 p-2' key={items?.id}>
                                                            <div style={{ display: "flex", gap: "20px" }}>
                                                                <div style={{ display: "flex", gap: "10px", justifyContent: "space-between", width: "100%" }}>
                                                                    <div style={{ display: "flex", gap: "10px" }}>
                                                                        <div>
                                                                            <div style={{ height: "50px", width: "50px", border: "1px solid ", borderRadius: "50%" }}>
                                                                                <img style={{ borderRadius: "50%" }} src={items?.userId ? img_url + items?.user?.profile : img_url + items?.employee_details?.profile} height={"100%"} width={"100%"} />
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div style={{ display: "flex", gap: "10px", alignItems: "end" }}>
                                                                                <div>
                                                                                    <h5 style={{ fontWeight: "bold", fontSize: "14px" }}>{items?.userId ? items?.user?.name : items?.employee_details?.name}</h5>
                                                                                </div>
                                                                                <div style={{ display: "flex", gap: "10px", alignItems: "baseline" }}>
                                                                                    <p className='leads' style={{ marginBottom: "6px", fontSize: "12px" }}>{formattedDate} {formattedTime}</p>
                                                                                    <div className='row'>
                                                                                        {items.file ? (
                                                                                            <Link to={img_url + items.file} download target={'_blank'}>
                                                                                                <CiRead style={{ fontSize: "20px", color: "black" }} /> <span style={{ color: "black" }}>file...</span>
                                                                                            </Link>
                                                                                        ) : null}

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <p className='leads mb-3'>
                                                                                    {showMoreList[index] || !isLongComment ? items.comment : `${items.comment?.substring(0, 250)}`}
                                                                                    {isLongComment && (
                                                                                        <p onClick={() => toggleShowMore(index)} style={{ color: "blue", cursor: "pointer" }}>
                                                                                            {showMoreList[index] ? "Show less" : "Show more"}
                                                                                        </p>
                                                                                    )}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        {emp_id?.user ? (
                                                                            <div className='userDeleteIcon'>
                                                                                <RxCross1 style={{ cursor: "pointer" }} onClick={() => deleteComment(items.id, postCmnt)} />
                                                                            </div>
                                                                        ) : (
                                                                            <div>
                                                                                {emp_id?.employee && (
                                                                                    <div>
                                                                                        {items.empId === emp_id.employee.id ? (
                                                                                            <div>
                                                                                                <RxCross1 style={{ cursor: "pointer" }} onClick={() => deleteComment(items.id, postCmnt)} />
                                                                                            </div>
                                                                                        ) : null}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className='card p-3 my-3'>
                                                <div className="row mb-3">
                                                    <textarea
                                                        className="onfocus scrollBar"
                                                        cols={80}
                                                        rows={4}
                                                        id='textArea2'
                                                        name='textArea2'
                                                        style={{ width: "100%" }}
                                                        value={postCmnt.comment}
                                                        onChange={(e) => setPostCmnt({ ...postCmnt, comment: e.target.value })}
                                                        placeholder="Write..."
                                                    ></textarea>
                                                    <input
                                                        type="file"
                                                        onChange={handleFileInputChange}
                                                        className="form-control onfocus xl_border"
                                                        ref={fileInputRef}
                                                    />
                                                </div>
                                                <div className="row" style={{ display: "flex", gap: "20px" }}>
                                                    <button
                                                        className="btn btn-primary"
                                                        style={{ width: "max-content" }}
                                                        onClick={sendComment}
                                                    >
                                                        Send Comments
                                                    </button>
                                                    <button
                                                        className="btn btn-secondary"
                                                        style={{ width: "max-content" }}
                                                        onClick={cancelComment}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPane>
                {permission.includes("Task View") && <TabPane tab="Task" key="2">
                    <div className='taskData'>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                            <div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                        <li className="breadcrumb-item"><Link to={"/admin/projects"}>Projects</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Task</li>
                                    </ol>
                                </nav>
                            </div>
                            <div className='d-flex justify-content-end mb-2 gap-2'>
                                <div className='text-end'>
                                    {permission.includes("Task Create") && <button className='btn btn-primary' onClick={handleOpen} style={{ padding: ".5rem 1rem" }}>Add</button>}
                                    <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style}>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <label>Employee<span style={{ color: "red" }}>*</span>	</label>
                                                    <div className='selectBox'>
                                                        <label><GrUserWorker /></label>
                                                        <select
                                                            style={{
                                                                border: 'none',
                                                                width: '100%',
                                                                fontSize: '14px'
                                                            }}
                                                            value={taskData?.empId}
                                                            onChange={(e) => setTaskData({ ...taskData, empId: e.target.value })}
                                                        >
                                                            <option hidden>Select Employee</option>
                                                            {projectIndividual ? projectIndividual?.data?.users?.map((item) => {
                                                                return (
                                                                    <option value={item.id} key={item.id}>{item.name}</option>
                                                                )
                                                            }) : ""}
                                                        </select>
                                                    </div>
                                                    <label>Title<span style={{ color: "red" }}>*</span>	</label>
                                                    <div className='inputBox mt-2 gap-2'>
                                                        <label><LuSubtitles /></label>
                                                        <input
                                                            id="task-my1"
                                                            style={{
                                                                border: 'none',
                                                                width: '100%',
                                                                fontSize: '14px'
                                                            }}
                                                            type='text'
                                                            value={taskData?.title}
                                                            placeholder="Title"
                                                            onChange={(e) => setTaskData({ ...taskData, title: e.target.value })}
                                                        />
                                                    </div>
                                                    <label>Time<span style={{ color: "red" }}>*</span></label>
                                                    <div className='inputBox mt-2 gap-2'>
                                                        <label><BiTimeFive /></label>
                                                        <input
                                                            id="task-my2"
                                                            style={{
                                                                border: 'none',
                                                                width: '100%',
                                                                fontSize: '14px'
                                                            }}
                                                            type='number'
                                                            value={taskData?.time}
                                                            placeholder="Time in Hours"
                                                            onChange={(e) => setTaskData({ ...taskData, time: e.target.value })}
                                                            onBlur={(e) => validateEmail(e.target.value)}
                                                        />
                                                    </div>
                                                    {emailError && <span style={{ color: 'red', fontSize: "10px", fontWeight: "bold", textAlign: "left" }}>{emailError}</span>}
                                                    <label>Description<span style={{ color: "red" }}>*</span>	</label>
                                                    <div className='textarea mt-2'>
                                                        <label><BsChatSquareText /></label>
                                                        <TextArea
                                                            style={{
                                                                border: 'none',
                                                                height: '100px'
                                                            }}
                                                            id='descTextArea'
                                                            name='descTextArea'
                                                            value={taskData?.desc}
                                                            onChange={(e) => setTaskData({ ...taskData, desc: e.target.value })}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='col-6'>
                                                    <label>Priority<span style={{ color: "red" }}>*</span>	</label>
                                                    <div className='selectBox'>
                                                        <label><MdOutlineLowPriority /></label>
                                                        <select
                                                            style={{
                                                                border: 'none',
                                                                width: '100%',
                                                                fontSize: '14px'
                                                            }}
                                                            value={taskData?.priority}
                                                            onChange={(e) => setTaskData({ ...taskData, priority: e.target.value })}
                                                        >
                                                            <option hidden>Select priority</option>
                                                            <option value={0}>Low</option>
                                                            <option value={1}>Medium</option>
                                                            <option value={3}>High</option>
                                                        </select>
                                                    </div>
                                                    <label>Start Date<span style={{ color: "red" }}>*</span>	</label>
                                                    <div className='inputBox mt-2'>
                                                        <input
                                                            style={{
                                                                border: 'none',
                                                                width: '100%',
                                                                fontSize: '14px'
                                                            }}
                                                            value={taskData?.startDate}
                                                            onChange={(e) => setTaskData({ ...taskData, startDate: e.target.value })}
                                                            type='date'
                                                        />
                                                    </div>
                                                    <label>File</label>
                                                    <div className='inputBox mt-2'>
                                                        <label for='taskFile'><BsFillImageFill /></label>
                                                        <input
                                                            style={{
                                                                display: 'block'
                                                            }}
                                                            classname='form-control'
                                                            placeholder='File'
                                                            onChange={(e) => {
                                                                const file = e.target.files[0];
                                                                if (file) {
                                                                    const reader = new FileReader();
                                                                    reader.onload = (event) => {
                                                                        const base64String = event.target.result;
                                                                        const parts = base64String
                                                                        // setObj({ ...obj, file: parts });
                                                                        setTaskData({ ...taskData, file: base64String })
                                                                    };
                                                                    reader.readAsDataURL(file);
                                                                }
                                                            }}
                                                            type='file'
                                                            id='taskFile'
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>

                                                <button onClick={() => { taskData?.id ? handleUpdateTask() : handleCreateTask() }} className='btn btn-primary'> {taskData?.id ? "Update" : "Submit"}</button>
                                                <button className='btn btn-danger' onClick={handleClose}>
                                                    Cancel
                                                </button>
                                            </div>
                                        </Box>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='row'>
                            <div className='card'>
                                <div className="card-body table-responsive recruitment">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Title</th>
                                                <th scope="col">Start Date</th>
                                                <th scope="col">Assign To</th>
                                                <th scope="col">Total Time</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentTasks.length ? (
                                                currentTasks.map((item, idx) => (
                                                    <tr key={item.id}>
                                                        <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                        <td>
                                                            <Link to={`/admin/task/${item.id}`} style={{ color: "black", textDecoration: "none" }} className='link_hover'>
                                                                {item.title.length > 20 ? `${item.title.slice(0, 20)}...` : item.title}
                                                            </Link>
                                                        </td>
                                                        <td>{item.startDate}</td>
                                                        <td>
                                                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                                <div style={{ height: "30px", width: "30px", borderRadius: "50%" }}>
                                                                    <img src={item?.employee?.profile !== null ? img_url + item?.employee?.profile : "https://newvintagewi.org/wp-content/uploads/2021/07/final-resized-male-image.jpg"} width={"100%"} height={"100%"} />
                                                                </div>
                                                                <div>
                                                                    <Link to={`/admin/employees/profile/${item.employee?.id}`} style={{ textDecoration: "none", color: "black" }}>
                                                                        {item.employee?.name}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{item.time} Hours</td>
                                                        <td>
                                                            <span style={{ borderRadius: "30px", padding: ".3rem 1rem", background: `${item.status == 'completed' ? "green" : "orange"}`, color: "white" }}>
                                                                {item.kanban_status?.name}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="btn p-1 m-2 btn-primary"
                                                                onClick={() => {
                                                                    handleOpen();
                                                                    setTaskData(item);
                                                                }}
                                                            >
                                                                <CiEdit style={{ fontSize: "20px" }} />
                                                            </button>
                                                            <button
                                                                className="btn p-1 m-2 btn-danger"
                                                                onClick={() => deleteTaskData(item.id, params.id)}
                                                            >
                                                                <CiTrash style={{ fontSize: "20px" }} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <th style={{ textAlign: "center" }} colSpan="7">
                                                        No Data
                                                    </th>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <div className="pagination-controls">{paginationControls}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPane>}
                {permission.includes("Files View") && <TabPane tab="Files" key="3">
                    <div className='fileData'>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                            <div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                        <li className="breadcrumb-item"><Link to={"/admin/projects"}>Projects</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">File</li>
                                    </ol>
                                </nav>
                            </div>
                            <div className='d-flex justify-content-end mb-2 gap-2'>
                                <div className='text-end'>
                                    {permission.includes("Files Create") && <button className='btn btn-primary' onClick={handleOpen3} style={{ padding: ".5rem 1rem" }}>Add</button>}
                                </div>
                                <Modal
                                    open={open3}
                                    onClose={handleClose3}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={style}>
                                        <div id="modal-modal-description" sx={{ mt: 2 }}>
                                            <div className='row'>
                                                <div className='col-md-6 mb-3'>
                                                    <div className='inputFields'>
                                                        <label>Description<span style={{ color: "red" }}>*</span>	</label>
                                                        <div className='inputBox mt-3'>
                                                            <input placeholder='Description' value={taskFilData.desc} type='text' onChange={(e) => setTaskFileData({ ...taskFilData, desc: e.target.value })}></input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='inputFields'>
                                                        <label>File<span style={{ color: "red" }}>*</span>	</label>
                                                        <div className='inputBox mt-3'>
                                                            <input type='file' className='form-control' value={taskFilData?.file?.name} onChange={handleProjectFileChange}></input>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-4'>
                                                <div className='modalBtns d-flex justify-content-end gap-2'>
                                                    <div style={{ display: "flex", justifyContent: "end" }}>
                                                        <button onClick={() => {
                                                            taskFilData?.id ? handleUpdateFile() : handleCreateFile()
                                                        }} className='btn btn-primary'>
                                                            {taskFilData?.id ? "Update" : "Submit"}
                                                        </button>
                                                    </div>
                                                    <button className='btn btn-secondary' onClick={handleClose3}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Box>
                                </Modal>
                            </div>
                        </div>
                        <hr />
                        <div className='row'>
                            <div className='card'>
                                <div className="card-body">
                                    <div className="card-body">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">File</th>
                                                    <th scope="col">Operation</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentTaskFiles.length ? (
                                                    currentTaskFiles?.map((item, idx) => {
                                                        const truncatedDescription = item.desc.slice(0, 20);
                                                        const isDescriptionTruncated = item.desc.length > 20;

                                                        return (
                                                            <tr key={item.id}>
                                                                <th scope="row">{indexOfFirstItemp + idx + 1}</th>
                                                                <td>
                                                                    {isDescriptionTruncated ? (
                                                                        <span
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => {
                                                                                setSelectedDescription(item.desc);
                                                                                setShowDescriptionOverlay(true);
                                                                            }}
                                                                        >
                                                                            {truncatedDescription}...
                                                                        </span>
                                                                    ) : (
                                                                        item.desc
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <DownloadPDFButton buttonText={"View File"} cv={item.file} pdfUrl={img_url + item.file} fileName={"taskFile"} />
                                                                </td>
                                                                {showDescriptionOverlay && (
                                                                    <div className="overlayy" style={{ background: "rgba(0,0,0,0.38)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                                        <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                                            <p style={{ color: "white" }}>{selectedDescription}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {showImageOverlay && (
                                                                    <div className="overlayy" style={{ background: "rgba(0,0,0,0.38)" }} onClick={() => setShowImageOverlay(false)}>
                                                                        <img
                                                                            src={selectedImageURL}
                                                                            alt="Full-Screen Image"
                                                                            style={{ maxHeight: '100%', maxWidth: '100%' }}
                                                                        />
                                                                    </div>
                                                                )}
                                                                <td>
                                                                    <p className="text-dark" style={{ margin: ".5rem", display: "flex", alignItems: "center", gap: "5px" }}>
                                                                        <button
                                                                            className="btn p-1 m-2 btn-primary"
                                                                            onClick={() => {
                                                                                handleOpen3();
                                                                                setTaskFileData(item);
                                                                            }}
                                                                        >
                                                                            <CiEdit style={{ fontSize: "20px" }} />
                                                                        </button>
                                                                        <button
                                                                            className="btn p-1 m-2 btn-danger"
                                                                            onClick={() => deleteTaskFileData(item.id)}
                                                                        >
                                                                            <CiTrash style={{ fontSize: "20px" }} />
                                                                        </button>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr>
                                                        <th style={{ textAlign: "center" }} colSpan="4">
                                                            No Data
                                                        </th>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        <div className="pagination-controls">{paginationControlsp}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPane>}
                {permission.includes("Kanban") && <TabPane tab="Board" key="4">
                    <div className='kanbanData'>
                        <div className='card'>
                            <div className='card-body'>
                                <Home />
                                {/* <Board initialBoard={board} onCardDragEnd={handleCardDragEnd} /> */}
                            </div>
                        </div>
                    </div>
                </TabPane>}
                {permission.includes("key Resources") && <TabPane tab="key Resources" key="5`">
                    <div className='card'>
                        <div className='card-body'>
                            <div style={{ display: "flex", justifyContent: "end" }}>
                                {/* <button onClick={handleOpen2} className='btn btn-primary mx-2'> <CiLink style={{ fontSize: "20px", marginRight: "3px" }} />Add Link</button> */}
                                <button onClick={handleOpen4} className='btn btn-primary mx-2'><IoAddCircleOutline style={{ fontSize: "20px", marginRight: "3px" }} />Add Resources</button>


                            </div>
                            <div style={{ marginTop: "1rem" }} className='row'>
                                <div className='col-8'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <h6>key Resources</h6>
                                            <div dangerouslySetInnerHTML={createMarkup(keyResource?.description)}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className='card'>
                                        <div className='card-body'>
                                            <h6>key Resources</h6>
                                            {keyResource?.link && <div className='row my-2' style={{ padding: ".5rem", background: "lightgray", borderRadius: "10px", cursor: "pointer" }}>
                                                <div style={{ padding: " 0" }} className='col-2'>
                                                    <div style={{ height: "50px", width: "50px", borderRadius: "50%", overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <CiLink style={{ fontSize: "25px" }} />
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex" }} className='col-9'>
                                                    <div>

                                                        <h6 style={{ margin: "0", whiteSpace: "nowrap" }}>{keyResource?.link_title}</h6>
                                                        <a href={keyResource?.link} target='_blank' style={{ margin: "0" }}>Link</a>
                                                    </div>

                                                </div>
                                            </div>}
                                            {keyResource?.image && <img style={{ height: "150px", width: "150px", borderRadius: "10px" }} src={img_url + keyResource?.image} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal
                            open={open4}
                            onClose={handleClose4}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <div style={{ height: "70vh", overflowY: "auto", overflowX: "hidden" }}>

                                    <div id="modal-modal-description" sx={{ mt: 2 }}>
                                        <label for="keyResource" className='btn btn-primary mx-2'><CiFileOn style={{ fontSize: "20px", marginRight: "3px" }} />Add File</label>
                                        <input type='file' id='keyResource' style={{ display: "none" }} accept='' onChange={handleChangeKey} />
                                        <div className='row'>
                                            <div className='col-md-6 mb-3'>
                                                {/* <div style={{border:"none"}} className='inputFields'> */}
                                                <div className='inputBox mt-3'>
                                                    <label><FaRegAddressCard style={{ fontSize: "20px", marginRight: "3px" }} /></label>
                                                    <input style={{ outline: "none" }}
                                                        onChange={(e) => {
                                                            setkeyResource({ ...keyResource, link_title: e.target.value });
                                                        }
                                                        } placeholder='Title' value={keyResource?.link_title} type='text' />
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                            <div className='col-md-6'>

                                                <div className='inputBox mt-3'>
                                                    <label><CiLink style={{ fontSize: "20px", marginRight: "3px" }} /></label>
                                                    <input style={{ outline: "none" }} value={keyResource?.link} placeholder='Link' type='text'
                                                        onChange={(e) => {
                                                            setkeyResource({ ...keyResource, link: e.target.value });
                                                        }
                                                        } className='form-control' />

                                                </div>
                                            </div>
                                            <div className='col-md-12 mb-2'>

                                                <div className='inputBox mt-3'>
                                                    <label><FaRegAddressCard style={{ fontSize: "20px", marginRight: "3px" }} /></label>
                                                    <input value={keyResource?.title} style={{ outline: "none" }} onChange={(e) => {
                                                        setkeyResource({ ...keyResource, title: e.target.value });

                                                    }
                                                    } placeholder='Resources Title' type='text' className='form-control' />

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <JoditEditor
                                        ref={editorRef}
                                        value={keyResource?.description}
                                        onChange={handleContentChange}
                                    />
                                </div>
                                <div className='row mt-4'>
                                    <div className='modalBtns d-flex justify-content-end gap-2'>
                                        <div style={{ display: "flex", justifyContent: "end" }}>
                                            <button onClick={() => {
                                                keyResource?.id ? updateKeyResource(
                                                    {
                                                        created_at: keyResource?.created_at,
                                                        description: keyResource?.description,
                                                        id: keyResource?.id,
                                                        image: keyResource?.image,
                                                        link: keyResource?.link,
                                                        link_title: keyResource?.link_title,
                                                        project_id: keyResource?.project_id,
                                                        title: keyResource?.title,
                                                        updated_at: keyResource?.updated_at,
                                                        emp_id: emp_id?.employee?.id,
                                                        user_id: emp_id?.user?.id
                                                    }
                                                ) : addKeyResource(keyResource, params.id)
                                                handleClose4()
                                            }} className='btn btn-primary'>
                                                {keyResource?.id ? "Update" : "Submit"}
                                            </button>
                                        </div>
                                        <button className='btn btn-secondary' onClick={handleClose4}>Cancel</button>
                                    </div>
                                </div>
                            </Box>
                        </Modal>

                    </div>
                </TabPane>}
            </Tabs>
            <Snackbar
                open={anyFieldEmpty}
                autoHideDuration={2000}
                anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}
            >
                <Alert
                    severity="error"
                    style={{
                        backgroundColor: '#8f2018',
                        color: "white",
                    }}
                    onClose={handleCloseSnackbar}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default ProjectDetail;
