import React, { useEffect, useState } from 'react';
import { useData } from '../../ContextProvider/Context';
import { Link } from 'react-router-dom';
import { Alert, Box, Snackbar, TextField } from '@mui/material';
import { Modal, Form } from 'antd';
import { CiBank, CiCalendarDate, CiClock2, CiCreditCard2, CiDollar, CiGlobe, CiMap, CiMoneyCheck1, CiRoute, CiStar, CiUser } from 'react-icons/ci';
import { PiGraduationCapThin } from 'react-icons/pi';
import { MdCastForEducation } from 'react-icons/md';
function JobList() {
    const { handleClick, setSnackbarValue, getCompnayCrud, jobData, getAllJob, createJob } = useData();
    const [isCreateAccount, setIsCreateAccount] = useState(false);
    const [isCreateTransfer, setIsCreateTransfer] = useState(false);
    const [dataJob, setCreateJob] = useState({
        title: "",
        description: "",
        state: "",
        city: "",
        country: "",
        experience: "",
        salary: "",
        skills: "",
        industry: "",
        functional_area: "",
        total_positions: "",
        job_sift: "",
        job_type: "",
        gender: "",
        education: "",
        career_level: "",
        apply_before: "",
        posting_date: new Date().toISOString().split('T')[0]
    })
    useEffect(() => {
        getCompnayCrud()
        getAllJob()
    }, []);
    const handleCancel = () => {
        setIsCreateAccount(false);
    };
    const showCreateTransfer = () => {
        setIsCreateTransfer(true);
    };
    const handleTransferCancel = () => {
        setIsCreateTransfer(false);
    };
    const isFormValid = () => {
        for (const key in dataJob) {
            if (dataJob[key] === '') {
                return false;
            }
        }
        return true;
    };
    // const handleAddDepartment = () => {
    //     if (obj?.companyId?.trim() === '') {
    //         setAnyFieldEmpty(true);
    //         setSnackbarMessage('Company is required.');
    //     } else if (obj?.branchId?.trim() === '') {
    //         setAnyFieldEmpty(true);
    //         setSnackbarMessage('Branch is required.');
    //     } else if (obj?.department?.trim() === '') {
    //         setAnyFieldEmpty(true);
    //         setSnackbarMessage('Department is required.');
    //     } else {
    //         storeDepartmentData(obj)
    //         handleClose()
    //     }
    // }
    const state = {
        open: false,
        vertical: 'bottom',
        horizontal: 'right',
    }
    const { vertical, horizontal } = state;
    const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const submitHandle = (e) => {
        if (dataJob?.title?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Title is required.');
        } else if (dataJob?.state?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('State is required.');
        } else if (dataJob?.city?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('City is required.');
        } else if (dataJob?.country?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Country is required.');
        } else if (dataJob?.experience?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Experience is required.');
        } else if (dataJob?.salary?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Salary is required.');
        } else if (dataJob?.skills?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Skill is required.');
        } else if (dataJob?.industry?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Industry is required.');
        } else if (dataJob?.functional_area?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Functional Area is required.');
        } else if (dataJob?.total_positions?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Total Position is required.');
        } else if (dataJob?.job_sift?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Job Shift is required.');
        } else if (dataJob?.job_type?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Job Type is required.');
        } else if (dataJob?.gender?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Gender is required.');
        } else if (dataJob?.education?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Education is required.');
        } else if (dataJob?.career_level?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Career Level is required.');
        } else if (dataJob?.apply_before?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Apply Before is required.');
        } else if (dataJob?.posting_date?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Posting Date is required.');
        } else if (dataJob?.description?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Description is required.');
        } else {
            createJob(dataJob)
            handleCancel()
            handleTransferCancel()
            setCreateJob({
                title: "",
                description: "",
                state: "",
                city: "",
                country: "",
                experience: "",
                salary: "",
                skills: "",
                industry: "",
                functional_area: "",
                total_positions: "",
                job_sift: "",
                job_type: "",
                gender: "",
                education: "",
                career_level: "",
                apply_before: "",
                posting_date: new Date().toISOString().split('T')[0]
            })
        }
    }

    const handleCloseSnackbar = () => {
        setAnyFieldEmpty(false);
    };
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentJobs = jobData?.slice(indexOfFirstItem, indexOfLastItem) || [];

    const totalPages = Math.ceil((jobData?.length || 0) / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    return (
        <>
            <div className='employeeData'>
                <div classname="row " style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Jobs</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='d-flex justify-content-end mb-2 gap-2'>
                        <div>
                            <button className='btn btn-primary' onClick={showCreateTransfer} style={{ padding: "8px 16px" }}>
                                Create Job
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                title="Create Job"
                open={isCreateTransfer}
                onCancel={handleTransferCancel}
                onOk={() => {
                    submitHandle()
                }}
                width={1000}
            >
                <Form name="create_transfer">
                    <div className="row create_job">
                        <div className='col-4'>
                            <label style={{ fontWeight: "bold", fontSize: "15px" }}>Title<span style={{ color: "red" }}>*</span></label>
                            <div className='inp_box mt-0 mb-3'>
                                <label>
                                    <CiMoneyCheck1 style={{ fontSize: "25px", color: "gray" }} />
                                </label>
                                <input onChange={(e) => setCreateJob({ ...dataJob, title: e.target.value })} value={dataJob.title} type='text' placeholder='Title' />
                            </div>
                            <label style={{ fontWeight: "bold", fontSize: "15px" }}>State<span style={{ color: "red" }}>*</span></label>
                            <div className='inp_box mt-0 mb-3'>
                                <label>
                                    <CiMap style={{ fontSize: "25px", color: "gray" }} />

                                </label>
                                <input onChange={(e) => setCreateJob({ ...dataJob, state: e.target.value })} value={dataJob.state} type='text' placeholder='State' />
                            </div>
                            <label style={{ fontWeight: "bold", fontSize: "15px" }}>City<span style={{ color: "red" }}>*</span></label>
                            <div className='inp_box mt-0 mb-3'>
                                <label>
                                    <CiMap style={{ fontSize: "25px", color: "gray" }} />

                                </label>
                                <input onChange={(e) => setCreateJob({ ...dataJob, city: e.target.value })} value={dataJob.city} type='text' placeholder='City' />
                            </div>
                            <label style={{ fontWeight: "bold", fontSize: "15px" }}>Country<span style={{ color: "red" }}>*</span></label>
                            <div className='inp_box mt-0 mb-3'>
                                <label>
                                    <CiGlobe style={{ fontSize: "25px", color: "gray" }} />

                                </label>
                                <input onChange={(e) => setCreateJob({ ...dataJob, country: e.target.value })} value={dataJob.country} type='text' placeholder='Country' />
                            </div>
                            <label style={{ fontWeight: "bold", fontSize: "15px" }}>Experience<span style={{ color: "red" }}>*</span></label>
                            <div className='inp_box mt-0 mb-3'>
                                <label>
                                    <CiStar style={{ fontSize: "25px", color: "gray" }} />

                                </label>
                                <input onChange={(e) => setCreateJob({ ...dataJob, experience: e.target.value })} value={dataJob.experience} type='text' placeholder='Experience' />
                            </div>
                            <label style={{ fontWeight: "bold", fontSize: "15px" }}>Salary<span style={{ color: "red" }}>*</span></label>
                            <div className='inp_box mt-0 mb-3'>
                                <label>
                                    <CiDollar style={{ fontSize: "25px", color: "gray" }} />

                                </label>
                                <input onChange={(e) => setCreateJob({ ...dataJob, salary: e.target.value })} value={dataJob.salary} type='number' placeholder='Salary' />
                            </div>
                        </div>
                        <div className='col-4'>
                            <label style={{ fontWeight: "bold", fontSize: "15px" }}>Skills<span style={{ color: "red" }}>*</span></label>
                            <div className='inp_box mt-0 mb-3'>
                                <label>
                                    <CiRoute style={{ fontSize: "25px", color: "gray" }} />

                                </label>
                                <input onChange={(e) => setCreateJob({ ...dataJob, skills: e.target.value })} value={dataJob.skills} type='text' placeholder='Skills' />
                            </div>
                            <label style={{ fontWeight: "bold", fontSize: "15px" }}>Industry<span style={{ color: "red" }}>*</span></label>
                            <div className='inp_box mt-0 mb-3'>
                                <label>
                                    <CiBank style={{ fontSize: "25px", color: "gray" }} />

                                </label>
                                <input onChange={(e) => setCreateJob({ ...dataJob, industry: e.target.value })} value={dataJob.industry} type='text' placeholder='Industry' />
                            </div>
                            <label style={{ fontWeight: "bold", fontSize: "15px" }}>Functional Area<span style={{ color: "red" }}>*</span></label>
                            <div className='inp_box mt-0 mb-3'>
                                <label>
                                    <CiMap style={{ fontSize: "25px", color: "gray" }} />

                                </label>
                                <input onChange={(e) => setCreateJob({ ...dataJob, functional_area: e.target.value })} value={dataJob.functional_area} type='text' placeholder='Functional Area' />
                            </div>
                            <label style={{ fontWeight: "bold", fontSize: "15px" }}>Total Positions<span style={{ color: "red" }}>*</span></label>
                            <div className='inp_box mt-0 mb-3'>
                                <label>
                                    <CiUser style={{ fontSize: "25px", color: "gray" }} />

                                </label>
                                <input onChange={(e) => setCreateJob({ ...dataJob, total_positions: e.target.value })} value={dataJob.total_positions} type='number' placeholder='Total Position' />
                            </div>
                            <label style={{ fontWeight: "bold", fontSize: "15px" }}>Job Shift<span style={{ color: "red" }}>*</span></label>
                            <div className='inp_box mt-0 mb-3'>
                                <label>
                                    <CiClock2 style={{ fontSize: "25px", color: "gray" }} />

                                </label>
                                <input onChange={(e) => setCreateJob({ ...dataJob, job_sift: e.target.value })} value={dataJob.job_sift} type='text' placeholder='Job Shift' />
                            </div>
                            <label style={{ fontWeight: "bold", fontSize: "15px" }}>Job Type<span style={{ color: "red" }}>*</span></label>
                            <div className='inp_box mt-0 mb-3'>
                                <label>
                                    <CiCreditCard2 style={{ fontSize: "25px", color: "gray" }} />

                                </label>
                                <input onChange={(e) => setCreateJob({ ...dataJob, job_type: e.target.value })} value={dataJob.job_type} type='text' placeholder='Job Type' />
                            </div>
                        </div>
                        <div className='col-4'>
                            <label style={{ fontWeight: "bold", fontSize: "15px" }}>Gender<span style={{ color: "red" }}>*</span></label>
                            <div className='inp_box mt-0 mb-3' style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                <label>
                                    <CiUser style={{ fontSize: "25px", color: "gray" }} />
                                </label>
                                <select className='form-control border-0' onChange={(e) => setCreateJob({ ...dataJob, gender: e.target.value })} value={dataJob.gender} >
                                    <option hidden>Select Gender</option>
                                    <option value={'male'}>Male</option>
                                    <option value={'female'}>Female</option>
                                    <option value={'both'}>Both</option>
                                </select>
                            </div>
                            <label style={{ fontWeight: "bold", fontSize: "15px" }}>Education<span style={{ color: "red" }}>*</span></label>
                            <div className='inp_box mt-0 mb-3'>
                                <label>
                                    <PiGraduationCapThin style={{ fontSize: "25px", color: "gray" }} />

                                </label>
                                <input onChange={(e) => setCreateJob({ ...dataJob, education: e.target.value })} value={dataJob.education} type='text' placeholder='Education' />
                            </div>
                            <label style={{ fontWeight: "bold", fontSize: "15px" }}>Career Level<span style={{ color: "red" }}>*</span></label>
                            <div className='inp_box mt-0 mb-3'>
                                <label>
                                    <MdCastForEducation style={{ fontSize: "25px", color: "gray" }} />

                                </label>
                                <input onChange={(e) => setCreateJob({ ...dataJob, career_level: e.target.value })} value={dataJob.career_level} type='text' placeholder='Career Level' />
                            </div>
                            <label style={{ fontWeight: "bold", fontSize: "15px" }}>Before Apply<span style={{ color: "red" }}>*</span></label>
                            <div className='inp_box mt-0 mb-3'>
                                <label>
                                    <CiCalendarDate style={{ fontSize: "25px", color: "gray" }} />
                                </label>
                                <input onChange={(e) => setCreateJob({ ...dataJob, apply_before: e.target.value })} value={dataJob.apply_before} type='date' placeholder='Apply Before' />
                            </div>
                            <label style={{ fontWeight: "bold", fontSize: "15px" }}>Posting date<span style={{ color: "red" }}>*</span></label>
                            <div className='inp_box mt-0 mb-3'>
                                <label>
                                    <CiCalendarDate style={{ fontSize: "25px", color: "gray" }} />
                                </label>
                                <input min={new Date().toISOString().split('T')[0]} onChange={(e) => setCreateJob({ ...dataJob, posting_date: e.target.value })} value={dataJob.posting_date} type='date' placeholder='Posting Date' />
                            </div>

                        </div>
                        <div className='col-12'>
                            <label style={{ fontWeight: "bold", fontSize: "15px" }}>Description<span style={{ color: "red" }}>*</span></label>
                            <div className='inp_box mt-0 mb-3'>
                                <textarea style={{ border: "none", background: "none", outline: "none" }} onChange={(e) => setCreateJob({ ...dataJob, description: e.target.value })} value={dataJob.description} type='text' placeholder='Discription' />
                            </div>
                        </div>

                    </div>
                </Form>
            </Modal>
            <Modal
                title="Add"
                open={isCreateAccount}
                onCancel={handleCancel}
                width={1000}
            >
                <Form name="edit_personal_details">
                    <div className="row">
                        <div className='col-md-4'>
                            <div className='border p-3 h-100 ' style={{ borderRadius: "10px" }}>
                                <div className="mb-2">
                                    <div className="form-group">
                                        <Box
                                            component="form"
                                            sx={{
                                                '& > :not(style)': { m: 0, width: '100%' },
                                                '& label.MuiInputLabel-root': { fontSize: '14px' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <TextField
                                                id="text"
                                                label="Company Name"
                                                variant="standard"
                                                type='text'
                                            />
                                        </Box>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <div className="form-group">
                                        <Box
                                            component="form"
                                            sx={{
                                                '& > :not(style)': { m: 0, width: '100%' },
                                                '& label.MuiInputLabel-root': { fontSize: '14px' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <TextField
                                                id="text"
                                                label="Opening Balance"
                                                variant="standard"
                                                type='number'
                                            />
                                        </Box>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <div className="form-group">
                                        <Box
                                            component="form"
                                            sx={{
                                                '& > :not(style)': { m: 0, width: '100%' },
                                                '& label.MuiInputLabel-root': { fontSize: '14px' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <TextField
                                                id="text"
                                                label="Bank Address"
                                                variant="standard"
                                                type='text'
                                            />
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='border p-3 h-100 ' style={{ borderRadius: "10px" }}>
                                <div className="mb-2">
                                    <div className="form-group">
                                        <Box
                                            component="form"
                                            sx={{
                                                '& > :not(style)': { m: 0, width: '100%' },
                                                '& label.MuiInputLabel-root': { fontSize: '14px' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <TextField
                                                id="text"
                                                label="Bank Name"
                                                variant="standard"
                                                type='text'
                                            />
                                        </Box>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <div className="form-group">
                                        <Box
                                            component="form"
                                            sx={{
                                                '& > :not(style)': { m: 0, width: '100%' },
                                                '& label.MuiInputLabel-root': { fontSize: '14px' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <TextField
                                                id="text"
                                                label="Contact Number"
                                                variant="standard"
                                                type='number'
                                            />
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='border p-3 h-100 ' style={{ borderRadius: "10px" }}>
                                <div className="mb-2">
                                    <div className="form-group">
                                        <Box
                                            component="form"
                                            sx={{
                                                '& > :not(style)': { m: 0, width: '100%' },
                                                '& label.MuiInputLabel-root': { fontSize: '14px' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <TextField
                                                id="text"
                                                label="Account Number"
                                                variant="standard"
                                                type='number'
                                            />
                                        </Box>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <div className="form-group">
                                        <Box
                                            component="form"
                                            sx={{
                                                '& > :not(style)': { m: 0, width: '100%' },
                                                '& label.MuiInputLabel-root': { fontSize: '14px' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <TextField
                                                id="text"
                                                label="I-BAN"
                                                variant="standard"
                                                type='number'
                                            />
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Form.Item>
                        <button className='btn btn-primary mt-3' type="button" >
                            Submit
                        </button>
                    </Form.Item>
                </Form>
            </Modal>
            <hr />
            <div className='row'>
                <div className='card'>
                    <div className="card-body recruitment table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Industry</th>
                                    <th scope="col">Designation</th>
                                    <th scope="col">City</th>
                                    <th scope="col">Country</th>
                                    <th scope="col">Experience</th>
                                    <th scope="col">Total Position</th>
                                    <th scope="col">Job Shift</th>
                                    <th scope="col">Job Type</th>
                                    <th scope="col">Salary</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentJobs.length > 0 ? (
                                    currentJobs?.map((item, i) => (
                                        <tr key={i}>
                                            <th scope="row">{indexOfFirstItem + i + 1}</th>
                                            <td scope="col">{item.industry}</td>
                                            <td scope="col">
                                                <Link
                                                    to={`/admin/dashboard/chat/applicant/${item.id}`}
                                                    style={{ textDecoration: "none", color: "black" }}
                                                    className='link_hover'
                                                >
                                                    {item.title}
                                                </Link>
                                            </td>
                                            <td scope="col">{item.city}</td>
                                            <td scope="col">{item.country}</td>
                                            <td scope="col">{item.experience}</td>
                                            <td scope="col">{item.total_positions}</td>
                                            <td scope="col">{item.job_sift}</td>
                                            <td scope="col">{item.job_type}</td>
                                            <td scope="col">{item.salary}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="10" style={{ textAlign: "center" }}>
                                            No Data
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div>
                            {paginationControls.map((button, index) => (
                                <React.Fragment key={index}>{button}</React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar
                open={anyFieldEmpty}
                autoHideDuration={2000}
                anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}
            >
                <Alert
                    severity="error"
                    style={{
                        backgroundColor: '#8f2018',
                        color: "white",
                    }}
                    onClose={handleCloseSnackbar}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default JobList;
