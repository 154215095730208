import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useData } from '../ContextProvider/Context';
import { useNavigate } from 'react-router-dom';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 2,
  };
  
  function DeleteEmployee({id}) {
    const navigate=useNavigate()
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const {base_url}=useData()
    const deleteEmployee=()=>{
        fetch(`${base_url}employeeDelete/${id}`, {
            method: "DELETE",
            headers: {
                'Content-Type': "application/json",
                "Accept": 'application/json',

            }
        })
            .then((res) => res.json())
            .then((data) => {
                navigate("/admin/employees")
            });
    }
  return (
    <>
    <button className='btn btn-primary' onClick={handleOpen}   style={{ padding: "8px 16px", marginRight: "1rem" }}>Suspend</button>
    
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
          <h5 style={{textAlign:"center"}}>Are You sure!</h5>
          <h6 style={{textAlign:"center"}}>Do You Want Suspend Employee?</h6>
          <div style={{display:"flex",justifyContent:"center",marginTop:"1rem"}}>
          <button className='btn btn-primary' onClick={handleClose}  style={{ padding: "8px 16px", marginRight: "1rem" }}>Cancel</button>
          <button className='btn btn-danger' onClick={deleteEmployee}  style={{ padding: "8px 16px"}}>suspend</button>

          </div>
        </Box>
      </Modal>
    </>
  )
}

export default DeleteEmployee