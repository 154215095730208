import React, { useEffect, useState } from 'react'
import { useData } from '../../../ContextProvider/Context'
import { Link, useParams } from 'react-router-dom'
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { CiImport } from 'react-icons/ci'
function SupplierView() {
    const params = useParams()
    const { getSupplierIndiData, supplierIndiData, getFilterSupplierIndi } = useData()
    useEffect(() => {
        getSupplierIndiData(params.id)
    }, [])
    const handleDownloadCsv = () => {
        const csv = Papa.unparse([supplierIndiData]);

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'SupplierDetail.csv');
    };
    const [date, setdate] = useState({
        id: params.id,
        startDate: "",
        endDate: ""
    })

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentSuplierDetails = supplierIndiData?.slice(indexOfFirstItem, indexOfLastItem) || [];

    const totalPages = Math.ceil((supplierIndiData?.length || 0) / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }

    return (
        <>
            <div classname="row " style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                            <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Suppliers</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Detail</li>
                        </ol>
                    </nav>
                </div>
                <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                    <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                        <div className='startdate'>
                            <label style={{ fontWeight: "bold" }}>Select Start Date</label> <br />
                            <input type='date' name='start' onChange={(e) => setdate({ ...date, startDate: e.target.value })} className='form-control' />
                        </div>
                        <div className='startdate'>
                            <label style={{ fontWeight: "bold" }}>Select End Date</label> <br />
                            <input type='date' name='end' onChange={(e) => setdate({ ...date, endDate: e.target.value })} className='form-control' />
                        </div>
                        <button onClick={() => {
                            handlePageChange(1)
                            getFilterSupplierIndi(date.id, date.startDate, date.endDate)
                        }} className='btn btn-primary'>Search</button>
                    </div>
                    <div>
                        <button onClick={handleDownloadCsv} className='btn btn-primary' style={{ display: "flex", alignItems: "end", gap: "10px" }}><CiImport style={{ fontSize: "20px" }} />Export</button>
                    </div>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='card'>
                    <div className='card-body table-responsive recruitment'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Supplier</th>
                                    <th scope="col">Supplier Address</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentSuplierDetails?.map((item, idx) => {
                                    const address = item?.supplier?.bill_address + " " + item?.supplier?.bill_city + " " + item?.supplier?.bill_province + " " + item?.supplier?.bill_country + " " + item?.supplier?.bill_postal ? item?.supplier?.bill_address + " " + item?.supplier?.bill_city + " " + item?.supplier?.bill_province + " " + item?.supplier?.bill_country + " " + item?.supplier?.bill_postal : "-"
                                    const addressParts = [
                                        item?.supplier?.ship_address,
                                        item?.supplier?.ship_city,
                                        item?.supplier?.ship_province,
                                        item?.supplier?.ship_country,
                                        item?.supplier?.ship_postal
                                    ];
                                    const ship_address = addressParts.filter(part => part !== null).join(' '); console.log(ship_address);
                                    const date = item.date?.split(' ')
                                    return (
                                        <tr key={idx}>
                                            <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                            <td>
                                                {item.supplier?.name}
                                            </td>
                                            <td>{ship_address ? ship_address : address}</td>
                                            <td>
                                                {date[0]}
                                            </td>
                                            <td>
                                                {item.amount}
                                            </td>
                                            <td>
                                                {item.description}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div>
                            {paginationControls.map((button, index) => (
                                <React.Fragment key={index}>{button}</React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default SupplierView
