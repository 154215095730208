import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react'
import { CiEdit } from 'react-icons/ci'
import { FaPlus } from 'react-icons/fa'
import { RxCross2 } from 'react-icons/rx';
import { Link, useParams } from 'react-router-dom'
import { useData } from '../ContextProvider/Context';

function CompanyAddPage() {
  const params = useParams()
  const { getCompanyProfile, companyProfile, companyIdData, img_url, updateCompanyIdData, editCompany } = useData()
  useEffect(() => {
    getCompanyProfile(params?.id)
  }, [])
  useEffect(() => {
    setFormData(companyProfile?.data)
  }, [companyProfile])

  const [showRow1, setShowRow1] = useState(true);
  const [showRow2, setShowRow2] = useState(false);
  const [showRow3, setShowRow3] = useState(true);
  const [showRow4, setShowRow4] = useState(false);
  const [showRow5, setShowRow5] = useState(true);
  const [showRow6, setShowRow6] = useState(false);
  const [showRow7, setShowRow7] = useState(true);
  const [showRow8, setShowRow8] = useState(false);
  const [showRow9, setShowRow9] = useState(true);
  const [showRow10, setShowRow10] = useState(false);
  const [showRow11, setShowRow11] = useState(true);
  const [showRow12, setShowRow12] = useState(false);
  const [showRow13, setShowRow13] = useState(true);
  const [showRow14, setShowRow14] = useState(false);
  const [showRow15, setShowRow15] = useState(true);
  const [showRow16, setShowRow16] = useState(false);
  const [showRow17, setShowRow17] = useState(true);
  const [showRow18, setShowRow18] = useState(false);
  const [showRow19, setShowRow19] = useState(true);
  const [showRow20, setShowRow20] = useState(false);
  const [showRow21, setShowRow21] = useState(true);
  const [showRow22, setShowRow22] = useState(false);
  const [showRow23, setShowRow23] = useState(true);
  const [showRow24, setShowRow24] = useState(false);
  const [showRow25, setShowRow25] = useState(true);
  const [showRow26, setShowRow26] = useState(false);
  const [showRow27, setShowRow27] = useState(true);
  const [showRow28, setShowRow28] = useState(false);

  const handleEditClick = () => {
    setShowRow1(!showRow1);
    setShowRow2(!showRow2);
  };
  const handleEditClick1 = () => {
    setShowRow3(!showRow3);
    setShowRow4(!showRow4);
  };
  const handleEditClick2 = () => {
    setShowRow5(!showRow5);
    setShowRow6(!showRow6);
  };
  const handleEditClick3 = () => {
    setShowRow7(!showRow7);
    setShowRow8(!showRow8);
  };
  const handleCancelClick = () => {
    setShowRow1(true);
    setShowRow2(false);
    setSelectedImage(null);
    setFormData({
      ...formData,
      logo: null,
    });
  };
  const handleCancelClick1 = () => {
    setShowRow3(true);
    setShowRow4(false);
  };
  const handleCancelClick2 = () => {
    setShowRow5(true);
    setShowRow6(false);
  };
  const handleCancelClick3 = () => {
    setShowRow7(true);
    setShowRow8(false);
  };
  const handleEditClick4 = () => {
    setShowRow9(!showRow9);
    setShowRow10(!showRow10);
  };
  const handleCancelClick4 = () => {
    setShowRow9(true);
    setShowRow10(false);
  };
  const handleEditClick5 = () => {
    setShowRow11(!showRow11);
    setShowRow12(!showRow12);
  };
  const handleCancelClick5 = () => {
    setShowRow11(true);
    setShowRow12(false);
  };
  const handleEditClick6 = () => {
    setShowRow13(!showRow13);
    setShowRow14(!showRow14);
  };
  const handleCancelClick6 = () => {
    setShowRow13(true);
    setShowRow14(false);
  };
  const handleEditClick7 = () => {
    setShowRow15(!showRow15);
    setShowRow16(!showRow16);
  };
  const handleCancelClick7 = () => {
    setShowRow15(true);
    setShowRow16(false);
  };
  const handleEditClick8 = () => {
    setShowRow17(!showRow17);
    setShowRow18(!showRow18);
  };
  const handleCancelClick8 = () => {
    setShowRow17(true);
    setShowRow18(false);
  };
  const handleEditClick9 = () => {
    setShowRow19(!showRow19);
    setShowRow20(!showRow20);
  };
  const handleCancelClick9 = () => {
    setShowRow19(true);
    setShowRow20(false);
  };
  const handleEditClick10 = () => {
    setShowRow21(!showRow21);
    setShowRow22(!showRow22);
  };
  const handleCancelClick10 = () => {
    setShowRow21(true);
    setShowRow22(false);
  };
  const handleEditClick11 = () => {
    setShowRow23(!showRow23);
    setShowRow24(!showRow24);
  };
  const handleCancelClick11 = () => {
    setShowRow23(true);
    setShowRow24(false);
  };
  const handleEditClick12 = () => {
    setShowRow25(!showRow25);
    setShowRow26(!showRow26);
  };
  const handleCancelClick12 = () => {
    setShowRow25(true);
    setShowRow26(false);
  };
  const handleEditClick13 = () => {
    setShowRow27(!showRow27);
    setShowRow28(!showRow28);
  };
  const handleCancelClick13 = () => {
    setShowRow27(true);
    setShowRow28(false);
  };
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState('');

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    setInputValue(event.target.value);
  };
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    logo: null,
    timings: [],
  });
  console.log(formData)
  const [selectedImage, setSelectedImage] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Image = e.target.result;
      const parts = base64Image
      setSelectedImage(base64Image);

      setFormData({
        ...formData,
        logo: parts,
      });
    };
    reader.readAsDataURL(file);
  };

  const handleSaveFunc = () => {
    updateCompanyIdData(formData, params?.id);
  }
  const handleInputChange = (event, idx, key) => {
    const newTimings = [...formData.timings];
    newTimings[idx][key] = event.target.value;

    const startMinutes = parseInt(newTimings[idx].startTime.split(':')[0]) * 60 + parseInt(newTimings[idx].startTime.split(':')[1]);
    const endMinutes = parseInt(newTimings[idx].endTime.split(':')[0]) * 60 + parseInt(newTimings[idx].endTime.split(':')[1]);
    const totalMinutes = endMinutes - startMinutes;
    const hours = Math.floor(totalMinutes / 60);
    newTimings[idx].totalTime = parseInt(hours);

    setFormData({ ...formData, timings: newTimings });
  };
  useEffect(() => {
    if (companyIdData && companyIdData.timing) {
      const { timing } = companyIdData;
      setFormData((prevFormData) => ({
        ...prevFormData,
        timings: timing.map((t) => ({
          day: t.day,
          startTime: t.startTime,
          endTime: t.endTime,
          totalTime: t.totalTime,
        })),
      }));
    }
  }, [companyIdData]);

  return (
    <>
      <div className='companyCreateData'>
        <div className='card' >
          <div className='card-body'>
            <div className="row">
              <div className="col-2">
                {/* Tab navs */}
                <div className="nav flex-column nav-pills text-center" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <Link className="nav-link active" id="v-pills-link1-tab" data-mdb-toggle="pill" to="#v-pills-link1" role="tab" aria-controls="v-pills-link1" aria-selected="true">Company</Link>
                  <Link className="nav-link" id="v-pills-link3-tab" data-mdb-toggle="pill" to="#v-pills-link2" role="tab" aria-controls="v-pills-link2" aria-selected="false">Sales</Link>
                  <Link className="nav-link" id="v-pills-link4-tab" data-mdb-toggle="pill" to="#v-pills-link4" role="tab" aria-controls="v-pills-link4" aria-selected="false">Expenses</Link>
                  <Link className="nav-link" id="v-pills-link5-tab" data-mdb-toggle="pill" to="#v-pills-link5" role="tab" aria-controls="v-pills-link5" aria-selected="false">Time</Link>
                </div>
                {/* Tab navs */}
              </div>
              <div className="col-10">
                {/* Tab content */}
                <div className="tab-content" id="v-pills-tabContent">
                  <div className="tab-pane fade show active" id="v-pills-link1" role="tabpanel" aria-labelledby="v-pills-link1-tab">
                    <div className='row'>
                      <table className="table table-stripped">
                        <tbody>
                          <tr id='row1' className={`tableHoverRow ${showRow1 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">Company Name</th>
                            <td>
                              <div className='row'>
                                <div style={{ marginLeft: "1rem", marginBottom: "5px", height: "100px", width: "100px", border: "1px transparent" }}>
                                </div>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Company Name</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Legal Name</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Bussiness ID No.</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>VAT #</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div style={{ padding: "0", marginLeft: "1rem", marginBottom: "5px", height: "100px", width: "100px", border: "1px solid lightgray", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  <div style={{ height: "100px", width: "100px" }}>
                                    <img src={img_url + formData?.logo} width={"100%"} height={"100%"} alt='logo' />
                                  </div>
                                </div>
                                <div>
                                  <h6 style={{ fontSize: "14px" }}>{formData?.name}</h6>
                                  <h6 style={{ fontSize: "14px" }}>{formData?.name}</h6>
                                  <h6 style={{ fontSize: "14px" }}>11122334455</h6>
                                  <h6 style={{ fontSize: "14px" }}>standard</h6>
                                </div>
                              </div>
                            </td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick} /></td>
                          </tr>
                          <tr id='row2' className={`tableHoverRow ${showRow2 ? '' : 'd-none'}`} style={{ borderRadius: "10px", background: "#f3f8fe" }}>
                            <th scope="row">Company Name</th>
                            <td>
                              <div className='row'>
                                <div style={{ marginBottom: "5px", height: "100px" }}>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Company Logo</h5>
                                  <p style={{ padding: "0", margin: "0" }}>Shown on Sales forms and purchase orders.</p>

                                </div>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Company Name</h5>
                                  <p style={{ padding: "0", margin: "0" }}>Shown on Sales forms and purchase orders.</p><br />
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Legal Name</h5>
                                  <p style={{ padding: "0", margin: "0" }}>Shown on Sales forms and purchase orders.</p><br />
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Bussiness ID No.</h5><br />
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>VAT #</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div style={{ padding: "0", background: "#ddd", marginLeft: "1rem", marginBottom: "5px", height: "100px", width: "100px", border: "1px solid lightgray", display: "flex", justifyContent: "end", alignItems: "end" }}>
                                  <label htmlFor="filee" className='h-100'>
                                    {selectedImage ? (
                                      <img src={selectedImage ? selectedImage : img_url + formData?.logo} alt="Selected" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                    ) : (
                                      <FaPlus />
                                    )}
                                  </label>
                                  <input type="file" id="filee" style={{ display: "none" }} onChange={handleFileChange} />
                                </div>
                                <div>
                                  <div>
                                    <input className='form-control' value={formData?.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} type='text' />
                                  </div><br />
                                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <input style={{ height: "20px", width: "20px" }} type='checkbox' /> <label>Same as company name</label>
                                  </div><br /><br />
                                  <div>
                                    <input className='form-control' type='number' placeholder='99-111-11' />
                                  </div><br />
                                  <div>
                                    <input className='form-control' type='text' />
                                  </div>
                                </div>
                                <div style={{ marginTop: "1rem" }}>
                                  <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClick}>Cancel</button>
                                  <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} onClick={() => editCompany(formData)}> Save</button>
                                </div>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                          <tr id='typeRow' className={`tableHoverRow ${showRow3 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">Company Type</th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Tax Form</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Industry</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <h6 style={{ fontSize: "14px" }}>-</h6>
                                <h6 style={{ fontSize: "14px" }}>Technology/Telecomminication</h6>
                              </div>
                            </td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick1} /></td>
                          </tr>
                          <tr id='typeRow2' className={`tableHoverRow ${showRow4 ? '' : 'd-none'}`} style={{ borderRadius: "10px", background: "#f3f8fe" }}>
                            <th scope="row">Company Type</th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Tax Form</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Industry</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div>
                                  <select className='form-select w-75 mb-3'>
                                    <option>Sole properitor</option>
                                    <option>Partnership or limited laibility company</option>
                                    <option>Small bussiness corporation, two or more owners</option>
                                    <option>Corporation, one or more shareholders</option>
                                    <option>Nonprofit organization</option>
                                    <option>Limited laibility</option>
                                    <option>Not sure/Other/None</option>
                                  </select>
                                </div>
                                <div>
                                  <select className='form-select w-75 mb-3'>
                                    <option>Accomodation and Food Services</option>
                                    <option>Adminstrative and Support Services</option>
                                    <option>Arts and Recreation Services</option>
                                    <option>Construction/Builder</option>
                                    <option>Education and Training</option>
                                    <option>Farming,forestry and fishing </option>
                                    <option>Financial services & insurance</option>
                                    <option>Manufacturing</option>
                                    <option>Medical/Health Care/Community services</option>
                                    <option>Personal,Beauty,Wellbeing and other services</option>
                                  </select>
                                </div>
                                <div>
                                  <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClick1}>Cancel</button>
                                  <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} onClick={() => editCompany(formData)}> Save</button>
                                </div>
                              </div>
                            </td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow5 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">Company Info</th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Company Email</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Customer-facing emial</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Company Phone</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Website</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div>
                                  <h6 style={{ fontSize: "14px" }}>{formData?.email}</h6>
                                  <h6 style={{ fontSize: "14px" }}>{formData?.email}</h6>
                                  <h6 style={{ fontSize: "14px" }}>{formData?.phone}</h6>
                                  <h6 style={{ fontSize: "14px" }}>-</h6>
                                </div>
                              </div>
                            </td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick2} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow6 ? '' : 'd-none'}`} style={{ borderRadius: "10px", background: "#f3f8fe" }}>
                            <th scope="row">Company Info</th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Company Email</h5>
                                  <p style={{ padding: "0", margin: "0" }}>Where Quick books can contact you</p><br />
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Customer-facing emial</h5>
                                  <p style={{ padding: "0", margin: "0" }}>Where Quick books can contact you</p><br />
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Company Phone</h5>
                                  <p style={{ padding: "0", margin: "0" }}>SHown on Sales forms.</p><br />
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Website</h5>
                                  <p style={{ padding: "0", margin: "0" }}>SHown on Sales forms.</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div>
                                  <div>
                                    <input value={formData?.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} type='email' className='form-control' />
                                  </div><br />
                                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <input style={{ height: "20px", width: "20px" }} type='checkbox' /> <label>Same as company name</label>
                                  </div><br /><br />
                                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <input value={formData?.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} className='form-control' type='number' />
                                  </div><br /><br />
                                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <input className='form-control' type='text' />
                                  </div><br /><br />
                                </div>
                              </div>
                              <div style={{ marginTop: "1rem" }}>
                                <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClick2}>Cancel</button>
                                <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} onClick={() => editCompany(formData)} > Save</button>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow7 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">Address</th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Company Address</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Customer-facing address</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Legal Address</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div>
                                  <h6 style={{ fontSize: "14px" }}>{formData?.address}</h6>
                                  <h6 style={{ fontSize: "14px" }}>{formData?.address}</h6>
                                  <h6 style={{ fontSize: "14px" }}>{formData?.address}</h6>
                                </div>
                              </div>
                            </td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick3} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow8 ? '' : 'd-none'}`} style={{ borderRadius: "10px", background: "#f3f8fe" }}>
                            <th scope="row">Address</th>
                            <td style={{ width: "600px" }}>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Company Address</h5>
                                  <p style={{ padding: "0", margin: "0" }}>Address where your company is bassed.</p><br />
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Customer-facing address</h5>
                                  <p style={{ padding: "0", margin: "0" }}>Address where customers contact you or send payments. Shown on sales forms.</p>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Legal Address</h5>
                                  <p style={{ padding: "0", margin: "0" }}>Used for filling taxes.</p>
                                </div>
                              </div>
                            </td>
                            <td style={{ width: "500px" }}>
                              <div className='row'>
                                <div>
                                  <div>
                                    <input value={formData?.address} type='text' className='form-control mb-2' onChange={(e) => setFormData({ ...formData, address: e.target.value })} placeholder='Address' />
                                  </div><br />
                                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <input style={{ height: "20px", width: "20px" }} type='checkbox' /> <label>Same as company name</label>
                                  </div><br /><br />
                                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <input style={{ height: "20px", width: "20px" }} type='checkbox' /> <label>Same as company name</label>
                                  </div><br /><br />
                                </div>
                                <div style={{ marginTop: "1rem" }}>
                                  <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClick3}>Cancel</button>
                                  <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} onClick={() => editCompany(formData)}> Save</button>
                                </div>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                          <tr className='tableHoverRow' style={{ borderRadius: "10px" }}>
                            <th scope="row">Communications with Intiut</th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>
                                    <Link>
                                      View Privacy Statement
                                    </Link>
                                  </h5>
                                </div>
                              </div>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="v-pills-link2" role="tabpanel" aria-labelledby="v-pills-link2-tab">
                    <div className='row'>
                      <table className="table table-stripped">
                        <tbody>
                          <tr className={`tableHoverRow ${showRow9 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Sales form content</p>
                            </th>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Preferred invoice terms</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Preferred  delivery method</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Shipping</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Custom fields</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Custom transaction numbers</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Service data </p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Discount</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Diposit</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Tags</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px" }}>Net 30</p>
                                  <p style={{ fontSize: "14px" }}>None</p>
                                  <p style={{ fontSize: "14px" }}>Off</p>
                                  <p style={{ fontSize: "14px" }}></p>
                                  <p style={{ fontSize: "14px" }}>On</p>
                                  <p style={{ fontSize: "14px" }}>Off</p>
                                  <p style={{ fontSize: "14px" }}>Off</p>
                                  <p style={{ fontSize: "14px" }}>Off</p>
                                  <p style={{ fontSize: "14px" }}>On</p>
                                </div>
                              </div>
                            </td>
                            <td></td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick4} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow10 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Sales form content</p>
                            </th>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px", fontWeight: "600", paddingTop: "8px" }}>Preferred invoice terms</p><br />
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Preferred  delivery method</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Shipping</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Custom fields</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Custom transaction numbers</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Service data </p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Discount</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Diposit</p>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Tags</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div>
                                  <div class="btn-group " style={{ border: "1px solid" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <input className='form-control border-0' type='text' value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
                                      <select style={{ width: "20px", height: "35px", border: "0" }} value={selectedOption} onChange={handleSelectChange}>
                                        <option value={'Due on receipt'}>Due on receipt</option>
                                        <option value={'Net 15'}>Net 15</option>
                                        <option value={'Net 30'}>Net 30</option>
                                        <option value={'Net 60'}>Net 60</option>
                                      </select>
                                    </div>
                                  </div><br /><br />
                                  <div>
                                    <select className='form-select w-50'>
                                      <option hidden>method</option>
                                      <option value={'Print Later'}>Print Later</option>
                                      <option value={'Send Later'}>Send Later</option>
                                      <option value={'None'}>None</option>
                                    </select>
                                  </div><br />
                                  <div>
                                    <label className="switch">
                                      <input type="checkbox" defaultChecked />
                                      <span className="slider round" />
                                    </label>
                                  </div>
                                  <p style={{ fontSize: "14px" }}></p>
                                  <div>
                                    <label className="switch">
                                      <input type="checkbox" defaultChecked />
                                      <span className="slider round" />
                                    </label>
                                  </div>

                                  <div style={{ paddingTop: '16px' }}>
                                    <label className="switch">
                                      <input type="checkbox" defaultChecked />
                                      <span className="slider round" />
                                    </label>
                                  </div>
                                  <div style={{ paddingTop: '18px' }}>
                                    <label className="switch">
                                      <input type="checkbox" defaultChecked />
                                      <span className="slider round" />
                                    </label>
                                  </div>
                                  <div style={{ paddingTop: '20px' }}>
                                    <label className="switch">
                                      <input type="checkbox" defaultChecked />
                                      <span className="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div style={{ marginTop: "1rem" }}>
                                <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClick4}>Cancel</button>
                                <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} onClick={() => editCompany(formData)}> Save</button>
                              </div>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow11 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Invoice Payments</p>
                            </th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h6>Payment Instructions </h6>
                                  <p>Contact Samsaf to pay</p>
                                </div>
                              </div>
                            </td>
                            <td>
                            </td>
                            <td></td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick5} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow12 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Invoice Payments</p>
                            </th>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Payment Instructions</p>
                                  <p style={{ fontSize: "14px" }}>set the payment instuctions for all new invoices</p>
                                  <p className='mb-0' style={{ fontSize: '12px' }}>Payment Instuction</p>
                                  <TextArea
                                    style={{ height: '100px' }}
                                  />
                                  <div className='table-btns mt-2 d-flex gap-3'>
                                    <div style={{ borderRadius: '30px' }} className='btn btn-secondary' onClick={handleCancelClick5}>Cancel</div>
                                    <div style={{ borderRadius: '30px' }} className='btn btn-success'>Save</div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow13 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Product and Services</p>
                            </th>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Show Product/Services column sales forms</p>
                                  <p style={{ fontSize: "14px" }}>Show SKU column</p>
                                  <p style={{ fontSize: "14px" }}>Track quantity and price/rate</p>
                                  <p style={{ fontSize: "14px" }}>Track invrntor  quantity on hand</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p style={{ fontSize: "14px", fontWeight: "600" }}>-</p>
                              <p style={{ fontSize: "14px" }}>-</p>
                              <p style={{ fontSize: "14px" }}>-</p>
                              <p style={{ fontSize: "14px" }}>-</p>
                            </td>
                            <td></td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick6} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow14 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Product and services</p>
                            </th>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Show Product/Services column sales forms</p>
                                  <p style={{ fontSize: "14px" }}>Show SKU column</p>
                                  <p style={{ fontSize: "14px" }}>Track quantity and price/rate</p>
                                  <p style={{ fontSize: "14px" }}>Track invrntor  quantity on hand</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div>
                                  <label className="switch">
                                    <input type="checkbox" defaultChecked />
                                    <span className="slider round" />
                                  </label>
                                </div>

                                <div style={{ paddingTop: '18px' }}>
                                  <label className="switch">
                                    <input type="checkbox" defaultChecked />
                                    <span className="slider round" />
                                  </label>
                                </div>
                                <div style={{ paddingTop: '20px' }}>
                                  <label className="switch">
                                    <input type="checkbox" defaultChecked />
                                    <span className="slider round" />
                                  </label>
                                </div>

                                <div style={{ paddingTop: '20px' }}>
                                  <label className="switch">
                                    <input type="checkbox" defaultChecked />
                                    <span className="slider round" />
                                  </label>
                                </div>
                              </div>
                              <div style={{ marginTop: "1rem" }}>
                                <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClick6}>Cancel</button>
                                <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} > Save</button>
                              </div>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow15 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Processing Invocing</p>
                            </th>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Create multiple partial invoice from a single estimate</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p style={{ fontSize: "14px", fontWeight: "600" }}>-</p>
                            </td>
                            <td></td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick7} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow16 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Processing Invocing</p>
                            </th>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Create multiple partial invoice from a single estimate</p>
                                </div>
                              </div>
                              <div className='table-btns mt-2 d-flex gap-3'>
                                <div style={{ borderRadius: '30px' }} className='btn btn-secondary' onClick={handleCancelClick7}>Cancel</div>
                                <div style={{ borderRadius: '30px' }} className='btn btn-success'>Save</div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div>
                                  <div>
                                    <label className="switch">
                                      <input type="checkbox" defaultChecked />
                                      <span className="slider round" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td></td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick7} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow17 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Messages</p>
                            </th>
                            <td>
                              <p> Default email messages sent with sales forms </p>
                            </td>
                            <td></td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick8} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow18 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Messages</p>
                            </th>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Default email message sent with sales forms</p>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='d-flex gap-4 align-items-baseline'>
                                  <div className="form-check d-flex">
                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                      Use Greeting
                                    </label>
                                  </div>
                                  <select style={{ fontSize: '12px', width: '30%' }} className="form-select" aria-label="Default select example">
                                    <option selected>Dear</option>
                                    <option value={1}>To:</option>
                                    <option value={2}>Blank</option>
                                    <option value={3}>Three</option>
                                  </select>
                                  <select style={{ fontSize: '12px', width: '30%' }} className="form-select" aria-label="Default select example">
                                    <option selected>[Full Name]</option>
                                    <option value={1}>[Company][Name]</option>
                                    <option value={2}>[Title][Last]</option>
                                    <option value={3}>[First][Last]</option>
                                    <option value={4}>[Display][Name]</option>
                                  </select>
                                </div>
                                <div className='d-flex gap-2 mt-3'>
                                  <select style={{ fontSize: '12px', width: '30%' }} className="form-select" aria-label="Default select example">
                                    <option selected>Invoice</option>
                                    <option value={1}>Estimate</option>
                                    <option value={2}>Dredit Name</option>
                                    <option value={3}>Statement</option>
                                    <option value={4}>Sales receipt</option>
                                    <option value={5}>Refund receipt</option>
                                  </select>
                                  <div style={{ borderRadius: '30px' }} className='btn btn-success'>Use Standard Message</div>
                                </div>
                                <div className='mt-3'>
                                  <p style={{ fontSize: '14px', fontWeight: '600' }}>Email Subject Line</p>
                                  <input
                                    className='form-control'
                                    type='text'
                                    style={{ fontSize: '14px', width: '50%', borderRadius: '5px' }}
                                  />
                                </div>
                                <div className='mt-3'>
                                  <p style={{ fontSize: '14px', fontWeight: '600' }}>Email Message</p>
                                  <textarea style={{ width: '50%', borderRadius: '5px' }} />
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                      Email me a copy at
                                    </label>
                                  </div>
                                </div>
                                <div className='mt-3'>
                                  <p style={{ fontWeight: '600', fontSize: '14px' }}>Copy (Cc) new invoices to address</p>
                                  <input
                                    className='form-control'
                                    type='text'
                                    placeholder='Seperate multiple emails with the comma'
                                    style={{ fontSize: '14px', width: '50%', borderRadius: '5px', padding: '5px' }}
                                  />
                                </div>
                                <div className='mt-3'>
                                  <p style={{ fontWeight: '600', fontSize: '14px' }}>Blind Copy (BCc) new invoices to address</p>
                                  <input
                                    className='form-control'
                                    type='text'
                                    placeholder='Seperate multiple emails with the comma'
                                    style={{ fontSize: '14px', width: '50%', borderRadius: '5px', padding: '5px' }}
                                  />
                                </div>
                                <div className='mt-3'>
                                  <p>Sales from</p>
                                  <select style={{ fontSize: '12px', width: '30%' }} className="form-select" aria-label="Default select example">
                                    <option style={{ fontWeight: '600' }} selected>Estimate</option>
                                    <option value={1}>Invoice and other sales forms</option>
                                  </select>
                                  <textarea
                                    style={{ width: '50%', borderRadius: '5px', marginTop: '10px' }}
                                  />
                                </div>
                                <div className='table-btns mt-2 d-flex gap-3'>
                                  <div style={{ borderRadius: '30px' }} className='btn btn-secondary' onClick={handleCancelClick8}>Cancel</div>
                                  <div style={{ borderRadius: '30px' }} className='btn btn-success'>Save</div>
                                </div>
                              </div>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow19 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Online Delivery</p>
                            </th>
                            <td>
                              <p> Default email messages sent with sales forms </p>
                            </td>
                            <td></td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick9} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow20 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Online Delivery</p>
                            </th>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px" }}>Email options for all sales forms</p>
                                </div>
                                <div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                      Show short summary on email
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" defaultChecked />
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                      Show full details in  email
                                    </label>
                                  </div>
                                </div>
                                <div style={{ marginLeft: '13px' }} className="form-check">
                                  <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                                  <label className="form-check-label" htmlFor="flexCheckDefault">
                                    PDF Attached
                                  </label>
                                </div>
                                <div>
                                  <p>Additional email options for invoices</p>
                                  <select style={{ width: '50%', fontSize: '14px' }} className="form-select" aria-label="Default select example">
                                    <option selected>Online Invoices</option>
                                    <option value={1}>plain text</option>
                                  </select>
                                </div>
                                <div className='table-btns mt-2 d-flex gap-3'>
                                  <div style={{ borderRadius: '30px' }} className='btn btn-secondary' onClick={handleCancelClick9}>Cancel</div>
                                  <div style={{ borderRadius: '30px' }} className='btn btn-success'>Save</div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>-</p>
                                </div>
                              </div>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow21 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Statement</p>
                            </th>
                            <td>
                              <p> Showing ageing table at bottom of statment </p>
                            </td>
                            <td></td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick10} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow22 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Statement</p>
                            </th>
                            <td>
                              <div className='row'>
                                <>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                      List all transactions as a single line
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault2"
                                      defaultChecked=""
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                      List each transaction including all details line
                                    </label>
                                  </div>
                                </>
                                <div>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Show ageing table at bottom of Statement</p>
                                </div>
                                <div className='table-btns mt-2 d-flex gap-3'>
                                  <div style={{ borderRadius: '30px' }} className='btn btn-secondary' onClick={handleCancelClick10}>Cancel</div>
                                  <div style={{ borderRadius: '30px' }} className='btn btn-success'>Save</div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row mt-5'>
                                <div style={{ paddingTop: '10px' }}>
                                  <label className="switch">
                                    <input type="checkbox" defaultChecked />
                                    <span className="slider round" />
                                  </label>
                                </div>
                              </div>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                  {/* <div className="tab-pane fade" id="v-pills-link3" role="tabpanel" aria-labelledby="v-pills-link3-tab">
                     </div> */}
                  <div className="tab-pane fade" id="v-pills-link4" role="tabpanel" aria-labelledby="v-pills-link4-tab">
                    <div className='row'>
                      <table className="table table-stripped">
                        <tbody>
                          <tr className={`tableHoverRow ${showRow23 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">Bills and Expenses</th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Shows Items table on expense and purchase forms</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Show Tags field on example and purchase forms</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Track expenses and items by customer</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Make expenses and items billable</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Industry</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Default bill payment terms</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <h6 style={{ fontSize: "14px" }}>Off</h6>
                                <h6 style={{ fontSize: "14px" }}>Off</h6>
                                <h6 style={{ fontSize: "14px" }}>Off</h6>
                                <h6 style={{ fontSize: "14px" }}>Off</h6>
                                <h6 style={{ fontSize: "14px" }}>-</h6>
                              </div>
                            </td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick11} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow24 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">Bills and Expenses</th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Shows Items table on expense and purchase forms</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600", paddingTop: '10px' }}>Show Tags field on example and purchase forms</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600", paddingTop: '10px' }}>Track expenses and items by customer</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600", paddingTop: '10px' }}>Make expenses and items billable</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600", paddingTop: '10px' }}>Industry</h5>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600", paddingTop: '30px' }}>Default bill payment terms</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div>
                                  <label style={{ paddingTop: '15px' }} className="switch">
                                    <input type="checkbox" defaultChecked />
                                    <span className="slider round" />
                                  </label>
                                </div>
                                <div>
                                  <label style={{ paddingTop: '15px' }} className="switch">
                                    <input type="checkbox" defaultChecked />
                                    <span className="slider round" />
                                  </label>
                                </div>
                                <div>
                                  <label style={{ paddingTop: '15px' }} className="switch">
                                    <input type="checkbox" defaultChecked />
                                    <span className="slider round" />
                                  </label>
                                </div>
                                <div>
                                  <label className="switch">
                                    <input type="checkbox" defaultChecked />
                                    <span className="slider round" />
                                  </label>
                                </div>
                                <div>
                                  <div style={{ paddingTop: '10px' }}>
                                    <input type='checkbox' /> <label>Markup with a default rate of </label><div> <input type='number' placeholder='0.00' /> %</div>
                                  </div>
                                  <div style={{ paddingTop: '13px' }}>
                                    <input type='checkbox' /> <label>Track billable expenses and items as income </label>
                                    <div>
                                      <input type='radio ' /> <label>In a single account</label>
                                      <br /><br />
                                      <input type='radio ' /> <label>In a multiple accounts</label>
                                    </div>
                                  </div>
                                </div>
                                <div className='mt-2'>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Default bill payment terms</h5>
                                  <div class="btn-group " style={{ border: "1px solid" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <input className='form-control border-0' type='text' value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
                                      <select style={{ width: "20px", height: "35px", border: "none", borderRadius: '5px' }} value={selectedOption} onChange={handleSelectChange}>
                                        <option value={'Due on receipt'}>Due on receipt</option>
                                        <option value={'Net 15'}>Net 15</option>
                                        <option value={'Net 30'}>Net 30</option>
                                        <option value={'Net 60'}>Net 60</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div style={{ marginTop: "1rem" }}>
                                  <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClick11}>Cancel</button>
                                  <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} onClick={() => setShowRow2(false)}> Save</button>
                                </div>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow25 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">Purchase orders</th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Use purchase orders</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <h5 style={{ fontSize: "14px", fontWeight: "600" }}>-</h5>
                              </div>
                            </td>
                            <td></td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick12} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow26 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">Purchase orders</th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Use purchase orders</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className='row'>
                                <div>
                                  <label className="switch">
                                    <input type="checkbox" defaultChecked />
                                    <span className="slider round" />
                                  </label>
                                </div>
                                <div style={{ marginTop: "1rem" }}>
                                  <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClick12}>Cancel</button>
                                  <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} > Save</button>
                                </div>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow27 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">Messages</th>
                            <td>
                              <div className='row'>
                                <div>
                                  <h5 style={{ fontSize: "14px", fontWeight: "600" }}>Default email message sent with purchase orders</h5>
                                </div>
                              </div>
                            </td>
                            <td>
                            </td>
                            <td></td>
                            <td><CiEdit style={{ cursor: "pointer", fontSize: "20px" }} onClick={handleEditClick12} /></td>
                          </tr>
                          <tr className={`tableHoverRow ${showRow28 ? '' : 'd-none'}`} style={{ borderRadius: "10px" }}>
                            <th scope="row">
                              <p>Messages</p>
                            </th>
                            <td>
                              <div className='row'>
                                <div>
                                  <p style={{ fontSize: "14px", fontWeight: "600" }}>Default email message sent with sales forms</p>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='d-flex gap-4 align-items-baseline'>
                                  <div className="form-check d-flex">
                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                      Use Greeting
                                    </label>
                                  </div>
                                  <select style={{ fontSize: '12px', width: '30%' }} className="form-select" aria-label="Default select example">
                                    <option selected>Dear</option>
                                    <option value={1}>To:</option>
                                    <option value={2}>Blank</option>
                                    <option value={3}>Three</option>
                                  </select>
                                  <select style={{ fontSize: '12px', width: '30%' }} className="form-select" aria-label="Default select example">
                                    <option selected>[Full Name]</option>
                                    <option value={1}>[Company][Name]</option>
                                    <option value={2}>[Title][Last]</option>
                                    <option value={3}>[First][Last]</option>
                                    <option value={4}>[Display][Name]</option>
                                  </select>
                                </div>
                                <div className='d-flex gap-2 mt-3'>
                                  <select style={{ fontSize: '12px', width: '30%' }} className="form-select" aria-label="Default select example">
                                    <option selected>Invoice</option>
                                    <option value={1}>Estimate</option>
                                    <option value={2}>Dredit Name</option>
                                    <option value={3}>Statement</option>
                                    <option value={4}>Sales receipt</option>
                                    <option value={5}>Refund receipt</option>
                                  </select>
                                  <div style={{ borderRadius: '30px' }} className='btn btn-success'>Use Standard Message</div>
                                </div>
                                <div className='mt-3'>
                                  <p style={{ fontSize: '14px', fontWeight: '600' }}>Email Subject Line</p>
                                  <input
                                    className='form-control'
                                    type='text'
                                    style={{ fontSize: '14px', width: '50%', padding: '5px', borderRadius: '5px' }}
                                  />
                                </div>
                                <div className='mt-3'>
                                  <p style={{ fontSize: '14px', fontWeight: '600' }}>Email Message</p>
                                  <textarea style={{ width: '50%', padding: '5px', borderRadius: '5px' }} />
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" defaultValue id="flexCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                      Email me a copy at
                                    </label>
                                  </div>
                                </div>
                                <div className='mt-3'>
                                  <p style={{ fontWeight: '600', fontSize: '14px' }}>Copy (Cc) new invoices to address</p>
                                  <input
                                    className='form-control'
                                    type='text'
                                    placeholder='Seperate multiple emails with the comma'
                                    style={{ fontSize: '14px', width: '50%', padding: '5px', borderRadius: '5px' }}
                                  />
                                </div>
                                <div className='mt-3'>
                                  <p style={{ fontWeight: '600', fontSize: '14px' }}>Blind Copy (BCc) new invoices to address</p>
                                  <input
                                    className='form-control'
                                    type='text'
                                    placeholder='Seperate multiple emails with the comma'
                                    style={{ fontSize: '14px', width: '50%', padding: '5px', borderRadius: '5px' }}
                                  />
                                </div>
                                <div style={{ marginTop: "1rem" }}>
                                  <button className='btn btn-mute' style={{ marginRight: "10px", border: "1px solid lightgray", borderRadius: "30px" }} onClick={handleCancelClick13}>Cancel</button>
                                  <button className='btn btn-success' style={{ border: "1px solid lightgray", borderRadius: "30px" }} > Save</button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="v-pills-link5" role="tabpanel" aria-labelledby="v-pills-link5-tab">
                    <div className='row'>
                      <table className="table table-stripped">
                        <thead>
                          <tr>
                            <th>Day</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>Total Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formData?.timings.map((timing, idx) => (
                            <tr key={idx}>
                              <td>{timing.day}</td>
                              <td>
                                <input
                                  className='form-control'
                                  type="time"
                                  value={timing.startTime}
                                  onChange={(e) => handleInputChange(e, idx, 'startTime')}
                                />
                              </td>
                              <td>
                                <input
                                  className='form-control'
                                  type="time"
                                  value={timing.endTime}
                                  onChange={(e) => handleInputChange(e, idx, 'endTime')}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={timing.totalTime}
                                  readOnly
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div>
                        <button className='btn btn-success' style={{ borderRadius: "30px" }} onClick={() => editCompany(formData)}>Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div >
    </>
  )
}

export default CompanyAddPage
