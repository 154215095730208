import React, { useState } from 'react';
import { MdOutlineLocationCity } from 'react-icons/md'
import { MdOutlineAttachEmail } from 'react-icons/md'
import { FaPhoneAlt } from 'react-icons/fa'
import { MdLocationPin } from 'react-icons/md'
import { LuImagePlus } from 'react-icons/lu'
function Step1Component({ formData, handleChange }) {
  return (
    <div className="step-content row">
      <div className='col-12'>
        <div style={{ border: "none" }} className='row'>
          <div className='col-12'>
            <div style={{ border: '2px solid', height: "100px", borderRadius: "50%", width: "100px", position: "relative" }}>
              <label style={{ border: "1px solid", borderRadius: "50%", width: "25px", height: "25px", background: "lightgray", color: "gray", position: "absolute", right: "0%", paddingLeft: ".2rem", paddingBottom: ".2rem" }} htmlFor="companyLogo"><LuImagePlus /></label>
              <img style={{ width: "100%", height: "100%", borderRadius: "50%" }} src={formData?.logo} />
            </div>
            <input
              type="file"
              id="companyLogo"
              name="companyLogo"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleChange('logo', e.target.files[0])}
            />
          </div>
        </div>

      </div>
      <div className='col-6'>
        <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Company Name:<span style={{ color: "red" }}>*</span></label>

        <div className='row mt-1'>
          <div className='col-12'>
            <label htmlFor="companyName"><MdOutlineLocationCity /></label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              placeholder='Company Name'
              value={formData.name}
              onChange={(e) => handleChange('name', e.target.value)}
            />
          </div>
        </div>

      </div>

      <div className='col-6'>
        <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Company Email:<span style={{ color: "red" }}>*</span></label>

        <div className='row mt-1'>
          <div className='col-12'>
            <label htmlFor="companyEmail"><MdOutlineAttachEmail /></label>


            <input
              type="email"
              id="companyEmail"
              name="companyEmail"
              placeholder='Company Email'
              value={formData.email}
              onChange={(e) => handleChange('email', e.target.value)}
            />
          </div>
        </div>

      </div>
      <div className='col-6'>
        <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Company Phone:<span style={{ color: "red" }}>*</span></label>

        <div className='row mt-1'>
          <div className='col-12'>
            <label htmlFor="companyPhone"><FaPhoneAlt /></label>


            <input
              type="number"
              id="companyPhone"
              name="companyPhone"
              placeholder='Company Phone'

              value={formData.phone}
              onChange={(e) => handleChange('phone', e.target.value)}
            />
          </div>
        </div>

      </div>
      <div className='col-6'>
        <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Trading Address:</label>

        <div className='row mt-1'>
          <div className='col-12'>
            <label htmlFor="trading_addr"><MdLocationPin /></label>


            <input
              type="text"
              id="trading_addr"
              name="trading_addr"
              placeholder='Trading Address'

              value={formData.trading_addr}
              onChange={(e) => handleChange('trading_addr', e.target.value)}
            />
          </div>
        </div>

      </div>


      <div className='col-12'>
        <label style={{ fontSize: '14px', margin: '15px 0 0 15px' }}>Company Address:<span style={{ color: "red" }}>*</span></label>

        <div className='row mt-1'>
          <div className='col-12'>
            <label htmlFor="companyAddress"><MdLocationPin /></label>


            <input
              id="companyAddress"
              type="text"
              name="companyAddress"
              placeholder="Company Address"
              value={formData.address}
              onChange={(e) => handleChange('address', e.target.value)}
            />
          </div>
        </div>

      </div>

    </div>
  );
}

export default Step1Component;
