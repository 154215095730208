import React, { useEffect, useState } from 'react'
import { useData } from '../../../ContextProvider/Context'
import { Link, useParams } from 'react-router-dom'
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { CiImport } from 'react-icons/ci'
function ProfitAndLose() {
    const params = useParams()
    const { getProfitData, profitData, getFilterProfit } = useData()
    const [revenue, setRevenue] = useState(0)
    const [expanse, setExpanse] = useState(0)

    useEffect(() => {
        getProfitData(params.id)

    }, [])
    useEffect(() => {
        let totalRevenue = 0
        profitData?.revenues?.map((item) => {
            totalRevenue = item.debit + totalRevenue
        })
        setRevenue(totalRevenue)
    }, [profitData])
    useEffect(() => {
        let totalExpense = 0
        profitData?.expenses?.map((item) => {
            totalExpense = item.credit + totalExpense
        })
        setExpanse(totalExpense)
    }, [profitData])
    const [date, setdate] = useState({
        companyId: params.id,
        month: "",
        startDate: "",
        endddate: ""
    });
    const handleDownloadCsvExp = () => {
        const csv = Papa.unparse(profitData?.expenses);

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'Expanses.csv');
    };
    const handleDownloadCsvReve = () => {
        const csv = Papa.unparse(profitData?.revenues);

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'Reveneus.csv');
    };
    const [selectedDescription, setSelectedDescription] = useState('');
    const [showDescriptionOverlay, setShowDescriptionOverlay] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const visibleRevenues = profitData?.revenues?.filter(item => true) || [];

    const currentRevenues = visibleRevenues?.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(visibleRevenues?.length / itemsPerPage);

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button key={i} className='btn btn-primary p-2' style={{ margin: "0px 5px" }} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    const [currentPagep, setCurrentPagep] = useState(1);
    const itemsPerPagep = 10;

    const indexOfLastItemp = currentPagep * itemsPerPagep;
    const indexOfFirstItemp = indexOfLastItemp - itemsPerPagep;

    const visibleExpenses = profitData?.expenses?.filter(item => true) || [];


    const currentExpenses = visibleExpenses?.slice(indexOfFirstItemp, indexOfLastItemp);
    const totalPagesp = Math.ceil(visibleExpenses.length / itemsPerPagep);

    const handlePageChangep = page => {
        setCurrentPagep(page);
    };

    const paginationControlsp = [];
    for (let i = 1; i <= totalPagesp; i++) {
        paginationControlsp.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChangep(i)}>
                {i}
            </button>
        );
    }

    return (
        <div>
            <div className='services'>
                <div className='expense-header d-flex justify-content-between align-items-baseline'>
                    <div className='inp-buttons d-flex gap-2 w-100' style={{ alignItems: "end", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "end", gap: "10px", justifyContent: "space-between", width: "100%" }}>
                            <div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Profit & Loss</li>
                                    </ol>
                                </nav>

                            </div>
                            <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                                <div className='startdate'>
                                    <label style={{ fontWeight: "bold", fontSize: "10px" }}>Select Start Date</label> <br />
                                    <input type='date' name='start' onChange={(e) => setdate({ ...date, startDate: e.target.value })} className='form-control' />
                                </div>
                                <div className='endDate'>
                                    <label style={{ fontWeight: "bold", fontSize: "10px" }}>Select End Date</label> <br />
                                    <input type='date' name='end' onChange={(e) => setdate({ ...date, endddate: e.target.value })} className='form-control' />
                                </div>
                                <button onClick={() => {
                                    handlePageChangep(1)
                                    handlePageChange(1)
                                    getFilterProfit(date.startDate, date.endddate, date.companyId)
                                }} className='btn btn-primary'>Search</button>
                                <div style={{ display: "flex" }}>
                                    <button onClick={handleDownloadCsvExp} className='btn btn-primary' style={{ display: "flex", alignItems: "end", gap: "10px", marginRight: "10px" }}><CiImport style={{ fontSize: "20px" }} />Expance</button>
                                    <button onClick={handleDownloadCsvReve} className='btn btn-primary' style={{ display: "flex", alignItems: "end", gap: "10px" }}><CiImport style={{ fontSize: "20px" }} />Revenue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div>
                    <ul className="nav nav-tabs nav-fill mb-3" id="ex1" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active" id="ex2-tab-1" data-mdb-toggle="tab" href="#ex2-tabs-1" role="tab" aria-controls="ex2-tabs-1" aria-selected="true" style={{ fontSize: "15px", fontWeight: "bold" }}>Revenues</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="ex2-tab-2" data-mdb-toggle="tab" href="#ex2-tabs-2" role="tab" aria-controls="ex2-tabs-2" aria-selected="false" style={{ fontSize: "15px", fontWeight: "bold" }}>Expense</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="ex2-content">
                        <div className="tab-pane fade show active" id="ex2-tabs-1" role="tabpanel" aria-labelledby="ex2-tab-1">
                            <div className='card'>
                                <div className='card-body table-responsive recruitment'>
                                    <table class="table">
                                        <thead style={{ textAlign: 'center' }}>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: 'center' }}>
                                            {currentRevenues?.map((item, idx) => {
                                                const datetimeParts = item.date.split(' ');
                                                const date = datetimeParts[0]
                                                const truncatedDescription = item.description.slice(0, 20);
                                                const isDescriptionTruncated = item.description.length > 20;

                                                return (
                                                    < tr >
                                                        <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                        <td>{date}</td>
                                                        <td>
                                                            {isDescriptionTruncated ? (
                                                                <div
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setSelectedDescription(item.description);
                                                                        setShowDescriptionOverlay(true);
                                                                    }}
                                                                >
                                                                    {truncatedDescription}...
                                                                </div>
                                                            ) : (
                                                                item.description
                                                            )}
                                                            {showDescriptionOverlay && (
                                                                <div className="overlayy" style={{ background: "rgba(0,0,0,1)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                                    <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                                        <p style={{ color: "white" }}>{selectedDescription}</p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td>{item.debit?.toLocaleString()}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    {paginationControls.map((button, index) => (
                                        <React.Fragment key={index}>{button}</React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="ex2-tabs-2" role="tabpanel" aria-labelledby="ex2-tab-2">
                            <div className='card'>
                                <div className='card-body table-responsive recruitment'>
                                    <table class="table">
                                        <thead style={{ textAlign: 'center' }}>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: 'center' }}>
                                            {currentExpenses?.map((item, idx) => {
                                                const datetimeParts = item.date.split(' ');
                                                const date = datetimeParts[0]
                                                const truncatedDescription = item.description.slice(0, 20);
                                                const isDescriptionTruncated = item.description.length > 20;

                                                return (
                                                    < tr >
                                                        <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                        <td>{date}</td>
                                                        <td>
                                                            {isDescriptionTruncated ? (
                                                                <div
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setSelectedDescription(item.description);
                                                                        setShowDescriptionOverlay(true);
                                                                    }}
                                                                >
                                                                    {truncatedDescription}...
                                                                </div>
                                                            ) : (
                                                                item.description
                                                            )}
                                                            {showDescriptionOverlay && (
                                                                <div className="overlayy" style={{ background: "rgba(0,0,0,1)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                                    <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                                        <p style={{ color: "white" }}>{selectedDescription}</p>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td>{item.credit?.toLocaleString()}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    {paginationControlsp.map((button, index) => (
                                        <React.Fragment key={index}>{button}</React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className='card p-2'>
                    <div className='card-body'>
                        <div className='row'>
                            <h3>Total</h3>
                            <div style={{ display: "flex", gap: "20px" }}>
                                <div>
                                    <h3 style={{ fontSize: "14px", fontWeight: "bold" }}>Total Revenue</h3>
                                </div>
                                <div>
                                    <p className='leads'>{revenue.toLocaleString()}</p>
                                </div>
                            </div>
                            <div style={{ display: "flex", gap: "20px" }}>
                                <div>
                                    <h3 style={{ fontSize: "14px", fontWeight: "bold" }}>Total Expense</h3>
                                </div>
                                <div>
                                    <p className='leads'>{expanse.toLocaleString()}</p>
                                </div>
                            </div>
                            <div style={{ display: "flex", gap: "20px" }}>
                                <div>
                                    <h3 style={{ fontSize: "14px", fontWeight: "bold" }}>Total Profit</h3>
                                </div>
                                <div>
                                    <h3 style={{ fontSize: "14px", fontWeight: "bold" }}>{(revenue - expanse).toLocaleString()}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ProfitAndLose
